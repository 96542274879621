({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
{build} = require 'utils/container_helpers'
style = require './classroom.styl'
e = require('react-e/bind') style
events = require './events'
_ = require 'lodash'
{redirect} = require 'actions/routing'
{getLetters, mergeLetters} = require './normalization_helper'
classroomStudentActions = require 'actions/classroom_student'
permissionformsActions = require 'actions/permissionforms'
messagesActions = require 'actions/messages'

Card = createFactory require 'components/shared/card'
Button = createFactory require 'components/shared/button'
Modal = createFactory require 'components/shared/modal'
ClassroomEditStudent = createFactory require 'pages/teacher/classroom_edit_student'
ClassroomViewMentor = createFactory require 'pages/teacher/classroom_view_mentor'
ClassroomTable = createFactory require './classroom_table'
ClassroomLetterModal = createFactory require './classroom_letter_modal'
Upload = createFactory require './upload.coffee'

mapStateToProps = ({store: {classroom, classroomStudent, messagesForClassroom, permissionforms, session}}) ->
  {mentors = [], studentMentorGroups = [], students = []} = classroom.data
  studentMap = _.keyBy students, 'id'
  mentorMap = _.keyBy mentors, 'id'
  permissionformsMap = {}
  permissionformsMap = _.keyBy permissionforms.data, 'student' if permissionforms and permissionforms.data
  groups = studentMentorGroups.map ({mentor, student}) ->
    mentor: mentorMap[mentor]
    permission: permissionformsMap[student]
    student: studentMap[student]
  groups = _.sortBy groups, (group) -> group.student.displayName
  {data:
    {
      pendingStudents
      uploadErrors
      uploadMetrics
      uploadStatus
      validationStatus
    }
  } = classroomStudent

  classroom: classroom.data
  groups: groups
  mentorMap: mentorMap
  messages: messagesForClassroom
  pendingStudents: pendingStudents
  session: session?.data.login
  studentMap: studentMap
  uploadErrors: uploadErrors
  uploadMetrics: uploadMetrics
  uploadStatus: uploadStatus
  validationStatus: validationStatus


class Classroom extends Component

  constructor: (props) ->
    super()
    @state = detail: undefined, letters: undefined, tabIndex: 0


  componentDidMount: ->
    @props.dispatch messagesActions.fetchAllForClassroom @props.classroom
    @props.dispatch permissionformsActions.fetchall @props.classroom._id


  _addStudentRedirect: =>
    events.addingIndividual()
    @props.dispatch redirect '/classroom/students/add'


  _detailClose: =>
    @setState detail: null


  _detailShow: ({id, type}) =>
    if id?
      data = switch type
        when 'student' then @props.studentMap[id]
        when 'mentor' then @props.mentorMap[id]
        else throw new Error "#{t 'classroomUnitPage.unexpectedType', type: type}"
      @setState detail: data: data, type: type


  _hideUploadModal: =>
    @setState uploadModalVisible: no
    @props.dispatch classroomStudentActions.clearUploadErrors()


  _letterClose: =>
    @setState letters: null


  _letterShow: ({mentor, student, type}) =>
    studentLetters = getLetters student, @props.messages.data
    mentorLetters = getLetters mentor, @props.messages.data
    if (type is 'student' and studentLetters.length > 0) or
    (type is 'mentor' and mentorLetters.length > 0)
      @setState
        letters:
          both: mergeLetters studentLetters, mentorLetters
          mentor: mentorLetters
          student: studentLetters
        tabIndex: if type is 'student' then 0 else 1


  _showUploadModal: =>
    events.clickedUploadRoster()
    @setState uploadModalVisible: yes


  render: ->
    classroomIsActive = @props.classroom.status is 'active'
    Card {}, e '.card-content',
      if classroomIsActive
        e '.card-header',
          if @props.groups.length is 0
            e '.heading', t 'classroomPage.emptyMessage'
          e '.card-header-item',
            Button onClick: @_addStudentRedirect, size: 'large', type: 'regular',
              t 'classroomPage.addStudent'
      e '.card-body',
        if @state.detail? then @renderDetailModal()
        else if @state.letters?
          ClassroomLetterModal
            bothLetters: @state.letters.both
            penpal2Letters: @state.letters.mentor
            onClose: @_letterClose
            penpal1Letters: @state.letters.student
            tabIndex: @state.tabIndex
        else if @state.uploadModalVisible then @renderUpload()
        if @props.groups.length
          ClassroomTable
            classroom: @props.classroom
            detailShow: @_detailShow
            groups: @props.groups
            letterShow: @_letterShow
            messages: @props.messages


  renderDetailModal: =>
    props =
      classroom: @props.classroom
      classroomName: @props.classroom.name
      onClose: @_detailClose
      userId: @state.detail.data._id
    Modal
      onClose: => @_detailClose()
      Card {},
        e '.modal_card',
          if @state.detail.type is 'student'
            ClassroomEditStudent props
          else
            ClassroomViewMentor props


  renderUpload: (modal = yes) =>
    classroomEmpty = @props.classroom.students.length is 0
    Upload
      addStudents: =>
        events.uploadAddedStudents()
        @props.dispatch classroomStudentActions.uploadStudents @props.pendingStudents
        @_hideUploadModal()
      cancelAddStudents: =>
        events.uploadCanceledAdd()
        @props.dispatch classroomStudentActions.clearPendingStudents()
        @_hideUploadModal()
      classroomEmpty: @props.classroom.students.length is 0
      classroomName: @props.classroom.name
      modal: modal
      onClose: @_hideUploadModal
      pendingStudents: @props.pendingStudents
      uploadErrors: @props.uploadErrors
      uploadMetrics: @props.uploadMetrics
      uploadStatus: @props.uploadStatus
      validateStudents: (parsedStudents, onSuccess) =>
        @props.dispatch classroomStudentActions.validateStudents parsedStudents, onSuccess
      validationStatus: @props.validationStatus

Classroom.displayName = "Classroom"

module.exports = build
  component: Classroom
  mapStateToProps: mapStateToProps
