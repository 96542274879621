import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/button';
import ComponentHelpers from 'utils/component_helpers';
import TeacherApplicationForm from "./application-form";
const style = require('./application.styl');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
const _ = require('lodash');
const teacherApplicationService = require('services/teacher_application_service');
const countries = require('utils/countries.json');
import {formatPhoneNumber}  from 'react-phone-number-input/input'

const mapStateToProps = ({store: {user, sessions, classrooms, tenant}}) => ({
  tenant: tenant.data,
});

const StepWelcome = ({tenant, onClickStart}) => {
  return (
      <div>
        <h2>Welcome! We're glad you're here.</h2>
        <img alt="Logos for CricketTogether, TryEngineering Together, and the Virtual Mentoring Portal"
             src="https://cm-cdn.s3.amazonaws.com/crickettogether/images/CT-VMP-TET.png" />
        <p>
          Currently, our eMentoring Programs (CricketTogether, TryEngineering Together, and the Virtual
          Mentoring Portal) are accepting applications for participation from classrooms and mentoring
          organizations.
        </p>
        <p>
          By submitting this application, you are confirming that your participating mentees fall
          into <u>both</u> of the following categories:
        </p>
        <ul>
          <li>children ages 8-14</li>
          <li>based in the United States or Canada</li>
        </ul>
        <div className={cn('buttons')}>
          <Button enabled={true} onClick={onClickStart} type="regular">
            Start My Application
          </Button>
        </div>
        <p className={cn('italics')}>
          If the mentees you serve do not fall into the above categories, and you would like to be notified of
          future service expansions regarding age ranges or geographical regions, please send an
          email to <a href={`mailto:${tenant.contactEmail}`}>{tenant.contactEmail}</a>.
        </p>
      </div>
  );
};

const StepConfirmation = ({application, onClickBack, onClickSubmit, onChange, confirmationChecked}) => {
  const country = countries[application.country];
  return (
      <div>
        <h2>Ready to Submit?</h2>
        <div>Please take a moment to review and confirm that the following information is correct:</div>
        <div className={cn('container-scrolling')}>
          <section className={cn('applicant-info')}>
            <h3>Applicant Info</h3>
            <dl>
              <dt>Name:</dt>
              <dd>{application.firstname} {application.lastname}</dd>
              <dt>Email:</dt>
              <dd>{application.email}</dd>
              <dt>Role:</dt>
              <dd>{application.role_formatted}</dd>
            </dl>
          </section>
          <section className={cn('org-info')}>
            <h3>Organization/School Info</h3>
            <dl>
              <dt>Name:</dt>
              <dd>{application.orgname}</dd>
              <dt>Type:</dt>
              <dd>{application.orgtype_formatted.join(', ')}</dd>
              <dt>Address:</dt>
              <dd>{application.address}</dd>
              {['US', 'CA'].includes(application.country) &&
              <dd>{application.city}, {application.state} {application.zipcode}</dd>
              }
              {!['US', 'CA'].includes(application.country) &&
              <dd>{application.city}, {application.state}</dd>
              }
              <dd>{country}</dd>
              <dt>Website:</dt>
              <dd>{application.url}</dd>
              <dt>Phone:</dt>
              <dd>{formatPhoneNumber(application.phone)}</dd>
            </dl>
          </section>
          <section className={cn('mentee-info')}>
            <h3>Mentee Info</h3>
            <dl>
              <dt># Mentees:</dt>
              <dd>{application.num_mentees}</dd>
              <dt>Grades:</dt>
              <dd>{application.grades.sort().join(', ')}</dd>
              <dt>Title I Funding:</dt>
              <dd>{application.title1_formatted}</dd>
              <dt>Comments:</dt>
              <dd className={cn('comments')}>{application.about_mentees || 'none'}</dd>
            </dl>
          </section>
          <section className={cn('mentor-info')}>
            <h3>eMentor Info</h3>
            <dl>
              <dt>Has eMentors:</dt>
              <dd>{application.has_mentors_formatted}</dd>
              <dt>eMentor Type:</dt>
              {application.mentor_type_formatted.map(s => <dd key={s}>{s}</dd>)}
              <dt>Comments:</dt>
              <dd className={cn('comments')}>{application.about_mentors || 'none'}</dd>
            </dl>
          </section>
          <section className={cn('program-info')}>
            <h3>Program Info</h3>
            <dl>
              <dt>1:1 eMentoring:</dt>
              <dd>{application.model_formatted}</dd>
              <dt>Technology:</dt>
              {application.technology_formatted.map(s => <dd key={s}>{s}</dd>)}
              <dt>Focus:</dt>
              {application.focus_formatted.map(s => <dd key={s}>{s}</dd>)}
              <dt>Referred By:</dt>
              <dd>{application.referrer_formatted || 'N/A'}</dd>
              <dt>Comments:</dt>
              <dd className={cn('comments')}>{application.program_other || 'none'}</dd>
            </dl>
          </section>
        </div>
        <div className={cn('confirmation-checkbox', 'checkbox-wrapper')}>
          <input type="checkbox" id="confirmation" name="confirmation"
                 checked={confirmationChecked}
                 onChange={(ev) => onChange('confirmationChecked', ev.target.checked)} />
          <label htmlFor="confirmation">
                  <span>
                  Check this box to indicate that your application is ready to submit or <a
                      onClick={onClickBack}>click here to make changes</a>
                    </span>
          </label>
        </div>
        <div className={cn('buttons')}>
          <Button enabled={confirmationChecked} onClick={onClickSubmit} type="regular"
                  title={confirmationChecked ? undefined : 'Please check the box above, then click to submit'}>
            Submit my application
          </Button>
        </div>
      </div>
  );
};

const StepSubmitted = ({tenant}) => {
  return (
      <div className={cn('step-submitted')}>
        <h2>Thank you! Your application has been submitted.</h2>
        <p>
          What's Next? Our team will review your application and be in touch shortly. If deemed a good fit,
          here are the next steps to anticipate:
        </p>
        <div className={cn('next-steps')}>
          <div className={cn('next-step', 'video-chat')}>
            <div className={cn('circle')}>
              <span className={cn('icon', 'icon-video-chat')}/>
            </div>
            Video Call with Together Team
          </div>
          <div className={cn('next-step', 'program-consent-form')}>
            <div className={cn('circle')}>
              <span className={cn('icon', 'icon-write-letter')}/>
            </div>
            Program Consent Form
          </div>
          <div className={cn('next-step', 'training')}>
            <div className={cn('circle')}>
              <span className={cn('icon', 'icon-lessons')}/>
            </div>
            Virtual Training
          </div>
        </div>
        <p>
          If you have any questions or concerns, feel free to reach out to us at <a
            href={`mailto:${tenant.contactEmail}`}>{tenant.contactEmail}</a>
        </p>
      </div>
  );
}

class TeacherApplication extends React.Component {

  constructor(props) {
    super(props);

    let defaultApplication = {
      firstname: '',
      lastname: '',
      email: '',
      email2: '',
      orgtype: [],
      grades: [],
      title1: "no",
      country: 'US',
      mentor_type: [],
      has_mentors: '',
      about_mentees: '',
      about_mentors: '',
      referrer: '',
      technology: [],
      technology_other: '',
      program_other: '',
      focus: [],
      focus_other: '',
    };

    const sample1 = {
      firstname: 'Willy',
      lastname: 'Johnson',
      email: 'WillyKJohnson@dayrep.com',
      email2: 'WillyKJohnson@dayrep.com',
      role: 'teacher',
      orgtype: ['private'],
      orgname: 'Midvale School For the Gifted',
      url: 'https://bit.ly/3q8gia5',
      country: 'US',
      state: 'Alabama',
      city: 'Mobile',
      zipcode: '36608',
      address: '4227 Lonely Oak Drive',
      grades: ['3', '4', '5'],
      title1: "no",
      mentor_type: ['adult_to_youth'],
      has_mentors: 'yes',
      about_mentees: 'This is some information about the mentees',
      num_mentees: 30,
      about_mentors: 'This is some information about the mentors',
      technology: ['device', 'browser', 'highspeed'],
      referrer: 'search',
      program_other: 'These are program comments',
      focus: ['stem'],
    };

    const sample2 = {
      firstname: 'Havarti',
      lastname: 'Washington',
      email: 'havarti@cheesemuseumamsterdam.com',
      email2: 'havarti@cheesemuseumamsterdam.com',
      role: 'other',
      role_other: 'Chief Curdler',
      orgtype: ['public', 'charter', 'other'],
      orgtype_other: 'Museum',
      orgname: 'Amsterdam Cheese Museum',
      url: 'http://www.cheesemuseumamsterdam.com/',
      country: 'CA',
      state: 'Ontario',
      city: 'Toronto',
      address: 'Prinsengracht 112, 1015 EA',
      grades: ['6', '7', '8'],
      title1: "no",
      mentor_type: ['cross_age', 'adult_to_youth'],
      has_mentors: 'no',
      about_mentees: 'This is some information about the mentees',
      num_mentees: 25,
      about_mentors: 'This is some information about the mentors',
      technology: ['browser', 'highspeed', 'other'],
      technology_other: 'Cheese Planer',
      referrer: 'other',
      referrer_other: 'Cheddar McManus',
      program_other: 'These are program comments',
      focus: ['stem', 'cross', 'other'],
      focus_other: 'cheesemongering'
    };


    let application = defaultApplication;
    switch (props.params.sample_id) {
      case 'sample1':
        application = sample1;
        break;
      case 'sample2':
        application = sample2;
        break;
    }

    this.state = {
      application,
      step: 'welcome',
      confirmationChecked: false,
    };

    this.steps = ['welcome', 'application', 'confirmation', 'submitted'];
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.onConfirmSubmit = this.onConfirmSubmit.bind(this);
  }

  onChange(field, val) {
    this.setState(ComponentHelpers.onChangeHandler(field, val));
  }

  onClickBack() {
    this.setState({step: 'application', confirmationChecked: false});
  }

  onSubmitForm(data) {
    window.scrollTo(0, 0);
    data.orgtype_formatted = this.getFormatted(data, 'orgtype', 'optionsOrgType');
    data.technology_formatted = this.getFormatted(data, 'technology', 'optionsTechnology');
    data.mentor_type_formatted = this.getFormatted(data, 'mentor_type', 'optionsMentorType');
    data.focus_formatted = this.getFormatted(data, 'focus', 'optionsFocus');
    data.grades_formatted = this.getFormatted(data, 'grades', 'optionsGrades');
    data.role_formatted = this.getFormatted(data, 'role', 'optionsRole');
    data.referrer_formatted = this.getFormatted(data, 'referrer', 'optionsReferrer');
    data.title1_formatted = TeacherApplicationForm.optionsTitle1.find(e => e.value === data.title1).label;
    data.has_mentors_formatted = TeacherApplicationForm.optionsMentorSource.find(e => e.value === data.has_mentors).label;
    data.model_formatted = TeacherApplicationForm.optionsModel.find(e => e.value === data.model).label;
    if (data.model === 'no') {
      data.model_formatted += ` (${data.model_other})`;
    }
    this.setState({application: data, step: 'confirmation'});
  }

  getFormatted(data, field, optionsField) {
    const getLabelWithOther = (val) => {
      const entry = TeacherApplicationForm[optionsField].find((e) => e.value === val);
      let label = entry && entry.label;
      if (val === 'other') {
        label = label + ` (${data[`${field}_other`]})`;
      }
      return label;
    };

    if (Array.isArray(data[field])) {
      return data[field].map((val) => {
        return getLabelWithOther(val);
      });
    } else {
      const val = data[field];
      return getLabelWithOther(val);
    }
  }

  onConfirmSubmit() {
    this.state.application.phone = formatPhoneNumber(this.state.application.phone);
    teacherApplicationService.create(this.state.application).then((response) => {
      this.setState({step: 'submitted'});
    })
  }

  render() {
    const {tenant} = this.props;
    const step = this.state.step;

    return (
        <div className={cn('application-page')}>
          <h1>Program Application</h1>
          <div className={cn('container-centered')}>
            {step === 'welcome' &&
            <StepWelcome tenant={tenant} onClickStart={() => this.setState({step: 'application'})}/>
            }
            {step === 'application' &&
            <TeacherApplicationForm onSubmit={this.onSubmitForm} type='dark' application={this.state.application}/>
            }
            {step === 'confirmation' &&
            <StepConfirmation application={this.state.application}
                              confirmationChecked={this.state.confirmationChecked}
                              onChange={this.onChange} onClickBack={this.onClickBack}
                              onClickSubmit={this.onConfirmSubmit}/>
            }
            {step === 'submitted' &&
            <StepSubmitted tenant={tenant}/>
            }
          </div>
        </div>
    );
  }
}

TeacherApplication.displayName = "TeacherApplication";

TeacherApplication.propTypes = {
  tenant: PropTypes.object,
};

module.exports = build({
  component: TeacherApplication,
  mapStateToProps
});
