({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './action_items.styl'
e = require('react-e/bind') style
classNames = require 'classnames/bind'
bindStyles = classNames.bind style

ActionItem = createFactory require './action_item'

class ActionItems extends Component

  @propTypes:
    actionItems: PropTypes.arrayOf( PropTypes.shape
      actionText: PropTypes.string
      icon: PropTypes.string.isRequired
      text: PropTypes.string.isRequired
    ).isRequired
    dispatchRedirect: PropTypes.func.isRequired



  render: ->
    {actionItems, dispatchRedirect} = @props

    e '.action-items',
      e 'h2.title',
        'Action Items!'
        e 'span.badge', actionItems.length if actionItems.length
      e '.items',
        if actionItems.length
          actionItems.map (actionItem, i) ->
            e '.item', key: i,
              ActionItem
                actionItem: actionItem
                className: bindStyles 'action-item'
                dispatchRedirect: dispatchRedirect
                e '.text',
                  e 'span.icon', className: "icon-#{actionItem.icon}"
                  actionItem.text
                e '.action-text', actionItem.actionText ? 'Go'
        else
          e '.action-item', "Excellent! You've cleared your to-do list!"
      # added footer for scroll should only show if scroll will appear
      if actionItems.length > 6
        e '.footer'

ActionItems.displayName = "ActionItems"

module.exports = ActionItems
