ReactGa = require 'react-ga'


env = process.env.NODE_ENV or NODE_ENV
#when writing analytics code, replace line 7 with the following:
#if env in ['production', 'development']
if env in ['production','development']
  ReactGa.initialize GOOGLE_ANALYTICS_ID
  console.log "GA ID is #{GOOGLE_ANALYTICS_ID}"

module.exports =

  recordEvent: (event = {}) ->
    unless event.category and event.action
      throw new Error 'cannot send an event without category and action'
    ReactGa.event event


  recordPageView: ->
    ReactGa.set page: window.location.pathname
    ReactGa.pageview window.location.pathname


  setCustomDimension: (keyword, value) ->
    index = switch keyword
      when 'role' then 1
      when 'classroom' then 2
      when 'unit' then 3
      when 'roleHit' then 4
    args = {}
    args["dimension#{index}"] = value
    ReactGa.set args


  setUserID: (userId) ->
    ReactGa.set userId: userId
