({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
LetterModal = createFactory require 'components/letter_modal'
Modal = createFactory require 'components/shared/modal'
LetterCollection = createFactory require 'components/letter_collection'

class ClassroomLetterModal extends Component

  constructor: (props) ->
    super(props)
    @state =
      letterIndex: if @props.letterIndex? then @props.letterIndex else 0
      tabIndex: @props.tabIndex
    @tabIndex = @props.tabIndex


  @propTypes:
    bothLetters: PropTypes.arrayOf PropTypes.object
    penpal2Letters: PropTypes.arrayOf PropTypes.object
    onClose: PropTypes.func.isRequired
    penpal1Letters: PropTypes.arrayOf PropTypes.object
    tabIndex: PropTypes.number.isRequired
    penpal1Name: PropTypes.string
    penpal2Name: PropTypes.string


  isNextVisible: (hasLetters) =>
    total = hasLetters[0]
    if hasLetters.length is 2
      switch @state.tabIndex
        when 0
          total = @props.penpal1Letters
        when 1
          total = @props.penpal2Letters
        when 2
          total = @props.bothLetters
    @state.letterIndex <= (total.length - 2)


  nextClick: (e) =>
    e.stopPropagation()
    @setState letterIndex: @state.letterIndex + 1


  prevClick: (e) =>
    e.stopPropagation()
    @setState letterIndex: @state.letterIndex - 1


  render: =>
    hasLetters = [@props.penpal1Letters, @props.penpal2Letters].filter (letters) ->
      letters.length > 0
    modalProps =
      maxHeight: yes
      next:
        onClick: @nextClick
        visible: @isNextVisible hasLetters
      onClose: @props.onClose
      previous:
        onClick: @prevClick
        visible: @state.letterIndex isnt 0
      top: true
    if hasLetters.length is 2
      modalProps.tabViewProps =
        onSelect: (tabIndex) =>
          @setState {tabIndex}, =>
            setTimeout (=> @setState letterIndex: 0), 50
        selectedIndex: @state.tabIndex
        tabs: [
          content:
            LetterCollection
              letterIndex: @state.letterIndex
              letters: @props.penpal1Letters
          name: @props.penpal1Name or t 'classroomPage.student'
        ,
          content:
            LetterCollection
              letterIndex: @state.letterIndex
              letters: @props.penpal2Letters
          name: @props.penpal2Name or t 'classroomPage.penpal'
        ,
          content:
            LetterCollection
              letterIndex: @state.letterIndex
              letters: @props.bothLetters
          name: t 'classroomPage.both'
        ]
        withPadding: no

    if hasLetters.length is 2
      Modal modalProps
    else
      Modal modalProps,
        LetterCollection
          letterIndex: @state.letterIndex
          letters: hasLetters[0]

ClassroomLetterModal.displayName = "ClassroomLetterModal"

module.exports = ClassroomLetterModal
