import React from 'react';
import PropTypes from 'prop-types';
const {arrayOf, bool, element, func, oneOf, shape, string} = PropTypes;
const style = require('./unit_template_preview.styl');
const cn = require('classnames/bind').bind(style);
import ContentPreview from 'components/shared/content_preview';


class UnitTemplatePreview extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    return this.props.onClick(this.props.id);
  }

  render() {
    const links = <a className={cn('details-link')} onClick={this.props.onClick}>See Details →</a>;
    return (
        <ContentPreview {...this.props} links={links} type='unit'/>
    );
  }
}

UnitTemplatePreview.displayName = "UnitTemplatePreview";
UnitTemplatePreview.propTypes = {
  description: string.isRequired,
  id: string.isRequired,
  image: string.isRequired,
  metadata: arrayOf(shape({
        name: string,
        value: string
      })
  ),
  onClick: func.isRequired,
  scheme: (oneOf(['plain', 'shadow'])).isRequired,
  title: string.isRequired
};

module.exports = UnitTemplatePreview;
