import React from 'react';
import PropTypes from 'prop-types';
const {formatMetadata} = require('utils/article_normalizer');
const style = require('./article_preview.styl');
const cn = require('classnames/bind').bind(style);
import Button from 'components/shared/button';
import ContentPreview from 'components/shared/content_preview';
import EReader from 'components/shared/e_reader';
import ModalWrapper from 'components/shared/modal/modal_wrapper';
import { ReactReader } from 'react-reader';

class ArticlePreview extends React.Component {

  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth, height: window.innerHeight };
    this.modalRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight });
  }

  getDefaultDimensions() {
    const {width, height} = this.state;
    const isPortraitMode = height > width;

    let defaultMargin = Math.min(Math.round(height / 12), 100);
    let defaultHeight = height - 2 * defaultMargin;
    let defaultWidth = Math.round(defaultHeight * 0.75);

    if (isPortraitMode) {
      defaultWidth = width - defaultMargin * 2;
      defaultHeight = Math.round( defaultWidth / 0.75 );
    }

    return {defaultWidth, defaultHeight, defaultMargin};
  }

  render() {
    const {article, onClickRead,fileType = 'pdf'} = this.props;
    const {defaultWidth, defaultHeight, defaultMargin} = this.getDefaultDimensions();
    const onClickLink = this.props.onClickLink ? this.props.onClickLink : () => {
      onClickRead && onClickRead();
      this.modalRef.current.toggleModal();
    }

    const {subjects, strands, grade, lexile, filename, thumbnail, summary, title, age, author, id} = article;
    const trigger = <Button onClick={onClickRead} type='regular'>{t('articlePreview.readIt')}</Button>
    const links = (
        <div className={cn('article-links')}>
          <ModalWrapper ref={this.modalRef} eReader={true} trigger={trigger}>
            {fileType === 'pdf' &&
              <EReader filename={filename}/>
            }
            {fileType === 'epub' &&
              <div style={{width:`${defaultWidth}px`, height:`${defaultHeight}px`, margin: `${defaultMargin}px auto`}}>
                <ReactReader
                  url={filename}
                title={title}
                showToc={false}
                epubInitOptions={{openAs: 'epub'}}
              />
              </div>
            }
          </ModalWrapper>
          {this.props.disableMoreLink ||
          <a className={cn('details')} onClick={onClickLink}>{t('articlePreview.more')}</a>
          }
        </div>
    );

    return (
        <ContentPreview type='article' title={title} description={summary} image={thumbnail} links={links}
                        metadata={formatMetadata({subjects, strands, grade, lexile, age, author})} onClick={onClickLink}
                        {...this.props} />
    );
  }
}

ArticlePreview.displayName = "ArticlePreview";

const {arrayOf, bool, element, object, func, number, oneOf, shape, string} = PropTypes;
ArticlePreview.propTypes = {
  article: object.isRequired,
  focus: oneOf(['approved', 'disabled', 'pending', 'rejected', 'none']),
  onClickLink: func,
  onClickRead: func,
  scheme: (oneOf(['plain', 'shadow'])).isRequired,
  selectedArticleIds: arrayOf(string),
  selectType: oneOf(['radio', 'check']),
  isSelected: bool,
  onSelect: func,
  disableMoreLink: bool,
  imageSize: oneOf(['cover','contain'])
};

module.exports = ArticlePreview;
