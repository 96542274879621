_ = require 'lodash'

module.exports =

  formatMetadata: (article) ->
    formatted = []
    if article.subjects then formatted.push
      name: t('articlePreview.subjects')
      value: article.subjects.map((x) -> x.name).join(', ')

    if article.strands then formatted.push
      name: t 'articlePreview.strands'
      value: article.strands.map((x) -> x.name).join(', ')

    if article.grade then formatted.push
      name: t 'articlePreview.grade'
      value: article.grade

    if article.age then formatted.push
      name: t 'articlePreview.age'
      value: article.age

    if article.author then formatted.push
      name: t 'articlePreview.author'
      value: article.author

    if article.lexile then formatted.push
      name: t 'articlePreview.lexile'
      value: t 'articlePreview.lexileValue', lexile: article.lexile
    formatted

  formatValue: (value) ->
    if value in ['', undefined] then '-' else value


  getArticleSelection: (mySelections, classroomUnitID) ->
    selections = _.map mySelections, ({article_id, rejectReason, status, classroomUnit_id}) -> {
      id: article_id
      rejectReason
      status
      classroomUnit_id
    }
    .filter ({classroomUnit_id}) -> classroomUnit_id is classroomUnitID
    selections[0] or status: 'none'


  getScrollOffset: (unit, selection) ->
    if selection.status in ['approved', 'pending']
      _.sortBy(unit.content, 'lexile').findIndex (article) ->
        article._id is selection.id
    else yes


  getStudentId: (groups, id) ->
    group = groups.find ({mentor}) -> mentor is id
    group?.student


  selectable: (selection) ->
    selection.status in ['rejected', 'none']


  showDetail: ({articleId, redirectTo, unitTemplateId}) ->
    redirectTo "/library/#{unitTemplateId}/articles/#{articleId}"
