i18n = require 'i18n-js'
_ = require 'lodash'
en = require '../locales/en'

try
  tenantLocale = require "../locales/en_#{TENANT}"
catch
  tenantLocale = {}

i18n.translations.en = _.merge en, tenantLocale
i18n.locale = 'en'

module.exports = (string, options) ->
  i18n.t string, options
