import React from 'react';
import style from './header_nav_container.styl';
const cn = require('classnames/bind').bind(style);
import { build } from 'utils/container_helpers';
import _ from 'lodash';
import { redirect } from 'actions/routing';
import sessionActions from 'actions/session';
import messagesActions from 'actions/messages';
import classnames from 'classnames';
const {isMentor, isStudent, isTeacher, isCustomer, isUnauthenticated} = require('routing');
const events = require('./events');
const analytics = require('utils/analytics');
import UserHelpers from "utils/user_helpers";

import Login from 'components/shared/login';
import ModalWrapper from 'components/shared/modal/modal_wrapper';
import Header from 'components/shared/header_js';
import ClassroomSelector from 'containers/classroom_selector';
import UnitsDropdown from 'components/units_dropdown';

const mapStateToProps = ({store: {classroom, classrooms, mailCount, session, user, tenant}}) => ({
  classroom: classroom.data,
  classrooms: classrooms.data,
  classroomsLoading: classrooms.loading,
  mailCount: mailCount.data,
  session: session.data,
  sessionError: session.error,
  sessionLoading: session.loading,
  tenant: tenant.data,
  user: user.data,
});

class HeaderNavContainer extends React.Component {

  componentDidUpdate(prevProps) {
    const {session, dispatch} = this.props;
    if ( session && prevProps.session && session.login.id !== prevProps.session.login.id) {
      dispatch(messagesActions.fetchUnreadMailCount(session.login.id, session.classroomId));
    }
  }

  componentDidMount() {
    const {session, dispatch} = this.props;
    if (session) {
      analytics.setUserID(session.login);
      analytics.setCustomDimension('role', session.login.roles[0]);
      if (session.login.id) {
        return dispatch(messagesActions.fetchUnreadMailCount(session.login.id, session.classroomId));
      }
    }
  }

  onClickLogout() {
    const { dispatch } = this.props;
    events.loggedOut();
    dispatch(
        sessionActions.logout(() => {
          dispatch(redirect('/'))
        })
    );
  }

  onClickMyAccount() {
    const { dispatch } = this.props;
    return dispatch(redirect('/account'));
  }

  onClickMessages() {
    const { dispatch, session } = this.props;
    return dispatch(redirect('/mail', 0, () => {
      return analytics.setUserID(session.login.id);
    }));
  }

  onClickForum() {
    const { dispatch, session } = this.props;
    return dispatch(redirect('/forum', 0, () => {
      return analytics.setUserID(session.login.id);
    }));
  }

  onLoginSubmit(credentials) {
    const { dispatch } = this.props;
    events.clickedLogin();
    return dispatch(
        sessionActions.login(credentials, ({role, userId}) => {
          events.loginSuccess({role, userId});
          return dispatch(redirect('/'));
        })
    );
  }

  _getChildren() {
    const {
      session, sessionError, sessionLoading, tenant, location, classroom,
      classrooms, classroomsLoading, router, dispatch} = this.props;

    // Classroom Selector
    const classroomSelector = <div><span className={cn('label')}>Classrooms:</span><ClassroomSelector/></div>;

    // Contact us - suppressed for now for better Privo compliance...
    //    contactUs = e 'a', href: 'http://www.cricketmedia.com/contact-us', target: '_blank', 'Contact Us'

    // Login
    const loginLinkText = t('login.signIn');
    const logoutLinkText = t('login.signOut');
    const login = <ModalWrapper onExit={() => dispatch(sessionActions.clearError())}
                                trigger={<span>{loginLinkText}</span>}
                                show={location.query.show_login === 'true'}>
      <Login error={sessionError}
             loading={sessionLoading}
             loginText={loginLinkText}
             showRegLink={tenant.showRegistrationLink}
             tenant={tenant}
             onSubmit={(credentials) => this.onLoginSubmit(credentials)} />
    </ModalWrapper>;
    const disabledLogin = <div className={cn('disabled')}>{loginLinkText}</div>;

    // Help
    const contactEmail = tenant.contactEmail || 'support@crickettogether.com';
    const help = <a className={cn('help')} href={`mailto:${contactEmail}`}>Help</a>;

    // Logout
    const logout = <span onClick={() => this.onClickLogout()}>{logoutLinkText}</span>;

    // Messages
    const messages = <div className={cn('messages')}
                          onClick={() => this.onClickMessages()}>
      Messages {this._messageCount() !== '0' && <span className={cn('messages-count')}>{this._messageCount()}</span>}
    </div>;

    const myaccount = <div className={cn('myaccount')}
                         onClick={() => this.onClickMyAccount()}>
                        My Account
                    </div>;

    const forum = (tenant && tenant.enableForum) ? <span onClick={() => this.onClickForum()}>{'Forum'}</span> : null;

    if (isUnauthenticated(session)) {
      // Unauthenticated
      if (router.location.pathname !== '/') {
        return [disabledLogin];
      } else {
        return [login];
      }
    } else if (isMentor(session)) {
      // Mentor
      if (classroomsLoading || location.pathname.startsWith('/onboarding')) {
        return [ help, logout ];
      } else if( classrooms && classrooms.length > 1) {
          if (classroom && classroom.status === 'completed') {
            return [classroomSelector, help, logout];
          }
          else {
            return [classroomSelector, messages, forum, help, logout];
          }
      } else if (classrooms && classrooms.length === 1) {
          if (classroom && classroom.status === 'completed') {
            return [help, logout];
          }
          else {
            return [messages, forum, help, logout];
          }
      } else {
        return [help, logout]
      }
    } else if (isStudent(session)) {
      // Student
      return [logout];
    } else if (isTeacher(session)) {
      // Teacher
      if (classrooms && classrooms.length > 1) {
        return [messages, forum, classroomSelector, help, logout];
      } else {
        return [messages, forum, help, logout];
      }
    }
    else {
      return [logout];
    }
  }

  _getLinks() {
    const {session, classroom, location} = this.props;
    const classroomLink = this._makeLink({name: t('headerNav.classroom'), route: '/classroom'});
    const home = this._makeLink({name: t('headerNav.home'), route: '/'});
    const letterCenter = this._makeLink({name: t('headerNav.letterCenter'), route: '/letters'});
    const library = this._makeLink({name: t('headerNav.library'), route: '/library'});
    const units = this._makeLink({name: t('headerNav.focusArticle'), route: '/units'});
    const teacherUnits = this._makeLink({hoverElement: <UnitsDropdown />, name: t('headerNav.teacherUnits'), route: '/units'});
    const resources = this._makeLink({name: t('headerNav.resources'), route: '/resources'});
    const browseEbooks = this._makeLink({name: t('headerNav.browseEbooks'), route: '/ebooks/browse'});
    const searchEbooks = this._makeLink({name: t('headerNav.searchEbooks'), route: '/ebooks/search'});

    let navLinks = [];

    if (location.pathname.startsWith('/onboarding')) {
      return [];
    }

    if (classroom && isTeacher(session)) {
      navLinks =  [home, classroomLink, teacherUnits, library, resources];
    } else if (classroom && (isStudent(session) || isMentor(session))) {
      const introUnit = classroom && classroom.currentUnit.isDefaultUnit;
      const reflectionUnit = classroom && classroom.currentUnit.isReflectionUnit;

      navLinks =  introUnit || reflectionUnit ?
        [home, letterCenter, library, resources]
      :
        [home, units, letterCenter, library, resources];
    } else if (isCustomer(session)) {
      navLinks = [browseEbooks];
    }

    if (navLinks.length && this.props.router.location.pathname === '/') {
      navLinks[0].active = true; // mark home page as active
    }

    return navLinks;
  }

  _makeLink({hoverElement, name, route}) {
    const { router, dispatch } = this.props;
    const {pathname} = router.location;

    return {
      active: (pathname === route) || _.startsWith(pathname, `${route}/`),
      hoverElement,
      name,
      onClick: () => {
        events.navigated(name);
        return dispatch(redirect(route));
      }
    };
  }


  _messageCount() {
     return this.props.mailCount || '0';
   }

  // Normally we'd do a dispatch(redirect('/')) to redirect to the app home.
  // Instead we change the window.location.href to cause the app to remount
  // and reload data to potentially pick up any changes in user state.  The
  // specific case prompting this was the email verification flow:
  //
  //    1. In browser window A, user logs in and is redirected to
  //       /onboarding/verify to verify email
  //    2. User clicks on link to send verification email
  //    3. User clicks verification email in email message
  //    4. New browser window B opens with confirmation that email is verified
  //    5. In window B user can click to go to home page successfully
  //    6. In window A user clicks app logo to go to home page, but app has
  //       not reloaded updated user with emailVerified == true so app redirects
  //       back to /onboarding/verify again.
  //
  // In general it seems helpful to reload data when the logo is clicked as
  // that may often be an effort to "reset" and start at the beginning when using
  // the app.

  onClickLogo() {
    window.location.href = '/';
  }

  render() {
    const {session, tenant, classroom} = this.props;

    let logoUrl = `/assets/${TENANT}/logo.svg`;
    let logoName = tenant.name || 'CricketTogether';
    if (tenant.pageHeaderConfig && tenant.pageHeaderConfig.cmLogo) {
      logoUrl = tenant.pageHeaderConfig.cmLogo.url || logoUrl;
      logoName = tenant.pageHeaderConfig.cmLogo.name || logoName;
    }

    if (classroom && classroom.pageHeaderConfig && classroom.pageHeaderConfig.cmLogo) {
      logoUrl = classroom.pageHeaderConfig.cmLogo.url || logoUrl;
      logoName = classroom.pageHeaderConfig.cmLogo.name || logoName;
    }

    const impersonate = session && session.impersonate;
    const className = classnames({
      mentor: isMentor(session),
      student: isStudent(session),
      teacher: isTeacher(session),
      unauthenticated: isUnauthenticated(session)
    });

    return (
        <div className={cn('header-nav', {impersonate: session && session.impersonate})}>
          {session && session.impersonate &&
          <div className={cn('impersonate-bar', impersonate)}>
            <span className={cn('message')}>
              {t('impersonateBar.warningMessage')}
              <span className={cn('username')}>{impersonate}</span>
            </span>
          </div>
          }
          <Header logoUrl={logoUrl}
                  logoName={logoName}
                  children={this._getChildren()}
                  className={className}
                  links={this._getLinks()}
                  onClickLogo={() => this.onClickLogo()}/>
        </div>
    );
  }
}

HeaderNavContainer.displayName = "HeaderNavContainer";

module.exports = build({
  component: HeaderNavContainer,
  mapStateToProps
});
