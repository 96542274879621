import React from 'react';
import PropTypes from 'prop-types';
import UserHelpers from "utils/user_helpers";
const style = require('./onboarding.styl');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
import LoadingSpinner from 'components/shared/loading_spinner';
import usersActions from 'actions/users';
import {redirect} from 'actions/routing';

const mapStateToProps = ({store}) => {
  const {session, sessions, user, classrooms, tenant} = store;
  return {
    user: UserHelpers.getTransformedUser(user.data, sessions.data, classrooms.data, tenant.data),
    session: session.data,
    sessions: sessions.data,
    classrooms: classrooms.data,
    tenant: tenant.data,
  };
};

class MentorHome extends React.Component {

  componentDidMount() {
    let {session, user, classrooms, sessions, location, tenant, dispatch} = this.props;
    const userId = session.login.id;

    dispatch(usersActions.checkOnboardedStatus(user.id, (updatedUser) => {
      if (updatedUser) {
        user = UserHelpers.getTransformedUser(user, sessions, classrooms, tenant);
      }

      const serviceRecord = _.find(user.cm_services, ['service', TENANT]);
      const sessionsOnboarded = (serviceRecord.appState && serviceRecord.appState.onboarded) || {};
      const requiresOnboarding = sessions.filter((session) => {
        const onboarded = sessionsOnboarded[session.id];
        return session.status === 'active' && !onboarded;
      });
      // const activeClassrooms = (classrooms && classrooms.length) ? classrooms.find(x => x.status === 'active') : [];

      // A mentor could have been onboarded, and then unmatched from a student.  In this case we want the mentor
      // to be taken back to the completed onboarding screen until they are matched again.  Check if there any
      // classrooms that a mentor is in but does not have a matching student.  If so we'll redirect to /onboarding

      let requiresMatching = [];
      if (classrooms.length > 0) {
        requiresMatching = classrooms.filter( x => !x.studentMentorGroups.find( smg => smg.mentor === user.id));
      }

      if (!user.emailVerified) {
        dispatch(redirect('/onboarding/verify'));
      } else if (requiresOnboarding.length > 0 || requiresMatching.length > 0 || classrooms.length === 0 ) {
        dispatch(redirect('/onboarding'));
      } else if (classrooms.length > 0) {
        const classroom = classrooms[0];
        const matchingStudent = classroom.studentMentorGroups.find(x => x.mentor === user.id);
        if (matchingStudent) {
          dispatch(redirect('/classroom'));
        }
      }
    }));
  }

  render() {
    return <LoadingSpinner />;
  }
}

MentorHome.displayName = "MentorHome";

MentorHome.propTypes = {
  user: PropTypes.object,
};


module.exports = build({
  component: MentorHome,
  mapStateToProps
});
