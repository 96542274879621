const style = require('./error_card.styl');
const cn = require('classnames/bind').bind(style);
const {Component, Fragment} = require('react');

const Card = require('components/shared/card');
const Button = require('components/shared/button')



class ErrorCard extends Component {

    constructor(props) {
        super(props)
        this.state = {showErrorDetails: false};
    }

    render() {
        const email = this.props.email || 'support@crickettogether.com';
        const errorMsg = this.props.error instanceof Error ? this.props.error.message : this.props.error;
        let errorStack = null;

        if (this.props.errorStack) {
            errorStack = this.props.errorStack.split("\n").map((e,i) => <div key={i} className={cn('stack-trace-line')}>{e}</div>);
        }

        return (
            <Card>
                <div className={cn('error-card')}>
                    <h1 className={cn('title')}>{t('errorCard.title')}</h1>
                    <div className={cn('steps')}>
                        <h2 className={cn('steps-title')}>{t('errorCard.steps')}</h2>
                        <ul className={cn('steps-list')}>
                            <li className={cn('step')}>{t('errorCard.step1')}</li>
                            <li className={cn('step')}>{t('errorCard.step2')}</li>
                            <li className={cn('step')}>{t('errorCard.step3')}</li>
                            <li className={cn('step')}>{t('errorCard.step4')}</li>
                        </ul>
                    </div>
                    <p className={cn('contact')}>
                        {t('errorCard.contact1')}
                        <span className={cn('email')}><a href={'mailto:'+email}>{email}</a>.</span>
                    </p>
                    <div className={cn('details')}>
                        <Button
                            onClick={() => this.setState({showErrorDetails: !this.state.showErrorDetails})}
                            size='small' type='regular' mode='primary-inverted'>
                            <span className={cn('details-btn',{'show':this.state.showErrorDetails})}>
                                {this.state.showErrorDetails ? t('errorCard.hideErrBtn') : t('errorCard.showErrBtn')}
                            </span>
                        </Button>
                        {this.state.showErrorDetails &&
                            <Fragment>
                                <div className={cn('error')}>Error: {errorMsg}</div>
                                {errorStack && <div className={cn('stack-trace')}>{t('errorCard.stackTrace')}{errorStack}</div>}
                            </Fragment>
                        }
                    </div>
                </div>
            </Card>
        );
    }
}

ErrorCard.displayName = "ErrorCard";
module.exports = ErrorCard;
