_ = require 'lodash'
api = require '../api'
{makeAction} = require 'utils/action_helpers'
classroomUnitsService = require 'services/classroom_units_service'
classroomsActions = require 'actions/classrooms'
moment = require 'moment'

classroomUnitsActions =
  create: (data, callback) -> makeAction (setState) ->
    classroomUnitsService.create(data)
      .then(if callback? then callback)
      .catch (error) ->
        setState 'classroomUnits', error: error.toString()


  delete: (id, callback) -> makeAction (setState, dispatch, getState) ->
    classroomUnitsService.delete(id)
      .then ->
        setState 'classroomUnit', data: {}, loading: false
        dispatch classroomsActions.loadCurrentClassroom callback
      .catch (error) ->
        setState 'classroomUnit', error: error.statusText, loading: false
        #TODO: delete lines below once the DELETE response comes back normal (with type and code)
        setState 'classroomUnit', data: undefined, loading: false
        dispatch classroomsActions.loadCurrentClassroom callback


  find: (id, callback) -> makeAction (setState, dispatch, getState) ->
    classroomUnit = _.find getState().classroom.data.units, (unit) -> unit._id is id
    if classroomUnit
      setState 'classroomUnit', data: classroomUnit, loading: false
      return callback?(classroomUnit)
    dispatch classroomUnitsActions.findFromAPI id, callback


  findFromAPI: (id, callback) -> makeAction (setState) ->
    setState 'classroomUnit', loading: true
    classroomUnitsService.find(id)
      .then ({data: classroomUnit}) ->
        setState 'classroomUnit', data: classroomUnit, loading: false
        callback?()
      .catch (error) ->
        setState 'classroomUnit', error: error.statusText, loading: false


  setLocked: (isLocked, id) -> makeAction (setState) ->
    setState ['unitLocked', 'data', id], isLocked


  startUnit: (data, callback) -> makeAction (setState, dispatch, getState) ->
    {messages, messagesForClassroom} = getState()
    updatedMessagesForClassroom = _.assign {}, messagesForClassroom.data, messages.data
    setState ['messagesForClassroom', 'data'], updatedMessagesForClassroom
    setState ['pastUnitsActions', 'data'], {}
    payload = _.assign {}, data,
      startDate: moment().utc().format()
      state: 'active'
    dispatch classroomUnitsActions.update payload, ->
      dispatch classroomsActions.update _id: data.classroomID, currentUnit: data._id, ->
        setState ['classroom', 'data', 'currentUnit'], payload
        dispatch classroomsActions.loadCurrentClassroom ->
          dispatch classroomUnitsActions.findFromAPI data._id, ->
            callback?()


  update: (data, callback) -> makeAction (setState) ->
    setState 'classroomUnit', loading: yes
    classroomUnitsService.update(data)
      .then ->
        setState 'classroomUnit', data: undefined, loading: no
        callback?()
      .catch (error) ->
        setState 'classroomUnit', error: error.statusText, loading: no


module.exports = classroomUnitsActions
