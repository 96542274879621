({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
e = require 'react-e'
{build} = require 'utils/container_helpers'
_ = require 'lodash'
sessionActions = require 'actions/session'
classroomsActions = require 'actions/classrooms'
ContentLoader = createFactory require 'components/shared/content_loader'

mapStateToProps = ({store}) -> {store}

class ContentLoaderContainer extends Component

  @defaultProps:
    showSpinner: true

  @propTypes:
    getContentFromStore: PropTypes.func.isRequired
    getError: PropTypes.func.isRequired
    getLoading: PropTypes.func.isRequired
    getReady: PropTypes.func.isRequired
    icon: PropTypes.string
    loadContent: PropTypes.func.isRequired
    name: PropTypes.string
    renderChild: PropTypes.func.isRequired
    showSpinner: PropTypes.bool


  componentDidMount: ->
    content = @props.getContentFromStore @props.store
    @props.loadContent content unless @props.getReady content


  render: ->
    content = @props.getContentFromStore @props.store
    ContentLoader
      error: @props.getError content
      icon: @props.icon
      loading: @props.getLoading content
      name: @props.name
      ready: @props.getReady content
      renderChild: => @props.renderChild content, @state
      showSpinner: @props.showSpinner

ContentLoaderContainer.displayName = "ContentLoaderContainer"

module.exports = build {
  component: ContentLoaderContainer
  mapStateToProps
}
