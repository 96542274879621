{createFactory} = require 'react'
_ = require 'lodash'
{build} = require 'utils/container_helpers'
{addInDates, validateDates} = require './helpers'
events = require 'pages/teacher/units/events'
routingActions = require 'actions/routing'
unitPreviewActions = require 'actions/unit_preview'
classroomsActions = require 'actions/classrooms'
classroomUnitsActions = require 'actions/classroom_units'
moment = require 'moment'
UnitPreviewBody = createFactory require './unit_preview_body.jsx'
ContentLoaderContainer = createFactory require 'containers/content_loader_container'


mapStateToProps = ({store: {classroom}}) -> {classroom}

UnitPreviewNew = (props) ->
  {sample, dispatch} = props
  classroomId = props.classroom.data._id
  {template_id} = props.params
  currentUnit = props.classroom.data.currentUnit

  ContentLoaderContainer
    getContentFromStore: ({unitTemplate}) -> {unitTemplate}
    getError: ({unitTemplate}) -> unitTemplate.error
    getLoading: ({unitTemplate}) -> unitTemplate.loading
    getReady: ({unitTemplate}) -> unitTemplate.data? and unitTemplate.data._id is template_id
    loadContent: ->
      dispatch unitPreviewActions.find template_id
    name: 'unit'
    renderChild: ({unitTemplate}) ->
      if sample
        classroomUnit =
          startDate: moment().add( 1, 'days')
          endDate: moment().add(6, 'weeks')
          content: unitTemplate.data.content
          unitDates:
            focusArticleDate: moment().add( 1, 'weeks')
            mentorDate: moment().add(2, 'weeks')
            studentDate: moment().add(3, 'weeks')
            mentor2Date: moment().add( 4, 'weeks')
            student2Date: moment().add( 5, 'weeks')

      UnitPreviewBody _.assign {}, props,
        allowAttachments: if currentUnit? then currentUnit.allowAttachments else yes
        buttonContent: 'Save Unit',
        buttonIsEnabled: ({articleMap, dates, isDefaultUnit, isReflectionUnit}) ->
          validateDates(dates) and
            ((_.filter(_.values articleMap).length > 0) or isDefaultUnit or isReflectionUnit)
        buttonOnClickWithState: ({allowAttachments, articleMap, dates}) ->
          payload =
            allowAttachments: allowAttachments
            classroomID: classroomId
            content: _.filter _.values articleMap
            name: unitTemplate.data.name
            parent: unitTemplate.data._id
            state: 'pending'
            unitDates: {}
          addInDates payload, dates
          dispatch classroomUnitsActions.create payload, ->
            dispatch classroomsActions.loadCurrentClassroom ->
              events.savedUnit 'new'
              dispatch routingActions.redirect '/units'
        datePickerIsEditable: true
        datePickerShouldOpenFirstPicker: true
        datePickerShouldShow: true
        datePickerShouldStartWithToday: true
        descriptionHeader:  t 'unitPreviewEdit.chooseArticles'
        descriptionText: t 'unitPreviewEdit.description'
        showChecks: true
        classroomUnit: classroomUnit
        unitTemplate: unitTemplate.data

UnitPreviewNew.displayName = "UnitPreviewNew"

module.exports = build {
  component: UnitPreviewNew
  mapStateToProps
}
