import React from 'react';
import PropTypes from 'prop-types';

const _ = require('lodash');
const style = require('./application.styl');
const cn = require('classnames/bind').bind(style);
import ComponentHelpers from 'utils/component_helpers';
import Button from 'components/shared/button';
import CountryPicker from 'components/shared/country_picker';
import RegionPicker from 'components/shared/region_picker';
import Input from 'react-phone-number-input/input';

export default class TeacherApplicationForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      application: Object.assign({}, props.application),
      validationErrors: {},
    }

    let minDate = new Date();
    minDate.setDate(minDate.getDate() + 7);
    this.minDate = minDate.toISOString().split('T')[0];
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 365*10);
    this.maxDate = maxDate.toISOString().split('T')[0];
  }

  onChange(field, val) {
    this.setState(ComponentHelpers.onChangeHandler(field, val));
    if (['application.title1', 'application.has_mentors', 'application.model'].includes(field)) {
      const validationErrors = this.checkFormValidation(null, field.substr(12));
      this.setState({validationErrors});
    }
    if (field === 'application.email2') {
      const email2El = document.getElementById('email2');
      email2El.setCustomValidity('');
    }
    if (field === 'application.phone') {
      const phoneEl = document.getElementById('phone');
      phoneEl.setCustomValidity('')
    }
  }

  onSubmit(ev) {
    const {onSubmit} = this.props;

    const email2 = document.getElementById('email2');
    if (this.state.application.email !== this.state.application.email2) {
      email2.setCustomValidity('Emails do not match');
    } else {
      email2.setCustomValidity('');
    }
    const phoneEl = document.getElementById('phone');
    if (this.state.application.phone.length !== 12) {
      phoneEl.setCustomValidity('Please enter a valid phone number');
    } else {
      phoneEl.setCustomValidity('');
    }

    ev.preventDefault();
    const form = document.forms[0];
    if (form.reportValidity()) {
      const validationErrors = this.checkFormValidation();
      this.setState({validationErrors});

      const invalidFields = _.keys(_.pickBy(validationErrors)).sort();
      if (invalidFields.length === 0) {
        const data = Object.assign({}, this.state.application);
        onSubmit(data)
      } else {
        const scrollToId = invalidFields[0];
        const elem = document.getElementById(scrollToId).closest('section').previousSibling;
        elem.scrollIntoView({behavior: 'smooth'});
      }
    }
    return false;
  }

  onToggleCheckbox(field, val, checked) {
    this.setState((prevState) => {
      const application = prevState.application;
      const values = application[field] || [];
      if (checked && !values.includes(val)) {
        values.push(val);
      } else if (!checked && values.includes(val)) {
        const index = values.indexOf(val);
        values.splice(index, 1);
      }
      const newApplication = Object.assign(application, {
        [field]: values,
      });
      const validationErrors = this.checkFormValidation(field);
      return {application: newApplication, validationErrors};
    });
  }

  renderCheckboxList(fieldName, options) {
    const className = fieldName.replace('_', '-');
    const applicationState = this.state.application;
    return (
        <div id={fieldName} className={cn('checkbox-list', className)}>
          {options.map((x) => {
            const id = `${fieldName}_${x.value}`;
            return (
                <div className={cn('checkbox-wrapper')} key={id}>
                  <input type="checkbox" id={id} value={x.value}
                         checked={applicationState[fieldName].includes(x.value)}
                         onChange={(ev) => this.onToggleCheckbox(fieldName, x.value, ev.target.checked)}/>
                  <label htmlFor={id}>{x.label}</label>
                </div>
            );
          })}
          {this.state.validationErrors[fieldName] &&
          <React.Fragment>
            <p className={cn('error', 'visible')}>
              Please select at least one of the options above
            </p>
          </React.Fragment>
          }
        </div>
    );
  }

  renderRadioButtons(fieldName, options) {
    const className = fieldName.replace('_', '-');
    const applicationState = this.state.application;
    return (
        <div id={fieldName} className={cn('radio-list', className)}>
          {options.map((x) => {
            const id = `${fieldName}_${x.value}`;
            return (
                <div className={cn('radio-wrapper')} key={id}>
                  <input type="radio" name={fieldName} id={id} value={x.value}
                         checked={applicationState[fieldName] === x.value}
                         onChange={(ev) => this.onChange(`application.${fieldName}`, ev.target.value)}/>
                  <label htmlFor={id}>{x.label}</label>
                </div>
            );
          })}
          {this.state.validationErrors[fieldName] &&
          <p className={cn('error', 'visible')}>
            Please select one of the options above.
          </p>
          }
        </div>

    );
  }

  checkFormValidation(checkboxField = null, radioField = null) {
    const checkboxLists = checkboxField ? [checkboxField] : ['orgtype', 'grades', 'mentor_type', 'focus', 'technology'];
    const radioLists = radioField ? [radioField] : ['title1', 'has_mentors', 'model'];
    const validationErrors = Object.assign({}, this.state.validationErrors);

    checkboxLists.forEach((fieldName) => {
      const className = fieldName.replace('_', '-');
      const checkboxes = document.querySelectorAll(`.${cn(className)} input[type="checkbox"]`);
      const checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
      validationErrors[fieldName] = !checkedOne;
    });

    radioLists.forEach((fieldName) => {
      const className = fieldName.replace('_', '-');
      const radioButtons = document.querySelectorAll(`.${cn(className)} input[type="radio"]`);
      const checkedOne = Array.prototype.slice.call(radioButtons).some(x => x.checked);
      validationErrors[fieldName] = !checkedOne;
    })

    return validationErrors;
  }

  render() {
    const {type, error, success, showDemographics} = this.props;
    const self = TeacherApplicationForm;
    const applicationState = this.state.application;

    const formClassName = (type === 'dark') ? 'form-dark' : 'form-light';
    const Star = () => showDemographics ? <b>*</b> : null;
    const enableSubmit = true;
    const autoComplete = Date.now(); // Weird hack to ensure autoComplete never appears in Chrome

    return (
        <form className={cn('application-form', formClassName)} onSubmit={(event) => {
          return this.onSubmit(event)
        }}>
          <div className={cn('error', {visible: !!error})}>{error || "Error in form data"}</div>
          <div className={cn('success', {visible: !!success})}>{success}</div>

          <h3>Applicant Info</h3>
          <section className={cn('applicant-info')}>
            <label htmlFor="firstname">First Name<Star/></label>
            <input name="firstname" id="firstname" placeholder="Enter your first name" autoFocus={true}
                   required value={applicationState.firstname} minLength="1" autoComplete={autoComplete}
                   onChange={(ev) => this.onChange('application.firstname', ev.target.value)}/>

            <label htmlFor="lastname">Last Name<Star/></label>
            <input placeholder="Enter your last name" id="lastname"
                   required value={applicationState.lastname} autoComplete={autoComplete}
                   onChange={(ev) => this.onChange('application.lastname', ev.target.value)}
                   minLength="1"/>

            <label htmlFor="email">Email Address<Star/></label>
            <input placeholder="Enter your email address" id="email" type="email"
                   onChange={(ev) => this.onChange('application.email', ev.target.value)}
                   required value={applicationState.email} autoComplete={autoComplete} minLength="6"/>

            <label htmlFor="email2">Re-Enter Your Email Address:<Star/></label>
            <input placeholder="Re-Enter your email address to verify" id="email2" type="email"
                   onChange={(ev) => this.onChange('application.email2', ev.target.value)}
                   required value={applicationState.email2} autoComplete={autoComplete} minLength="6"/>

            <label className={cn('question')} htmlFor="role">
              Which of the following best describes your role at your organization/school?<Star/>
              <i>If none apply, please select 'Other' and use the open text field to tell us more about your role.</i>
            </label>
            <div className={cn('role')}>
              <select value={applicationState.role} required
                      onChange={(ev) => this.onChange('application.role', ev.target.value)}>
                <option value="">-- Select your role --</option>
                {TeacherApplicationForm.optionsRole.map((option) => {
                  return (
                    <option value={option.value} key={option.value}>{option.label}</option>
                  );
                })}
              </select>
              {applicationState.role === "other" &&
              <input id="role_other" placeholder="Tell us more about your role here" className={cn('other')}
                     required value={applicationState.role_other || ''} minLength="1"
                     onChange={(ev) => this.onChange('application.role_other', ev.target.value)}/>
              }
            </div>
          </section>

          <h3>Organization/School Info</h3>
          <section className={cn('org-info')}>
            <label className={cn('question')} htmlFor="orgtype">
              Tell us more about your organization/school:<Star/>
              <i>Check all that apply</i>
            </label>
            <div id="orgtype" className="orgtype">
              {this.renderCheckboxList('orgtype', self.optionsOrgType)}
              {applicationState.orgtype.includes('other') &&
              <React.Fragment>
                <input placeholder="Please explain your type of organization here" id="orgtype_other"
                       className={cn('other')} autoComplete={autoComplete}
                       required value={applicationState.orgtype_other || ""} minLength="1"
                       onChange={(ev) => this.onChange('application.orgtype_other', ev.target.value)}/>
              </React.Fragment>
              }
            </div>

            <label htmlFor="orgname">Org/School Name<Star/></label>
            <input placeholder="Enter your organization/school name" id="orgname"
                   required value={applicationState.orgname} minLength="1"
                   onChange={(ev) => this.onChange('application.orgname', ev.target.value)}/>

            <label htmlFor="url">Org/School Website<Star/></label>
            <input placeholder="Enter your organization/school website" id="url"
                   required value={applicationState.url} minLength="1"
                   onChange={(ev) => this.onChange('application.url', ev.target.value)}/>

            <label htmlFor="phone">Org/School Phone Number<Star/></label>
            <Input placeholder="Enter your organization/school phone number" id="phone"
                   required value={applicationState.phone} country="US"
                   onChange={(val) => this.onChange('application.phone', val)}/>

            <label htmlFor="country">Org/School Country<Star/></label>
            <CountryPicker className={cn('country', 'value')}
                           required value={applicationState.country} prompt={false}
                           countries={['US', 'CA']}
                           onChange={(countryCode) => this.onChange('application.country', countryCode)}/>

            <label htmlFor="address">Street Address<Star/></label>
            <input id="address" placeholder="Enter your organization/school street address"
                   required value={applicationState.address} minLength="1" autoComplete={autoComplete}
                   onChange={(ev) => this.onChange('application.address', ev.target.value)}/>

            <label htmlFor="city">City<Star/></label>
            <input id="city" placeholder="Enter your city"
                   required value={applicationState.city} minLength="1" autoComplete={autoComplete}
                   onChange={(ev) => this.onChange('application.city', ev.target.value)}/>

            <label htmlFor="state">{applicationState.country === 'US' ? 'State' : 'Province'}<Star/></label>
            <RegionPicker className={cn('state')}
                          required value={applicationState.state}
                          country={applicationState.country}
                          onChange={(regionName) => this.onChange('application.state', regionName)}/>

              <label htmlFor="zipcode">Zipcode</label>
              <input id="zipcode" placeholder="Enter your zipcode" type={this.state.country === 'US' ? 'number' : 'text'}
                     value={applicationState.zipcode} minLength="1" autoComplete={autoComplete}
                     onChange={(ev) => this.onChange('application.zipcode', ev.target.value)}/>
          </section>

          <h3>Mentee Info</h3>
          <section className={cn('mentee-info')}>
            <label className={cn('question')} htmlFor="grades">
              What grade(s) are your participating mentees in?<Star/>
              <i>Check all that apply</i>
            </label>
            {this.renderCheckboxList('grades', self.optionsGrades)}

            <label className={cn('question')} htmlFor="num_mentees">How many mentees will be participating?<Star/></label>
            <input id="num_mentees" placeholder="Enter # of mentees" type="number" min="1"
                   required autoComplete={autoComplete}
                   value={applicationState.num_mentees} minLength="1"
                   onChange={(ev) => this.onChange('application.num_mentees', ev.target.value)}/>

            <label className={cn('question')} htmlFor="title1">Does your organization receive Title I funding?<Star/></label>
            {this.renderRadioButtons('title1', self.optionsTitle1)}

            <label className={cn('question')} htmlFor="about_mentees">
              Share a little bit about your participating mentees
              (e.g. demographic information; access needs; English-language fluency, etc.):
            </label>
            <textarea id="about_mentees" placeholder=""
                      className={cn('about-mentees')} autoComplete={autoComplete}
                      value={applicationState.about_mentees} minLength="1"
                      onChange={(ev) => this.onChange('application.about_mentees', ev.target.value)}/>
          </section>

          <h3>eMentor Info</h3>
          <section className={cn('mentor-info')}>
            <label className={cn('question')} htmlFor='mentor-source'>Do you have volunteers who would serve as eMentors
              to your mentees?<Star/></label>
            {this.renderRadioButtons('has_mentors', self.optionsMentorSource)}

            <label className={cn('question')} htmlFor='mentor-type'>
              What type of eMentoring would you like to provide for your mentees?<Star/><i>Check all that apply</i>
            </label>
            {this.renderCheckboxList('mentor_type', self.optionsMentorType)}

            <label className={cn('question')} htmlFor='about-mentors'>
              Share anything else you'd like us to know about your current or desired eMentors:
            </label>
            <textarea id="about-mentors" className={cn('about-mentors')} autoComplete={autoComplete}
                      value={applicationState.about_mentors} minLength="1"
                      onChange={(ev) => this.onChange('application.about_mentors', ev.target.value)}/>
          </section>

          <h3>Program Info</h3>
          <section className={cn('program-info')}>
            <label className={cn('question')} htmlFor='mentor-source'>
              Our platform and program models are currently designed to support 1:1 eMentoring (i.e. one eMentor, per
              mentee). Are you seeking a 1:1 eMentoring solution?<Star/>
            </label>
            <div className={cn('model')}>
              {this.renderRadioButtons('model', self.optionsModel)}
              {applicationState.model === 'no' &&
              <React.Fragment>
                <input placeholder="Please share more about your program model" id="model_other" className={cn('other')}
                       value={applicationState.model_other || ""} minLength="1" required
                       onChange={(ev) => this.onChange('application.model_other', ev.target.value)}/>
              </React.Fragment>
              }
            </div>

            <label className={cn('question')} htmlFor='technology'>
              Describe your mentees' device access and connectivity:<Star/><i>Check all that apply</i>
            </label>
            <div className={cn('technology')}>
              {this.renderCheckboxList('technology', self.optionsTechnology)}
              {applicationState.technology.includes('other') &&
              <React.Fragment>
                <input placeholder="Please explain" id="technology_other" className={cn('other')}
                       value={applicationState.technology_other || ""} minLength="1" required
                       onChange={(ev) => this.onChange('application.technology_other', ev.target.value)}/>
              </React.Fragment>
              }
            </div>

            <label className={cn('question')} htmlFor='focus'>
              Indicate which of the following content collections would be of interest for your program implementation:<Star/>
              <i>Check all that apply</i>
            </label>
            <div className={cn('focus')}>
              {this.renderCheckboxList('focus', self.optionsFocus)}
              {applicationState.focus.includes('other') &&
              <React.Fragment>
                <input placeholder="Please explain" id="focus_other" className={cn('other')}
                       value={applicationState.focus_other || ""} minLength="1" required
                       onChange={(ev) => this.onChange('application.focus_other', ev.target.value)}/>
              </React.Fragment>
              }
            </div>

            <label className={cn('question')} htmlFor='referrer'>How did you hear about our eMentoring
              programs?</label>
            <div className={cn('referrer')}>
              <select value={applicationState.referrer}
                      onChange={(ev) => this.onChange('application.referrer', ev.target.value)}>
                <option value="">-- Select an option --</option>
                {TeacherApplicationForm.optionsReferrer.map((option) => {
                  return (
                      <option value={option.value} key={option.value}>{option.label}</option>
                  );
                })}
              </select>
              {applicationState.referrer === "other" &&
              <input id="referrer_other" placeholder="Please explain" className={cn('other')}
                     required value={applicationState.referrer_other || ''} minLength="1"
                     onChange={(ev) => this.onChange('application.referrer_other', ev.target.value)}/>
              }
            </div>

            <label className={cn('question')} htmlFor="program-other">Share anything else you'd like us to know:</label>
            <textarea id="program-other"
                      className={cn('program-other')} autoComplete={autoComplete}
                      value={applicationState.program_other} minLength="1"
                      onChange={(ev) => this.onChange('application.program_other', ev.target.value)}/>

          </section>
          <div className={cn('instructions')}>{t('register.required')}</div>

          <Button enabled={enableSubmit} submit={true} type="regular">
            Continue
          </Button>
          <div className={cn('instructions')}>
            Cricket Media will protect your data, subject to our&nbsp;
            <a href="https://cricketmedia.com/privacy/" target="_blank">Privacy Policy</a>.
          </div>
        </form>
    );
  }
}

TeacherApplicationForm.displayName = "TeacherApplicationForm";
TeacherApplicationForm.propTypes = {
  application: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['light', 'dark']).isRequired,
  showDemographics: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

TeacherApplicationForm.optionsRole = [
  {value: 'teacher', label: 'Teacher'},
  {value: 'mpc', label: 'Mentoring Program Coordinator'},
  {value: 'other', label: 'Other'},
];

TeacherApplicationForm.optionsMentorType = [
  {value: 'adult_to_youth', label: 'Adult to Youth'},
  {value: 'cross_age', label: 'Cross-Age (i.e. Older Youth to Younger Youth)'},
];

TeacherApplicationForm.optionsOrgType = [
  {value: 'public', label: 'Public School'},
  {value: 'private', label: 'Private School'},
  {value: 'charter', label: 'Charter School'},
  {value: 'nonprofit', label: 'Non-Profit Mentoring Organization'},
  {value: 'forprofit', label: 'For-Profit Mentoring Organization'},
  {value: 'other', label: 'Other'},
];

TeacherApplicationForm.optionsGrades = [
  {value: '3', label: 'Grade 3'},
  {value: '4', label: 'Grade 4'},
  {value: '5', label: 'Grade 5'},
  {value: '6', label: 'Grade 6'},
  {value: '7', label: 'Grade 7'},
  {value: '8', label: 'Grade 8'},
];

TeacherApplicationForm.optionsTitle1 = [
  {value: 'yes', label: 'Yes'},
  {value: 'no', label: 'No'},
];

TeacherApplicationForm.optionsModel = [
  {value: 'yes', label: 'Yes'},
  {value: 'no', label: 'No'},
];

TeacherApplicationForm.optionsMentorSource = [
  {
    value: 'no',
    label: 'No, my organization/school is interested in being provided eMentors for our mentees.'
  },
  {
    value: 'yes',
    label: 'Yes, my organization/school has eMentors with whom we would like to connect our mentees online.'
  },
];

TeacherApplicationForm.optionsTechnology = [
  {value: 'device', label: '1:1 Device Access (for at least a recommended 1 hour per week)'},
  {value: 'browser', label: 'Access to Google Chrome, Safari, or Microsoft Edge'},
  {value: 'highspeed', label: 'High-Speed Connectivity'},
  {value: 'other', label: 'Other'},
];

TeacherApplicationForm.optionsFocus = [
  {value: 'literacy', label: 'Literacy Development'},
  {value: 'cross', label: 'Cross-Curricular'},
  {value: 'social', label: 'Social Emotional Learning'},
  {value: 'stem', label: 'STEM'},
  {value: 'engineering', label: 'Engineering Only '},
  {value: 'other', label: 'Other'},
];

TeacherApplicationForm.optionsReferrer = [
  {value: 'search', label: 'Search Engine (seeking a virtual mentoring program)'},
  {value: 'socialmedia', label: 'Social Media'},
  {value: 'referral', label: 'Referral'},
  {value: 'email', label: 'Email from Cricket'},
  {value: 'other', label: 'Other'},
];

TeacherApplicationForm.defaultProps = {
  showDemographics: true,
};
