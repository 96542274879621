api = require '../api'

module.exports =

    fetch: (sessionId) ->
        api.send
          method: 'GET'
          path: "/api/v2/sessions/#{sessionId}?withSponsor=true&activeOnly=true&tenant=#{TENANT}"

    findByMentor: (mentorId) ->
        api.send
          method: 'GET'
          path: "/api/v2/sessions?mentors=#{mentorId}&tenantID=#{TENANT}"

    addToSession: (sessionId, userId, parentRegID) ->
         api.send
            method: 'POST'
            path: "/api/v2/sessions/#{sessionId}/mentors"
            payload: {id: userId, tenantID: TENANT, parentRegID}
