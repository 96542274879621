({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
{compact, map} = require 'lodash'
{getUnitDates} = require 'utils/date_normalizer'
style = require './current_unit_card.styl'
e = require('react-e/bind') style
DatePickerCard = createFactory require 'components/shared/date_picker_card'
UnitDetailDatePicker = createFactory require 'components/unit_detail_date_picker'

class CurrentUnitCard extends Component

  @propTypes:
    role: PropTypes.oneOf(['student', 'mentor']).isRequired
    unit: PropTypes.shape(
      description: PropTypes.string.isRequired
      endDate: PropTypes.string
      name: PropTypes.string.isRequired
      startDate: PropTypes.string.isRequired
      unitDates: PropTypes.shape(
        focusArticleDate: PropTypes.string
        mentorDate: PropTypes.string
        studentDate: PropTypes.string
      ).isRequired
    ).isRequired


  render: ->
    DatePickerCard
      datepicker:
        UnitDetailDatePicker
          dates: getUnitDates(@props.unit, @props.role)
          isEditable: no
      e 'div',
        e 'h2.unit-name', @props.unit.name
        e 'p.description', @props.unit.description

CurrentUnitCard.displayName = "CurrentUnitCard"

module.exports = CurrentUnitCard
