_ = require 'lodash'
{makeAction} = require 'utils/action_helpers'
usersService = require 'services/users_service'
sessionsService = require 'services/classroom_session_service'

module.exports =

  backgroundcheck: (id, test, callback) -> makeAction (setState) ->
    usersService.backgroundcheck id, test
      .then (data) ->
        user = data.body
        service = _.find user.cm_services, ['service', TENANT]
        _.assign user, service
        setState 'user', data: user
        callback?()
      .catch (error) ->
        callback?(error)

  changePassword: ({id, password}, callback) -> makeAction (setState) ->
    usersService.changePassword {id, password}
      .then (data) -> callback? data
      .catch (error) ->
        setState 'user', error: error, loading: false

  verifyAccount: (id, token, callback) -> makeAction (setState) ->
    usersService.verifyEmailWithToken(id, token)
      .then ({user}) ->
        service = _.find user.cm_services, ['service', TENANT]
        _.assign user, service
        setState 'user', data: user
        callback?()
      .catch (error) ->
        callback?(error)

  checkOnboardedStatus: (id, callback) -> makeAction (setState) ->
    usersService.checkOnboardedStatus(id)
      .then (response) ->
        if response.data
          user = response.data
          service = _.find user.cm_services, ['service', TENANT]
          _.assign user, service
          setState 'user', data: user
        callback?(user)
      .catch (error) ->
        callback?(error)

  clearUserFromStore: (callback) -> makeAction (setState) ->
#    console.log 'users: clearUserFromStore'
    setState 'user', data: undefined, loading: false
    callback?()

  find: (id, callback) -> makeAction (setState) ->
#    console.log "users: find('#{id}')"
    usersService.find(id)
      .then ({body}) ->
        if body.cm_services?.length
          service = _.find body.cm_services, ['service', TENANT]
          _.assign  body, service
        setState 'user', data: body, loading: false
        callback?(body)
      .catch (error) ->
        setState 'user', error: error.statusText, loading: false

  findSessions: (mentorId, callback) -> makeAction (setState) ->
#    console.log "users: findSessions('#{mentorId}')"
    setState 'sessions', loading: true
    sessionsService.findByMentor(mentorId)
      .then  (results) ->
        setState 'sessions', data: results.data, loading: false
        callback?(results.data)
      .catch (error) ->
        setState 'sessions', error: error.statusText, loading: false

  updateAppState: ({id, classroomId, appState}, callback) -> makeAction (setState) ->
#    console.log "users: updateAppState"
    if classroomId
      data = id: id, appState: {"#{classroomId}": appState}, service: TENANT
    else
      data = id: id, appState: appState, service: TENANT
    usersService.update(data)
      .then ({user}) ->
#        console.log 'users: updateAppState then:', {user}
        if user.cm_services?.length
          service = _.find user.cm_services, ['service', TENANT]
          setState ['user', 'data', 'appState'], service.appState
          callback?()
      .catch (error) ->
        setState 'user', error: error.toString(), loading: false

  update: (data, callback) -> makeAction (setState) ->
#    console.log "users: update"
    data.service = TENANT
    usersService.update(data)
      .then ({user}) ->
        if user.cm_services?.length
          service = _.find user.cm_services, ['service', TENANT]
          _.assign user, service
#        console.log 'update user result = ', user
        setState ['user', 'data'], user
        callback?()
      .catch (error) ->
#        setState 'user', error: error.toString(), loading: false
        callback?(error)
