api = require '../api'
_ = require 'lodash'
{buildFetchPath} = require 'utils/service_helpers'


module.exports =
  addStudent: ({classroomId, studentId}) ->
    api.send
      method: 'POST'
      path: "/api/v2/classrooms/#{classroomId}/students"
      payload: id: studentId


  fetch: (args) ->
    api.send
      method: 'GET'
      path: buildFetchPath args, '/api/v2/classrooms/mine?tenant=' + TENANT


  find: (id) ->
    api.send
      method: 'GET'
      path: "/api/v2/classrooms/#{id}"


  update: (data) ->
    api.send
      method: 'PUT'
      path: "/api/v2/classrooms/#{data._id}"
      payload: data
