({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
Table = createFactory require 'components/shared/table'
{mapDataToRow} = require './normalization_helper'

style = require './classroom.styl'
e = require('react-e/bind') style

class ClassroomTable extends Component

  @propTypes =
    classroom: PropTypes.object.isRequired
    detailShow: PropTypes.func.isRequired
    letterShow: PropTypes.func.isRequired
    groups: PropTypes.arrayOf(PropTypes.shape
      student: PropTypes.object
      mentor: PropTypes.object
      permission: PropTypes.object
    ).isRequired
    messages: PropTypes.object.isRequired


  render: ->
    showPermission = @props.classroom.permissionFormType is 'paper' or @props.classroom.permissionFormType is 'esign'
    Table
      columns: [
        header: t 'classroomPage.student'
        isSortingDisabled: true
        key: 'student'
        transformValue: (data) ->
          e 'div', data.student,
            e 'div',
              style:
                display: 'flex'
              if data.preSurvey
                e 'div',
                  style:
#                    visibility: if data.preSurvey then '' else 'hidden'
                    background: '#5bc0de'
                    fontSize: '75%'
                    fontWeight: '700'
                    lineHeight: '1'
                    color: '#fff'
                    textAlign: 'center'
                    padding: '0.2em 0.6em 0.3em'
                    whiteSpace: 'nowrap'
                    verticalAlign: 'baseline'
                    borderRadius: '0.25em'
                    marginRight: '0.5em'
                  'Pre'
              if data.postSurvey
                e 'div',
                  style:
#                    visibility: if data.postSurvey then '' else 'hidden'
                    background: '#337ab7'
                    fontSize: '75%'
                    fontWeight: '700'
                    lineHeight: '1'
                    color: '#fff'
                    textAlign: 'center'
                    padding: '0.2em 0.6em 0.3em'
                    whiteSpace: 'nowrap'
                    verticalAlign: 'baseline'
                    borderRadius: '0.25em'
                    marginRight: '0.5em'
                  'Post'
        onValueClick: ({student}) =>
          if student? then @props.detailShow id: student.key, type: 'student'
      ,
        isSeparator: true,
      ,
        if showPermission
          flex: 1
          header: t 'classroomPage.permission.title'
          isSortingDisabled: true
          key: 'permission'
          transformValue: (data) ->
            permission = data.permission
            return unless permission
            iconClassName = '.' + permission
            e '.permission',
              e iconClassName, title: t 'classroomPage.permission.' + permission
        else
          isSeparator: true
      ,
        flex: 1
        header: t 'classroomPage.letters'
        isSortingDisabled: true
        key: 'studentLetters'
        onValueClick: ({mentor, student}) =>
          if student?
            @props.letterShow mentor: mentor?.key, student: student?.key, type: 'student'
      ,
        isSeparator: true,
      ,
        flex: 1
        header: t 'classroomPage.penpal'
        isSortingDisabled: true
        key: 'mentor'
        onValueClick: ({mentor}) =>
          if mentor? then @props.detailShow id: mentor.key, type: 'mentor'
      ,
        isSeparator: true,
      ,
        flex: 1
        header: t 'classroomPage.letters'
        isSortingDisabled: true
        key: 'mentorLetters'
        onValueClick: ({mentor, student}) =>
          if mentor?
            @props.letterShow mentor: mentor?.key, student: student?.key, type: 'mentor'
      ]
      data: @props.groups.map (group) => mapDataToRow group, @props.messages.data, @props.classroom
      isPagingEnabled: false

ClassroomTable.displayName = "ClassroomTable"

module.exports = ClassroomTable
