({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './footer.styl'
e = require('react-e/bind') style
{map} = require 'lodash'

class Footer extends Component

  _userNavLinksDefault: [
      name: 'Cricket Media'
      url: 'http://www.cricketmedia.com'
    ,
      name: 'Products'
      url: 'http://www.cricketmedia.com/childrens-magazines'
    ,
      name: 'Store'
      url: 'http://shop.cricketmedia.com/'
    ,
      name: 'Licensing'
      url: 'http://www.cricketmedia.com/licensing'
    ,
      name: 'Contact Us'
      url: 'http://www.cricketmedia.com/contact-us'
    ,
      name: 'Terms of Use'
      url: 'http://www.cricketmedia.com/terms'
  ]

  _cmLogoDefault:
    name: 'Crick Media'
    url: '/assets/cm-logo.svg'

  _loggedIn: ->
    @props.session?.data?.login


  _renderUserLink: (link) ->
    e 'li.nav-link',
      e 'a.nav-link-text', {href: link.url, target: '_blank'}, link.name


  render: ->
    navLinks = @props.tenant.data.pageFooterConfig?.navLinks ? @_userNavLinksDefault
    cmLogo =  Object.assign {}, @_cmLogoDefault
    copyrightText = @props.tenant.data.copyrightText ? '© CricketMedia, Inc. All Rights Reserved.'

    if @props.tenant?.data?.pageFooterConfig?.cmLogo
      cmLogo.url = @props.tenant.data.pageFooterConfig.cmLogo?.url or cmLogo.url
      cmLogo.name = @props.tenant.data.pageFooterConfig.cmLogo?.name or cmLogo.name

    if @props.classroom?.data?.pageFooterConfig?.cmLogo
      cmLogo.url = @props.classroom.data.pageFooterConfig.cmLogo?.url or cmLogo.url
      cmLogo.name = @props.classroom.data.pageFooterConfig.cmLogo?.name or cmLogo.name


    e 'footer.page-footer',
      unless true or @_loggedIn() # suppress the nav links for now for Privo compliance...
        e 'ul.nav', (map navLinks, @_renderUserLink)...
      e '.left',
        e 'img.cricket-media-logo', alt: cmLogo.name, src: cmLogo.url
        e 'small.copyright', copyrightText
      e '.right',
        ###if @_loggedIn()
          e '.nav-link',
            e 'a.nav-link-text',
              {href: 'http://www.cricketmedia.com/terms', target: '_blank'}, 'Terms of Use' ###
        e '.nav-link.privacy-policy',
          e 'a.nav-link-text',
            {href: 'http://cricketmedia.com/privacy-privo', target: '_blank'}, 'Privacy Policy'
        if  @props.tenant.data.showCOPPASeal
          e 'a.privo-logo', {href: 'https://cert.privo.com/#/companies/cricket_media', target: '_blank'}

Footer.displayName = "Footer"

module.exports = Footer
