{makeAction} = require 'utils/action_helpers'
tenantsService = require 'services/tenants_service'


module.exports =

    fetch: (cb, setLoading=true) -> makeAction (setState) ->
        if setLoading
          setState 'tenant', loading: true
        tenantsService.fetch()
          .then ({data: tenant}) ->
              if tenant.length
                setState 'tenant', data: tenant[0], loading: false, error: undefined
                cb?(tenant[0])
              else
                setState 'tenant', error: 'Tenant does not exist', loading: false
          .catch (error) ->
              setState 'tenant', error: error.statusText, loading: false
              cb?()
