analytics = require 'utils/analytics'

CATEGORIES =
  tips: 'Tips & Resources'
  write: 'Write Letter'


LETTER_ACTIONS =
  began: 'Began Letter'
  draftAttachments: 'Draft Letter Attachments'
  draftImages: 'Draft Letter Images'
  draftText: 'Draft Letter Text'
  planText: 'Plan Letter Text'
  reviseAttachments: 'Revise & Send Letter Attachments'
  reviseImages: 'Revise & Send Letter Images'
  reviseText: 'Revise & Send Letter Text'
  sent: 'Sent Letter'


TIPS_ACTIONS =
  checklist: 'Viewed Writing Checklist'
  expandedPenPalLetter: 'Expanded Most Recent Letter'
  expandedSampleLetter: 'Expanded Sample Letter'
  importantWords: 'Viewed Important Words'
  penPalLetter: 'Viewed Most Recent Letter'
  sampleLetter: 'Viewed Sample Letter'
  thinkingQuestions: 'Viewed Thinking Questions'
  tips: 'Viewed Writing Tips'


WRITE_ACTIONS =
  draftNextStep:
    action: 'Moved to Revise & Send'
    label: 'Clicked "Next Step" on Draft Tab'
  draftTab:
    action: 'Moved to Draft Tab'
    label: 'Clicked Draft Tab'
  keepPlanning: 'Clicked "Keep Planning" on Plan Tab'
  nextQuestion: 'Clicked "Next" on Plan Tab'
  planNextStep:
    action: 'Moved to Draft Tab'
    label: 'Clicked "Next Step" on Plan Tab'
  print: 'Printed Letter'
  reviseTab:
    action: 'Moved to Revise & Send'
    label: 'Clicked Revise & Send Tab'
  saveExit: 'Save & Exit'
  startOver: 'Start Over'
  viewLetter: 'Viewed Letter in Inbox'
  writeFromActionItem: 'Write All About Me Letter - Action Item'


humanize = (string) ->
  string[0].toUpperCase() + string.slice(1)


makeActions = (actionList, category) ->
  _.mapValues actionList, (action) -> () ->
    if typeof action is 'string'
      analytics.recordEvent {action, category}
    else
      analytics.recordEvent
        action: action.action
        category: category
        label: action.label


makeLetterActions = ->
  _.mapValues LETTER_ACTIONS, (action) -> ({type, value}) ->
    event =
      action: "#{action} - #{humanize type}"
      category: CATEGORIES.write
    if value?
      event.value = value
    analytics.recordEvent event


module.exports = {
  letter: makeLetterActions()
  tips: makeActions TIPS_ACTIONS, CATEGORIES.tips
  write: makeActions WRITE_ACTIONS, CATEGORIES.write
}
