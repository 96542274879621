moment = require 'moment'

module.exports =

  cardButtonTitleForRole: (role, hasRejectedMessage, hasDraft, hasPrevDraft, isInitialState, userState) ->
    isMatched = userState?.pal?
    palName = userState?.pal?.firstname
    if hasRejectedMessage
      t 'pageLetterCenter.cardButtonTitle.hasRejectedMessage'
    else if hasDraft
      switch role
        when 'student'
          if hasPrevDraft
            t 'pageLetterCenter.cardButtonTitle.hasPrevDraft'
          else
            t 'pageLetterCenter.cardButtonTitle.hasDraft.student'
        else
          if hasPrevDraft
            t 'pageLetterCenter.cardButtonTitle.hasPrevDraft'
          else
            t 'pageLetterCenter.cardButtonTitle.hasDraft.mentor'
    else if isMatched and isInitialState
      switch role
        when 'student' then t 'pageLetterCenter.cardButtonTitle.isInitialState.student', palName: palName
        else t 'pageLetterCenter.cardButtonTitle.isInitialState.mentor', palName: palName
    else
      switch role
        when 'student' then t 'pageLetterCenter.cardButtonTitle.default.student'
        else t 'pageLetterCenter.cardButtonTitle.default.mentor'


  cardContentForRole: (role, hasRejectedMessage, hasDraft, hasPrevDraft, isInitialState, isIntroUnit, userState) ->
    isMatched = userState?.pal?
    palName = userState?.pal?.firstname
    if hasRejectedMessage
      t "pageLetterCenter.cardContent.hasRejectedMessage.#{role}"
    else if hasDraft
      switch role
        when 'student'
          if hasPrevDraft
            t 'pageLetterCenter.cardContent.hasPrevDraft.student'
          else
            t 'pageLetterCenter.cardContent.hasDraft.student'
        else
          if hasPrevDraft
            t 'pageLetterCenter.cardContent.hasPrevDraft.mentor'
          else
            t 'pageLetterCenter.cardContent.hasDraft.mentor'
    else if isInitialState
      switch role
        when 'student'
          if isMatched
            t 'pageLetterCenter.cardContent.isInitialState.student', palName: palName
          else
            t 'pageLetterCenter.cardContent.isInitialState.studentMatched'
        else t 'pageLetterCenter.cardContent.isInitialState.mentor', palName: palName
    else
      switch role
        when 'student' then t 'pageLetterCenter.cardContent.default.student', palName: palName
        else t 'pageLetterCenter.cardContent.default.mentor',
          suffix: unless isIntroUnit then t 'pageLetterCenter.cardContent.nonIntroUnitSuffix', palName: palName else ''


  cardSubtitleForRole: (role, isInitialState, {mentor, student}, userState) ->
    isMatched = userState?.pal?
    if isMatched and isInitialState
        date = if role is 'student' then student else mentor
        if moment(date).isValid() then t 'pageLetterCenter.cardSubtitle',
          date: moment(date).format('MMM DD, YYYY')
        else ''
    else
      return ''


  cardTitleForRole: (role, hasRejectedMessage, hasDraft, isInitialState, firstname, userState) ->
    isMatched = userState?.pal?
    if not isMatched
      t 'pageLetterCenter.cardTitle.notMatched',
    else if hasRejectedMessage
      t 'pageLetterCenter.cardTitle.hasRejectedMessage',
        name: firstname
    else if hasDraft
      t 'pageLetterCenter.cardTitle.hasDraft',
        name: firstname
    else if isInitialState
      t 'pageLetterCenter.cardTitle.isInitialState',
        name: firstname
    else
      t 'pageLetterCenter.cardTitle.default',
        name: firstname


  inboxPlaceholderForRole: (role) ->
    switch role
      when 'student' then t 'pageLetterCenter.placeholder.inbox.student'
      else t 'pageLetterCenter.placeholder.inbox.mentor'


  sentPlaceholderForRole: (role) ->
    switch role
      when 'student' then t 'pageLetterCenter.placeholder.sent.student'
      else t 'pageLetterCenter.placeholder.sent.mentor'
