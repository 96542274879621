({Component} = require 'react') and (PropTypes = require 'prop-types')
style = require './card.styl'
e = require('react-e/bind') style

class Card extends Component

  @propTypes:
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    icon: PropTypes.element


  _displayIcon: (icon) ->
    return unless icon?

    e '.icon-container',
      e '.icon-container-circle',
        icon


  render: ->
    cardStyle = @props.cardStyle ? {}
    cardContainerStyle = @props.cardContainerStyle ? {}
    e '.card', style: cardStyle,
      @_displayIcon @props.icon
      e '.card-container', style: cardContainerStyle,
        @props.children

Card.displayName = "Card"

module.exports = Card
