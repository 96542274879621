_ = require 'lodash'
{makeAction} = require 'utils/action_helpers'
permissionformsService = require 'services/permissionforms_service'

module.exports =

  fetchall: (classroomId, callback) -> makeAction (setState) ->
    setState 'permissionforms', loading: true
    permissionformsService.fetchall(classroomId)
      .then ({data: permissionforms}) ->
        setState 'permissionforms', data: permissionforms, loading: false
        callback? permissionforms
      .catch (error) ->
        setState 'permissionforms', error: error.statusText, loading: false
