const style = require('./account_verify.styl');
const cn = require('classnames/bind').bind(style);
const {Component, Fragment} = require('react');
const {dispatch} = require('store');

const userActions = require('actions/users');
const {redirect} = require('actions/routing');

const Card = require('components/shared/card');
const Button = require('components/shared/button');


class VerifyAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {

            userId: props.location.query.u ? props.location.query.u : "",
            token: props.location.query.t ? props.location.query.t : "",
            success: false,
            error: false,

        };

    }

    navigateToHomePage() {
        dispatch(redirect('/'));
    }

    componentDidMount() {
        const {userId, token} = this.state;
        dispatch(userActions.verifyAccount(userId, token, (error) => {
            if (error) {
                this.setState({error: true});
            }
            else {
                this.setState({success: true, error: false});
            }
        }));
    }

    render() {
        return (
            <Card>
                <div className={cn('container')}>
                    {!this.state.success &&  !this.state.error &&
                        <div className={cn('instructions')}>{t('accountVerify.verifying')}</div>
                    }
                    { !this.state.success && this.state.error &&
                        <div className={cn('error')}>{t('accountVerify.error')}</div>
                    }
                    {this.state.success &&
                        <div className={cn('success')}>
                            <div className={cn('instructions')}>{t('accountVerify.success')}</div>
                        </div>
                    }
                    <Button onClick={(ev)=> this.navigateToHomePage()} type="regular">{t('accountVerify.returnBtn')}</Button>
                </div>
            </Card>
        );
    }
}

module.exports = VerifyAccount;
