import React from "react";
import PropTypes from 'prop-types';
const _ = require('lodash');
const style = require('./onboarding.styl');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
const usersActions = require('actions/users');
const store = require('store');
import Carousel from 'components/shared/carousel';
import Modal from 'components/shared/modal';
import KalturaVideo from 'components/shared/kaltura_video';
import Button from 'components/shared/button';
import ContentPreview from 'components/shared/content_preview';
import UserHelpers from "../../utils/user_helpers";
import getAppName from 'utils/app_name';
const {redirect} = require('actions/routing');

const mapStateToProps = ({store: {user, session, sessions, classrooms, tenant, classroom}}) => {
  const transformedUser = UserHelpers.getTransformedUser(user.data, sessions.data, classrooms.data, tenant.data);
  const role = transformedUser.roles.find((x) => x === 'mentor' || x === 'student');
  return {
    user: transformedUser,
    tenant: tenant.data,
    role,
    classroom: classroom && classroom.data
  };
}

class MentorOnboardingTraining extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showVideoId: false,
    };
  }

  onClickShowVideo( videoId ) {
    this.setState( {
      showVideoId: videoId
    });
  }

  onClickDismissVideo() {
    const {user, dispatch, role} = this.props;
    this.setState({
      showVideoId: false
    });
    dispatch(usersActions.updateAppState({
          id: user.id,
          appState: {trainingViewed: {[this.state.showVideoId]: true}}
        }, () => {
          const newStore = store.getState().store;
          const userState = UserHelpers.getTransformedUser(newStore.user.data,
              newStore.sessions.data, newStore.classrooms.data, newStore.tenant.data);
          if (role === 'mentor' && userState.trainingIsComplete) {
            dispatch(redirect(this.props.back));
          }
        }
    ));
  }

  render() {
    const { user, dispatch, back, role, tenant, classroom } = this.props;
    let trainingItems = user.trainingItems;
    const appName = getAppName(tenant, classroom);
    return (
        <div className={cn('onboarding-page', 'onboarding-training')}>
          <h2>
            {t(`onboarding.training.${role}.heading`)}
            <Button mode='transparent'
                    onClick={() => dispatch(redirect(back))}
                    size='small'
                    type='regular'>
              <span className={cn('icon-back')} />Back
            </Button>
          </h2>
          <div className={cn('instructions')}>
            {t(`onboarding.training.${role}.instructions`, {appName})}
          </div>

          {this.state.showVideoId &&
          <Modal onClose={() => this.onClickDismissVideo()}>
            <KalturaVideo entryId={this.state.showVideoId}/>
          </Modal>
          }

          <div>
            <Carousel>
              {trainingItems.map( (item) => {
                const trainingViewed = (user.appState && user.appState.trainingViewed) || {};
                const watched = !!trainingViewed[ item.videoId ];
                const buttonText = t( watched ? 'onboarding.training.watchAgainButton' : 'onboarding.training.watchButton');
                return (
                    <div className={cn('training-item')} key={item.name}>
                      <ContentPreview image={item.image}
                                      onClick={() => this.onClickShowVideo(item.videoId)}
                                      description={item.description}
                                      links={
                                        <div className={cn('training-buttons')}>
                                          <Button type='regular' onClick={() => this.onClickShowVideo(item.videoId)}>
                                            {buttonText}
                                          </Button>
                                        </div>
                                      }
                                      metadata={[{name: 'Time', value: item.time}]}
                                      scheme='plain'
                                      title={item.name}
                                      type={'training'} />
                    </div>
                );
              })}
            </Carousel>
          </div>
        </div>
    );
  }
}

MentorOnboardingTraining.displayName = "MentorOnboardingTraining";
MentorOnboardingTraining.propTypes = {
  user: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  back: PropTypes.string,
  mode: PropTypes.string
};

module.exports = build({
  component: MentorOnboardingTraining,
  mapStateToProps
});
