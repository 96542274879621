api = require 'api'
{buildFetchPath} = require 'utils/service_helpers'


module.exports =

  create: (data) ->
    api.send
      method: 'POST'
      path: '/api/v2/articleSelections'
      payload: data


  fetch: (args) ->
    api.send
      method: 'GET'
      path: buildFetchPath args, '/api/v2/articleSelections'


  update: (data) ->
    api.send
      method: 'PUT'
      path: "/api/v2/articleSelections/#{data._id}"
      payload: data
