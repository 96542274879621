import React from 'react';
import Frame from 'react-frame-component';
import PropTypes from 'prop-types';
import events from 'pages/teacher/units/events';
import {printLetter} from 'components/print_letters/print_helper';
import Modal from 'components/shared/modal';
import {build} from 'utils/container_helpers';

const style = require('./unit_detail.styl');
const cn = require('classnames/bind').bind(style);

const mapStateToProps = ({store: {tenant}}) => ({tenant});

const WritingPrompts = ({prompts}) => {
  return prompts.map(({subtitle, title}, i) => {
        return (
            <div className={cn('prompt')} key={i}>
              <div className={cn('prompt-title')}
                   style={{fontWeight: 'bold', marginBottom: '0.5rem'}}>{title}</div>
              <div className={cn('prompt-subtitle')} style={{marginBottom: '0.5rem'}}>{subtitle}</div>
            </div>
        );
      }
  );
};

const TabContent = ({prompts, unitName, tenant}) => {
  return (
      <div className={cn('prompt-container')}>
        <Frame className='print-prompts' style={{height: '0px', position: 'absolute', width: '0px'}}>
          <div className={cn('print-letter-prompts')}>
            <PrintView prompts={prompts} unitName={unitName} tenant={tenant} />
          </div>
        </Frame>
        <a className={cn('print-link')} onClick={() => printLetter('print-prompts')}>{t('unitDetail.printPrompts')}</a>
        <div className={cn('title')}>
          <div className={cn('icon-tips')}/>
          <span>{t('unitDetail.promptsTitle', {unitName})}</span>
        </div>
        <WritingPrompts prompts={prompts} />
      </div>
  );
};

const PrintView = ({prompts, unitName, tenant}) => {
  return (
      <div className={cn('printed-prompt-content-column')}>
        <img className={cn('logo')}
             alt={tenant !== null ? tenant : t('printLetter.logo')}
             src={`/assets/${TENANT}/logo.svg`}
             style={{
               margin: '0 0 0.25in 0',
               width: '2in'
             }}/>
        <div className={cn('title')}
             style={{
               fontWeight: 'bold',
               marginBottom: '1rem',
               textDecoration: 'underline'
             }}>
          <div className={cn('icon-tips')}/>
          <span>{t('unitDetail.promptsTitle', {unitName})}</span>
        </div>
        <WritingPrompts prompts={prompts} />
      </div>
  );
};


class UnitDetail extends React.Component {

  constructor() {
    super();
    this.state = {modalOpen: false};
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onClickGuide = this.onClickGuide.bind(this);
  }

  onClickGuide() {
    events.viewedTeachersGuide(this.props.mode, this.props.unit.name);
  }

  onCloseModal() {
    this.setState({modalOpen: false});
  }

  onOpenModal() {
    events.viewedLetterPrompts(this.props.mode, this.props.unit.name);
    this.setState({modalOpen: true});
  }

  render() {
    const {unit, tenant} = this.props;
    const {
      description,
      essentialQuestion,
      subjects,
      strands,
      grades,
      lexileHigh,
      lexileLow,
      strand,
      teacherGuide
    } = unit;

    const tabs = [
      {
        name: t('unitDetail.student'),
        content: <TabContent prompts={unit.student.questions}
                             unitName={unit.name} tenant={tenant.data.name} />,
      },
      {
        name: t('unitDetail.mentor'),
        content: <TabContent prompts={unit.mentor.questions}
                             unitName={unit.name} tenant={tenant.data.name} />,
      }
    ];

    return (
        <div className={cn('detail')}>
          {this.state.modalOpen &&
          <Modal onClose={this.onCloseModal} tabViewProps={{tabs}}/>
          }
          <div className={cn('left')}>
            <div className={cn('description')}>{description}</div>
            <div className={cn('icons')}>
              <a className={cn('icon')} href={teacherGuide} onClick={this.onClickGuide} target="_blank">
                <div className={cn('icon-guide')}/>
                <label>{t('unitDetail.teachersGuide')}</label>
              </a>
              <a className={cn('icon')} onClick={this.onOpenModal}>
                <div className={cn('icon-tips')}/>
                <label>{t('unitDetail.letterPrompts')}</label>
              </a>
            </div>
          </div>
          <div className={cn('right')}>
            <label>{t('unitDetail.essentialQuestion')}</label>
            <div className={cn('value')}>{essentialQuestion}</div>
            <div className={cn('metadata')}>
              {subjects && subjects.length > 0 &&
              <div className={cn('metadata-row')}>
                <label>{t('unitDetail.subjects')}</label>
                {subjects.map((subject) => (<div key={subject._id} className={cn('value')}>{subject.name}</div>))}
              </div>
              }
              {strands && strands.length > 0 &&
              <div className={cn('metadata-row')}>
                <label>{t('unitDetail.strands')}</label>
                {strands.map((strand) => (<div key={strand._id} className={cn('value')}>{strand.name}</div>))}
              </div>
              }
            </div>
            <div className={cn('metadata')}>
              {grades &&
              <div className={cn('metadata-row')}>
                <label>{t('unitDetail.grades')}</label>
                <div className={cn('value')}>{grades}</div>
              </div>
              }
              {lexileLow && lexileHigh &&
              <div className={cn('metadata-row')}>
                <label>{t('unitDetail.lexileBand')}</label>
                <div className={cn('value')}>{lexileLow} - {lexileHigh}L</div>
              </div>
              }
            </div>
          </div>
        </div>
    );
  }
}

const {arrayOf, object, shape, string} = PropTypes;

UnitDetail.displayName = "UnitDetail";
UnitDetail.propTypes = {
  unit: object.isRequired,
  mode: string.isRequired,
};

module.exports = build({
  component: UnitDetail,
  mapStateToProps
});
