style = require './article_collection.styl'
e = require('react-e/bind') style

module.exports =

  getInstructions: (role, isDefaultUnit, selectionStatus, palName) ->
    switch role
      when 'student'
        if isDefaultUnit
          e '.copy',
            e 'p.choose', t 'articleCollection.choose'
            e 'p.ordered', t 'articleCollection.ordered'
            e 'ul.choose-list',
              e 'li.choose-item',
                e 'span.choose-span', t 'articleCollection.peek'
              e 'li.choose-item',
                e 'span.choose-span', t 'articleCollection.click'
              e 'li.choose-item',
                e 'span.choose-span', t 'articleCollection.submit'
        else
          switch selectionStatus
            when 'approved' then t 'articleCollection.approvedStudent', palName: palName
            when 'pending' then t 'articleCollection.pendingStudent', palName: palName
            else t 'articleCollection.selectStudent', palName: palName
      when 'mentor'
        if isDefaultUnit
          t 'articleCollection.defaultMentor'
        else
          switch selectionStatus
            when 'approved'
              t 'articleCollection.approvedMentor', palName: palName
            else
              t 'articleCollection.unapprovedMentor', palName: palName

  getTitle: (role, isDefaultUnit, selectionStatus) ->
    switch role
      when 'student'
        if isDefaultUnit then t 'articleCollection.defaultTitle'
        else
          switch selectionStatus
            when 'approved' then t 'articleCollection.approvedStudentTitle'
            when 'pending' then t 'articleCollection.pendingStudentTitle'
            else t 'articleCollection.selectableTitle'
      when 'mentor'
        if isDefaultUnit then t 'articleCollection.defaultTitle'
        else
          switch selectionStatus
            when 'approved' then t 'articleCollection.approvedMentorTitle'
            else t 'articleCollection.defaultTitle'
