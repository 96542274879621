import React from 'react';
import PropTypes from 'prop-types';
const style = require('./letter_composer_tabs.styl');
const cn = require('classnames/bind').bind(style);
import Modal from 'components/shared/modal';
import Button from 'components/shared/button';

const ModalSentConfirmation = ({onClose}) => {
  return (
      <Modal top={false}
             onClose={onClose}>
        <div className={cn('modal')}>
          <div className={cn('modal-header')}>{t('letterComposerTabView.reviseAndSendModalTitle')}</div>
          <div className={cn('modal-text')}>
            {t('pageLetterComposer.reviseAndSendModalContentText1')}
            {t('pageLetterComposer.reviseAndSendModalContentText2')}
          </div>
          <div className={cn('modal-buttons')}>
            <div className={cn('buttons')}>
              <div className={cn('button-right')}>
                <Button size='large'
                        type='regular'
                        onClick={onClose}>
                  {t('letterComposerTabView.reviseAndSendModalCloseButtonTitle')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
  );
};

ModalSentConfirmation.displayName = 'ModalSentConfirmation';
ModalSentConfirmation.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalSentConfirmation;
