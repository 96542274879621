import React from 'react';
import PropTypes from 'prop-types';
const {build} = require('utils/container_helpers');
const style = require('./classroom_edit_student.styl');
const cn = require('classnames/bind').bind(style);
const _ = require('lodash');
import usersService from 'services/users_service';
import classroomsActions from 'actions/classrooms';
import usersActions from 'actions/users';
const classroomStudentActions = require('actions/classroom_student');

import Card from 'components/shared/card';
import Button from 'components/shared/button';
import LoadingSpinner from 'components/shared/loading_spinner';
import StudentDataEntry from 'components/student_data_entry';

const mapStateToProps = ({store: {classroom}}) => ({
  classroom: classroom.data,
});

class ClassroomEditStudent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      student: null,
      formData: null,
      validationErrors: {},
    };
    this.fieldNames = ['firstname', 'lastname', 'gender', 'parentemail', 'parentname', 'password', 'username'];
  }

  componentDidMount() {
    const {userId} = this.props;
    usersService.find(userId)
        .then(({body}) => {
          if (body.cm_services && body.cm_services.length > 0) {
            const service = _.find(body.cm_services, ['service', TENANT]);
            const student = _.omit(Object.assign({}, body, service), 'cm_services', 'services');
            const formData = _.pick(student, ...this.fieldNames);
            this.setState({student, formData});
          }
        })
        .catch((error) => {
          console.log({error});
        });
  }

  onChange(formData) {
    this.setState({formData});
  }

  onSubmit(event) {
    const {dispatch, userId, onClose} = this.props;
    event.preventDefault();

    if (this.validateFields()) {
      const payload = Object.assign({}, this.state.student, this.state.formData);
      const updateAndReloadClassroom = () => {
        dispatch(classroomStudentActions.update(payload, () => {
          dispatch(classroomsActions.loadCurrentClassroom(onClose));
        }));
      };
      if (this.state.formData.password) {
        dispatch(usersActions.changePassword({id: userId, password: this.state.formData.password}, updateAndReloadClassroom));
      } else {
        updateAndReloadClassroom();
      }
    }
  }

  validateFields() {
    const formData = this.state.formData;
    const validationErrors = {};
    const fields = Object.keys(formData);
    fields.forEach( (field) => {
      if (!formData[field] || formData[field].trim() === '') {
        validationErrors[field] = t('classroomStudent.missingField', {field: t(`studentDataEntry.${field}`)});
      }
    });
    this.setState({validationErrors} )
    return Object.keys(validationErrors).length === 0;
  }

  render() {
    const {userId, classroom, onClose} = this.props;

    const classroomIsActive = classroom.status === 'active';
    const {permissionFormType} = classroom;
    const fieldEnabled = classroomIsActive;

    if (this.state.student === null) {
      return <LoadingSpinner/>;
    }

    return (
        <Card>
          <form onSubmit={(event) => this.onSubmit(event)}>
            <div className={cn('body')}>
              <div className={cn('row')}>
                <StudentDataEntry data={this.state.formData}
                                  enabled={{
                                    firstname: fieldEnabled,
                                    gender: fieldEnabled,
                                    lastname: fieldEnabled,
                                    parentemail: fieldEnabled,
                                    parentname: fieldEnabled,
                                    password: fieldEnabled,
                                    username: false
                                  }}
                                  errors={this.state.validationErrors}
                                  key={userId}
                                  onChange={(fields) => this.onChange(fields)}
                                  permissionFormType={permissionFormType}/>
              </div>
            </div>
            <div className={cn('footer')}>
              <div className={cn('footer-left')}>
                <a className={cn('delete-student')}>Delete Student</a>
              </div>
              <div className={cn('footer-right')}>
                <div className={cn('footer-item')}>
                  <Button mode='secondary' onClick={onClose} size='medium' type='regular'>Cancel</Button>
                </div>
                {classroomIsActive &&
                <div className={cn('footer-item')}>
                  <Button size='medium' submit={true} type='regular'>Save</Button>
                </div>
                }
              </div>
            </div>
          </form>
        </Card>
    );
  }
}

ClassroomEditStudent.propTypes = {
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
};

ClassroomEditStudent.displayName = "ClassroomEditStudent";

module.exports = build({
  component: ClassroomEditStudent,
  mapStateToProps
});
