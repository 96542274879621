const style = require('./search_ebooks.styl');
const cn = require('classnames/bind').bind(style);

const store = require('store');
const {redirect} = require('actions/routing');
const {Component, Fragment} = require('react');

const damService = require('services/dam_service');

const Card = require('components/shared/card');
const Button = require('components/shared/button');
const ArticlePreview = require('components/shared/article_preview/index.jsx');
const ContentLoader = require('components/shared/content_loader');

class SearchEbooks extends Component {

    constructor(props) {
        super(props);
        this.state = {assets: [], q:"", loading: true};
    }

    componentDidMount() {
        //this.updateAssetList();
    }

    updateAssetList() {
        damService.fetch(this.state.q).then((assets) => {
            this.setState({assets: assets.data, loading: false});
        });
        this.setState({loading: true});
    }

    updateSearchQuery(ev) {
        this.setState({q: ev.target.value});
    }

    checkForEnter(ev) {
       if (ev.keyCode === 13) {
           this.updateAssetList();
       }
    }

    render() {

        return (
            <Fragment>
                <h2 className={cn('title')}></h2>
                <div className={cn('searchbar')}>
                    <input type="search" name="q" placeholder={t('ebooks.search.placeholder')} onKeyUp={(ev) => this.checkForEnter(ev)} onChange={(ev) => this.updateSearchQuery(ev)}></input>
                    <Button onClick={(ev)=> this.updateAssetList()} type="regular">Search</Button>
                </div>
                { this.state.assets.length !== 0 &&
                <Card>
                     <div className={cn('container')}>
                        <div className={cn('articles')}>
                            {this.state.assets.map((asset) => {
                                return (<div className={cn('article')} key={asset.id}>
                                    <ArticlePreview
                                        article={asset}
                                        image={asset.thumbnail}
                                        onClickLink={()=> true}
                                        onClickRead={() => true}
                                        scheme='shadow'
                                        type='article'
                                        fileType='epub'
                                    />
                                </div>)
                            })}
                        </div>
                    </div>
                </Card>
                }
            </Fragment>

        );
    }

}

SearchEbooks.displayName = "SearchEbooks";
module.exports = SearchEbooks;