module.exports =
    articleCollection:
        approvedMentor: "A new magazine is ready for you and {{palName}} to read and discuss together!"
        approvedMentorTitle: "Read the Magazine"
        approvedStudent: "A new magazine is ready for you and {{palName}} to read and discuss together!"
        approvedStudentTitle: "Read the Magazine"
        defaultTitle: "Current Magazine"
        pendingStudent: "A new magazine is ready for you and {{palName}} to read and discuss together!"
        pendingStudentTitle: "Current Magazine"
        rejectReason: "Moderator's Suggestions:"
        selectStudent: "Click on the circle to choose a magazine to read with {{palName}}."
        selectableTitle: "Choose your Magazine"
        unapprovedMentor: "Enjoy reading and discussing the current magazine with {{palName}}!"
    articleDetail:
        articleContext: "Magazine Context"
        unitsIn: "This magazine was featured as the "
    articlePreview:
        readFocusArticle: "Read Magazine"
    onboarding:
        overview:
            message: "To get started, complete the checklist below by {{date}}:"
            titleNew: "New Pen Pal Checklist"
            titleReturning: "Returning Pen Pal Checklist"
            classroomButton: "Get Started"
        message:
            allComplete: "Congratulations, you have completed your checklist! Stay tuned, you'll receive an email notification once your pen pal has written you back."
            letterSubmitted: "Thanks for submitting your first letter! Once your letter has been approved, we'll reach out to your pen pal's parent/guardian to let them know it's time to get started!"
        training:
            mentor:
                instructions: "Learn more about how to use the {{appName}} platform to support and communicate with your pen pal."
        letter:
            instructions: "Use the tabs below to move through the process of writing your first letter to your pen pal!"
    classroomPage:
        addStudent: "Add Child"
        emptyMessage: "You have no children in your room. Please click 'Add Child' to get started."
        penpal: "Adult"
        student: "Child"
    classroomUnit:
        unfinishedItem:
            missingStudent: "{{count}} child(ren) missing letters"
            missingMentor: "{{count}} adult(s) missing letters"
    classroomUnitPage:
        articleSelection: "Magazine Selection"
        focusArticles: "MAGAZINES"
        missingFocusArticle: "{{missingCount}} of {{totalCount}} children have not selected a magazine yet."
        missingLetters: "{{missingCount}} of {{studentsCount}} children have not finished their letter yet."
        noFocusArticles: "There are no magazines for this unit."
        penpal: "Adult"
        student: "Child"
    classroomViewMentor:
        emailSubject: "{{appName}} Adult Pen Pal Replacement Request"
    contentPreview:
        approved: "Your Magazine"
        disabled: "Already Selected by Children"
        pending: "Upcoming Magazine"
    dateNormalizer:
        focusLabel: "Magazine Ready!"
        studentFocusLabel: "Magazine Ready!"
        defaultStudentName: 'Child'
        defaultMentorName: 'Adult'
    letterComposerTabView:
        defaultPal: "Pen Pal"
        defaultStudent: "Pen Pal"
        feedbackTitleText: "Moderator's Suggestions:"
    letterModal:
        rejectMessage: "Moderator's Suggestions ({{rejectedDate}}): {{rejectedMsg}}"
    login:
        errorNotInClassroom: "We're still in the process of getting everything ready for you! We'll send out an email when it's time to sign in. If you think you've gotten this message in error, please reach out to our help desk at {{supportEmail}}."
    pageLetterCenter:
        cardButtonTitle:
            isInitialState:
                mentor: "WRITE TO {{palName}}"
                student: "WRITE TO {{palName}}"
        cardContent:
            default:
                mentor: "Continue the conversation by writing again!"
                student: 'Read new letters from {{palName}} and write back soon! Click on the "Write Another Letter!" button to get started.'
            hasRejectedMessage:
                mentor: "Oops! There's something in your letter that the Moderator needs you to revise!"
                student: "Oops! There's something in your letter that the Moderator needs you to revise!"
            isInitialState:
                mentor: 'Use this page to read letters from or compose a letter to {{palName}}. Click on the "Write to {{palName}}" button to get started.'
                student: 'Use this page to read letters from {{palName}} or to write and send them a letter of your own! Click on the "Write to {{palName}}" button to get started.'
            nonIntroUnitSuffix: "Are there any parts of the magazine that you and {{palName}} haven't discussed yet?"
        placeholder:
            inbox:
                mentor: "This is where you'll see the letters that your pen pal sends to you!"
                student: "Once you write your first letter, this is where you'll see your pen pal's response."
            sent:
                mentor: "This is where you'll see the letters you send to your pen pal."
                student: "This is where you'll see the letters you send to your pen pal."
    pageLetterComposer:
        introduction:
            mentor: "Draft a letter to your pen pal below!"
            reply: "Remember to refer to your ‘Most Recent Letter’ in the Tips & Resources section when responding. Expand the conversation by discussing different parts of the magazine!"
    teacherHomePage:
        footer:
            focusArticles: "Magazines"
            focusArticlesApprove:
                other: "You have {{count}} magazines to review and approve."
                one: "You have {{count}} magazine to review and approve."
        headings:
            focusArticle: "Magazines Shipped!"
            mentorLetters: "Adult Letters"
            studentLetters: "Child Letters"
        pastUnits:
            reviewRevisions: "Letter revision for '{{name}}' was requested {{date}}."
    studentUnits:
        modalTitle: "Your magazine request has been submitted!"
        pending: "Keep your eyes peeled -- your magazine will be added to the Letter Center shortly!"
    teacherUnits:
        next:
            one: "{{nextName}} is scheduled to start in {{daysTill}} day. Need more time for {{currentName}}? Click on your current Unit to access your schedule. Then click on the pencil icon in the timeline to adjust the end date. After you adjust your schedule, we'll let the adult pen pals know!"
            other: "{{nextName}} is scheduled to start in {{daysTill}} days. Need more time for {{currentName}}? Click on your current Unit to access your schedule. Then click on the pencil icon in the timeline to adjust the end date. After you adjust your schedule, we'll let the adult pen pals know!"
    unitDetail:
        mentor: "Adult"
        student: "Child"
        teachersGuide: "Moderator's Guide"
    unitPreview:
        attachmentsDescription: "Allow child and adult pen pals to embed images and attach files to their letters for this unit. You will need to review all images and attachments during letter approval."
        previewDescHeader: "After You Launch This Magazine Unit, Don't Forget To:"
        desc:
            line1: 'Select a magazine for every child.'
            line2: 'Send a magazine unit description to the parents/guardians.'
    unitPreviewEdit:
        chooseArticles: "Select Magazine"
        description: "A little reminder: after you start the unit, don't forget to select the magazine for each child!"
    unitProgressBar:
        teacherGuide: "Moderator's Guide"
    unitsDropdown:
        focusArticlesCurrent: "Approve Magazines"

    topContents:
        mentor:
            top2:
                welcome: "Thank you for sending your letter! We'll let you know when {{palName}} has written you back."
            top3:
                welcome: "Great news! {{palName}} is ready to get started! Read their first letter to you and write back soon to continue the conversation."
            top4:
                welcome: "Enjoy writing back and forth with {{palName}} until your first magazine is ready!"
            top5:
                welcome: "Hot off the presses, a new magazine for you and {{palName}} to share!"
            top6:
                welcome: "Hot off the presses, a new magazine for you and your pen pal to share! Enjoy reading and discussing it with {{palName}}. Remember, you write first!"
            top7:
                welcome: "Thanks for sending a letter to {{palName}}! While you're waiting for {{palName}} to write back, feel free to peruse the Library and check out other fun magazines you might want to share with your pen pal!"
            top9:
                welcome: "Woohoo! You have a new letter from {{palName}}!"
            top10:
                welcome: "Oops! The Moderator needs you to revise your letter. Please revise and resend based on their recommendations."
            top11:
                welcome: "Thank you for participating in {{appName}}! We hope you had a great time. While you can no longer send nor receive letters, you can always revisit your correspondence as well as the magazines you and your pen pal shared!"
        student:
            top1:
                unitDesc: "Wondering what {{appName}} is all about? Watch the video to learn more about how you can use {{appName}} to communicate and read magazines with {{palName}}!"
                welcome: "Enjoy reading your first letter from {{palName}} and write back soon!"
            top3:
                welcome: "Way to go! Now that you've sent your first letter to {{palName}}, the fun can begin!"
            top4:
                welcome: "Enjoy writing back and forth with {{palName}} until the next magazine is ready!"
            top5:
                welcome: "Hot off the presses, a new magazine for you and {{palName}} to share! Make sure to select the magazine so that {{palName}} knows it's time to read."
            top6:
                welcome: "Hot off the presses, a new magazine for you and {{palName}} to share! Visit 'Current Magazine' to start reading, while you wait for {{palName}}'s letter."
            top8:
                welcome: "Woohoo! You have a new letter from {{palName}}!"
            top9:
                welcome: "Oops! The Moderator needs you to revise your letter. Please revise and resend based on their recommendations."
            top10:
                welcome: "Thank you for participating in {{appName}}! We hope you had a great time."
        teacher:
            top1:
                desc: "Bringing children closer to important, caring adults in their lives through reading and writing about magazines is an exciting opportunity!"
                welcome: "We're delighted you're here! Keep your eyes peeled for incoming letters to review & approve."
            top2:
                welcome: "Encourage the pen pals to continue exchanging letters to get used to using the platform!"
            top3:
                welcome: "Great news! You have adult pen pal letters to review!"
            top5:
                welcome:
                    line1: "Hope the pen pals are having great conversations about the current magazine!"
                    line2: "Make sure to keep those parents/guardians and adult pen pals updated!"
            top6:
                welcome: "Keep the conversations going by reviewing and approving letters promptly!"
            top7:
                welcome: "Yee-Haw! Way to support children and adults in a fabulous reading and writing adventure -- thank you!"
    actionItems:
        mentor:
            action1:
                text: "Read message from the Moderator."
            action3:
                text: "Read your first letter from {{palName}}!"
            action4:
                text: "Enjoy reading {{palName}}'s letter."
            action5:
                text: "Write back to {{palName}} to continue the conversation."
            action6:
                text: "Please revise and resend your letter based on the Moderator's recommendations."
            action8:
                text: "Read the current magazine and write a letter to {{palName}} about it!"
            action10:
                text: "Enjoy reading {{palName}}'s letter and write back soon!"
            action11:
                text: "Please revise and resend your letter based on the Moderator's recommendations."
            action12:
                text: "Share your reflections on this experience with {{palName}} by sending them a final letter!"
        student:
            action2:
                text: "Watch the video to get started!"
            action3:
                text: "Get inspired! Read a sample first letter."
            action4:
                text: "Ready to get started? Read {{palName}}'s first letter to you!"
            action5:
                text: "Exciting News! You have a letter from {{palName}}!"
            action6:
                text: "Write back to {{palName}} to continue the conversation!"
            action7:
                text: "Please revise and resend your letter based on the Moderator's recommendations."
            action8:
                text: "Check out the new magazine and send it virtually to {{palName}}, so that the two of you can read and discuss it together!"
            action10:
                text: "Exciting News! You have a letter from {{palName}}!"
            action11:
                text: "{{palName}} is eagerly waiting for your reply! Write back soon!"
            action13:
                text: "Please change your magazine selection."
            action14:
                text: "Please revise and resend your letter based on the Moderator's recommendations."
        teacher:
            action1:
                text: "Read message from an adult pen pal."
            action4:
                text: "Read the Moderator's Guide to learn about how to best support the {{appName}} pen pals!"
            action5:
                text: "Watch the child pen pal 'Welcome to {{appName}}' Video to get a sense of what the children pen pals are experiencing as they get on the platform."
            action7:
                text: "View a sample of a child pen pal's letter."
            action8:
                text: "Review and approve child pen pal letters."
            action9:
                text: "Review and approve adult pen pal letters."
            action14:
                text: "Read Moderator's Guide"
            action16:
                text: "Review and approve magazine selections"
            action17:
                text: "Review and approve adult pen pal letters."
            action18:
                text: "Review and approve child pen pal letters."
    forgotPassword:
        instructions: "Please enter the {{appName}} username for which you need password reset instructions."
        success1: " Keep your eyes peeled -- password reset instructions have been sent to the email address associated with the account. If you don't receive an email with instructions within the next 5 minutes, please contact us, at"
    forgotUsername:
        instructions: "Please enter the email address associated with the account."
        success1: "Success! A message containing the username has been sent to the email address provided. If you don't receive it within the next 5 minutes, please reach out to us, at"
    resetPassword:
        instructions: "Enter a new password below to update the password for this account:"
        error: "There was an error updating the password for this account. Try again."
        success: "Thank you. This account's password has been updated."
    signup:
        welcome: "Welcome, Pen Pal!"
        success1: "Thanks! You'll receive an email invitation onto the platform shortly."
        instructions1a: "You’ve been selected to read digital magazines and correspond with an important child in your life through the {{appName}}' safe and secure platform. There is no cost for you to participate."
        instructions1b: "The program will not begin for the child, until you sign in and complete your Pen Pal Checklist. So, please don't delay!"
        instructions2: "To confirm your willingness to participate, enter your {{appName}} username and password below."
        instructions5: "To confirm your willingness to participate, enter your {{appName}} username and password below."
        terms1: "I agree to the"
        termsLink: "Pen Pal Terms and Conditions"
        terms2: "."
    register:
        studentCreateError: "Oops! Either you've already created an account for this child or your account creation link is invalid. If you need to recover your child's username or feel you've received this message in error, please reach out to "
        session:
            signup: "Click 'Next' to enter your username and password. This will confirm your commitment to participate in {{appName}}."
    headerNav:
        classroom: "Pen Pals"
        focusArticle: "Current Magazine"