({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
Button = createFactory require 'components/shared/button'
Dropzone = createFactory require 'react-dropzone'
style = require './file_uploader.styl'
e = require('react-e/bind') style
_ = require 'lodash'

class FileUploader extends Component

  @propTypes:
    errorHeader: PropTypes.string
    onDrop: PropTypes.func.isRequired
    successMessage: PropTypes.string
    uploadErrors: PropTypes.arrayOf PropTypes.shape
      label: PropTypes.string
      value: PropTypes.arrayOf PropTypes.string
    uploadSucceeded: PropTypes.bool
    validationStatus: PropTypes.string


  _renderSuccessMessage: =>
    e 'div.success',
      e 'span.icon-check'
      e 'span', @props.successMessage


  render: ->
    e '.drag-and-drop',
      Dropzone
        accept: 'text/csv'
        activeStyle:
          backgroundColor: '#eee'
          borderStyle: 'dashed'
        onDrop: @props.onDrop
        style:
          borderRadius: 5
          borderStyle: 'dashed'
          borderWidth: 2
        e 'div',
          switch @props.validationStatus
            when 'loading'
              e '.loading',
                e '.spinner',
                  e '.bounce1'
                  e '.bounce2'
                  e '.bounce3'
            when 'complete'
              e '.message',
                if _.some _.values @props.uploadErrors
                  e '.errors',
                    if @props.successMessage
                      @_renderSuccessMessage()
                    e '.err-description',
                      e 'span.icon-cancel',
                      e 'span', @props.errorHeader
                    e 'ul.errors',
                      @props.uploadErrors.map ({label, value}, index) ->
                        e 'li.error', key: index,
                          e 'span.label', label
                          e 'ul.error-messages', value.map (message, i) ->
                            e 'li.error-message', key: i, message
                else
                  @_renderSuccessMessage()
            else
              e 'div.default-state',
                e '.item.icon-hands-raised'
                e '.item', t 'sharedComponents.fileUploader.dragAndDrop'
                e '.item.default-or', t 'sharedComponents.fileUploader.or'
                e 'div.upload-actions',
                  Button type: 'regular', t 'sharedComponents.fileUploader.browse'

FileUploader.displayName = "FileUploader"

module.exports = FileUploader
