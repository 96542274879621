(React = require 'react') and (PropTypes = require 'prop-types')
style = require './button.styl'
e = (require 'react-e/bind') style
createClass = require 'create-react-class'

Button = createClass

  getDefaultProps: ->
    enabled: yes
    fullWidth: no
    mode: 'primary'
    size: 'medium'


  propTypes:
    enabled: PropTypes.bool
    fullWidth: PropTypes.bool
    href: PropTypes.string
    mode: PropTypes.oneOf(['primary', 'primary-inverted', 'secondary', 'transparent'])
    onClick: PropTypes.func
    size: PropTypes.oneOf(['small', 'medium', 'large'])
    submit: PropTypes.bool
    type: PropTypes.oneOf(['rounded', 'regular']).isRequired


  render: ->
    tag = if @props.href then 'a' else 'button'
    classes = [
      'button'
      @props.type
      'full-width': @props.fullWidth
      "button-#{@props.size}"
      @props.mode
      disabled: not @props.enabled
      enabled: @props.enabled
    ]
    e tag,
      className: classes
      title: @props.title
      href: @props.href
      # the onMouseDown() handler is to prevent a button element
      # from getting focus on clicking while still allowing keyboard
      # use to select and trigger button for accessibilty purposes,
      # see https://stackoverflow.com/questions/19053181/how-to-remove-focus-around-buttons-on-click/37580028#37580028
#      onMouseDown: (el) => (not @props.href) and el.preventDefault()
      onClick: if @props.enabled then @props.onClick else undefined
      type: if @props.submit then 'submit' else 'button'
      disabled: not @props.enabled
    , @props.children

Button.displayName = "Button"

module.exports = Button
