({Component} = require 'react') and (PropTypes = require 'prop-types')
style = require './date_picker_card.styl'
e = require('react-e/bind') style

class DatePickerCard extends Component

  @defaultProps:
    condensed: no


  @propTypes:
    children: PropTypes.element
    condensed: PropTypes.bool
    icon: PropTypes.element
    wide: PropTypes.bool


  _displayIcon: (icon) ->
    return unless icon?

    e '.icon-container',
      e '.icon-container-circle',
        icon


  render: ->
    e '.card',
      @_displayIcon @props.icon
      e '.card-container', className: condensed: @props.condensed, wide: @props.wide,
        e '.top', @props.children
        e '.datepicker', @props.datepicker

DatePickerCard.displayName = "DatePickerCard"

module.exports = DatePickerCard
