React = require 'react'
e = require 'react-e'
{build} = require 'utils/container_helpers'
{redirect} = require 'actions/routing'

class NotFound extends React.Component

  componentDidMount: ->
    @props.dispatch redirect '/', 1000


  render: ->
    e '.dashboard', t('notFound.redirect');

NotFound.displayName = "NotFound"

module.exports = build {
  component: NotFound
  mapStateToProps: -> {}
}
