{createReducer} = require 'utils/reducers'
initialState = require 'store/initial_state'
_ = require 'lodash'

buildNewObject = (path, state, payload) ->
  path = [path] if typeof path is 'string'
  # guard to set loading to false if payload includes data or error
  payload.loading = no if payload?.data? or payload?.error
  index = path.length - 1
  newObject = payload
  while index >= 0
    updatedPath = path.slice 0, index
    oldObject = _.get state, updatedPath
    newObject = _.assign {}, oldObject, "#{path[index]}": newObject
    index -= 1
  _.assign {}, state, newObject


module.exports = createReducer

  'store': (state = initialState, {clearState, path, payload}) ->
    if clearState
      _.assign {}, initialState.store, {tenant: state.tenant}
    else
      buildNewObject path, state, payload
