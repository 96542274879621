fetch = require 'isomorphic-fetch'
store = require '../store'
url = require 'url'

_generateOpts = ({impersonate, method, payload}) ->
  token = "Bearer #{store.getState().store.session?.data?.login.loginToken.hashedToken}"
  opts =
    body: JSON.stringify payload
    headers:
      'Authorization': token
      'Content-Type': 'application/json'
    method: method
    credentials: 'include'
  if impersonate
    # Indicates we are requesting login impersonating specified username.  We need to add
    # the X-Impersonate-Username header to tell the webservice login to impersonate
    opts.headers['X-Impersonate-Username'] = impersonate
  else if store.getState().store.session?.data?.impersonate
    opts.headers['X-Impersonate-User-Id'] = store.getState().store.session?.data?.login.id
  return opts


module.exports =
  send: (options) ->
    opts = _generateOpts options
    fetch "#{options.path}", opts
      .then (response) ->
        return Promise.reject response unless response.ok
        response.json()
      .then (jsonResponse) ->
        switch jsonResponse.type
          when 'Success', 'OK', 'Created', 'DELETED', 'Added'
            return jsonResponse
          when 'Fail'
            Promise.reject new Error jsonResponse.reason
          else
            Promise.reject new Error "Unknown response type #{jsonResponse.type}"

  sendAndReceiveRaw: (options) ->
    opts = _generateOpts options
    fetch "#{options.path}", opts
      .then (response) ->
        return Promise.reject response unless response.ok
        response.text()
