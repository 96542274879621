_ = require 'lodash'
api = require '../api'
{makeAction} = require 'utils/action_helpers'
articleService = require '../services/article_selections_service'

module.exports =

  create: (data, callback) -> makeAction (setState) ->
    articleService.create(data)
      .then (data) ->
        setState ['articleSelections', 'data', data.data._id], data.data
        callback?()
      .catch (error) ->
        setState 'articleSelections', error: error.statusText


  fetch: (args, callback) -> makeAction (setState) ->
#    console.log "actions/article_selections: fetch()"
    setState 'articleSelections', loading: true
    articleService.fetch args
      .then ({data}) ->
#        console.log "actions/article_selections: .then()"
        articleSelections = _.keyBy data, '_id'
        setState 'articleSelections', data: articleSelections, loading: false
        callback?(articleSelections)
      .catch (error) ->
        setState 'articleSelections', error: error.toString(), loading: false


  update: (data, callback) -> makeAction (setState) ->
    id = data._id
    articleService.update(data)
    .then (data) ->
      setState ['articleSelections', 'data', id], data.data
      callback?()
    .catch (error) ->
      setState 'articleSelections', error: error.statusText
