_ = require 'lodash'
({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
moment = require 'moment'
style = require './letter_exchange_letter_cell.styl'
e = require('react-e/bind') style
{formatDate} = require 'utils/date_normalizer'
{formatRejectionDate} = require 'pages/teacher/classroom_unit/normalization_helper'
events = require 'pages/teacher/classroom_unit/events'

Button = createFactory require 'components/shared/button'
Popup = createFactory require 'components/shared/popup'

class LetterExchangeLetterCell extends Component

  constructor: (props) ->
    super props
    @state =
      rejectDate: ''
      rejectReason: ''
      rejectReasonPopupLeft: 0
      rejectReasonPopupTop: 0

  @propTypes =
    data: PropTypes.arrayOf(PropTypes.shape
      created: PropTypes.string
      from: PropTypes.shape
        displayName: PropTypes.string
        id: PropTypes.string
      id: PropTypes.string
      modified: PropTypes.string
      rejectMessage: PropTypes.string
      status: PropTypes.string
      subject: PropTypes.string
      text: PropTypes.string
      to: PropTypes.shape
        displayName: PropTypes.string
        id: PropTypes.string
    )
    letterDueDate: PropTypes.string
    onReviewButtonClick: PropTypes.func
    onViewButtonClick: PropTypes.func
    readOnly: PropTypes.bool
    role: PropTypes.string
    unitType: PropTypes.string


  _pendingButton: (letter) ->
    Button
      mode: 'primary-inverted'
      onClick: =>
        events.letterExchange.reviewed {role: @props.role, unit: @props.unitType}
        @props.onReviewButtonClick? letter
      type: 'regular',
      t 'letterExchangeLetterCell.review'


  _rejectReasonPopup: =>
    Popup
      bindPopup: (popup) => @rejectReasonPopup = popup
      left: @state.rejectReasonPopupLeft
      offsetLeft: -130
      offsetTop: 10
      top: @state.rejectReasonPopupTop,
      e '.reject-reason-popup-container',
        e '.reject-reason-popup-content',
          e '.reject-reason-caption', t 'focusArticleStatusCell.rejectReasonCaption'
          e '.reject-reason', @state.rejectReason
          e '.reject-date', formatRejectionDate @props.rejectDate


  _rejectedCell: (letter) =>
    e '.status-rejected',
      e '.status', t 'letterExchangeLetterCell.needsRevision'
      e '.rejected-details',
        onMouseEnter: (e) =>
          @setState
            rejectDate: letter.modified ? ''
            rejectReason: letter.rejectMessage ? ''
          @onRejectReasonHover e
        onMouseLeave: (e) => @rejectReasonPopup?.closePopup()


  _viewButton: (letter) ->
    e 'a.letter-view',
      onClick: =>
        events.letterExchange.viewed {role: @props.role, unit: @props.unitType}
        @props.onViewButtonClick? letter
      t 'letterExchangeLetterCell.view'


  onRejectReasonHover: (e) =>
    bodyRect = document.body.getBoundingClientRect()
    targetRect = e.target.getBoundingClientRect()
    @setState
      rejectReasonPopupLeft: targetRect.left - bodyRect.left
      rejectReasonPopupTop: targetRect.bottom - bodyRect.top,
      =>
        @rejectReasonPopup?.openPopup()


  render: ->
    e '.letter-exchange-letter-cell-container',
      _.map @props.data, (letter, i) =>
        status =
          if letter.status is 'revisionPending'
            t 'letterExchangeLetterCell.revisionPending'
          else if letter.status is 'revisionApproved'
            t 'letterExchangeLetterCell.revisionApproved'
          else
            letter.status
        status = @_rejectedCell letter if status is 'rejected'
        e '.letter-row',
          key: i,
          e '.letter-column-start',
            e '.letter-status',
              className: isBold: letter.status in ['missing', 'pending','revisionPending'],
              status
          e '.letter-column-center',
            e '.letter-review-button',
              switch letter.status
                when 'pending','revisionPending'
                  if @props.readOnly
                    @_viewButton letter
                  else
                    @_pendingButton letter
                when 'missing' then ''
                when 'draft'
                  if @props.role is 'student'
                    @_viewButton letter
                  else
                    ''
                else @_viewButton letter
          e '.letter-column-end',
            unless letter.status is 'missing'
              date = if letter.status is 'draft'
                letter.modified
              else
                letter.letterSent
              e '.letter-date', formatDate date
            else
              e '.letter-date',
                if @props.letterDueDate
                  t 'letterExchangeLetterCell.dateDue',
                    date: formatDate @props.letterDueDate
      @_rejectReasonPopup()

LetterExchangeLetterCell.displayName = "LetterExchangeLetterCell"

module.exports = LetterExchangeLetterCell
