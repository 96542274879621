sanitizer = require 'sanitize-html'

makeSanitizerExtendingDefaults = ({allowedAttributes, allowedTags}) -> (dirtyHtml) ->
  sanitizer dirtyHtml,
    allowedAttributes: _.merge {}, sanitizer.defaults.allowedAttributes, allowedAttributes
    allowedTags: _.concat [], sanitizer.defaults.allowedTags, allowedTags
    allowedSchemes: [ 'data', 'http', 'https' ]

defaultAllowedTags = ['a', 'h1', 'h2', 'img', 'section', 'span', 'u']

defaultAllowedAttributes = do ->
  allowedAttributes = {}
  defaultAllowedTags.forEach (tag) -> allowedAttributes[tag] = ['style']
  allowedAttributes.img = ['style', 'src', 'width', 'height']
  allowedAttributes.a = ['class', 'href', 'style']
  allowedAttributes

module.exports =
  sanitizeHtml: makeSanitizerExtendingDefaults
    allowedAttributes: defaultAllowedAttributes
    allowedTags: defaultAllowedTags

  sanitizeHtmlWithoutImageStyle: makeSanitizerExtendingDefaults
    allowedAttributes: _.assign {}, defaultAllowedAttributes, img: ['src']
    allowedTags: defaultAllowedTags
