_ = require 'lodash'
api = require '../api'
{makeAction} = require 'utils/action_helpers'
messagesService = require '../services/messages_service.coffee'

_fetchWithArguments = ({classroom, classroomUnit, noFilter} = {}) -> makeAction (setState) ->
  if classroomUnit and classroomUnit?._id isnt classroom?.currentUnit.id
    path = 'messagesForClassroomUnit'
  else
    path = 'messages'
  setState path, loading: true
  if classroom
    args =
      classroomUnitID: classroomUnit?._id or classroom?.currentUnit.id
  messagesService.fetch(args)
    .then ({data}) ->
      unless noFilter
        data = data.filter ({state}) -> state not in ['newMessageDraft', 'draft']
      #TODO: logic on the client that pulls out letters from unmatched mentors.
      # remove this when messages gets integrated
      messages = _parseData data, classroom
      if path is 'messagesForClassroomUnit'
        setState path, error: undefined, loading: false
        setState [path, 'data', classroomUnit?._id], messages
      else
        setState path, data: messages, error: undefined, loading: false
    .catch (error) ->
      setState path, error: error.statusText, loading: no


_parseData = (data, classroom) ->
  if classroom
    studentMentorGroups = classroom.studentMentorGroups or []
    mentorIDs = classroom.mentors?.map(({_id}) -> _id) or []
    matchedMentorIDs = studentMentorGroups.map ({mentor}) -> mentor
    filteredMessages = data.filter ({from}) ->
      from.id not in mentorIDs or from.id in matchedMentorIDs
  else
    filteredMessages = data
  _.keyBy filteredMessages, '_id'


messagesActions =

  clearDraft: (type, thread, cb) -> makeAction (setState, dispatch, getState) ->
#    console.log 'messages::clearDraft'
    #if type is 'new'
      #setState ['letterComposer', 'newDraftMessage'], undefined
    switch type
      when 'new'
        setState ['letterComposer', 'newDraftMessage'], undefined
      when 'revision'
        setState ['letterComposer', 'revisionCurrentDraft'], undefined
    cb?()

  create: (message, type = 'new', cb=null) -> makeAction (setState) ->
#    console.log 'messages::create'
    messagesService.create(message)
      .then ({data}) ->
#        console.log 'messages::create then handler', {data}
        switch type
          when 'new'
            setState ['letterComposer', 'newDraftMessage'], data
          when 'revision'
            setState ['letterComposer', 'revisionCurrentDraft'], data
          else
            setState ['messages', 'data', message._id], data
        cb?(data)
      .catch (error) ->
#        console.log 'messages::create catch handler'
        setState ['messages', 'error'], error.statusText


  delete: (_id, cb) -> makeAction (setState, dispatch, getState) ->
#    console.log 'messages::delete'
    messagesService.delete {_id}
      .then -> cb?()


  fetchAllForClassroom: (classroom, noFilter=false) -> makeAction (setState) ->
#    console.log 'messages::fetchAllForClassroom'
    messages = []
    Promise.all(classroom.units.map ({_id}) ->
      args =
        classroomUnitID: _id
      messagesService.fetch(args)
        .then ({data}) ->
          unless noFilter
            data = data.filter ({state}) -> state not in ['newMessageDraft', 'draft']
          messages = messages.concat data
        .catch (error) ->
          setState 'messages', error: error.statusText, loading: no
    ).then ->
#      console.log 'messages::fetchAllForClassroom then handler'
      messageObj = _parseData messages, classroom
      setState 'messagesForClassroom', data: messageObj, error: undefined, loading: false


  fetchForClassroom: (classroom, classroomUnit, noFilter=false) ->
#    console.log 'messages::fetchForClassroom'
    _fetchWithArguments {classroom, classroomUnit, noFilter}


  fetchForUserWithId: (userId) ->
#    console.log 'messages::fetchForUserWithId'
    _fetchWithArguments()


  fetchUnreadMailCount: (userId, classroomId) -> makeAction (setState) ->
#    console.log 'messages::fetchNumUnreadMessages'
    messagesService.fetchNumUnreadMessages userId, classroomId
      .then ({count}) ->
#        console.log 'messages::fetchNumUnreadMessages then handler'
        setState 'mailCount', data: count
      .catch (error) ->
#        console.log 'messages::fetchNumUnreadMessages catch handler'
        setState 'mailCount', error: error.statusText


  fetchUserClassroomMessages: (classroom, noFilter=false, cb=null) -> makeAction (setState) ->
#    console.log 'messages::fetchUserClassroomMessages'
    path = 'messages'
    setState path, loading: true
    messagesService.fetchUserClassroomMessages(classroom._id)
      .then ({data}) ->
#        console.log 'messages::fetchUserClassroomMessages then handler'
        unless noFilter
          data = data.filter ({state}) -> state not in ['newMessageDraft', 'draft']
        messages = _.keyBy data, '_id'
        setState path, data: messages, error: undefined, loading: false
        cb(data) if cb
      .catch (error) ->
#        console.log 'messages::fetchUserClassroomMessages catch handler'
        setState path, error: error.statusText, loading: no


  getNewDraftMessage: (args, cb) -> makeAction (setState) ->
#    console.log 'messages::getNewDraftMessage'
    setState ['letterComposer', 'newDraftMessage'], undefined
    messagesService.fetch _.assign args, state: 'newMessageDraft'
      .then ({data}) ->
#        console.log 'messages::getNewDraftMessage then handler', data
        #return cb?() if data.length is 0
        draft = if data.length is 0 then null else data[0]
        setState ['letterComposer', 'newDraftMessage'], draft
        cb?()


  getUpdatedDraftMessage: (type, message, from, cb) -> makeAction (setState, dispatch, getState) ->
#    console.log 'messages::getUpdateDraftMessage'
    setState ['letterComposer', "#{type}CurrentDraft"], undefined
    messagesService.fetch from: from, state: 'draft', thread: message.thread
      .then ({data}) ->
#        console.log 'messages::getUpdateDraftMessage then handler', data
        # NOTE - may need similar logic to getNewDraftMessage to clear draft
        # if data.length is 0, not sure yet...
        draft = if data.length is 0 then null else data[0]
        setState ['letterComposer', "#{type}CurrentDraft"], draft
        cb?()


  readMessage: (letter) -> makeAction (setState) ->
#    console.log 'messages::readMessage'
    id = letter._id
    data =
      _id: id
      id: id
      state: 'read'

    messagesService.update(data)
      .then ({data}) ->
#        console.log 'messages::readMessage then handler'
        setState ['messages', 'data', id], data
      .catch (error) ->
#        console.log 'messages::readMessage catch handler'
        setState ['messages', 'error'], error.statusText


  send: (message, callback) -> makeAction (setState) ->
#    console.log 'messages::send'
    messagesService.update _id: message._id, state: 'delivered', text: message.text
      .then ->
#        console.log 'messages::send then handler'
        callback?()
      .catch (error) ->
        callback? error
        setState ['messages', 'error'], error.statusText


  setPastUnitsActions: (actions) -> makeAction (setState) ->
    setState ['pastUnitsActions', 'data'], actions


  setRejectedLetter: (message, callback) -> makeAction (setState) ->
    setState ['letterComposer', 'rejectedLetter'], message
    callback?()


  update: (message, type, withStore = true, callback) -> makeAction (setState, dispatch, getState) ->
    messagesService.update(message)
      .then ({data}) ->
        if withStore
          switch type
            when 'new'
              setState ['letterComposer', 'newDraftMessage'], data
            when 'revision'
              setState ['letterComposer', 'revisionCurrentDraft'], data
            else
              setState ['messages', 'data', message._id], data
        callback?()
      .catch (error) ->
        errorStr = "#{error.message} (update message #{message._id})"
        callback? errorStr
        setState ['messages', 'error'], errorStr


  updateStatus: (letter, status, rejectMessage = '') -> makeAction (setState, dispatch, getState) ->
#    console.log 'messages::updateStatus'
    id = letter._id

    data =
      _id: id
      id: id
      rejectMessage: rejectMessage
      status: status
      text: letter.text

    messagesService.update(data)
      .then ({data}) ->
#        console.log 'messages::updateStatus then handler'
        setState ['messages', 'data', id], data
        classroomUnit = letter.classroomUnitID
        setState ['messagesForClassroomUnit', 'data', classroomUnit, id], data
        setState ['messagesForClassroom', 'data', id], data
      .catch (error) ->
        setState ['messages', 'error'], error.statusText

module.exports = messagesActions
