_ = require 'lodash'
({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './table.styl'
e = require('react-e/bind') style

PagingNavigation = createFactory require 'components/shared/paging_navigation'
TableFilter = createFactory require 'components/shared/table_filter'

class Table extends Component

  @defaultProps:
    isPagingEnabled: true


  @propTypes =
    columns: PropTypes.arrayOf(PropTypes.shape
      didClickAscending: PropTypes.func
      didClickDescending: PropTypes.func
      flex: PropTypes.number
      isDisabled: PropTypes.bool
      isSeparator: PropTypes.bool
      isSortingDisabled: PropTypes.bool
      header: PropTypes.string
      headerFlex: PropTypes.number
      headerIsCentered: PropTypes.bool
      key: PropTypes.string
      onValueClick: PropTypes.func
      sortOrder: PropTypes.oneOf ['ascending', 'descending', 'none']
      transformValue: PropTypes.func
    ).isRequired
    data: PropTypes.arrayOf(PropTypes.object).isRequired
    isPagingEnabled: PropTypes.bool
    onClickHeader: PropTypes.func
    paging: PropTypes.shape
      currentRange: PropTypes.string.isRequired
      isNextEnabled: PropTypes.bool
      isPreviousEnabled: PropTypes.bool
      onNextClick: PropTypes.func.isRequired
      onPreviousClick: PropTypes.func.isRequired
      totalItems: PropTypes.string.isRequired


  _headerRow: =>
    e '.table-header-row',
      _.map @props.columns, (column, i) =>
        return e '.separator', key: i if column.isSeparator
        e '.table-column-header',
          className: divider: column.isSeparator
          key: i
          style:
            flex: column.headerFlex ? column.flex ? 1
            justifyContent: if column.headerIsCentered then 'center' else 'flex-start'
          column.header
          unless column.isSortingDisabled
            e '.table-column-filter',
              TableFilter
                column: column.key
                onClickHeader: @props.onClickHeader
                didClickAscending: column.didClickAscending
                didClickDescending: column.didClickDescending
                sortOrder: column.sortOrder


  _pagingNavigation: =>
    return unless @props.isPagingEnabled
    e '.letter-list-paging',
      PagingNavigation
        currentRange: @props.paging.currentRange
        isNextEnabled: @props.paging.isNextEnabled
        isPreviousEnabled: @props.paging.isPreviousEnabled
        onNextClick: @props.paging.onNextClick
        onPreviousClick: @props.paging.onPreviousClick
        totalItems: @props.paging.totalItems


  render: ->
    e '.table-container',
      e '.table',
        @_headerRow()
        _.map @props.data, (data, i) =>
          e '.table-row', key: i,
            _.map @props.columns, (column, j) ->
              return e '.separator', key: j if column.isSeparator
              return e '.empty', key: j if column.isDisabled
              e '.table-column',
                className: [if column.onValueClick? then 'clickable']
                key: j
                onClick: -> column.onValueClick? data
                style:
                  flex: if column.flex then column.flex else 1
                if column.transformValue
                  column.transformValue data
                else
                  data["#{column.key}"] or ''
      @_pagingNavigation()

Table.displayName = "Table"

module.exports = Table
