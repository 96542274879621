import React from 'react';
import PropTypes from 'prop-types';
const style = require('./editor_view.styl');
const cn = require('classnames/bind').bind(style);
import EditorView from './editor_view';
import PlanContent from './plan_content';
const events = require('pages/shared/letter_center/events'); // TODO: should this be customized for onboarding or can we just ditch
import ComponentHelpers from 'utils/component_helpers';
import TabView from 'components/shared/tab_view';
import ModalPlanLocked from './modal_plan_locked';
import ModalPlanReset from './modal_plan_reset';
import ModalSentConfirmation from './modal_sent_confirmation';

export default class LetterComposerTabs extends React.Component {
  constructor(props) {
    super(props);

    let letter = props.letter;
    if (!letter) {
      letter = {
        status: 'draft',
        rejectMessage: undefined,
        answers: props.answers || [],
        locked: false,
        text: '',
      }
    }
    let initialStage = (props.showPlanner && !letter.locked) ? 'plan' : 'draft';
    if (letter.rejectMessage) {
      initialStage = 'revise';
    }

    this.state = {
      stage: initialStage,
      letter,
      isResetModalVisible: false,
      isLockModalVisible: false,
      isSentModalVisible: false,
    };

    this.renderEditorView = this.renderEditorView.bind(this);
    this.onSelectTab = this.onSelectTab.bind(this);
  }

  _getStages() {
    if (this.props.showPlanner) {
      return ['plan', 'draft', 'revise'];
    } else {
      return ['draft', 'revise'];
    }
  }

  _getLetterText() {
    const greeting = `${t('letterComposerTabView.greeting', {name: this.props.palFirstName})}`;
    const closing = `${t('letterComposerTabView.sincerely')}</p><p>${this.props.firstName}`;
    const answers = this.state.letter.answers.filter(x => !!x);
    const initialEditorValues = _.concat(greeting, answers, closing);
    const htmlEditorValues = initialEditorValues.map(value => `<p>${value}</p>`);
    return htmlEditorValues.join('<p><br></p>');
  }

  onChange(field, val) {
    this.setState(ComponentHelpers.onChangeHandler(field, val), () => {
      const callbackData = Object.assign({}, this.state.letter);
      if (field === 'letter.answers') {
        callbackData.text = this._getLetterText();
        // If we are making modifications to the answers, then by definition we are NOT locked.
        // This line is required to ensure that the locked flag is explicitly set to false, even
        // if it was undefined in the original letter prop
        callbackData.locked = false;
      }
      this.props.onChange(callbackData);
    });
  }

  // Clicked the "Next Step" button on the planner tab.  Show the lock modal
  onClickPlanNext() {
    if (this.state.letter.locked) {
      this.setState({
        stage: 'draft'
      });
    } else {
      return this.setState({
        isLockModalVisible: true,
      });
    }
  }

  // Clicked the "Start Over" button on the planner
  onClickPlanReset() {
    return this.setState({
      isResetModalVisible: true
    });
  }

  // Clicked the "Start Over" button on the reset confirmation modal
  onClickModalStartOver() {
    events.write.startOver();

    const {onClickStartOver, onChange} = this.props;
    const letter = {
      status: 'draft',
      rejectMessage: undefined,
      answers: [],
      locked: false,
      text: '',
    };
    this.setState((prevState) => {
          return {
            isResetModalVisible: false,
            letter,
            stage: this._getStages()[0]
          }
        },
        () => {
          window.scrollTo(0, 0);
          if (onClickStartOver) {
            onClickStartOver(letter);
          } else if (onChange) {
            onChange(letter);
          }
        }
    );
  }

  // Clicked the "Keep Planning" button on the lock message modal
  onClickModalKeepPlanning() {
    events.write.keepPlanning();
    return this.setState({
      isLockModalVisible: false,
      isResetModalVisible: false,
    });
  }

  // Clicked the "Continue" button on the lock message modal:
  //
  //   - Move to the draft tab
  //   - Populate the letter with the results of the planner
  //   - Set the letter locked flag
  onClickModalContinue() {
    const letter = Object.assign({}, this.state.letter );
    letter.text = this._getLetterText();
    letter.locked = true;

    this.setState({
      isLockModalVisible: false,
      stage: 'draft',
      letter,
    });

    this.props.onChange(letter);
  }

  onClickModalDismiss() {
    const {onClickSent} = this.props;
    this.setState({isSentModalVisible: false} );
    if (onClickSent) {
      onClickSent();
    }
  }

  onClickSaveAndExit() {
    const { onClickCancel } = this.props;
    if (onClickCancel) {
      onClickCancel();
    }
  }

  onClickSend() {
    const {onClickSend, onChange} = this.props;

    if (onClickSend) {
      const isSent = onClickSend();
      if (isSent) {
        const letter = Object.assign({}, this.state.letter);
        letter.status = 'review';
        this.setState({
          isSentModalVisible: true,
          letter,
        });
      }
    } else {
      const letter = Object.assign({}, this.state.letter);
      letter.status = 'review';
      this.setState({
        isSentModalVisible: true,
        letter,
      });
      onChange(letter);
    }
  }

  onSelectTab(index, lastIndex, ev) {
    if (this.state.stage === 'plan' && !this.state.letter.locked) {
      return this.setState({
        isLockModalVisible: true,
      });
    }

    const nextStage = this._getStages()[index];
    if (nextStage === 'draft') {
      events.write.draftTab();
    } else if (nextStage === 'revise') {
      events.write.reviseTab();
    }
    return this.setState({
      stage: nextStage
    });
  }

  renderEditorView(mode, onClickNext) {
    return (
        <EditorView
            allowAttachments={this.props.allowAttachments}
            events={this.props.events}
            feedback={this.state.letter.rejectMessage}
            initialEditorValue={this.state.letter.text}
            mode={mode}
            onCancelButtonClick={() => this.onClickSaveAndExit()}
            onEditorValueChange={(text) => this.onChange('letter.text', text )}
            onNextClick={onClickNext}
            reply={this.props.reply}
            userType={this.props.role} />
    );
  }

  render() {
    let tabs = [];
    if (this.props.showPlanner) {
      tabs.push({
        content: <PlanContent questions={this.props.questions}
                              answers={this.state.letter.answers}
                              isPlanEditable={!this.state.letter.locked}
                              firstName={this.props.firstName}
                              palFirstName={this.props.palFirstName}
                              feedback={this.state.letter.rejectMessage}
                              events={this.props.events}
                              onChange={(answers) => this.onChange('letter.answers', answers)}
                              onClickReset={() => this.onClickPlanReset()}
                              onClickNext={() => this.onClickPlanNext()}
                              onClickCancel={() => this.onClickSaveAndExit()} />,
        editable: !this.state.letter.locked,
        name: t('letterComposerTabView.plan')
      });
    }

    tabs = tabs.concat([
      {
        content: this.renderEditorView('draft', () => this.setState({stage: 'revise'})),
        name: t('letterComposerTabView.draft')
      },
      {
        content: this.renderEditorView('revise', () => this.onClickSend() ),
        name: t('letterComposerTabView.reviseAndSend')
      },
    ]);

    const selectedIndex = this._getStages().indexOf(this.state.stage);

    return (
        <div className={cn('letter-composer-tab-view')}>
          {this.state.isLockModalVisible &&
          <ModalPlanLocked onClickKeepPlanning={() => this.onClickModalKeepPlanning()}
                           onClickContinue={() => this.onClickModalContinue()}
                           onClose={() => this.onClickModalKeepPlanning()}/>
          }
          {this.state.isResetModalVisible &&
          <ModalPlanReset onClickStartOver={() => this.onClickModalStartOver()}
                          onClose={() => this.onClickModalKeepPlanning()} />
          }
          {this.state.isSentModalVisible &&
          <ModalSentConfirmation onClose={() => this.onClickModalDismiss()} />
          }

          <TabView onSelect={this.onSelectTab}
                   selectedIndex={selectedIndex}
                   tabs={tabs}
                   withPadding={false}/>
        </div>
    );
  }
}

LetterComposerTabs.displayName = "LetterComposerTabs";
LetterComposerTabs.propTypes = {
  role: PropTypes.oneOf(['mentor', 'student']).isRequired,
  showPlanner: PropTypes.bool,
  questions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    bullets: PropTypes.array,
    postBullet: PropTypes.string,
  })).isRequired,

  letter: PropTypes.shape({
    status: PropTypes.oneOf(['draft', 'review', 'approved', 'rejected']),
    rejectMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    answers: PropTypes.array,
    locked: PropTypes.bool,
    text: PropTypes.string,
  }),

  onChange: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickSend: PropTypes.func,
  onClickSent: PropTypes.func.isRequired,
  onClickStartOver: PropTypes.func,

  allowAttachments: PropTypes.bool,
  events: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  palFirstName: PropTypes.string.isRequired,
  reply: PropTypes.bool,  // TODO: How was this used?
};

LetterComposerTabs.defaultProps = {
  showPlanner: true,
  allowAttachments: false,
};
