import getAppName from 'utils/app_name'

events = require 'pages/shared/utils/events'
letterEvents = require 'pages/shared/letter_center/events'
usersActions = require 'actions/users'

welcomeVideoIdDefault = '1_gnxkgkh6'
allAboutMeLinkDefault = 'https://cm-cdn.s3.amazonaws.com/crickettogether/units/GettingStarted/resources/mentor' +
      '/CT-AllAboutMeLetters-mentor.pdf'

welcomeVideoCoverDefault = 'https://cm-cdn.s3.amazonaws.com/crickettogether/images/Cricket-Together' +
  '-still2-retina.png'

module.exports = ({dispatch, userId, classroom, userState, tenant}) ->

  updateAppState = (appState) ->
    dispatch usersActions.updateAppState {id: userId, classroomId: classroom._id, appState}

  palName = (prefix = "your ") -> userState.pal?.firstname or t 'home.defaultPalName', prefix: prefix

  actionItems: [
    active: ({mentorMessagesUnread}) ->
      mentorMessagesUnread
    icon: 'message'
    redirect: '/mail'
    text: t 'actionItems.mentor.action1.text'
  ,
    active: ({introUnit, initialSurveyComplete, initialSurveyId}) ->
      introUnit and not initialSurveyComplete and initialSurveyId
    icon: 'write-letter'
    redirect: "/survey/#{classroom.initialSurveyIdMentor}/#{classroom._id}/#{userId}?type=initial"
    text: t 'actionItems.mentor.action2.text', appName: getAppName(tenant.data, classroom)
  ,
    active: ({introUnit, receivedLetterApproved, receivedMultipleLettersApproved, displayUnreadLetterAction}) ->
      introUnit and receivedLetterApproved and not receivedMultipleLettersApproved and displayUnreadLetterAction
    icon: 'read-letter'
    redirect: '/letters'
    text: t 'actionItems.mentor.action3.text', palName: palName()
  ,
    active: ({introUnit, receivedMultipleLettersApproved, displayUnreadLetterAction}) ->
      introUnit and receivedMultipleLettersApproved and displayUnreadLetterAction
    icon: 'read-letter'
    redirect: '/letters'
    text: t 'actionItems.mentor.action4.text', palName: palName()
  ,
    active: ({palNeedsReply, introUnit, receivedLetterApproved}) ->
      introUnit and receivedLetterApproved and palNeedsReply
    icon: 'write-letter'
    redirect: '/letters'
    text: t 'actionItems.mentor.action5.text', palName: palName()
  ,
    active: ({introUnit, sentLetterRejected}) ->
      introUnit and sentLetterRejected
    icon: 'write-letter'
    redirect: '/letters/compose'
    text: t 'actionItems.mentor.action6.text'
  ,
    active: ({introUnit, unitVideoViewed, currentUnit}) ->
      not introUnit and currentUnit?.unitVideoMentor?.id and not unitVideoViewed
    event: -> events.clickedVideo 'action item'
    done: -> updateAppState {"#{classroom?.currentUnit?.id}": {unitVideoViewed: true}}
    icon: 'watch-video'
    text: t 'actionItems.mentor.action7.text', unitVideoDesc: classroom?.currentUnit?.unitVideoMentor?.description ? 'Watch a video'
    video: classroom?.currentUnit?.unitVideoMentor?.id
  ,
    active: ({prevUnitDraft, articleSelectionApproved, introUnit, sentLetter}) ->
      not prevUnitDraft and articleSelectionApproved and not sentLetter and not introUnit
    icon: 'write-letter'
    redirect: '/letters/compose?show_article=true'
    text: t 'actionItems.mentor.action8.text', palName: palName()
  ,
    active: ({prevUnitDraft, articleSelectionApproved, introUnit, sentLetter}) ->
      prevUnitDraft and not introUnit
    icon: 'write-letter'
    redirect: '/letters'
    text: t 'actionItems.mentor.action9.text'
  ,
    active: ({displayUnreadLetterAction, introUnit}) ->
      not introUnit and displayUnreadLetterAction
    icon: 'read-letter'
    redirect: '/letters'
    text: t 'actionItems.mentor.action10.text', palName: palName()
  ,
    active: ({introUnit, sentLetterRejected}) ->
      sentLetterRejected and not introUnit
    icon: 'write-letter'
    redirect: '/letters/compose'
    text: t 'actionItems.mentor.action11.text'
  ,
    active: ({currentUnit, appState, sentLetter}) ->
      currentUnit.isReflectionUnit and not sentLetter #not appState.reflectionStarted
    done: -> updateAppState reflectionStarted: true
    icon: 'write-letter'
    redirect: '/letters/compose'
    text: t 'actionItems.mentor.action12.text', palName: palName()
  ,
    active: ({currentUnit, finalSurveyComplete, finalSurveyId}) ->
      currentUnit.isReflectionUnit and not finalSurveyComplete and finalSurveyId
    icon: 'write-letter'
    redirect: "/survey/#{classroom.finalSurveyIdMentor}/#{classroom._id}/#{userId}?type=final"
    text:  t 'actionItems.mentor.action13.text', appName: getAppName(tenant.data, classroom)
  ]

  topContents: [
    # keeping this stuff in here for now commented out for now in case we
    # want to reintroduce some of these states or variations of them...
    #
    # "Intro State 1"
    #action: 'Watch This Video And Find Out More!'
    active: ({introUnit}) ->
      introUnit
    description: t 'topContents.mentor.top1.unitDesc'
    event: -> events.clickedVideo 'top card'
    hideFlag: yes
    image: tenant.data.mentor?.welcomeVideoCover ? welcomeVideoCoverDefault
    video: tenant.data.mentor?.welcomeVideoIdMentor ? welcomeVideoIdDefault
    welcome: ({mentorDate}) ->
      t 'topContents.mentor.top1.welcome'

  ,
    # "Intro State 2"
    #action: 'Read Stories about exchanging letters'
    active: ({introUnit, sentLetter}) ->
      introUnit and sentLetter
    #redirect: '/units'
    welcome:  t 'topContents.mentor.top2.welcome', palName: palName()
    hideDate: yes
  ,
    # "Intro State 3"
    #action: 'Read Your Letter'
    active: ({introUnit, receivedLetterApproved, sentLetter}) ->
      introUnit and sentLetter and receivedLetterApproved
    #redirect: '/letters'
    welcome: t 'topContents.mentor.top3.welcome', palName: palName()
    hideDate: yes
  ,
    active: ({introUnit, receivedLetterApproved, sentMultipleLetters}) ->
      introUnit and sentMultipleLetters and receivedLetterApproved
    welcome: t 'topContents.mentor.top4.welcome', palName: palName()
    hideDate: yes
  ,
    # "Non-Intro State 1"
    active: ({hasFocusArticle}) ->
      hasFocusArticle
    #redirect: '/units'
    welcome: t 'topContents.mentor.top5.welcome', palName: palName()
    hideDate: yes
  ,
    # "Non-Intro State 2"
    #action: "Read Your Student's Focus Article"
    active: ({articleSelectionApproved, introUnit}) ->
      articleSelectionApproved and not introUnit
    #redirect: '/units'
    welcome: t 'topContents.mentor.top6.welcome', palName: palName()
    hideDate: yes
  ,
    # "Non-Intro State 3"
    #action: 'Read Your Letter'
    active: ({articleSelectionApproved, introUnit, sentLetter}) ->
      articleSelectionApproved and sentLetter and not introUnit
    #redirect: '/letters'
    welcome: t 'topContents.mentor.top7.welcome', palName: palName()
    hideDate: yes
  ,
    active: ({articleSelectionApproved, introUnit, receivedLetterApproved, sentLetter}) ->
      articleSelectionApproved and sentLetter and receivedLetterApproved and not introUnit
    #redirect: '/letters'
    welcome: t 'topContents.mentor.top8.welcome'
    hideDate: yes
  ,
    # "Non-Intro State 4"
    #action: 'Read Your Letter'
    active: ({articleSelectionApproved, introUnit, receivedLetterApproved, displayUnreadLetterAction}) ->
      not introUnit and displayUnreadLetterAction
      #redirect: '/letters'
    welcome: t 'topContents.mentor.top9.welcome', palName: palName()
    hideDate: yes
  ,
    active: ({sentLetterRejected}) ->
        sentLetterRejected
    welcome: t 'topContents.mentor.top10.welcome'
    hideDate: yes
  ,
    # "Completed State 1"
    active: ({classroomStatusIsCompleted}) -> classroomStatusIsCompleted
    hideFlag: yes
    image: 'http://cm-cdn.s3.amazonaws.com/crickettogether/images/CT_completed.jpg'
    welcome: t 'topContents.mentor.top11.welcome', {appName: getAppName(tenant.data, classroom), email: tenant.data.contactEmail ? 'support@crickettogether.com'}





  ]