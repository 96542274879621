_ = require 'lodash'
moment = require 'moment'
({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './letter_modal.styl'
e = require('react-e/bind') style
{sanitizeHtml} = require 'utils/sanitize_html'
Button = createFactory require 'components/shared/button'
PrintLetters = createFactory require 'components/print_letters'

class LetterModal extends Component

  @defaultProps:
    currentMessageInConversation: 0
    reduced: false
    totalMessagesInConversation: 0


  @propTypes =
    events: PropTypes.object
    indexOfMessageInConversation: PropTypes.number
    isInReviewMode: PropTypes.bool
    isTotalNumberInfoBoxHidden: PropTypes.bool
    message: PropTypes.shape(
      classroomUnitID: PropTypes.string
      date: PropTypes.string
      from: PropTypes.string
      id: PropTypes.string
      subject: PropTypes.string
      text: PropTypes.string
      to: PropTypes.string
    ).isRequired
    onReviewModeApproveButtonClick: PropTypes.func
    onReviewModeRejectButtonClick: PropTypes.func
    print: PropTypes.bool
    role: PropTypes.string
    totalMessagesInConversation: PropTypes.number
    unitType: PropTypes.string


  _letterContentColumn: =>
    contentClass = if @props.contentClass then ".#{@props.contentClass}" else ""
    e '.letter-content-column',
      e '.letter-modal-header',
        if @props.message?.from?
          [
            if @props.message?.letterSent? or @props.message?.letterApproved?
              e '.header-row', key: 'row1',
                if @props.message?.letterSent?
                  sent = moment(@props.message.letterSent).format('MMM D, YYYY')
                  e '.header-sent',
                    e '.header-key', 'Sent: '
                    e '.header-value', sent
                if @props.message?.letterApproved?
                  approved = moment(@props.message.letterApproved).format('MMM D, YYYY')
                  e '.header-approved',
                    e '.header-key', 'Approved: '
                    e '.header-value', approved
            e '.header-row', key: 'row2',
              e '.header-key', 'Subject: '
              e '.header-value', @props.message?.subject
            e '.header-row', key: 'row3',
              e '.header-key', t 'letterModal.headerFrom'
              e '.header-value', @props.message?.from
              e '.header-key', t 'letterModal.headerTo'
              e '.header-value', @props.message?.to
            if @props.message?.status is 'rejected' or @props.message?.status is 'revisionPending'
              rejected = moment(@props.message.letterRejected).format('MMM D, YYYY')
              e '.header-row', key: 'row4',
                e '.header-reject-message', t 'letterModal.rejectMessage', rejectedDate: rejected, rejectedMsg: @props.message?.rejectMessage
          ]
      e '.letter-content-container',
        e ".letter-content#{contentClass}",
          dangerouslySetInnerHTML: __html: sanitizeHtml @props.message?.text
      e '.letter-index-container',
        unless @props.isTotalNumberInfoBoxHidden
          e '.letter-index-content',
            e '.letter-index-row-range',
              e '.letter-index', @props.indexOfMessageInConversation.toString()
              e '.letter-separator', t 'letterModal.lettersInConversationOf'
              e '.letter-index', @props.totalMessagesInConversation.toString()
            e '.letter-index-row-caption', t 'letterModal.lettersInConversation'


  constructor: (props) ->
    super()
    @state =
      isAboutToReject: false
      rejectMessage: ''


  onRejectedTextfieldValueChange: (target) =>
    @setState rejectMessage: target.target.value


  render: ->
    e '.letter-modal',
      e '.letter-modal-container',
        if @props.print
          PrintLetters
            bulk: no
            className: 'letter-print-contents'
            events: @props.events
            letters: [@props.message]
            role: @props.role
            unitType: @props.unitType
        @_letterContentColumn()
      if @props.isInReviewMode
        e '.letter-review-container',
          unless @state.isAboutToReject
            e '.letter-review-modal-buttons',
              e '.letter-review-modal-button',
                Button
                  onClick: =>
                    if @props.events?
                      @props.events.letterExchange.approved
                        role: @props.role
                        unit: @props.unitType
                    @props.onReviewModeApproveButtonClick @props.message
                  type: 'regular'
                  t 'letterModal.approve'
              e '.letter-review-modal-button',
                Button
                  onClick: =>
                    if @props.events?
                      @props.events.letterExchange.clickedRevision
                        role: @props.role
                        unit: @props.unitType
                    @setState isAboutToReject: true
                  type: 'regular'
                  t 'letterModal.askForRevision'
          else
            e '.letter-review-reject-reason-container',
              e '.rejected-caption',
                t 'letterModal.reviseInstruction'
              e 'textarea.rejected-textfield', onBlur: @onRejectedTextfieldValueChange
              e '.rejected-buttons',
                e '.buttons-container',
                  e '.letter-review-reject-button',
                    Button
                      mode: 'secondary'
                      onClick: =>
                        if @props.events?
                          @props.events.letterExchange.canceledRejection
                            role: @props.role
                            unit: @props.unitType
                        @setState isAboutToReject: false
                      type: 'regular',
                      t 'letterModal.cancel'
                  e '.letter-review-reject-button',
                    Button
                      onClick: =>
                        if @props.events?
                          @props.events.letterExchange.rejected
                            role: @props.role
                            unit: @props.unitType
                            value: @state.rejectMessage.length
                        @props.onReviewModeRejectButtonClick @props.message, @state.rejectMessage
                      type: 'regular'
                      t 'letterModal.submit'

LetterModal.displayName = "LetterModal"

module.exports = LetterModal
