({Component} = require 'react') and (PropTypes = require 'prop-types')
style = require './count_cards.styl'
e = require('react-e/bind') style

class CountCards extends Component

  @propTypes:
    cards: PropTypes.arrayOf( PropTypes.shape
      count: PropTypes.number.isRequired
      heading: PropTypes.string.isRequired
      onClick: PropTypes.func.isRequired
      total: PropTypes.number.isRequired
      secLink: PropTypes.string
      secOnClick: PropTypes.func
    ).isRequired


  render: ->
    {cards} = @props

    e '.count-cards', cards.map ({type, count, countList, heading, onClick, secLink, secOnClick, total}, i) ->
      if type is 'student'
        e '.card', key: i,
            e 'h3.heading', heading
            e '.counts', countList.map ({title,count,link,filter}, i) ->
              e '.numbers', key: i,
                e '.number', count
                e '.count-title', title
                if link?
                  e 'a.footer', onClick: -> onClick filter,
                  link
      else
        e '.card', key: i,
          e 'h3.heading', heading
          e '.numbers',
            e '.number', count
            e '.of', 'of'
            e '.number', total
          e 'a.footer', onClick: onClick,
            t 'topContents.focusArticleMissing', count: total - count
          if secLink and secOnClick
            e 'a.footer.secondary', onClick: secOnClick,
              secLink

CountCards.displayName = "CountCards"

module.exports = CountCards
