import React from 'react';
import PropTypes from 'prop-types';
import {showDetail} from 'utils/article_normalizer';
const style = require('./unit_preview.styl');
const cn = require('classnames/bind').bind(style);
import events from 'pages/student/units/events';
import teacherEvents from 'pages/teacher/units/events';
import _ from 'lodash';
import {redirect} from 'actions/routing';
import {getUnitDates} from 'utils/date_normalizer';
import moment from 'moment';
import Button from 'components/shared/button';
import Card from 'components/shared/card';
import Link from 'containers/link';
import UnitDetail from 'components/unit_detail';
import Carousel from 'components/shared/carousel';
import ArticlePreview from 'components/shared/article_preview';
import UnitDetailDatePicker from 'components/unit_detail_date_picker';

class UnitPreviewBody extends React.Component {

  constructor(props, context) {
    super(props, context);

    const {allowAttachments, classroomUnit, unitTemplate} = props;
    let articleMap = _.keyBy( unitTemplate.content, '_id' );
    let dates = {};
    if (classroomUnit) {
      const {endDate, startDate, unitDates = {}} = classroomUnit;
      const {focusArticleDate, mentorDate, studentDate, mentor2Date, student2Date} = unitDates;
      dates = {startDate, endDate, focusArticleDate, mentorDate, mentor2Date, studentDate, student2Date};
      dates = _.mapValues(dates, d => d ? moment(d) : undefined);
      articleMap = _.keyBy(classroomUnit.content, '_id');
    }

    this.state = {
      allowAttachments,
      articleMap,
      dates,
    };

    this.onClickButton = this.onClickButton.bind(this);
    this.onClickToggleAttachments = this.onClickToggleAttachments.bind(this);
  }

  makeUnitDetailDates() {
    const nonEmptyDateKeys = [];
    const dates = getUnitDates(this.props.unitTemplate);
    dates.forEach(milestone => {
      if (this.state.dates[milestone.id]) {
        nonEmptyDateKeys.push(milestone.id);
      }

      const lastNonEmptyDateKey = nonEmptyDateKeys[nonEmptyDateKeys.length - 1];
      milestone.initialVisibleMonthFallback = lastNonEmptyDateKey ? this.state.dates[lastNonEmptyDateKey] : undefined;
      milestone.onChange = (value) => {
        teacherEvents.selectedDate(milestone.id, this.props.mode, this.props.state);
        return this.setState(({dates}) => ({dates: _.assign({}, dates, {[milestone.id]: value})}));
      };
      milestone.value = this.state.dates[milestone.id];
    });
    return dates;
  }

  onClickButton(ev) {
    const {buttonOnClickWithState} = this.props;
    if (buttonOnClickWithState) {
      const { ctrlKey, altKey } = ev;
      const testMode = ctrlKey && altKey;
      buttonOnClickWithState(this.state, testMode);
    }
  }

  onClickArticleRead(article) {
    events.read(article.title, this.props.mode)
  }

  onClickArticleLink(article) {
    events.details(article.title);
    return showDetail({
      articleId: article._id,
      articleName: article.name,
      redirectTo: path => this.props.dispatch(redirect(path)),
      unitTemplateId: this.props.unitTemplate._id
    });
  }

  onSelectArticle(article) {
    return this.setState(function (state) {
      state.articleMap[article._id] = state.articleMap[article._id] ? undefined : article;
      return state;
    });
  }

  onClickToggleAttachments() {
    teacherEvents.toggledAttachments(!this.state.allowAttachments);
    return this.setState({allowAttachments: !this.state.allowAttachments});
  }

  render() {
    const {mode, unitTemplate, linkContent, linkOnClick, displayLink, buttonContent} = this.props;
    const isReflectionUnit = this.props.unitTemplate.isReflectionUnit;
    const isDefaultUnit = this.props.unitTemplate.isDefaultUnit;
    const buttonIsEnabled = this.props.buttonIsEnabled(Object.assign({}, this.state, { isDefaultUnit, isReflectionUnit }));
    const sortedArticles = _.sortBy(unitTemplate.content, 'lexile');
    const unitDetailDates = this.props.datePickerShouldShow ? this.makeUnitDetailDates() : [];

    return (
      <div className={cn('unit-template')}>
        <div className={cn('header')}>
          <Card>
            <div className={cn('header_top')}>
              <div className={cn('title_row')}>
                <div className={cn('title')}>{unitTemplate.name}</div>
                {linkContent && linkOnClick && displayLink &&
                <div className={cn('link')}>
                  <Link onClick={linkOnClick}>{linkContent}</Link>
                </div>
                }
                <div className={cn('button')}>
                  <Button size='large' type='regular' enabled={buttonIsEnabled} onClick={this.onClickButton}>
                    {buttonContent}
                  </Button>
                </div>
              </div>
              <UnitDetail unit={unitTemplate} mode={mode} />
            </div>
            <div className={cn('header_bottom')}>
              <UnitDetailDatePicker mode={mode} dates={unitDetailDates}
                                    isEditable={this.props.datePickerIsEditable}
                                    shouldOpenFirstPicker={this.props.datePickerShouldOpenFirstPicker}
                                    shouldStartWithToday={this.props.datePickerShouldStartWithToday} />
            </div>
          </Card>
        </div>
        {this.props.datePickerIsEditable &&
        <div className={cn('attachments-card')}>
          <Card>
            <div className={cn('attachments')}>
              <div className={cn('text')}>
                <div className={cn('header')}>{t('unitPreview.allowAttachments')}</div>
                <div className={cn('copy')}>{t('unitPreview.attachmentsDescription')}</div>
              </div>
              <div className={cn('control')}>
                <div className={cn('setting')}>{t('unitPreview.disallow')}</div>
                <div className={cn('toggle-area', {on: this.state.allowAttachments})}
                     onClick={this.onClickToggleAttachments}>
                  <div className={cn('toggle-button', {on: this.state.allowAttachments})}/>
                </div>
                <div className={cn('setting')}>{t('unitPreview.allow')}</div>
              </div>
            </div>
          </Card>
        </div>
        }
        {!unitTemplate.isDefaultUnit && !unitTemplate.isReflectionUnit &&
        <div>
          <div className={cn('instructions')}>
            <h2 className={cn('title')}>{this.props.descriptionHeader}</h2>
            <div className={cn('description')}>{this.props.descriptionText}</div>
          </div>
          <Carousel>
            {sortedArticles.map(article => {
              const _id = article._id;
              return (
                  <div key={_id} className={cn('article')}>
                    <ArticlePreview article={article} scheme='plain'
                                    focus={this.props.selectedArticleIds.includes(_id) ? 'disabled' : undefined}
                                    onClickLink={() => this.onClickArticleLink(article)}
                                    onClickRead={() => this.onClickArticleRead(article)}
                                    selectType={this.props.showChecks ? 'check' : null}
                                    isSelected={!!this.state.articleMap[_id]}
                                    onSelect={() => this.onSelectArticle(article)}/>
                  </div>
              );
            })}
          </Carousel>
        </div>
        }
      </div>
    );
  }
}

UnitPreviewBody.displayName = "UnitPreviewBody";
UnitPreviewBody.defaultProps = {
  selectedArticleIds: []
};

const {arrayOf, bool, func, object, oneOfType, shape, string} = PropTypes;
const Unit = shape({content: arrayOf(object)}).isRequired;

UnitPreviewBody.propTypes = {
  allowAttachments: bool,
  buttonIsEnabled: func.isRequired,
  buttonOnClickWithState: func,
  buttonContent: string.isRequired,
  classroomUnit: object,
  datePickerIsEditable: bool.isRequired,
  datePickerShouldOpenFirstPicker: bool.isRequired,
  datePickerShouldShow: bool.isRequired,
  datePickerShouldStartWithToday: bool.isRequired,
  descriptionHeader: string.isRequired,
  descriptionText: oneOfType([string, object]).isRequired,
  displayLink: bool,
  linkContent: string,
  linkOnClick: func,
  selectedArticleIds: arrayOf(string),
  showChecks: bool.isRequired,
  toggleAllowAttachments: func,
  unitTemplate: object.isRequired,
};

module.exports = UnitPreviewBody;
