import React from 'react';
import PropTypes from 'prop-types';
const countries = require('utils/countries.json');
const usersService = require('services/users_service');

export default class ZipcodePicker extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      zipcode: props.value || '',
      city: '',
      state: '',
    };
  }

  componentDidMount() {
    // If the zipcode has been set already at the time the component is mounted,
    // fire off an onChange event so the city and state are looked up automatically
    if (this.state.zipcode.length === 5) {
      this.onChange(this.state.zipcode);
    }
  }

  onChange(zipcode) {
    const {onChange} = this.props;
    this.setState({zipcode, city: '', state: ''} );
    onChange(zipcode);

    if (zipcode.length === 5) {
      usersService.findZipcode(zipcode)
          .then((zipcodeDataResponse) => {
            const zipcodeData = zipcodeDataResponse ? zipcodeDataResponse.data : undefined;
            if (zipcodeData) {
              const {city, state} = zipcodeData;
              this.setState({zipcode, city, state} );
              onChange(`${zipcode}`, zipcodeData);
            }
          })
          .catch((error) => {
            console.log({error});
          });
    }
  }

  render() {
    const { value, className, inputClassName, required } = this.props;
    const { city, state } = this.state;
    const autoComplete = Date.now(); // Weird hack to ensure autoComplete never appears in Chrome
    return (
        <div className={className}>
          <input placeholder="Enter your 5-digit zipcode" id="zipcode" className={inputClassName} type="text"
                 value={value || ''} autoComplete={autoComplete}
                 onChange={(ev) => this.onChange(ev.target.value)}
                 title={city && state ? `${city}, ${state}` : ''}
                 pattern="[0-9]{5}" minLength="5" maxLength="5" required={required}/>
          {city && state &&
          <summary>
            {city}, {state}
          </summary>
          }
        </div>
    );
  }
};

ZipcodePicker.displayName = "ZipcodePicker";
ZipcodePicker.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

ZipcodePicker.defaultProps = {
  required: false,
};
