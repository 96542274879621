import React from 'react';
import usersActions from 'actions/users';
import classroomsActions from 'actions/classrooms';
import {build} from 'utils/container_helpers';
import LoadingSpinner from 'components/shared/loading_spinner';

const mapStateToProps = ({store: {session, sessions, user, classrooms, tenant}}) =>{
  return {session, sessions, user, classrooms, tenant};
};

// The UserContainer is a HOC for routes that require an authenticated user.
// If necessary it will call the appropriate actions to ensure that child
// components can access the following fields from the store when they are
// mounted and rendered:
//
//    user
//    classrooms
//    sessions     (loaded for mentors only)
//
// The list of classrooms and sessions are necessary to determine whether
// or not a user needs to be "onboarded".  This test must be done at high
// level in case a redirect is required.

class UserContainer extends React.Component {

  // It has been observed that this component is often mounted, and then
  // unmounted, before being mounted yet again. According to the documentation
  // components are unmounted when the parent component is no longer rendered
  // or the parent component performs an update that does not render this
  // instance.
  //
  // In order to prevent unnecessary flicker in the user interface we
  // make sure to check the data in the store before dispatching. Only if
  // the data has not been loaded (i.e. the data field === undefined) is it
  // necessary to dispatch any actions.  If the data has been loaded already,
  // there is no need to fetch the data again.

  componentDidMount() {
    let {session, user, classrooms, sessions, dispatch} = this.props;
    const userId = session.data.login.id;
    const isMentor = user.data && user.data.roles[0] === 'mentor';
    // console.log('user_container:componentDidMount', {user, sessions, classrooms});
    if (user.data === undefined || (sessions.data === undefined && isMentor) || classrooms.data === undefined) {
      dispatch(usersActions.find(userId, (user) => {
        if (user.roles[0] === 'mentor') {
          dispatch(usersActions.findSessions(userId, (sessions) => {
            dispatch(classroomsActions.fetch());
          }));
        }
        else {
          dispatch(classroomsActions.fetch());
        }
      }));
    }
  }

  render() {
    const {user, sessions, classrooms} = this.props;
    const userDataReady = (!user.loading && user.data !== undefined && !user.error);
    const classroomsDataReady = (!classrooms.loading && !classrooms.error && classrooms.data !== undefined);
    const sessionsDataReady = (userDataReady && user.data.roles[0] === 'mentor') ? sessions.data !== undefined && !sessions.error : true;

    if (user.error || classrooms.error || sessions.error) {
      throw new Error(`Error loading user: ${user.error || classrooms.error || sessions.error}`);
    } else if (userDataReady && classroomsDataReady && sessionsDataReady) {
      return this.props.children;
    } else {
      return <LoadingSpinner/>;
    }
  }
}

UserContainer.displayName = "UserContainer";

const Resolver = build({component: UserContainer, mapStateToProps});
const withUser = (Component, extraProps={}) => props => {
  props = Object.assign({}, props, extraProps);
  return (
    <Resolver>
      <Component {...props} />
    </Resolver>
  );
};

module.exports = {
  UserContainer: Resolver,
  withUser
};
