({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
{build} = require 'utils/container_helpers'
classroomUnitsActions = require 'actions/classroom_units'
classroomsActions = require 'actions/classrooms'
messagesActions = require 'actions/messages'
routingActions = require 'actions/routing'
style = require './units.styl'
e = require('react-e/bind') style
{sentByClassMember} = require './normalization_helper'
unitsEvents = require './events'
events = require 'pages/teacher/classroom_unit/events'
moment = require 'moment'
_ = require 'lodash'

Button = createFactory require 'components/shared/button'
PastUnits = createFactory require 'components/shared/past_units'
UnitPreview = createFactory require 'components/unit_preview'

_makeUnitsTitle = (currentUnit, upcomingUnits) ->
  return t 'teacherUnits.select' unless currentUnit?
  # TODO: handle what hapens when endDate is not chosen
  daysBetween = moment(currentUnit.endDate).diff(moment(), 'days')
  # return "This unit ends in #{daysBetween} days." if daysBetween >= 15
  if upcomingUnits.length > 0
    nextUnit = upcomingUnits[0]
    daysTilNext = moment(nextUnit.startDate).diff(moment(), 'days')
    return t 'teacherUnits.next',
      count: daysTilNext
      currentName: currentUnit.name
      daysBetween: daysBetween
      daysTill: daysTilNext
      nextName: nextUnit.name
  else
    if Number.isInteger(daysBetween) and daysBetween > 0
        resource = if currentUnit.isReflectionUnit then 'teacherUnits.final' else 'teacherUnits.end'
      else
        resource = 'teacherUnits.noEndDate'
    return t resource,
      count: daysBetween
      currentName: currentUnit.name
      daysBetween: daysBetween


_pendingLettersByUnit = (messagesForClassroom, pastUnits, students, mentors) ->
  pastUnitIDs = pastUnits.map ({id}) -> id
  pendingMessages = _.filter messagesForClassroom, ({classroomUnitID, from, status}) ->
    status is 'pending' and
      classroomUnitID in pastUnitIDs and
      sentByClassMember(from, students, mentors)
  groupedPendingMessages = _.groupBy pendingMessages, 'classroomUnitID'


mapStateToProps = ({store: {classroom, messagesForClassroom}}) ->
  throw new Error 'classroom data not initialized!' unless classroom.data?
  classroomIsActive = classroom.data.status is 'active'
  units = pastUnits = classroom.data.units ? []
  pastUnits = _.filter(units, ({state}) -> state is 'completed') if classroomIsActive
  pastUnits = _.orderBy(pastUnits, (({startDate}) -> moment(startDate)), 'desc')
  classroom: classroom.data
  classroomIsActive: classroomIsActive
  currentUnit: classroom.data.currentUnit
  pastUnits: pastUnits
  pendingLettersByUnit: _pendingLettersByUnit(messagesForClassroom?.data, pastUnits,
    classroom.data.students, classroom.data.mentors)
  upcomingUnits: _.filter units, (unit) -> unit.state is 'pending'


class MyUnits extends Component

  constructor: (props) ->
    super()


  componentDidMount: ->
    @props.dispatch messagesActions.fetchAllForClassroom @props.classroom


  _makeUnitProps: (unit) =>
    return unless unit
    Object.assign unit, onClick: => @props.dispatch routingActions.redirect "/units/#{unit._id}"


  onCreateUnitClick: (source) =>
    unitsEvents.exploringUnits source
    @props.dispatch routingActions.redirect '/units/explore/'


  render: ->
    {classroom, classroomIsActive, currentUnit, dispatch, pastUnits, upcomingUnits} = @props
    myPastUnits = pastUnits.map @_makeUnitProps
    currentUnitData = @_makeUnitProps currentUnit
    upcomingUnitData = _.map upcomingUnits, @_makeUnitProps
    e '.units-container',
      if classroomIsActive
        [
          e '.unit-manager', key: 'unit-manager',
            e '.unit-manager-top',
              e 'h1.unit-manager-title', t 'teacherUnits.manager'
              if not currentUnit.isReflectionUnit
                e '.units-add-button',
                  Button
                    onClick: => @onCreateUnitClick 'Unit Manager'
                    type: 'regular', t 'teacherUnits.addNewUnit'
              e '.units-title', _makeUnitsTitle currentUnit, upcomingUnits

          e '.units-headers', key: 'units-headers',
            e 'h2.units-header.current-unit',
              e '.header-flag'
              e 'span', t 'teacherUnits.current'
            if not currentUnit.isReflectionUnit
              e 'h2.units-header.upcoming-units', t 'teacherUnits.upcoming'

          e '.units', key: 'units',
            if currentUnitData
              e '.unit-preview',
                @renderUnit currentUnitData
            else
              e '.empty.unit-preview', t 'teacherUnits.notSet'

            if upcomingUnitData.length
              for unit in upcomingUnitData
                e '.unit-preview', key: unit._id,
                 @renderUnit unit
            else if not currentUnit.isReflectionUnit
              e '.empty.unit-preview',
                e 'div', "What's next?"
                Button
                  onClick: => @onCreateUnitClick 'No Upcoming Units'
                  type: 'regular' , t 'teacherUnits.addNewUnit'
        ]

      if myPastUnits.length
        PastUnits
          classroomIsActive: classroomIsActive
          onClick: (pastUnit) =>
            @props.dispatch classroomUnitsActions.find pastUnit.id, =>
              @props.dispatch routingActions.redirect "/units/#{pastUnit.id}"
          onPendingLetterClick: (pastUnit) =>
            events.letterExchange.viewedCard {unit: 'past'}
            @props.dispatch classroomUnitsActions.find pastUnit.id, =>
              @props.dispatch routingActions.redirect "/units/#{pastUnit.id}/letters"
          pendingLettersByUnit: @props.pendingLettersByUnit
          role: 'teacher'
          units: myPastUnits


  renderUnit: (props) ->
    e '.preview',
      UnitPreview props

MyUnits.displayName = "MyUnits"

module.exports = build {
  component: MyUnits,
  mapStateToProps
}
