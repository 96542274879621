api = require '../api'

module.exports =

  backgroundcheck: (userId, test=false) ->
    api.send
      method: 'POST'
      path: "/api/v2/users/#{userId}/backgroundcheck"
      payload: {tenantID: TENANT, test}

  changePassword: ({id, password}) ->
    api.send
      method: 'POST'
      path: '/api/v2/users/password/change'
      payload: {id, password}


  checkUsername: (username) ->
    api.send
      method: 'GET'
      path: "/api/v2/users/checkusername/#{username}"


  create: (data, withNotify=false) ->
    data.service = TENANT
    api.send
      method: 'POST'
      path: "/api/v2/users?withNotify=#{withNotify}"
      payload: data


  find: (id) ->
    api.send
      method: 'GET'
      path: "/api/v2/users/#{id}"

  findZipcode: (zipcode) ->
    api.send
      method: 'GET'
      path: "/api/v2/zipcodes/#{zipcode}"

  impersonate: ({password, username}, impersonate) ->
    api.send
      impersonate: impersonate
      method: 'POST'
      path: '/api/v2/users/login'
      payload: {password, service: TENANT, username}


  login: ({password, username, sessionId}, inClassroom=false) ->
    path = '/api/v2/users/login'
    if (sessionId)
      path += "?sessionId=#{sessionId}"

    api.send
      method: 'POST'
      path: path
      payload: {password, service: TENANT, username, inClassroom}


  update: (data) ->
    api.send
      method: 'PUT'
      path: "/api/v2/users/#{data.id}"
      payload: data


  sendRecoverUsernameEmail: (email) ->
    api.send
      method: 'POST'
      path: '/api/v2/users/username/recover'
      payload: {email, service: TENANT}

  sendRecoverPasswordEmail: (username,next = null) ->
    api.send
      method: 'POST'
      path: '/api/v2/users/password/recover'
      payload: {username, service: TENANT, next}

  sendVerifyEmail: (username) ->
    api.send
      method: 'POST'
      path: '/api/v2/users/email/verify'
      payload: {username, service: TENANT}

  changePasswordWithToken: (id, token, password) ->
    api.send
      method: 'POST'
      path: '/api/v2/users/password/reset-with-token'
      payload: {id, token, password}

  checkOnboardedStatus: (id) ->
    api.send
      method: 'POST'
      path: "/api/v2/users/#{id}/checkOnboardedStatus"
      payload: { tenantID: TENANT }

  verifyEmailWithToken: (id, token) ->
    api.send
      method: 'POST'
      path: '/api/v2/users/email/verify-with-token'
      payload: {id, token}

  fetchDefaultIntroLetter: (id) ->
    api.send
      method: 'GET'
      path: "/api/v2/users/#{id}/defaultintro"
