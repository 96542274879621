import React from 'react';
const style = require('./loading_spinner.styl');
const cn = require('classnames/bind').bind(style);

export default class LoadingSpinner extends React.Component {
  render() {
    return (
        <div className={cn('loading_spinner')}>
          <div className={cn('spinner')}>
            <div className={cn('bounce1')}/>
            <div className={cn('bounce2')}/>
            <div className={cn('bounce3')}/>
          </div>
        </div>
    );
  }
}
