import UserHelpers from "utils/user_helpers";

const style = require('./browse_ebooks.styl');
const cn = require('classnames/bind').bind(style);

const store = require('store');
const {redirect} = require('actions/routing');
const {build} = require('utils/container_helpers');

const {Component, Fragment} = require('react');

const damService = require('services/dam_service');
const ArticlePreview = require('components/shared/article_preview/index.jsx');

const Card = require('components/shared/card');

const analytics = require('utils/analytics');

const mapStateToProps = ({store}) => {
    const { user, tenant } = store;
    return {
      user: UserHelpers.getTransformedUser(user.data, null, null, tenant.data),
      tenant: tenant.data,
    };
  };

class BrowseEbooks extends Component {

    constructor(props) {
        super(props);
        this.state = {assets: [], q:""};
    }

    componentDidMount() {
        if (!this.props.user.emailVerified) {
            this.props.dispatch(redirect('/account/notverified'));
        }
        else {
            this.updateAssetList();
        }
    }

    updateAssetList() {
        damService.fetch(this.state.q).then((assets) => {
            this.setState({assets: assets.data});
        });
    }

    render() {
        const email = this.props.tenant.contactEmail;
        return (
            <Fragment>
                <h2 className={cn('title')}></h2>
                <Card>
                     <div className={cn('container')}>
                        <div className={cn('info')}>{t('ebooks.browse.contact')} <a href={"mailto:"+email}>{email}</a>.</div>
                        <div className={cn('articles')}>
                            {this.state.assets.map((asset) => {
                                return (<div className={cn('article')} key={asset.id}>
                                    <ArticlePreview
                                        article={asset}
                                        image={asset.thumbnail}
                                        imageSize='contain'
                                        disableMoreLink={true}
                                        scheme='shadow'
                                        type='article'
                                        fileType='epub'
                                        onClickRead={() => analytics.recordEvent({category:'Ebook',action:'ClickRead',label:asset.id + ' - ' + asset.title})}
                                    />
                                </div>)
                            })}
                        </div>
                    </div>
                </Card>
            </Fragment>

        );
    }
}

BrowseEbooks.displayName = "BrowseEbooks";

module.exports = build({
    component: BrowseEbooks,
    mapStateToProps
  });
