import React from 'react';
import PropTypes from 'prop-types';
import FroalaEditor from 'react-froala-wysiwyg';

const style = require('./editor.styl');
const cn = require('classnames/bind').bind(style);

require('froala-editor/js/froala_editor.min.js');
require('froala-editor/css/froala_editor.min.css');
require('froala-editor/js/plugins/image.min.js');
require('froala-editor/css/plugins/image.min.css');
require('froala-editor/js/plugins/file.min.js');
require('froala-editor/css/plugins/file.min.css');
require('froala-editor/js/plugins/colors.min.js');
require('froala-editor/css/plugins/colors.min.css');
require('froala-editor/js/plugins/font_family.min.js');
require('froala-editor/js/plugins/font_size.min.js');
require('froala-editor/js/plugins/emoticons.min.js');
require('froala-editor/css/plugins/emoticons.min.css');
require('froala-editor/js/plugins/link.min.js');
require('font-awesome/css/font-awesome.css');

class Editor extends React.Component {

  constructor(...args) {
    super(...args);
    this.getConfig = this.getConfig.bind(this);
  }

  getConfig() {
    const toolbarButtons = [
      'bold',
      'italic',
      'underline',
      '|',
      'fontFamily',
      'fontSize',
      '|',
      'color',
      'emoticons'
    ];
    const config = {
      emoticonsSet: [
        {code: '1F9BE', desc: 'mechanical arm'},
        {code: '1F9BF', desc: 'mechanical leg'},
        {code: '1F6F0', desc: 'satellite'},
        {code: '1F6F8', desc: 'flying saucer'},
        {code: '1F52D', desc: 'telescope'},
        {code: '1F4E1', desc: 'stationary satellite'},
        {code: '1F40B', desc: 'whale'},
        {code: '1F684', desc: 'high speed train'},
        {code: '1F697', desc: 'electric car'},
        {code: '2708', desc: 'airplane'},
        {code: '1F315', desc: 'full moon'},
        {code: '1F321', desc: 'thermometer'},
        {code: '1FA90', desc: 'planet'},
        {code: '1F327', desc: 'cloud with rain'},
        {code: '1F328', desc: 'cloud with snow'},
        {code: '1F32A', desc: 'tornado'},
        {code: '26A1', desc: 'high voltage'},
        {code: '1F30A', desc: 'water wave'},
        {code: '2699', desc: 'gear'},
        {code: '1F923', desc: 'laughing'},
        {code: '1F643', desc: 'upside-down face'},
        {code: '1F61F', desc: 'sad face'},
        {code: '1F631', desc: 'face screaming in fear'},
        {code: '1F620', desc: 'angry face'},
        {code: '1F61E', desc: 'disappointed face'},
        {code: '1F600', desc: 'grinning face'},
        {code: '1F60E', desc: 'cool face'},
        {code: '1F615', desc: 'confused face'},
        {code: '1F632', desc: 'wow face'},
        {code: '1F912', desc: 'face with thermometer'},
        {code: '1F9D3', desc: 'older person'},
        {code: '1F929', desc: 'star-struck'},
        {code: '1F44B', desc: 'waving hand'},
        {code: '270C', desc: 'victory hand'},
        {code: '1F91E', desc: 'crossing fingers'},
        {code: '1F44D', desc: 'thumbs up'},
        {code: '1F44F', desc: 'clapping hands'},
        {code: '270D', desc: 'writing hand'},
        {code: '1F440', desc: 'eyes'},
        {code: '1F46A', desc: 'family'},
        {code: '1F63A', desc: 'cat'},
        {code: '1F435', desc: 'monkey'},
        {code: '1F436', desc: 'dog'},
        {code: '1F981', desc: 'lion'},
        {code: '1F42F', desc: 'tiger'},
        {code: '1F434', desc: 'horse'},
        {code: '1F984', desc: 'unicorn'},
        {code: '1F42E', desc: 'cow'},
        {code: '1F437', desc: 'pig'},
        {code: '1F418', desc: 'elephant'},
        {code: '1F401', desc: 'mouse'},
        {code: '1F43C', desc: 'panda'},
        {code: '1F43E', desc: 'paw prints'},
        {code: '1F426', desc: 'bird'},
        {code: '1F438', desc: 'frog'},
        {code: '1F420', desc: 'tropical fish'},
        {code: '1F411', desc: 'ewe'},
        {code: '1F410', desc: 'goat'},
        {code: '1F42A', desc: 'camel'},
        {code: '1F42B', desc: 'two-hump camel'},
        {code: '1F407', desc: 'rabbit'},
        {code: '1F994', desc: 'hedgehog'},
        {code: '1F42C', desc: 'dolphin'},
        {code: '1F41E', desc: 'ladybug'},
        {code: '1F98A', desc: 'fox'},
        {code: '1F43B', desc: 'bear'},
        {code: '1F997', desc: 'cricket'},
        {code: '1F98D', desc: 'gorilla'},
        {code: '1F3C2', desc: 'snowboarder'},
        {code: '1F3CC', desc: 'person golfing'},
        {code: '1F3C4', desc: 'person surfing'},
        {code: '1F6A3', desc: 'person rowing boat'},
        {code: '1F6B4', desc: 'person biking'},
        {code: '1F938', desc: 'person cartwheeling'},
        {code: '1F9DC', desc: 'merperson'},
        {code: '1F9DF', desc: 'zombie'},
        {code: '26BD', desc: 'soccer ball'},
        {code: '26BE', desc: 'baseball'},
        {code: '1F3C0', desc: 'basketball'},
        {code: '1F3C8', desc: 'american football'},
        {code: '1F3C9', desc: 'rugby football'},
        {code: '1F3D1', desc: 'field hockey'},
        {code: '1F3C7', desc: 'horse racing'},
        {code: '1F3BE', desc: 'tennis'},
        {code: '1F33C', desc: 'blossom'},
        {code: '1F337', desc: 'tulip'},
        {code: '1F333', desc: 'deciduous tree'},
        {code: '1F335', desc: 'cactus'},
        {code: '1F342', desc: 'fallen leaf'},
        {code: '1F32D', desc: 'hot dog'},
        {code: '1F36A', desc: 'cookie'},
        {code: '1F35A', desc: 'rice'},
        {code: '1F969', desc: 'meat'},
        {code: '1F349', desc: 'watermelon'},
        {code: '1F353', desc: 'strawberry'},
        {code: '1F354', desc: 'hamburger'},
        {code: '1F32E', desc: 'taco'},
        {code: '1F366', desc: 'soft ice cream'},
        {code: '1F34E', desc: 'red apple'},
        {code: '1F382', desc: 'birthday cake'},
        {code: '1F33D', desc: 'ear of corn'},
        {code: '1F355', desc: 'pizza'},
        {code: '1F371', desc: 'bento box'},
        {code: '1F372', desc: 'pot of food'},
        {code: '1F9C0', desc: 'cheese wedge'},
        {code: '1F95E', desc: 'pancakes'},
        {code: '1F35D', desc: 'spaghetti'},
        {code: '1F36B', desc: 'chocolate bar'},
        {code: '1F369', desc: 'doughnut'},
        {code: '1F30D', desc: 'globe'},
        {code: '1F3E0', desc: 'house'},
        {code: '1F305', desc: 'sunrise'},
        {code: '1F3A0', desc: 'carousel horse'},
        {code: '1F3A8', desc: 'artist palette'},
        {code: '1F682', desc: 'locomotive'},
        {code: '1F68C', desc: 'bus'},
        {code: '1F697', desc: 'automobie'},
        {code: '1F6B2', desc: 'bicycle'},
        {code: '26F5', desc: 'sailboat'},
        {code: '1F680', desc: 'rocket'},
        {code: '1F31B', desc: 'moon'},
        {code: '1F31E', desc: 'sun'},
        {code: '2B50', desc: 'star'},
        {code: '1F308', desc: 'rainbow'},
        {code: '2614', desc: 'umbrella'},
        {code: '26C4', desc: 'snowman'},
        {code: '1F3B5', desc: 'musical note'},
        {code: '1F3B9', desc: 'keyboard'},
        {code: '1F3BA', desc: 'trumpet'},
        {code: '1F3BB', desc: 'violin'},
        {code: '1F3B8', desc: 'guitar'},
        {code: '1F388', desc: 'balloon'},
        {code: '1F381', desc: 'wrapped gift'},
        {code: '1F389', desc: 'party popper'},
        {code: '1F3AB', desc: 'ticket'},
        {code: '1F3C6', desc: 'trophy'},
        {code: '1F3C5', desc: 'sports medal'},
        {code: '1F4D5', desc: 'book'},
        {code: '1F4DA', desc: 'books'},
        {code: '1F4E7', desc: 'envelope'},
        {code: '1F4EB', desc: 'mailbox'},
        {code: '1F4CF', desc: 'straight ruler'},
        {code: '1F4BB', desc: 'laptop'},
        {code: '1F3AE', desc: 'video game'},
        {code: '1F4F7', desc: 'camera'},
        {code: '1F3AC', desc: 'clapper board'},
        {code: '1F4AD', desc: 'thought balloon'},
        {code: '1F4A4', desc: 'zzz'},
        {code: '1F525', desc: 'fire'},
        {code: '1F3AF', desc: 'direct hit'},
        {code: '1F453', desc: 'glasses'},
        {code: '1F393', desc: 'graduation cap'},
        {code: '1F50E', desc: 'magnifying glass tilted right'},
        {code: '1F4A1', desc: 'lightbulb'},
        {code: '1F4B0', desc: 'money bag'},
        {code: '1F52C', desc: 'microscope'},
        {code: '1F4A7', desc: 'droplet'},
        {code: '1F6A7', desc: 'construction'},
      ],
      emoticonsUseImage: false,
      heightMin: 260,
      key: FROALA_KEY,
      linkStyles: null,
      placeholderText: t('editor.placeholder'),
      pluginsEnabled: ['colors', 'fontFamily', 'fontSize', 'emoticons', 'link'],
      quickInsertButton: [],
      toolbarButtons,
      toolbarButtonsMD: toolbarButtons,
      toolbarButtonsSM: toolbarButtons,
      toolbarButtonsXS: toolbarButtons
    };
    if (this.props.uploadPolicy && this.props.allowAttachments) {
      config.pluginsEnabled.push('image');
      _.assign(config, {
        imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
        imageMaxSize: 5 * 1024 * 1024,
        imagePaste: true,
        imageUploadToS3: {
          bucket: 'cricket-together',
          keyStart: `${AWS_BUCKET_ID}/letter_uploads/`,
          params: _.assign({}, this.props.uploadPolicy.fields, {acl: 'public-read'}),
          region: 's3'
        }
      });
      toolbarButtons.push('insertImage');
      config.pluginsEnabled.push('file');
      _.assign(config, {
        fileAllowedTypes: ['*'],
        fileMaxSize: 5 * 1024 * 1024,
        fileUploadToS3: {
          bucket: 'cricket-together',
          keyStart: `${AWS_BUCKET_ID}/letter_uploads/`,
          params: _.assign({}, this.props.uploadPolicy.fields, {acl: 'public-read'}),
          region: 's3'
        }
      });
      toolbarButtons.push('insertFile');
    }
    return config;
  }

//  componentDidMount: ->
//      console.log "Editor #{@oid} mounted"
//
//  componentWillUnmount: ->
//      console.log "Editor #{@oid} will unmount"

  render() {
//    onModelChange = (val) =>
//      console.log "Editor #{@oid} value change - #{val}"
//      @props.onValueChange(val)

    return (
        <div className={cn('editor')}>
          <FroalaEditor config={this.getConfig()}
                        model={this.props.initialValue}
                        onModelChange={this.props.onValueChange} />
        </div>
    );
  }
}

Editor.displayName = "Editor";
Editor.propTypes = {
  allowAttachments: PropTypes.bool,
  initialValue: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  uploadPolicy: PropTypes.object
};

module.exports = Editor;

