({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
{build} = require 'utils/container_helpers'
style = require './resources.styl'
e = require('react-e/bind') style
mailActions = require 'actions/mail'

Card = createFactory require 'components/shared/card'
ContentLoaderContainer = createFactory require 'containers/content_loader_container'

mapStateToProps = ({store}) ->
    classroomId: store.classroom?.data._id

class Mail extends Component

  render: ->
    Card {},
      e '.card',
        @renderMail()

  renderMail: ->
    {dispatch} = @props
    ContentLoaderContainer
      getContentFromStore: ({mailHtml}) -> mailHtml
      getError: ({error}) -> error
      getLoading: ({loading}) -> loading
      getReady: ({data,classroomId}) => data? and classroomId is @props.classroomId
      loadContent: => dispatch mailActions.getResources(@props.classroomId)
      name: 'mail'
      renderChild: (mailHtml) ->
        e '.body', dangerouslySetInnerHTML: __html: mailHtml.data

Mail.displayName = "Mail"

module.exports = build {
  component: Mail
  mapStateToProps
}
