import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
const countries = require('utils/countries.json');

export default class CountryPicker extends React.Component {

  constructor(props) {
    super(props);

    let selectedCountries = countries;
    if (props.countries) {
      selectedCountries = _.pick(countries, ...props.countries);
    }

    this.countryList = _.sortBy(selectedCountries);
    this.countryMap = _.invert(selectedCountries);
  }

  render() {
    const { value, prompt, className, onChange } = this.props;
    return (
        <select id="country" className={className}
                value={value}
                required
                onChange={(ev) => onChange(ev.target.value)}>
          {prompt && <option key={'select'} value={''}>-- Please select from list --</option>}
          {this.countryList.map((c) => {
            return (
                <option key={this.countryMap[c]} value={this.countryMap[c]}>{c}</option>
            );
          })}
        </select>
    );
  }
};

CountryPicker.displayName = "CountryPicker";
CountryPicker.propTypes = {
  value: PropTypes.string,
  countries: PropTypes.array,
  prompt: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

CountryPicker.defaultProps = {
  prompt: true,
}
