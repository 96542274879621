import React from 'react';
import PropTypes from 'prop-types';

import { animateScroll as scroll } from 'react-scroll';

const _ = require('lodash');
const style = require('./profile-form.styl');
const cn = require('classnames/bind').bind(style);

import Button from 'components/shared/button';
import CountryPicker from 'components/shared/country_picker';
import RegionPicker from 'components/shared/region_picker';
import ZipcodePicker from 'components/shared/zipcode_picker';
import UserHelpers from "utils/user_helpers";

export default class MentorProfileForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: Object.assign({}, props.user)
    }
  }

  // This onChange handler is the most recent implementation as of 11/26/2019 which
  // does a full nested state update.  For example if a change is made to
  // foo.bar.baz it will correctly construct the merged update using the existing
  // state and the new changes.  This is very tricky because React's setState does not handle
  // nested state updates so you have to update the entire toplevel attribute and ensure that all
  // the nested object fields are preserved including the one that was changed.

  onChange(field, val) {
    const fields = field.split('.');
    const field1 = fields[0];
    this.setState((prevState) => {
      const fields2 = field.split('.');
      fields2.pop();
      let updates = fields.reverse()
          .reduce((acc, key) => {
            if (acc === null) {
              const prevObject = fields2.reduce((o, i) => o[i], prevState);
              return Object.assign({}, prevObject, {[key]: val});
            }
            fields2.pop();
            const prevObject = fields2.reduce((o, i) => o[i], prevState);
            return Object.assign(prevObject, {[key]: acc});
          }, null);

      updates = _.pick(updates, field1);
      return updates;
    });
  }

  onChangeZipcode(zipcode, zipcodeData) {
    const {city, state} = zipcodeData ? zipcodeData : {};
    this.onChange('user.zipcode', zipcode);
    this.onChange('user.city', city);
    this.onChange('user.state', state);
  }

  onSubmit(ev) {
    const { onSubmit } = this.props;
    ev.preventDefault();
    if (ev.target.form.reportValidity()) {
        const newUser = Object.assign({}, this.state.user);
        const oldUser = this.props.user;
        Object.keys(newUser).forEach((key) => {
          const newVal = newUser[key];
          const oldVal = oldUser[key];
          if (newVal === oldVal && key !== 'id') {
            delete newUser[key];
          }
        });
        console.log({submitData: newUser});
        scroll.scrollToTop();

        onSubmit(newUser);
    }
  }

  render() {
    const {onVerify, type, error, success, showDemographics} = this.props;
    const userState = this.state.user;

    const formClassName = (type === 'dark') ? 'form-dark' : 'form-light';
    const missingFields = UserHelpers.getMissingProfileFields(userState);
    const Star = () => showDemographics ? <b>*</b> : null;

    const enableSubmit =
        userState.firstname.length > 0 &&
        userState.lastname.length > 0 &&
        userState.email.length > 0;
    const autoComplete = Date.now(); // Weird hack to ensure autoComplete never appears in Chrome
    return (
        <form className={cn(formClassName)}>
          <div className={cn('error', {visible: !!error})}>{error || "Error in form data"}</div>
          <div className={cn('success', {visible: !!success})}>{success}</div>

          <label htmlFor="firstname" className={cn('label', 'firstname')}>First Name<Star/></label>
          <input placeholder="Enter your first name" id="firstname" className={cn('input')}
                 type="text" autoFocus="true"
                 value={userState.firstname} minLength="1" required
                 onChange={(ev) => this.onChange('user.firstname', ev.target.value)}/><br/>

          <label htmlFor="lastname" className={cn('label', 'lastname')}>Last Name<Star/></label>
          <input placeholder="Enter your last name" id="lastname" className={cn('input')} type="text"
                 value={userState.lastname}
                 onChange={(ev) => this.onChange('user.lastname', ev.target.value)}
                 minLength="1" required/><br/>

          <label htmlFor="email" className={cn('label', 'email')}>Email Address<Star/></label>
          <input placeholder="Enter your email address" id="email"
                 className={cn('input')} type="email"
                 value={userState.email}
                 onChange={(ev) => this.onChange('user.email', ev.target.value)}
                 minLength="6" required/><br/>
          {onVerify && !userState.emailVerified &&
              <div className={cn('verify-link-container')}>
                <a onClick={(ev) => onVerify(ev)}
                   title="Click to send">Send verification email</a>
              </div>
          }

          <label htmlFor="country" className={cn('label', 'country')}>Country of Residence<Star/></label>
          <CountryPicker className={cn('input', 'country', 'value', {missing: missingFields.includes('country')})}
                         value={userState.country} required
                         onChange={(countryCode) => this.onChange('user.country', countryCode)}/>
          <br/>

          {userState.country && userState.country === "US" &&
          <React.Fragment>
            <label htmlFor="zipcode" className={cn('label', 'zipcode')}>Zipcode<Star/></label>
            <ZipcodePicker className={cn('zipcode-picker')}
                           inputClassName={cn('input')}
                           value={userState.zipcode} required
                           onChange={(zipcode, zipcodeData) => this.onChangeZipcode(zipcode, zipcodeData)}/>
          </React.Fragment>
          }
          {userState.country && userState.country !== "US" &&
          <React.Fragment>
            <label htmlFor="region" className={cn('label', 'region')}>Region<Star/></label>
            <RegionPicker className={cn('input', 'region', {missing: missingFields.includes('state')})}
                          value={userState.state} required
                          country={userState.country}
                          onChange={(regionName) => this.onChange('user.state', regionName)}/>

            <label htmlFor="city" className={cn('label', 'city')}>
              City<Star/>
            </label>
            <input placeholder="Enter city/town of residence" id="city" type="text"
                   className={cn('input', {missing: missingFields.includes('city')})}
                   value={userState.city} onChange={(ev) => this.onChange('user.city', ev.target.value)}
                   minLength="2" required autoComplete={autoComplete}/>
            <br/>
          </React.Fragment>
          }

          {showDemographics &&
          <React.Fragment>
            <div className={cn('instructions')}>* Indicates required field</div>
            <div className={cn('instructions')}>
              Some schools look for specific eMentor matches to reflect their population.
              In an effort to help provide our schools with the best matches, please share information
              about your occupation, gender, and ethnicity.
            </div>

            <label htmlFor="occupation" className={cn('label', 'occupation')}>Occupation</label>
            <input placeholder="Enter your occupation" id="occupation" className={cn('input')} type="text"
                   value={userState.occupation} minLength="1"
                   onChange={(ev) => this.onChange('user.occupation', ev.target.value)}/><br/>

            <label htmlFor="gender" className={cn('label', 'gender')}>Gender</label>
            <select id="gender" className={cn('input', 'gender', {value: userState.gender !== ""})}
                    value={userState.gender}
                    onChange={(ev) => this.onChange('user.gender', ev.target.value)}>
              <option value="">Select your gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Decline">Prefer Not To Say</option>
              <option value="Custom">Add Gender</option>
            </select><br/>

            {userState.gender === "Custom" &&
            <React.Fragment>
              <input placeholder="Enter your gender here" id="gender2" className={cn('input', 'gender2')} type="text"
                     value={userState.gender2}
                     onChange={(ev) => this.onChange('user.gender2', ev.target.value)}
                     minLength="1"/><br/>
            </React.Fragment>
            }

            <label htmlFor="ethnicity" className={cn('label', 'ethnicity')}>Ethnicity</label>
            <select id="ethnicity" className={cn('input', 'ethnicity', {value: userState.ethnicity !== ""})}
                    value={userState.ethnicity}
                    onChange={(ev) => this.onChange('user.ethnicity', ev.target.value)}>
              <option value="">Select your ethnicity</option>
              <option value="American Indian or Alaskan Native<">American Indian or Alaskan Native</option>
              <option value="Asian or Pacific Islander">Asian or Pacific Islander</option>
              <option value="Black, not of Hispanic origin<">Black, not of Hispanic origin</option>
              <option value="Hispanic">Hispanic</option>
              <option value="Middle Eastern">Middle Eastern</option>
              <option value="White, not of Hispanic origin">White, not of Hispanic origin</option>
            </select>
            <br/>
            <div className={cn('spanish')}>
              <input type="checkbox" id="spanish"
                     value="es" onChange={(ev) => this.onChange('user.spanish', ev.target.checked)}/>
              <label htmlFor="spanish">
                I am proficient in Spanish, and willing to participate in a bilingual correspondence.
              </label>
            </div>
            <br/>
          </React.Fragment>
          }
          <Button enabled={enableSubmit} onClick={(ev) => this.onSubmit(ev)} submit={true} type="regular">
            OK
          </Button>
          <div className={cn('instructions')}>
            Cricket Media will protect your data, subject to our&nbsp;
            <a href="https://cricketmedia.com/privacy/" target="_blank">Privacy Policy</a>.
          </div>
        </form>
    );
  }
}

MentorProfileForm.displayName = "MentorProfileForm";
MentorProfileForm.propTypes = {
  user: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['light', 'dark']).isRequired,
  showDemographics: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onVerify: PropTypes.func,
};


MentorProfileForm.defaultProps = {
  showDemographics: true,
};
