const style = require('./header_js.styl');
const cn = require('classnames/bind').bind(style);
const {Component} = require('react');
const _ = require('lodash');

const Link = require('./Link');

class Header extends Component {

    _renderChild(child, i) {
        return (child ?
            <li
                className={cn('control',{disabled: (child.props.className === 'disabled')})}
                key={i}>
                {child}
            </li>
            : null
        );
    }

    render() {
        const {children, className, links, onClickLogo } = this.props;

        return (
            <div className={cn('navbar')+" "+className}>
                <div className={cn('header')}>
                    <a onClick={onClickLogo}>
                        <img className={cn('logo')}
                            alt={this.props.logoName}
                            src={this.props.logoUrl}>
                        </img>
                    </a>
                    <div className={cn('header-controls')}>
                        <ul className={cn('controls')}>
                            {_.map(children, this._renderChild)}
                        </ul>
                    </div>
                </div>
                {links.length > 0 &&
                <nav className={cn('links')}>
                    {_.map(links, (link,i) => <Link key={i} link={link}></Link>)}
                </nav>
                }
            </div>
        );
    }
}

Header.displayName = "Header";
module.exports = Header;
