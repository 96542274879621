_ = require 'lodash'
moment = require 'moment'

module.exports =

  addInDates: (payload, dates) ->
    payload.unitDates or= {}
    if dates.endDate? then _.assign payload, endDate: dates.endDate.utc().format()
    if dates.startDate? then _.assign payload, startDate: dates.startDate.utc().format()
    if dates.focusArticleDate? then _.assign payload.unitDates,
      focusArticleDate: dates.focusArticleDate.utc().format()
    if dates.mentorDate? then _.assign payload.unitDates,
      mentorDate: dates.mentorDate.utc().format()
    if dates.studentDate? then _.assign payload.unitDates,
      studentDate: dates.studentDate.utc().format()
    if dates.mentor2Date? then _.assign payload.unitDates,
      mentor2Date: dates.mentor2Date.utc().format()
    if dates.student2Date? then _.assign payload.unitDates,
      student2Date: dates.student2Date.utc().format()


  validateDates: (dates) -> dates.startDate?
