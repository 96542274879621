_ = require 'lodash'
api = require '../api'
{makeAction} = require 'utils/action_helpers'
{redirect} = require 'actions/routing'
usersActions = require 'actions/users'
classroomsActions = require 'actions/classrooms'
usersService = require '../services/users_service'
classroomsService = require '../services/classrooms_service'

HUMANIZED_FIELDS =
  firstname: t 'studentDataEntry.firstName'
  gender: t 'studentDataEntry.gender'
  lastname: t 'studentDataEntry.lastName'
  password: t 'studentDataEntry.password'
  username: t 'studentDataEntry.username'

_makeFieldCheck = (key) -> (student) ->
  unless student[key]? and student[key] isnt ''
    t 'classroomStudent.missingField', field: HUMANIZED_FIELDS[key]
  else
    console.log "check bad username #{key}"
    if key is 'username' and /\s/.test student[key].trim()
      console.log "bad username"
      t 'classroomStudent.badUsernameField'

_makeClientErrors = (student, fieldsToCheck) ->
  error = {}
  for key in fieldsToCheck
    error[key] = _makeFieldCheck(key) student
  error

classroomStudentActions =

  addStudentToCurrentClassroom: (studentId, cb) -> makeAction (setState, dispatch, getState) ->
    {session} = getState()
    {classroomId} = session.data
    classroomsService.addStudent({classroomId, studentId})
      .then(if cb? then cb)
      .catch (error) ->
        setState 'classroomStudent', error: error.toString()


  checkUsername: ({exclude, username}, callback) -> makeAction (setState) ->
    return callback? false if username is exclude
    usersService.checkUsername(username)
      .then ({result}) ->
        callback? result.user_exists


  clearPendingStudents: -> makeAction (setState) ->
    setState ['classroomStudent', 'data', 'pendingStudents'], {}
    setState ['classroomStudent', 'data', 'validationStatus'], 'initial'
    setState ['classroomStudent', 'data', 'validationErrors'], {}
    setState ['classroomStudent', 'data', 'uploadMetrics'], {}


  clearUploadErrors: -> makeAction (setState) ->
    setState ['classroomStudent', 'data', 'fields'],
      firstname: undefined
      gender: undefined
      lastname: undefined
      password: undefined
      username: undefined
    setState ['classroomStudent', 'data', 'uploadErrors'], {}
    setState ['classroomStudent', 'data', 'uploadStatus'], 'initial'


  create: (data, callback) -> makeAction (setState) ->
    usersService.create(data)
      .then(callback)
      .catch (error) ->
        setState 'classroomStudent', error: error.toString()


  initializeData: (student) -> makeAction (setState) ->
    fields =
      firstname: undefined
      gender: undefined
      lastname: undefined
      password: undefined
      username: undefined
#    _.assign fields, student.profile if student? and student.profile?
    _.assign fields, student if student?
    setState 'classroomStudent', data:
      fields: fields
      pendingStudents: {}
      validationErrors:
        firstname: undefined
        gender: undefined
        lastname: undefined
        password: undefined
        username: undefined


  setFields: (fields) -> makeAction (setState, dispatch, getState) ->
    setState ['classroomStudent', 'data', 'fields'], fields


  setUploadErrors: (index, errors) -> makeAction (setState) ->
    errors.index = index
    setState ['classroomStudent', 'data', 'uploadErrors', index], errors


  setValidationErrors: (errors) -> makeAction (setState, dispatch, getState) ->
    setState ['classroomStudent', 'data', 'validationErrors'], errors


  update: (data, callback) -> makeAction (setState) ->
    usersService.update(data)
      .then(callback)
      .catch (error) ->
        setState 'classroomStudent', error: error.toString()


  uploadStudents: (validatedStudents, callback) -> makeAction (setState, dispatch, getState) ->
    setState ['classroomStudent', 'data', 'uploadErrors'], {}
    setState ['classroomStudent', 'data', 'uploadStatus'], 'loading'
    Promise.all(_.map validatedStudents, (fields) ->
      payload = _.assign {}, fields, role: 'student'
      dispatch classroomStudentActions.create payload, ({user}) ->
        dispatch classroomStudentActions.addStudentToCurrentClassroom user.id
    ).then ->
      setState ['classroomStudent', 'data', 'uploadStatus'], 'complete'
      dispatch classroomsActions.loadCurrentClassroom -> callback?()


  validateFields: ({fields, user, validatorKeys}, successCallback) ->
    makeAction (setState, dispatch) ->
      errors = _makeClientErrors fields, validatorKeys
      if _.some _.values errors
        if fields.index?
          dispatch classroomStudentActions.setUploadErrors fields.index, errors
        else
          dispatch classroomStudentActions.setValidationErrors errors
        return Promise.resolve()

      dispatch classroomStudentActions.checkUsername
        exclude: user?.username
        username: fields.username
      ,
        (isTaken) ->
          return successCallback() unless isTaken
          errors.username = 'Username already in use'
          if fields.index?
            dispatch classroomStudentActions.setUploadErrors fields.index, errors
          else
            dispatch classroomStudentActions.setValidationErrors errors


  validateFieldsAndCreate: ({fields}, callback) -> makeAction (setState, dispatch) ->
    validatorKeys = ['firstname', 'lastname', 'password', 'username']
    dispatch classroomStudentActions.validateFields {fields, validatorKeys}, ->
      payload = _.assign {}, fields, role: 'student'
      dispatch classroomStudentActions.create payload, ({user}) ->
        dispatch classroomStudentActions.addStudentToCurrentClassroom user.id, ->
          dispatch classroomsActions.loadCurrentClassroom ->
            callback?()


  validateStudents: (parsedStudents, onSuccess) -> makeAction (setState, dispatch) ->
    validatorKeys = ['firstname', 'lastname', 'password', 'username']
    setState ['classroomStudent', 'data', 'uploadErrors'], {}
    setState ['classroomStudent', 'data', 'uploadStatus'], 'loading'
    successfulValidations = 0
    _.map parsedStudents, (fields) ->

      dispatch classroomStudentActions.validateFields {fields, validatorKeys}, ->
        payload = _.assign {}, fields, role: 'student'
        setState ['classroomStudent', 'data', 'pendingStudents', successfulValidations], payload
        successfulValidations += 1
      .then ->
        setState ['classroomStudent', 'data', 'uploadMetrics'],
          failed: parsedStudents.length - successfulValidations
          succeeded: successfulValidations
        setState ['classroomStudent', 'data', 'validationStatus'], 'complete'
        onSuccess() if (parsedStudents.length - successfulValidations) is 0


module.exports = classroomStudentActions
