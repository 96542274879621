analytics = require 'utils/analytics'

module.exports =
  clickedLogin: ->
    analytics.recordEvent
      action: "Clicked 'Login' Button"
      category: 'Login'


  loggedOut: ->
    analytics.recordEvent
      action: 'Logged Out'
      category: 'Logged Out'
    analytics.setUserID 'anonymous'
    analytics.setCustomDimension 'role', 'anonymous'
    analytics.setCustomDimension 'roleHit', 'anonymous'
    analytics.setCustomDimension 'classroom', 'unknown'
    analytics.setCustomDimension 'unit', 'unknown'


  loginSuccess: ({role, userId}) ->
    analytics.setUserID userId
    analytics.setCustomDimension 'role', role
    analytics.setCustomDimension 'roleHit', role
    analytics.recordEvent
      action: 'Logged In'
      category: 'Login'


  navigated: (link) ->
    analytics.recordEvent
      action: "#{link} - Navigation Bar"
      category: link
