{connect} = require 'react-redux'
{withRouter} = require 'react-router'
{setDisplayName} = require 'recompose'
_ = require 'lodash'

module.exports = (mapStateToProps) -> _.flowRight [
  (component) -> setDisplayName(component.displayName + 'Container') component
  withRouter
  connect mapStateToProps
]
