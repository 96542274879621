import React from 'react';
import PropTypes from 'prop-types';
import {formatDate, getUnitDates, progressPercent} from 'utils/date_normalizer';
import moment from 'moment';
import style from './unit_progress_bar.styl';
const cn = require('classnames/bind').bind(style);

class UnitProgressBar extends React.Component {

  render() {
    const { unit, role, teacherLinks, palFirstName } = this.props;
    const dates = getUnitDates(unit, role, palFirstName);

    const inProgress = moment(unit.startDate) < Date.now();
    const width = progressPercent({
      approvalData: this.props.progress,
      currentUnitId: unit._id,
      dates,
      isDefaultUnit: unit.isDefaultUnit,
      role,
    });

    return (
        <div className={cn('unit-progress-bar')}>
          <div className={cn('header')}>
            <div className={cn('title')}>{t('unitProgressBar.currentUnitProgress')}</div>
            {teacherLinks &&
            <div className={cn('options')}>
              <a onClick={teacherLinks.onEditUnitClick}>{t('unitProgressBar.editUnit')}</a>
              <span className={cn('dashed')}/>
              <a onClick={teacherLinks.onGetTeacherGuideClick}>
                <span className={cn('icon-guide')}/>
                <span>{t('unitProgressBar.teacherGuide')}</span>
              </a>
            </div>
            }
          </div>
          <div className={cn('bar')}>
            <div className={cn('progress', {'not-in-progress': !inProgress})} style={{width}}/>
          </div>
          <div className={cn('dates', {'not-in-progress': !inProgress})}>
            {dates.map(date => {
              return (
                  <div className={cn('date-container')} key={date.id}>
                    <div className={cn('date')}>
                      <div className={cn('label')}>{date.label}</div>
                      <div className={cn('value')}>
                        {!date.value &&
                        <div className={cn('not-chosen')}>{t('unitProgressBar.not')}</div>
                        }
                        {date.value && formatDate(date.value)}
                      </div>
                    </div>
                  </div>
              );
            })}
          </div>
        </div>
    );
  }
}
UnitProgressBar.displayName = "UnitProgressBar";
UnitProgressBar.propTypes = {
  unit: PropTypes.object.isRequired,
  progress: PropTypes.object.isRequired,
  role: PropTypes.oneOf(['teacher', 'mentor', 'student']).isRequired,
  teacherLinks: PropTypes.shape({
    onEditUnitClick: PropTypes.func.isRequired,
    onGetTeacherGuideClick: PropTypes.func.isRequired
  }),
};

module.exports = UnitProgressBar;