({Component} = require 'react') and (PropTypes = require 'prop-types')
style = require './placeholder.styl'
e = require('react-e/bind') style

class Placeholder extends Component

  @propTypes:
    icon: PropTypes.element
    text: PropTypes.string.isRequired


  render: ->
    e '.placeholder-container',
      e '.icon', @props.icon
      e '.placeholder-text', @props.text

Placeholder.displayName = "Placeholder"

module.exports = Placeholder
