{createElement} = require 'react'
UnitPreviewView = require './unit_preview_view'
UnitPreviewNew = require './unit_preview_new'
UnitPreviewEdit = require './unit_preview_edit'


UnitPreview = (props) ->
  switch props.mode
    when 'view' then createElement UnitPreviewView, props
    when 'new' then createElement UnitPreviewNew, props
    when 'edit' then createElement UnitPreviewEdit, props
    else throw new Error 'unexpected mode'

UnitPreview.displayName = "UnitPreview"

module.exports = UnitPreview
