import React from 'react';
const {build} = require('utils/container_helpers');
const exploreUnitsActions = require('actions/explore_units');
const style = require('./explore_units.styl');
const cn = require('classnames/bind').bind(style);
const events = require('../units/events');
import UnitTemplatePreview from 'components/unit_template_preview';
import Dropdown from 'components/shared/dropdown';
import _ from 'lodash';

const mapStateToProps = ({store}) => ({
  error: store.unitTemplates.error,
  unitTemplates: store.unitTemplates.data
});


class ExploreUnits extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      filterSubject: null,   // Subject ID
      filterStrand: null,    // Strand ID
      filterGrades: null,    // Grades string e.g. "3-5"
    }

    this.onUnitTemplateClick = this.onUnitTemplateClick.bind(this);
    this.getSubjectsAndStrands = this.getSubjectsAndStrands.bind(this);

  }

  componentDidMount() {
    return this.props.dispatch(exploreUnitsActions.fetch());
  }

  getFilteredUnits(units) {
    // This may be the biggest hack in the whole application,
    // forcing 'Engineering is Everywhere' to be the second unit listed,
    // this should really be done by configuring the unit display order as an
    // explicit field of the unit data...

    let reflectionUnit;
    let [introUnit, otherUnits] = Array.from(_.partition(units, 'isDefaultUnit'));
    [reflectionUnit, otherUnits] = Array.from(_.partition(otherUnits, 'isReflectionUnit'));
    const secondUnit = _.remove(otherUnits, u => /Engineering is Everywhere/i.test(u.name));
    const rest = _.sortBy(_.values(otherUnits), 'name');
    const orderedUnits = introUnit.concat(secondUnit).concat(rest).concat(reflectionUnit);

    // Filter results if necessary
    let filteredUnits = orderedUnits;
    if (this.state.filterSubject || this.state.filterStrand || this.state.filterGrades) {
      filteredUnits = orderedUnits.filter( (unit) => {
        if (this.state.filterSubject && !unit.subjects.find((subject) => subject._id === this.state.filterSubject)) {
          return false;
        }
        if (this.state.filterStrand && !unit.strands.find((strand) => strand._id === this.state.filterStrand)) {
          return false;
        }
        if (this.state.filterGrades && unit.grades !== this.state.filterGrades) {
          return false;
        }
        return true;
      });
    }

    // "format" the units so they contain the metadata that the ContentPreview needs
    const formattedUnits = filteredUnits.map(function ({_id, description, subjects, strands, grades, image, lexileHigh, lexileLow, name}) {
      let lexileBand;
      if (lexileLow && lexileHigh) {
        lexileBand = `${lexileLow}-${lexileHigh}`;
      }

      const subjectNames = subjects.map(x => x.name).join(', ');
      const strandNames = strands.map(x => x.name).join(', ');

      return {
        description,
        id: _id,
        image,
        metadata: [
          {name: 'Subjects', value: subjectNames},
          {name: 'Strands', value: strandNames},
          {name: 'Grades', value: grades},
          {name: 'Lexile Band', value: lexileBand}
        ].filter(entry => entry.value),
        title: name
      };
    });

    return formattedUnits;
  }

  onUnitTemplateClick(unitTemplateID, title) {
    events.viewedUnit(title);
    return this.props.router.push(`/units/explore/${unitTemplateID}`);
  }

  getSubjectsAndStrands() {
    const {unitTemplates} = this.props;
    const subjects = {};
    const strands = {};
    const strandsBySubjectId = {};
    const grades = {};

    Object.values(unitTemplates).forEach( (unit) => {
      unit.subjects.forEach( (subject) => {
        subjects[ subject.name ] = subject._id;
      });
      unit.strands.forEach( (strand) => {
        strands[ strand.name ] = strand._id;
        if (!(strand.subjectID in strandsBySubjectId)) {
          strandsBySubjectId[ strand.subjectID ] = {};
        }
        strandsBySubjectId[ strand.subjectID ][ strand.name ] = strand._id;
      });
      grades[ unit.grades ] = true;
    })

    return {subjects, strands, strandsBySubjectId, grades: Object.keys(grades).sort()};
  }

  onChangeFilter(filter, option) {
    if (filter === 'subject') {
      const newState = {
        filterSubject: option ? option.value : null,
        filterStrand: null
      };
      if (option === null) {
        newState.filterStrand = null; // Resetting subject filter also clears strand filter
      }
      this.setState(newState);

    } else if (filter === 'strand') {
      this.setState({filterStrand: option ? option.value : null});
    } else if (filter === 'grades') {
      this.setState({filterGrades: option ? option.value : null});
    }
  }

  render() {
    const {unitTemplates} = this.props;
    if (!unitTemplates) {
      return null;
    }

    const data = this.getFilteredUnits(unitTemplates);
    const {subjects, strands, strandsBySubjectId, grades} = this.getSubjectsAndStrands();
    const subjectNames = Object.keys(subjects).sort();
    const optionsSubjects = subjectNames.map((name) => {
      const subjectId = subjects[name];
      return {
        value: subjectId,
        label: name
      }
    });

    let optionsStrands = [];
    if (this.state.filterSubject) {
      const strandsForSubject = strandsBySubjectId[this.state.filterSubject];
      const strandNames = Object.keys(strandsForSubject).sort();
      optionsStrands = strandNames.map((name) => {
        const strandId = strandsForSubject[name];
        return {
          value: strandId,
          label: name
        }
      });
    } else {
      const strandNames = Object.keys(strands).sort();
      optionsStrands = strandNames.map((name) => {
        const strandId = strands[name];
        return {
          value: strandId,
          label: name
        }
      })
    }

    const optionsGrades = grades.map((grade) => {
      return {
        value: grade,
        label: grade,
      };
    });

    return (
        <div className={cn('explore-units')}>
          <div className={cn('header')}>
            <h1 className={cn('heading')}>{t('unitExplore.heading')}</h1>
            <p className={cn('message')}>
              {t('unitExplore.message')}
            </p>
            <div className={cn('filter')}>
              <div className={cn('item', 'subject')}>
                <h2>Subject</h2>
                <Dropdown clearable={true} disabled={false}
                          onChange={(option) => this.onChangeFilter('subject', option)}
                          options={optionsSubjects}
                          value={this.state.filterSubject}/>
              </div>
              <div className={cn('item', 'strand')}>
                <h2>Strand</h2>
                <Dropdown clearable={true} disabled={false}
                          onChange={(option) => this.onChangeFilter('strand', option)}
                          options={optionsStrands}
                          value={this.state.filterStrand}/>
              </div>
              <div className={cn('item', 'grades')}>
                <h2>Grades</h2>
                <Dropdown clearable={true} disabled={false}
                          onChange={(option) => this.onChangeFilter('grades', option)}
                          options={optionsGrades}
                          value={this.state.filterGrades}/>
              </div>
              <div className={cn('item', 'lexile-high')}>
                <h2>Lexile Low</h2>
                <input placeholder="" id="lexile-low" className={cn('input')} type="text"
                       value={''}
                       onChange={(ev) => this.onChange('user.lastname', ev.target.value)}
                       minLength="1" required/><br/>
              </div>
              <div className={cn('item', 'lexile-high')}>
                <h2>Lexile High</h2>
                <input placeholder="" id="lexile-high" className={cn('input')} type="text"
                       value={''}
                       onChange={(ev) => this.onChange('user.lastname', ev.target.value)}
                       minLength="1" required/><br/>
              </div>
            </div>
          </div>
          <div className={cn('body')}>
            {data.map(({description, id, image, metadata, title}, i) => {
              return (
                  <div className={cn('unit-template')} key={i}>
                    <UnitTemplatePreview description={description} id={id} image={image} metadata={metadata}
                                         onClick={() => this.onUnitTemplateClick(id, title)} scheme='plain'
                                         title={title}/>
                  </div>
              );
            })}
          </div>
        </div>
    );
  }
}

ExploreUnits.displayName = "ExploreUnits";

module.exports = build({
  component: ExploreUnits,
  mapStateToProps
});
