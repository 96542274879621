const style = require('./signup.styl');
const cn = require('classnames/bind').bind(style);
const store = require('store')
const {Component, Fragment} = require('react');

const Card = require('components/shared/card');
const Button = require('components/shared/button');
import LoadingSpinner from 'components/shared/loading_spinner';

const sessionService = require('services/classroom_session_service');
const usersService = require('services/users_service');
const {redirect} = require('actions/routing');
const {logout} = require('actions/session');

class Signup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            terms: false,
            success: false,
            error: false,
            errorMsg: "",
            sessionLoading: false,
            loadingError: null,
            parentRegID: this.props.location.query.regID || null,
            sessionData: {sponsorID: {}}, // this is "classroom" session, NOT user session...
        };
    }

    updateUsername(ev) {
        this.setState({username: ev.target.value, error: false});
    }

    updatePassword(ev) {
        this.setState({password: ev.target.value, error: false});
    }

    updateTerms(ev) {
        this.setState({terms: ev.target.checked, error: false});
    }

    submitForm(ev) {
        ev.preventDefault();
        if (ev.target.form.reportValidity()) {
            const username = this.state.username;
            const password = this.state.password;
            const sessionId = this.props.params.session_id;
            const parentRegID = this.state.parentRegID;
            usersService.login({username,password, sessionId}, false)
            .then((resp) => {
                return sessionService.addToSession(sessionId, resp.user.id, parentRegID);
            })
            .then((resp) => {
                this.setState({error: false, success: true});
            })
            .catch((error) => {
                let errorMsg = "";
                console.log(error);
                if (error instanceof Response && error.status == 403) {
                    errorMsg = "Password is not correct. Please try again.";
                }
                else  if (error instanceof Response && error.status == 404) {
                    errorMsg = "There is no user with that username. Please try again.";
                }
                else if (error instanceof Error) {
                    errorMsg = error.message;
                }
                else {
                    errorMsg = "Unknown error.";
                }
                this.setState({error: true, errorMsg});
            });
        }
    }

    componentDidMount() {
        if (localStorage.cricketTogetherSession) {
            console.log("Logged in on session page, logging out...");
            store.dispatch(logout( () => {
                store.dispatch(redirect(location.pathname + location.search));
            }));
            return;
        }

        this.setState({sessionLoading: true});

        sessionService.fetch(this.props.params.session_id)
        .then((resp) => {
            this.setState({sessionLoading:false, sessionData: resp.data});
        })
        .catch((error) => {
            this.setState({sessionLoading:false, loadingError: error, success: error.status === 403});
            //throw error; // let the top-level error boundary handle this?...
        });
    }

    renderSessionFull(tenant) {
        let contactEmail = "support@crickettogether.com";

        if (tenant.data)  {
            contactEmail = tenant.data.contactEmail || contactEmail;
        }

        return (
            <form className={cn('form')}>
                <div className={cn('success')}>
                    <div className={cn('instructions')}>
                        Thanks for your interest in serving as an eMentor! This session is currently full.
                        We have sufficient volunteers to serve the current volume of students.
                    </div>
                    <div className={cn('instructions')}>
                        Please reach out to us at <a href={`mailto:${contactEmail}`}>{contactEmail}</a> to get placed on the wait list.
                    </div>
                </div>
            </form>
        );
    }

    renderSignupForm(tenant) {
        let mentorTermsUrl = "https://static.cricketmedia.com/crickettogether/ct-terms.pdf";
        let appName = "Cricket Together";
        let contactEmail = "support@crickettogether.com";

        if (tenant.data)  {
            mentorTermsUrl = tenant.data.mentor.mentorTermsUrl || mentorTermsUrl;
            appName = tenant.data.name || appName;
            contactEmail = tenant.data.contactEmail || contactEmail;
        }

        if (this.state.loadingError) {
            switch (this.state.loadingError.status) {
                case 404:
                    store.dispatch(redirect('/'));
                    return;
                case 403:
                    return (
                        <div className={cn('success')}>
                            <div className={cn('instructions')}>Sorry, this session is no longer available.</div>
                            <div className={cn('instructions')}>To learn about current eMentoring opportunites, contact <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.</div>
                        </div>
                    );
                default:
                    throw new Error(`Unknown error loading session data - code=${this.state.loadingError.status}`);
            }
        }

        const pageLink = encodeURIComponent(this.props.location.pathname + this.props.location.search);
        let registerLink = `/register?sessionId=${this.props.params.session_id}&sessionName=${encodeURIComponent(this.state.sessionData.signupName || this.state.sessionData.name)}`;
        const forgotUsernameLink = `/forgot/username?next=${pageLink}`;
        const forgotPasswordLink = `/forgot/password?next=${pageLink}`;
        const regDone = this.props.location.query.regDone;

        if (this.state.parentRegID) {
            registerLink += `&regID=${this.state.parentRegID}`;
        }

        return !this.state.success ?
            <form className={cn('form')}>
                {!regDone &&
                    <Fragment>
                        <div className={cn('instructions')}>{t('signup.instructions1a', {sessionName: (this.state.sessionData.signupName || this.state.sessionData.name), appName})} </div>
                        <div className={cn('instructions')}>{t('signup.instructions1b', {sessionName: (this.state.sessionData.signupName || this.state.sessionData.name), appName})} </div>
                        <div className={cn('instructions')}>{t('signup.instructions2', {appName})}</div>
                        <div className={cn('instructions')}>{t('signup.instructions3pre',{appName})} <a href={registerLink}>{t('signup.instructions3link')}</a>{t('signup.instructions3post')}</div>
                    </Fragment>
                }
                {regDone &&
                    <Fragment>
                        <div className={cn('instructions')}>{t('signup.instructions4')}</div>
                        <div className={cn('instructions')}>{t('signup.instructions5', {appName})}</div>
                    </Fragment>
                }
                <label htmlFor="username" className={cn('label')}>Username</label>
                <input id="username" tabIndex={1} className={cn('input','username')} type="text" autoFocus="true" onChange={(ev) => this.updateUsername(ev)} minLength="1" value={this.state.username}/><br/>
                <a href={forgotUsernameLink} className={cn('forgot')}>Forgot username?</a><br/>
                <label htmlFor="password" className={cn('label')}>Password</label>
                <input id="password" tabIndex={2} className={cn('input')} type="password" onChange={(ev) => this.updatePassword(ev)} minLength="1" value={this.state.password}/><br/>
                <a href={forgotPasswordLink} className={cn('forgot')}>Forgot password?</a><br/>
                <div className={cn('instructions')}>
                <input type="checkbox" tabIndex={3} className={cn('terms')} id="terms" value="Agree" required="required" onChange={(ev) => this.updateTerms(ev)}/>
                <label htmlFor="terms" className={cn('label','terms')}>
                    {t('signup.terms1')}<a href={mentorTermsUrl} target="_blank"> {t('signup.termsLink')}</a>{t('signup.terms2')}
                </label><br/>
                </div>
                <Button enabled={this.state.username.length > 0 && this.state.password.length > 0 && this.state.terms} onClick={(ev)=> this.submitForm(ev)} submit={true} type="regular">Submit</Button>
                {this.state.error &&
                    <div className={cn('error')}>{this.state.errorMsg}</div>
                }
            </form>
            :
            <div className={cn('success')}>
                <div className={cn('instructions')}>{t('signup.success1')}</div>
                <div className={cn('instructions')}>{t('signup.success2')}</div>
            </div>;
    }

    render() {
        const tenant = store.getState().store.tenant;
        let contactEmail = "support@crickettogether.com";
        const {sessionLoading, sessionData: session} = this.state;
        const sessionLimitReached = session.max > 0 && session.mentors.length >= session.max;
        if (tenant.data)  {
            contactEmail = tenant.data.contactEmail || contactEmail;
        }

        if (sessionLoading) {
            return <LoadingSpinner />;
        }

        return (
            <Fragment>
            <h2 className={cn('title')}>{t('signup.welcome',{sponsorName: session.sponsorID.name})}</h2>
            <Card>
                <div className={cn('container',{success: this.state.success})}>
                    {!sessionLimitReached && this.renderSignupForm(tenant)}
                    {sessionLimitReached && this.renderSessionFull(tenant)}
                </div>
            </Card>
            <div className={cn('contact')}>{t('signup.contact')}&nbsp;
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
            </div>
            </Fragment>
        );
    }
}

module.exports = Signup;
