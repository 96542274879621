({Component} = require 'react') and (PropTypes = require 'prop-types')
style = require './paging_navigation.styl'
e = require('react-e/bind') style

class PagingNavigation extends Component

  @defaultProps:
    currentRange: '0-0'
    isNextEnabled: true
    isPreviousEnabled: true
    totalItems: 0


  @propTypes =
    currentRange: PropTypes.string.isRequired
    isNextEnabled: PropTypes.bool
    isPreviousEnabled: PropTypes.bool
    onNextClick: PropTypes.func.isRequired
    onPreviousClick: PropTypes.func.isRequired
    totalItems: PropTypes.string.isRequired


  onNextClick: =>
    if @props.isNextEnabled is true
      @props.onNextClick()


  onPreviousClick: =>
    if @props.isPreviousEnabled is true
      @props.onPreviousClick()


  render: ->
    e '.paging-navigation',
      e '.range',
        t 'pagingNavigation.range',
          currentRange: @props.currentRange
          totalItems: @props.totalItems
      e 'a.previous-page',
        className: inactive: not @props.isPreviousEnabled
        onClick: @onPreviousClick
      e 'a.next-page',
        className: inactive: not @props.isPreviousEnabled
        onClick: @onNextClick

PagingNavigation.displayName = "PagingNavigation"

module.exports = PagingNavigation
