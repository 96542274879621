import React from 'react';
import {redirect} from 'actions/routing';
import classroomsActions from 'actions/classrooms';
import {build} from 'utils/container_helpers';
import LoadingSpinner from 'components/shared/loading_spinner';
import UserHelpers from 'utils/user_helpers';

// The ClassroomContainer is a HOC that ensures child components can access
// the 'classroom' field from the store.
//
//   - If there is no classroom loaded yet, it will dispatch a setClassroom
//     action to load the FIRST classroom from the list of classrooms

//   - If the list of classrooms is empty, it will redirect to /onboarding to
//     start the onboarding process.

//   - If there is already a classroom set then it will do nothing.  It is
//     important to not reload classroom data unless necessary, otherwise
//     it will cause unnecessary component unmounts and flashing/flicker.

const mapStateToProps = ({store: {user, sessions, classroom, classrooms, tenant}}) =>{
  return {
    user: UserHelpers.getTransformedUser(user.data, sessions.data, classrooms.data, tenant.data),
    classroom,
    classrooms,
  };
};

class ClassroomContainer extends React.Component {

  componentDidMount() {
    const {classroom, classrooms, user, dispatch} = this.props;
    if (classroom.data === undefined) {
      const role = user.roles[0];
      let defaultClassroom;
      if (role === 'mentor') {
        if (classrooms.data && classrooms.data.length > 0) {
          defaultClassroom = classrooms.data.find(x => x.studentMentorGroups.find( smg => smg.mentor === user.id));
        }
      } else {
        defaultClassroom = classrooms.data[0];
      }

      if (defaultClassroom) {
        const defaultClassroomId = defaultClassroom.id;
        console.log(`classroom_container: setting classroom to ${defaultClassroomId}`);
        // console.log(`classroom_container: classroom = `, defaultClassroom);
        dispatch(classroomsActions.setClassroom(defaultClassroomId));
      } else {
        if (user.emailVerified) {
          console.log(`classroom_container: no classrooms, redirecting to /onboarding`);
          dispatch(redirect('/onboarding'));
        } else {
          console.log(`classroom_container: email not verified, redirecting to /onboarding/verify`);
          dispatch(redirect('/onboarding/verify'));
        }
      }
    }
  }

  render() {
    const { classroom } = this.props;
    const isClassroomDataReady = (!classroom.loading && classroom.data !== undefined && !classroom.error);
    if (isClassroomDataReady) {
      return this.props.children;
    } else {
      console.log('classroom_container: loading', {classroom});
      return <LoadingSpinner />
    }
  }
}

ClassroomContainer.displayName = "ClassroomContainer";

const Resolver = build({component: ClassroomContainer, mapStateToProps});
const withClassroom = (Component, extraProps={}) => props => {
  props = Object.assign({}, props, extraProps);
  return (
    <Resolver>
      <Component {...props} />
    </Resolver>
  );
};

module.exports = {
  ClassroomContainer: Resolver,
  withClassroom
};
