({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
require 'react-select/dist/react-select.css'
style = require './dropdown.styl'
e = require('react-e/bind') style

Async = createFactory require('react-select').Async
Select = createFactory require('react-select').default
Entry = PropTypes.shape
  label: PropTypes.string.isRequired
  value: PropTypes.string


class Dropdown extends Component

  constructor: (props) ->
    super()
    @state = value: props.value


  @propTypes:
    clearable: PropTypes.bool
    disabled: PropTypes.bool
    onChange: PropTypes.func
    options: PropTypes.arrayOf(Entry)
    value: PropTypes.string


  @getDerivedStateFromProps: (props,state) ->
    return value: props.value

  render: ->
    e '.dropdown',
      if @props.async
        Async
          arrowRenderer: ->
            e '.arrow-container', e '.icon-dropdown-arrow'
          clearable: @props.clearable
          disabled: @props.disabled
          loadOptions: @props.loadOptions
          onChange: (option) =>
            @setState value: option?.value
            @props.onChange option
          value: @state.value
      else
        Select
          arrowRenderer: ->
            e '.arrow-container', e '.icon-dropdown-arrow'
          clearable: @props.clearable
          disabled: @props.disabled
          onChange: (option) =>
            @setState value: option?.value
            @props.onChange option
          options: @props.options
          searchable: no
          value: @state.value

Dropdown.displayName = "Dropdown"

module.exports = Dropdown
