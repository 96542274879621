_ = require 'lodash'
({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './letter_center_card.styl'
e = require('react-e/bind') style

Card = createFactory require 'components/shared/card'
Button = createFactory require 'components/shared/button'

class LetterCenterCard extends Component

  @propTypes:
    buttonOnClick: PropTypes.func
    buttonTitle: PropTypes.string
    classroomIsActive: PropTypes.bool
    content: PropTypes.string
    loaded: PropTypes.bool
    subtitle: PropTypes.string
    title: PropTypes.string
    showDeleteButton: PropTypes.bool
    deleteButtonTitle: PropTypes.string
    deleteButtonOnClick: PropTypes.func
    viewButtonTitle: PropTypes.string
    viewButtonOnClick: PropTypes.func
    showButton: PropTypes.bool

  render: ->
    e '.letter-center-card',
      Card {},
        e '.card-container',
          if @props.loaded
            e '.content-container',
              e '.card-row',
                e '.card-column',
                  e '.card-title', @props.title
                  e '.card-subtitle', @props.subtitle
                if @props.classroomIsActive and @props.showButton
                  e '.student-write-pen-pal-button',
                    Button
                      onClick: @props.buttonOnClick
                      type: 'regular',
                      @props.buttonTitle
              if @props.classroomIsActive
                e '.card-row',
                  e '.card-content', @props.content
              if @props.showDeleteButton and @props.classroomIsActive
                e '.delete-draft',
                  [
                    Button
                      key: 1
                      onClick: @props.deleteButtonOnClick
                      type: 'regular'
                      mode: 'primary-inverted'
                      size: 'small',
                      @props.deleteButtonTitle
                    Button
                      key: 2
                      onClick: @props.viewButtonOnClick
                      type: 'regular'
                      mode: 'primary-inverted'
                      size: 'small',
                      @props.viewButtonTitle
                  ]


LetterCenterCard.displayName = "LetterCenterCard"

module.exports = LetterCenterCard
