// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a6691f61761b5ce02eee7e3ef94333cc-styl > div > div {\n  position: inherit !important;\n}\n.fr-file {\n  color: var(--theme-blue-light, #00aff2);\n}\n.fr-file:before {\n  color: var(--theme-grey-medium, #666);\n  content: '\\e80c';\n  font-family: fontello;\n  font-size: 0.625rem;\n  margin: 0 0.25rem;\n}\n.fr-popup {\n  max-height: 22rem;\n  overflow: auto;\n}\n", "",{"version":3,"sources":["webpack://cricket-together/app/components/shared/editor/editor.styl"],"names":[],"mappings":"AAEA;EACE,4BAAS;AADX;AAIE;EACE,uCAA8C;AAFlD;AAII;EACE,qCAAgD;EAChD,gBAAQ;EACR,qBAAY;EACZ,mBAAU;EACV,iBAAO;AAFb;AAIE;EACE,iBAAW;EACX,cAAS;AAFb","sourcesContent":["@import 'variables'\n\n.editor > div > div\n  position inherit !important\n\n:global\n  .fr-file\n    color var(--theme-blue-light,$theme-blue-light)\n\n    &:before\n      color var(--theme-grey-medium,$theme-grey-medium)\n      content $icon-link\n      font-family fontello\n      font-size .625rem\n      margin 0 .25rem\n\n  .fr-popup\n    max-height 22rem\n    overflow auto\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": "a6691f61761b5ce02eee7e3ef94333cc-styl"
};
module.exports = ___CSS_LOADER_EXPORT___;
