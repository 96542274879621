({Component} = require 'react') and (PropTypes = require 'prop-types')
style = require './content_loader.styl'
e = (require 'react-e/bind') style
_ = require 'lodash'

class ContentLoader extends Component

  @defaultProps:
    icon: 'icon.png'
    showSpinner: true


  @propTypes:
    content:
      PropTypes.shape
        data: PropTypes.oneOfType [PropTypes.array, PropTypes.object]
        error: PropTypes.string
        loading: PropTypes.bool
    #TODO: deprecate contentIsReadyOverride
    contentIsReadyOverride: PropTypes.func
    error: PropTypes.string
    icon: PropTypes.string
    loading: PropTypes.bool
    name: PropTypes.string
    ready: PropTypes.bool
    renderChild: PropTypes.func.isRequired
    showSpinner: PropTypes.bool


  render: ->
    error = @props.error or @props.content?.error
    loading = @props.loading or @props.content?.loading
    if @props.contentIsReadyOverride?
      return @props.renderChild() if @props.contentIsReadyOverride()
      return @renderLoading()

    if error?
      throw new Error("#{t 'contentLoader.error', error: @props.name or ''}: #{error}")

    if loading or (@props.ready is false)
      return @renderLoading()

    if @props.content? and _.isEmpty @props.content.data
      return e '.empty',
        e 'p',
          t 'contentLoader.empty',
            name: @props.name
        e 'span.icon', @props.icon

    @props.renderChild()

  renderLoading: ->
    e '.loading',
      if @props.showSpinner
        e '.spinner',
          e '.bounce1'
          e '.bounce2'
          e '.bounce3'

ContentLoader.displayName = "ContentLoader"

module.exports = ContentLoader
