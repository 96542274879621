api = require '../api'

module.exports =

  create: (data) ->
    data.service = TENANT
    api.send
      method: 'POST'
      path: "/api/v2/applications"
      payload: data
