api = require '../api'
{makeAction} = require 'utils/action_helpers'
unitsService = require 'services/units_service'

_findFromAPI = (id) -> (setState) ->
  unitsService.find(id)
    .then ({data: unitTemplate}) ->
      setState 'unitTemplate', data: unitTemplate, loading: false
    .catch (error) ->
      setState 'unitTemplate', error: error.toString(), loading: false


find = (id) -> makeAction (setState) ->
  # TODO: if template exists in unitTemplates, get it from there
  (_findFromAPI id) setState


module.exports = {find}
