import React from 'react';
import PropTypes from "prop-types";
const _ = require('lodash');
const style = require('./register.styl');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
const {redirect} = require('actions/routing');
const Recaptcha = require('react-recaptcha');
const Button = require('components/shared/button');
const usersService = require('services/users_service');
const applicationsService = require('services/applications_service');
import LoadingSpinner from 'components/shared/loading_spinner';

const mapStateToProps = ({store: {tenant}}) => ({
    tenant: tenant.data,
});

class RegisterTeacher extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      password2: "",
      applicationId: props.params.application_id || null,
      application: null,
      step: 'form',
    };

    this._refs = {};
  }

  componentDidMount() {
    let {location, params, dispatch} = this.props;
    const applicationId = params.application_id;
    applicationsService.find(applicationId)
      .then((response) => {
        const application = response.data;
        const step = application.userID ? 'submitted' : 'form';
        this.setState({application, step});
      })
      .catch((error) => {
        console.log({error});
        this.setState({error: true, errorMsg: error.statusText});
      });
  }


  setRef(field, e) {
    this._refs[field] = e;
  }

  updateField(field, ev, checkbox = false) {
    let state = {error: false, errorMsg: ""};
    state[field] = checkbox ? ev.target.checked : ev.target.value;
    this.setState(state);
    this._refs[field] && this._refs[field].setCustomValidity("");
  }

  verifyCallback(resp) {
    this.setState({verified: true});
  }

  expiredCallback(resp) {
    this.setState({verified: false});
  }

  loadCallback(resp) {
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target.form;
    if (this.state.password === this.state.password2) {
      if (form.reportValidity()) {
        const { username, password, applicationId, application } = this.state;
        const {firstname, lastname, email} = application;
        const tenant = TENANT; // should be the same as the "id" field in tenant data
        const role = 'teacher';
        const emailVerified = true;
        usersService.create({username, password, firstname, lastname, email, emailVerified, tenant, role, applicationId}, true).then(
          (resp) => {
            this.setState({step: 'submitted'});
          }).catch(
          (error) => {
            let msg = null;
            if (error instanceof Error) {
              this._refs['username'].setCustomValidity(error.message);
            } else {
              this._refs['username'].setCustomValidity(error.reason);
            }
            form.reportValidity();
          });
      }
    } else {
      this._refs['password2'].setCustomValidity("Password fields do not match");
      form.reportValidity();
    }
  }

  render() {
    const {tenant} = this.props;
    const contactEmail = tenant.contactEmail || "support@crickettogether.com";
    const appName = tenant.name || "Cricket Together";
    const enableSubmit = this.state.username.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password2.length > 0 &&
      this.state.verified === true;

    if (this.state.application === null && !this.state.error) {
      return <LoadingSpinner/>;
    }

    const autoComplete = Date.now(); // Weird hack to ensure autoComplete never appears in Chrome

    return (
      <div className={cn('register-teacher-page')}>
        <h2>{t('register.title', {appName})}</h2>
        <div className={cn('container-centered')}>
          {this.state.step === 'form' &&
          <form autoComplete="off">
            <h3>
              {t('register.teacher.instructions', {name: this.state.application.firstname})}
            </h3>
            {this.state.error &&
            <div className={cn('error', {visible: this.state.error})}>
              {this.state.errorMsg || "Error in form data"}
            </div>
            }
            <fieldset>
              <label htmlFor="username">{t('register.username')}</label>
              <input ref={(e) => this.setRef('username', e)}
                     placeholder={t('register.usernamePlaceholder')} id="username"
                     type="text" minLength="5" required={true} pattern="[_a-zA-Z0-9]+"
                     value={this.state.username}
                     onChange={(ev) => this.updateField('username', ev)}/>

              <label htmlFor="password">{t('register.password')}</label>
              <input id="password" placeholder={t('register.passwordPlaceholder')}
                     type="password" minLength="1" required={true} value={this.state.password}
                     onChange={(ev) => this.updateField('password', ev)}/>

              <label htmlFor="password2">{t('register.password2')}</label>
              <input id="password2" placeholder={t('register.password2Placeholder')}
                     type="password" minLength="1" required={true}
                     value={this.state.password2}
                     onChange={(ev) => this.updateField('password2', ev)}
                     ref={(e) => this.setRef('password2', e)} />

              <span>&nbsp;</span>
              <div className={cn('captcha')}>
                <Recaptcha sitekey={RECAPTCHA_SITEKEY} render="explicit"
                           verifyCallback={(resp) => this.verifyCallback(resp)}
                           expiredCallback={() => this.expiredCallback()}
                           onloadCallback={() => this.loadCallback()}/>
              </div>
            </fieldset>

            <Button enabled={enableSubmit} submit={true} type="regular" onClick={(ev) => this.submitForm(ev)}>
              Submit
            </Button>

            <div className={cn('instructions', 'privacy')}>
              {t('register.privacyPre')}
              <a href="https://cricketmedia.com/privacy/" target="_blank">{t('register.privacyLink')}</a>.
            </div>
          </form>
          }
          {this.state.step === 'submitted' &&
          <div>
            {t('register.teacher.success', {appName})}
          </div>
          }
        </div>
        <div className={cn('contact')}>{t('register.contact')}&nbsp;
          <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
        </div>
      </div>
    );
  }
}

RegisterTeacher.displayName = "RegisterTeacher";

RegisterTeacher.propTypes = {
    tenant: PropTypes.object,
};

module.exports = build({
    component: RegisterTeacher,
    mapStateToProps
});
