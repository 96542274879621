import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from  'moment';
import ArticleCollection from 'components/shared/article_collection';
import PastUnits from 'components/shared/past_units';
import LoadingSpinner from 'components/shared/loading_spinner'

import UserHelpers from 'utils/user_helpers'


const style = require('./units.styl');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
const {redirect} = require('actions/routing');
const {
  getArticleSelection,
  getScrollOffset,
  getStudentId,
  showDetail
} = require('utils/article_normalizer');
const events = require('pages/student/units/events');
const articleSelectionActions = require('actions/article_selections');


const mapStateToProps = function({store}) {
  let pastUnits;
  const classroom = store.classroom.data;
  const classroomIsActive = classroom.status === 'active';
  const units = (pastUnits = classroom.units != null ? classroom.units : []);
  if (classroomIsActive) { pastUnits = _.filter(units, ({state}) => state === 'completed'); }
  pastUnits = _.orderBy(pastUnits, (({startDate}) => moment(startDate)), 'desc');
  return {
    articleSelections: store.articleSelections,
    classroom,
    classroomIsActive,
    classrooms: store.classrooms,
    id: store.session.data.login.id,
    pastUnits,
    userState: UserHelpers.getTransformedUser(store.session.data.login, null, classroom, store.tenant.data)
  };
};

class MyUnits extends React.Component {

  componentDidMount() {
    console.log('MyUnits::componentDidMount()');
    const {dispatch, classroom, id} = this.props;
    const studentId = getStudentId(classroom.studentMentorGroups, id);
    return dispatch(articleSelectionActions.fetch({
      student_id: studentId
    }));
  }

  componentWillUnmount() {
    console.log('MyUnits::componentWillUnmount()');
  }

  onClickReadArticle(articleId, articleName) {
    events.details(articleName);
    return showDetail({
      articleId,
      redirectTo: path => this.props.dispatch(redirect(path)),
      unitTemplateId: (this.props.classroom != null ? this.props.classroom.currentUnit.parent : undefined)
    });
  }

  render() {
    const {articleSelections, classroom, classroomIsActive, pastUnits, userState} = this.props;
    if (articleSelections.loading) {
      return <LoadingSpinner/>
    }
    if (articleSelections.error) {
      throw new Error("selected articles: #{articleSelections.error}");
    }

    const unit = classroom.currentUnit;
    const articleSelection = getArticleSelection(articleSelections.data, unit._id);

    return (
        <div className={cn('units')}>
          {classroomIsActive &&
          <ArticleCollection
              arrow={false}
              articleSelection={articleSelection}
              articles={unit.content}
              isDefaultUnit={unit.isDefaultUnit}
              onClick={(articleId, articleName) => this.onClickReadArticle(articleId, articleName)}
              readEvent={(articleTitle) => {return events.read(articleTitle);}}
              role='mentor'
              palName={userState.pal.firstname}
              scrollPosition={getScrollOffset(unit, articleSelection)}
          />
          }
          {pastUnits.length > 0 &&
          <PastUnits
              role='mentor'
              units={pastUnits}/>
          }
        </div>
    );
  }
}

MyUnits.displayName = "MyUnits";

module.exports = build({
  component: MyUnits,
  mapStateToProps
});
