_ = require 'lodash'
({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './selection_modal.styl'
e = require('react-e/bind') style

Button = createFactory require 'components/shared/button'
Modal = createFactory require 'components/shared/modal'

class SelectionModal extends Component

  constructor: (props) ->
    super(props)
    @state = show: @props.show


  @propTypes:
    closeButtons: PropTypes.arrayOf(PropTypes.shape
      element: PropTypes.element
      onClick: PropTypes.func
    ).isRequired
    content: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired
    enabled: PropTypes.bool.isRequired
    onModalCrossButtonClick: PropTypes.func
    title: PropTypes.string.isRequired
    triggerAction: PropTypes.func
    triggerButtonTitle: PropTypes.string.isRequired


  render: ->
    e '.modal-wrapper',
      e '.trigger', onClick: @toggleModal,
        Button
          enabled: @props.enabled
          onClick: @props.triggerAction
          size: 'large'
          type: 'regular'
          @props.triggerButtonTitle
      if @state.show
        Modal
          onClose: =>
            @toggleModal()
            @props.onModalCrossButtonClick?()
          e '.selection-modal',
            e '.header', @props.title
            _.map @props.content, (value, index) ->
              e 'p.text', key: index, value
            e '.buttons',
              @props.closeButtons.map ({element, onClick}, index) =>
                e '.button',
                  key: index
                  onClick: =>
                    @toggleModal()
                    onClick?()
                  element


  toggleModal: =>
    @setState ({show}) -> show: not show

SelectionModal.displayName = "SelectionModal"

module.exports = SelectionModal
