({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './past_units.styl'
e = (require 'react-e/bind') style
classNames = require 'classnames/bind'
bindStyles = classNames.bind style
_ = require 'lodash'
{getUnitDates} = require 'utils/date_normalizer'
DatePickerCard = createFactory require 'components/shared/date_picker_card'
UnitDetailDatePicker = createFactory require 'components/unit_detail_date_picker'

class PastUnits extends Component

  @propTypes:
    classroomIsActive: PropTypes.bool
    onClick: PropTypes.func
    pendingLettersByUnit: PropTypes.object
    role: PropTypes.string.isRequired
    units: PropTypes.array.isRequired


  render: ->
    e '.past-units', className: classNames('with-margin': @props.role in ['mentor', 'student']),
      e '.past-units-title-container',
        e 'h2.past-units-title', t 'teacherUnits.pastUnits'
        if @props.role is 'teacher' and @props.classroomIsActive
          totalUnreviewed = (_.sum _.map @props.pendingLettersByUnit, 'length') or 0
          if totalUnreviewed
            e '.past-units-counter',
               e '.pending-count', totalUnreviewed
      _.map @props.units, (pastUnit, i) =>
        numUnreviewedLetters = @props.pendingLettersByUnit?[pastUnit.id]?.length
        e '.small-datepicker', key: i,
          DatePickerCard
            condensed: yes
            datepicker:
              UnitDetailDatePicker
                dates: _.filter(getUnitDates(pastUnit, @props.role), (x) => x.id is 'startDate' or x.id is 'endDate')
                isEditable: no
                wide: yes
            wide: yes
            e 'div',
              e '.unit-name-container',
                e 'h2.unit-name',
                  className: 'with-max-width': numUnreviewedLetters
                  onClick: => @props.onClick? pastUnit
                  pastUnit.name
                if @props.role is 'teacher' and numUnreviewedLetters and @props.classroomIsActive
                  e 'a.unit-letters', onClick: (=> @props.onPendingLetterClick? pastUnit),
                    t 'teacherUnits.pendingLetters',
                      count: numUnreviewedLetters
              if pastUnit.subjects.length > 0 or pastUnit.strands.length > 0
                e '.metadata',
                  e '.metadata-line',
                    e '.name', "#{t 'studentUnits.subjects'} "
                    e '.value', pastUnit.subjects.map((x) -> x.name).join(', ')
                  e 'p.metadata-line',
                    e 'span.name', "#{t 'studentUnits.strands'} "
                    e 'span.value', pastUnit.strands.map((x) -> x.name).join(', ')

PastUnits.displayName = "PastUnits"

module.exports = PastUnits
