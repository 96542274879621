const style = require('./survey.styl');
const cn = require('classnames/bind').bind(style);
const {Component} = require('react');
const {ReactTypeformEmbed} = require('react-typeform-embed');
const {dispatch} = require('store');
const usersActions = require('actions/users');
const {redirect} = require('actions/routing');
const Modal = require('components/shared/modal');
const Button = require('components/shared/button')

class Survey extends Component {

    constructor(props) {
        super(props);
        this.state = {surveyComplete: false}
        this.markSurveyComplete = this.markSurveyComplete.bind(this);
    }

    markSurveyComplete() {
        dispatch(usersActions.updateAppState({
            id: this.props.params.user_id,
            classroomId: this.props.params.classroom_id,
            appState: { [`${this.props.location.query.type}SurveyComplete`]: true}
            },
            () => this.setState({surveyComplete: true})
        ));
    }

    navigateToHomePage() {
        dispatch(redirect('/'));
    }

    displaySubmitConfModal() {
    return (
        <Modal onClose={this.navigateToHomePage} top={false}>
            <div className={cn('modal')}>
                <div className={cn('modal-header')}>{t('surveyPage.surveyCompleteModalTitle')}</div>
                <div className={cn('modal-text')}>{t('surveyPage.surveyCompleteModalText')}</div>
                <div className={cn('modal-buttons')}>
                    <div className={cn('button-right')}>
                        <Button onClick={this.navigateToHomePage} size='large' type='regular'>
                            {t('surveyPage.surveyCompleteModalButton')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
    }

    render() {
        console.log(this.props.location.query);
        if (this.state.surveyComplete) {
            return this.displaySubmitConfModal();
        }

        const embedId = this.props.params.survey_id; //'yQGeQd'; //props.params.survey_id;
        const embedLink = `https://cricketmedia.typeform.com/to/${embedId}`;
        //const embedCode = `<div class="typeform-widget" data-url="https://cricketmedia.typeform.com/to/yQGeQd" style="width: 100%; height: 500px;"></div>`
        //return <div className="typeform-widget" data-url={embedLink} style={{width: '100%',height:'500px'}}></div>
        return <ReactTypeformEmbed
                    url={embedLink}
                    style={{position:'static', width: '100%',height:'500px',borderRadius:'.625rem'}}
                    onSubmit={this.markSurveyComplete}
                />
    }
}

module.exports = Survey;
