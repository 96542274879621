({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
{build} = require 'utils/container_helpers'
{assign, compact, filter, find, flatMap, flatten, includes, map, reject, uniqBy} = require 'lodash'
{browserHistory} = require 'react-router'
{formatMetadata, formatValue, showDetail} = require 'utils/article_normalizer'
{redirect} = require 'actions/routing'
events = require '../events'
exploreUnitsActions = require 'actions/explore_units'
style = require './article_detail.styl'
e = require('react-e/bind') style
style = require './article_detail.styl'
ArticlePreview = createFactory require 'components/shared/article_preview/index.jsx'
Button = createFactory require 'components/shared/button'
Card = createFactory require 'components/shared/card'
Carousel = createFactory require 'components/shared/carousel'
ContentLoader = createFactory require 'components/shared/content_loader'
EReader = createFactory require 'components/shared/e_reader'
ModalWrapper = createFactory require 'components/shared/modal/modal_wrapper'

mapStateToProps = ({store}) ->
  units: store.unitTemplates
  hideRelatedArticles: store.tenant?.data?.hideRelatedArticles

class ArticleDetail extends Component

  componentDidMount: ->
    @props.dispatch exploreUnitsActions.fetch()


  _getArticle: (articleId, unit) ->
    find unit.content, ({_id}) -> _id is articleId


  _getContainingUnits: (units, article) ->
    filter units, ({_id}) -> includes article.unit, _id


  _getQuestions: (unit) ->
    essentialQuestion: unit.essentialQuestion
    supportingQuestions: unit.supportingQuestions


  _getRelatedArticles: (containingUnits) ->
    unitArticles = map containingUnits, (unit) =>
      map unit.content, (article) =>
        article: @_getArticle article._id, unit
        unitTemplateId: unit._id
    articles = flatten unitArticles
    deduplicated = uniqBy(articles, ({article: {_id}}) -> _id)
    filter deduplicated, ({article: {_id}}) => _id isnt @props.params.article_id


  _getUnit: (unitTemplateId) ->
    find @props.units.data, ({_id}) -> _id is unitTemplateId


  _renderBackButton: ->
    e '.back-button', Button
      mode: 'transparent'
      onClick: browserHistory.goBack
      size: 'small'
      type: 'regular'
      e '.back', 'Back'


  _renderQuestions: (questions) ->
    e '.questions',
      e '.header', t 'articleDetail.thinkingQuestions'
      e '.rule'
      e '.subheader', t 'articleDetail.essentialQuestion'
      e '.body.essential', questions.essentialQuestion
      e '.subheader', t 'articleDetail.supportingQuestions'
      e '.body',
        e 'ol.question-list',
          map questions.supportingQuestions, (question, i) ->
            e 'li.question-item', key: i, question


  _renderRelatedArticles: (containingUnits) ->
    e '.related-articles',
      e '.header', t 'articleDetail.relatedArticles'
      Carousel {},
        map @_getRelatedArticles(containingUnits), ({article, unitTemplateId}) =>
          e '.article', key: article._id,
            ArticlePreview
              article: article
              onClickLink: =>
                events.details article.title
                showDetail {
                  articleId: article._id
                  redirectTo: (path) => @props.dispatch redirect path
                  unitTemplateId
                }
              onClickRead: -> events.readArticle article.title
              scheme: 'plain'


  _renderTopCard: (articleData, containingUnits) ->
    Card {},
      e '.top-card',
        e '.image',
          e '.content-image',
            style: 'backgroundImage': "url(#{articleData.image})"
        e '.content-container',
          e '.text',
            e '.title', articleData.title
            e '.byline', "by #{articleData.author}"
            e '.summary', articleData.summary
          e '.metadata',
            e '.button',
              ModalWrapper
                eReader: yes
                trigger:
                  Button
                    onClick: -> events.readArticle articleData.title
                    size: 'medium'
                    type: 'regular'
                    t 'articleDetail.readIt'
                EReader filename: articleData.filename

            if articleData.subjects.length > 0 or articleData.strands.length > 0
              e '.metadata-line',
                e '.metadata-subjects',
                  e 'dt', t('articlePreview.subjects')
                  e 'dd', formatValue articleData.subjects.map((x) -> x.name).join(', ')
                e '.metadata-strands',
                  e 'dt', t('articlePreview.strands')
                  e 'dd', formatValue articleData.strands.map((x) -> x.name).join(', ')
            if articleData.grade or articleData.lexile
              e '.metadata-line',
                e '.metadata-grades',
                 style: 'display': (if articleData.grade in ['', undefined] then 'none' else ''),
                  e 'dt', t('articlePreview.grade')
                  e 'dd', formatValue articleData.grade
                if articleData.lexile
                  e '.metadata-lexile',
                    e 'dt', t('articlePreview.lexile')
                    e 'dd', formatValue t 'articlePreview.lexileValue', lexile: articleData.lexile

  _renderWordArticle: (word) ->
    {article = '', name = ''} = word
    regex = new RegExp name, 'i'
    boldWord = article.match regex
    [before, after] = article.split regex
    e 'p.info.article',
      e 'span', before
      e 'span.bold', boldWord
      e 'span', after


  _renderWords: (importantWords) ->
    e '.words',
      e 'h2.header', t 'articleDetail.importantWords'
      e '.word-cards',
        map importantWords, (word, i) =>
          e '.word-card', key: i,
            Card {},
              e '.word-content',
                e 'h3.word-header', word.name
                e '.word-rule'
                e '.word-label', t 'articleDetail.articleContext'
                @_renderWordArticle word
                e '.word-label', t 'articleDetail.definition'
                e 'p.info.definition', word.definition or ''


  render: ->
    ContentLoader
      content: @props.units
      name: 'content'
      renderChild: =>
        articleId = @props.params.article_id
        unit = @_getUnit @props.params.template_id
        articleData = @_getArticle articleId, unit
        containingUnits = @_getContainingUnits @props.units.data, articleData
        e '.article-detail',
          @_renderBackButton()

          @_renderTopCard articleData, containingUnits

          @_renderQuestions @_getQuestions(unit)

          @_renderWords articleData.importantWords

          unless @props.hideRelatedArticles
            @_renderRelatedArticles containingUnits

ArticleDetail.displayName = "ArticleDetail"

module.exports = build {
  component: ArticleDetail,
  mapStateToProps
}
