analytics = require 'utils/analytics'

actions =
  endDate: 'Unit End Date'
  focusArticleDate: 'Student Selects Article Date'
  mentorDate: 'Mentor Letter Due Date'
  startDate: 'Unit Start Date'
  studentDate: 'Student Letter Due Date'
  mentor2Date: 'Mentor Letter 2 Due Date'
  student2Date: 'Student Letter 2 Due Date'


categories =
  activeUnit: 'Current Unit'
  addUnit: 'Add Unit'
  completedUnit: 'Past Unit'
  currentUnit: 'Current Unit'
  editUnit: 'Edit Unit'
  newUnit: 'Explore Units'
  pendingUnit: 'Upcoming Unit'
  viewUnit: 'Explore Units'

module.exports =

  addingUnit: (name) ->
    analytics.recordEvent
      action: "Clicked 'Add Unit'"
      category: categories.addUnit
      label: name


  canceledStart: ->
    analytics.recordEvent
      action: 'Canceled Start Unit'
      category: categories.pendingUnit


  deletedUnit: ->
    analytics.recordEvent
      action: 'Delete Unit'
      category: 'Upcoming Unit'


  editUnit: (mode, source) ->
    analytics.recordEvent
      action: "Edited #{if mode is 'pending' then 'upcoming' else mode} Unit"
      category: categories["#{mode}Unit"]
      label: source


  exploringUnits: (source) ->
    analytics.recordEvent
      action: "Explore Units - #{source}"
      category: categories.newUnit


  savedUnit: (mode, state) ->
    if mode is 'new' then mode = 'add'
    analytics.recordEvent
      action: 'Saved Unit'
      category: categories["#{state or mode}Unit"]


  selectedDate: (dateKey, mode, state) ->
    if mode is 'new' then mode = 'add'
    analytics.recordEvent
      action: actions[dateKey]
      category: categories["#{state or mode}Unit"]
      label: if state then 'Edited' else 'Added'


  startedUnit: ->
    analytics.recordEvent
      action: 'Started Unit'
      category: categories.currentUnit


  startingUnit: ->
    analytics.recordEvent
      action: "Clicked 'Start Unit' Button"
      category: categories.pendingUnit


  toggledAttachments: (active) ->
    state = if active then 'On' else 'Off'
    analytics.recordEvent
      action: "Turned #{state} Attachements and Images"
      category: categories.currentUnit


  viewedLetterPrompts: (mode, unitName) ->
    if mode is 'new' then mode = 'add'
    analytics.recordEvent
      action: 'Viewed Letter Prompts'
      category: categories["#{mode}Unit"]
      label: unitName


  viewedTeachersGuide: (mode, unitName) ->
    if mode is 'new' then mode = 'add'
    analytics.recordEvent
      action: "Viewed Teacher's Guide"
      category: categories["#{mode}Unit"]
      label: unitName


  viewedUnit: (name) ->
    analytics.recordEvent
      action: 'Viewed Unit'
      category: categories.newUnit
      label: name
