({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './popup.styl'
e = require('react-e/bind') style
Portal = createFactory require('react-portal').PortalWithState

class Popup extends Component

  @defaultProps:
    isHidden: false
    left: 0
    offsetLeft: 0
    offsetTop: 0
    top: 0


  @propTypes =
    bindPopup: PropTypes.func
    isHidden: PropTypes.bool
    left: PropTypes.number
    offsetLeft: PropTypes.number
    offsetTop: PropTypes.number
    top: PropTypes.number


  closePopup: () =>
    @_closePortal()


  componentDidMount: () ->
    @props.bindPopup? this


  openPopup: () =>
    @_openPortal()


  render: ->
    portalContent = e '.popup-container',
      className: hidden: @props.isHidden
      style:
        'left': (@props.left + @props.offsetLeft)
        'position': 'absolute'
        'top': (@props.top + @props.offsetTop)
      @props.children

    Portal closeOnOutsideClick: true,
      ({portal, openPortal, closePortal}) =>
        @_openPortal = ->
          # A known issue requires us to wrap this in setTimeout if we set closeOnOutsideClick to true
          # (see https://github.com/tajo/react-portal/issues/222 for more info)
          setTimeout openPortal, 0
        @_closePortal = closePortal
        portal portalContent


Popup.displayName = "Popup"

module.exports = Popup
