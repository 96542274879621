{Component, createFactory} = require 'react'
{build} = require 'utils/container_helpers'
_ = require 'lodash'
moment = require 'moment'
{redirect} = require 'actions/routing'
articleSelectionActions = require 'actions/article_selections'
messagesActions = require 'actions/messages'
usersActions = require 'actions/users'
{sentByClassMember} = require 'pages/teacher/units/normalization_helper'
{getUnitDates} = require 'utils/date_normalizer'
events = require 'pages/teacher/classroom_unit/events'

ContentLoaderContainer = createFactory require 'containers/content_loader_container'
Home = createFactory require 'components/home'

getLocalizationData = require './localization_helper'
actionItems = topContents = null

_getNumPending = (array) ->
  array
  .filter ({status}) -> status is 'pending'
  .length

mapStateToProps = ({store}) ->
  {classroom, mailCount, messagesForClassroom, session, tenant} = store
  classroom: classroom
  mailCount: mailCount
  messagesForClassroom: messagesForClassroom
  session: session
  tenant: tenant

class TeacherHome extends Component

  componentDidUpdate: (oldProps) ->
    unless _.isEqual oldProps.messagesForClassroom.data, @props.messagesForClassroom.data
      pastUnreviewedActionItems = @_pastUnreviewedActionItems @props.classroom, @props.messagesForClassroom
      rejectedLetterActionItems = @_rejectedLetterActionItems @props.classroom, @props.messagesForClassroom
      actions = pastUnreviewedActionItems.concat(rejectedLetterActionItems)
      @setState actions: actions


  componentDidMount: ->
    if @props.classroom.data and @props.messagesForClassroom.data
      pastUnreviewedActionItems = @_pastUnreviewedActionItems @props.classroom, @props.messagesForClassroom
      rejectedLetterActionItems = @_rejectedLetterActionItems @props.classroom, @props.messagesForClassroom
      actions = pastUnreviewedActionItems.concat(rejectedLetterActionItems)
      @setState actions: actions


  constructor: ->
    super()
    @state = actions: []


  render: ->
    {classroom, dispatch, session, tenant} = @props
    {currentUnit} = classroom.data
    firstName = session.data.login.firstname
    userId = session.data.login.id
    classroomId = classroom.data._id

    {actionItems, topContents} = getLocalizationData {dispatch, userId, classroom: classroom.data, tenant}

    ContentLoaderContainer
      getContentFromStore: ({articleSelections, messages, messagesForClassroom, user}) ->
        {articleSelections, messages, messagesForClassroom, user}
      getError: ({articleSelections, messages, messagesForClassroom, user}) ->
        articleSelections.error or messages.error or messagesForClassroom.error or user.error
      getLoading: ({articleSelections, messages, messagesForClassroom, user}) ->
        articleSelections.loading or messages.loading or messagesForClassroom.loading or user.loading
      getReady: ({articleSelections, messages, messagesForClassroom, user}) ->
        articleSelections.data? and messages.data? and messagesForClassroom.data? and (user.data?.id is userId)
      loadContent: ->
        dispatch messagesActions.fetchAllForClassroom classroom.data
        dispatch articleSelectionActions.fetch classroomUnit_id: currentUnit.id
        dispatch messagesActions.fetchForClassroom classroom.data, null, true
      name: t 'teacherHomePage.contentLoaderName'
      renderChild: (content) =>
        articleSelections = _.values content.articleSelections.data
        allMessages = _.values content.messages.data
        messages = allMessages.filter ({state}) -> state not in ['draft','newMessageDraft']
        messagesForClassroom = (_.values content.messagesForClassroom.data).filter ({state}) -> state not in ['draft','newMessageDraft']
        user = content.user
        {cards, context} = @_getCardsAndContext {articleSelections, allMessages, messages, messagesForClassroom, user}

        Home
          actionItems: actionItems
          classroom: classroom
          context: context
          currentUnit: currentUnit
          dispatchRedirect: (path) -> dispatch redirect path
          extraActionItems: @state.actions
          firstName: firstName
          footerCards: @_getFooterCards {articleSelections, messages}
          progress:
            articleSelections: articleSelections
            mentors: @props.classroom.data.mentors
            messages: messages
            students: @props.classroom.data.students
          role: 'teacher'
          teacherCards: cards
          teacherLinks:
            onEditUnitClick: -> dispatch redirect '/units/current/edit'
            onGetTeacherGuideClick: -> window.open currentUnit.teacherGuide
          topContents: topContents
          unitPage: '/units/current'


  _getCardsAndContext: ({articleSelections, allMessages, messages, messagesForClassroom, user}) ->
    {classroom, dispatch, mailCount, session} = @props

    currentUnit = classroom.data.currentUnit
    dates = Object.assign
      endDate: currentUnit.endDate
      startDate: currentUnit.startDate
      currentUnit.unitDates
    setDates = _.pickBy dates, (date) -> date?
    numDates = getUnitDates(currentUnit).length

    mentors = classroom.data.studentMentorGroups
      .filter ({mentor}) -> mentor?
      .map ({mentor}) -> mentor
    students = classroom.data.students.map ({id}) -> id

    mentorMessages = messages.filter ({from: {id}}) -> mentors.includes(id)
    studentMessages = messages.filter ({from: {id}}) -> students.includes(id)
    allStudentMessages = allMessages.filter ({from: {id}}) -> students.includes(id)
    teacherMessagesSent = messages.filter ({from: {id}, type: type}) ->
      id is session.data.login.id and type is 'message'
    teacherMessagesUnread = messages.filter ({state, to, type}) ->
      to?.id is session.data.login.id and type is 'message' and state is 'delivered'
    approvedMentorMessages = mentorMessages.filter ({status}) -> status is 'approved'
    approvedStudentMessages = studentMessages.filter ({status}) -> status is 'approved'

    mentorsWithMessages = new Set (mentorMessages.map ({from: {id}}) -> id)
    mentorsWithApprovedMessages = new Set (approvedMentorMessages.map ({from: {id}}) -> id)
    studentsWithArticleSelections = new Set (articleSelections.filter ({student_id, status}) -> status isnt 'rejected' and students.includes(student_id))
    studentsWithMessages = new Set (studentMessages.map ({from: {id}}) -> id)
    studentsWithAllMessages = new Set (allStudentMessages.map ({from: {id}}) -> id)
    studentsWithApprovedMessages = new Set (approvedStudentMessages.map ({from: {id}}) -> id)

    draftStudentMessages = allStudentMessages.filter ({state}) -> state is 'newMessageDraft'
    rejectedStudentMessages = studentMessages.filter ({status}) -> status is 'rejected'
    pendingStudentMessages = studentMessages.filter ({state,status}) -> status is 'pending' and state is 'delivered'

    units = classroom.data.units

    classroomId = classroom.data._id
    appState = user.data?.appState?[classroomId] ? {}

    classroomIsActive = classroom.data.status is 'active'
    if classroomIsActive
      focusArticleCard =  do ->
        count = studentsWithArticleSelections.size
        total = students.length
        type: 'focus'
        count: count
        heading: t 'teacherHomePage.headings.focusArticle'
        onClick: ->
          dispatch redirect "/units/current/focus_articles"
        total: total

      cards =
        [
          do ->
            count = mentorsWithMessages.size
            total =  mentors.length
            type: 'mentor'
            count: count
            heading: t 'teacherHomePage.headings.mentorLetters'
            onClick: ->
              filter = if count < total then 'missing' else 'all'
              dispatch redirect "/units/current/letters?letters=#{filter}"
            secLink: t 'teacherHomePage.studentLetters.inprocess.link'
            secOnClick: ->
              dispatch redirect "/units/current/letters?letters=draft"
            total: total
        ,
          do ->
            count = studentsWithMessages.size
            total = students.length
            type: 'student'
            count: count
            countList: [
                title: t 'teacherHomePage.studentLetters.missing', count: students.length - studentsWithAllMessages.size
                count: students.length - studentsWithAllMessages.size
                link: t 'teacherHomePage.studentLetters.missing.link'
                filter: 'missing'
              ,
                title: t 'teacherHomePage.studentLetters.inprocess', count: draftStudentMessages.length
                count: draftStudentMessages.length
                link: t 'teacherHomePage.studentLetters.inprocess.link'
                filter: 'draft'
              ,
                title: t 'teacherHomePage.studentLetters.inrevision', count: rejectedStudentMessages.length
                count: rejectedStudentMessages.length
                link: t 'teacherHomePage.studentLetters.inrevision.link'
                filter: 'rejected'
              ,
                title: t 'teacherHomePage.studentLetters.pending', count: pendingStudentMessages.length
                count: pendingStudentMessages.length
                link: t 'teacherHomePage.studentLetters.pending.link'
                filter: 'all' # not pending...
              ,
                title: t 'teacherHomePage.studentLetters.sent', count: approvedStudentMessages.length
                count: approvedStudentMessages.length

            ]
            heading: t 'teacherHomePage.headings.studentLetters'
            onClick: (filter) ->
              dispatch redirect "/units/current/letters?letters=#{filter}"
            secLink:  t 'teacherHomePage.studentLetters.inprocess.link'
            secOnClick: ->
              dispatch redirect "/units/current/letters?letters=draft"
            total: total
        ]

      if not currentUnit.isDefaultUnit and not currentUnit.isReflectionUnit
        cards.unshift focusArticleCard

    else
      cards = []

    cards: cards
    context:
      articleSelectionApproved:
        articleSelections.some ({status}) -> status is 'approved'
      articleSelectionPending:
        articleSelections.some ({status}) -> status is 'pending'
      classroomStatusIsCompleted:
        classroom.data.status is 'completed'
      currentUnit: currentUnit
      currentUnitId:
        currentUnit.id
      currentUnitTeacherGuide:
        currentUnit.teacherGuide
      dateBlank:
        (Object.keys setDates).length < numDates
      introUnit:
        currentUnit.isDefaultUnit
      letterApprovedForAllMentors:
        mentors.length > 0 and mentorsWithApprovedMessages.size is mentors.length
      letterApprovedForAllStudents:
        students.length > 0 and studentsWithApprovedMessages.size is students.length
      letterSent:
        mentorMessages.length + studentMessages.length > 0
      mentorLetterPending:
        mentorMessages.some ({status}) -> status is 'pending'
      mentorLetterSent:
        mentorMessages.length > 0
      reflectionUnit:
        currentUnit.isReflectionUnit
      studentLetterPending:
        studentMessages.some ({status}) -> status is 'pending'
      studentLetterSent:
        studentMessages.length > 0
      studentMatched:
        classroom.data.studentMentorGroups.some ({mentor, student}) -> mentor? and student?
      teacherMessagesUnread:
        mailCount.data > 0
      pendingUnits:
        units.some ({state}) -> state is 'pending'
      teacherGuideRead:
        appState.teacherGuideRead is true
      welcomeVideoViewed:
        appState.welcomeVideoViewed is true
      studentWelcomeVideoViewed:
        appState.studentWelcomeVideoViewed is true
      studentWritingGuideRead:
        appState.studentWritingGuideRead is true
      unitGuidesRead:
        appState.unitGuidesRead ? []
      initialSurveyComplete:
        appState.initialSurveyComplete is true
      initialSurveyId:
        classroom.data.initialSurveyIdTeacher
      finalSurveyComplete:
        appState.finalSurveyComplete is true
      finalSurveyId:
        classroom.data.finalSurveyIdTeacher
      unitVideoViewed:
        appState[currentUnit.id]?.unitVideoViewed is true

  _getFooterCards: ({articleSelections, messages}) ->
    [
      iconClass: 'icon-read-letter'
      label: t 'teacherHomePage.footer.letterCenter'
      link: {label: 'View', to: '/units/current/letters'}
      message: do ->
          t 'teacherHomePage.footer.letterApprove', count:  _getNumPending messages
    ,
      iconClass: 'icon-article'
      label: t 'teacherHomePage.footer.focusArticles'
      link: {label: 'View', to: '/units/current/focus_articles'}
      message: do ->
        t 'teacherHomePage.footer.focusArticlesApprove', count: _getNumPending articleSelections

    ,
      iconClass: 'icon-link'
      label: t 'teacherHomePage.footer.quickLinks'
      quickLinks: [
        label: t 'teacherHomePage.footer.calendar'
        to: '/units/current/edit'
      ,
        label: t 'teacherHomePage.footer.explore'
        to: '/resources'
      ,
        href: 'http://wp.crickettogether.com/teacher-faqs/'
        label: t 'teacherHomePage.footer.faq'
      ,
        href: 'https://cm-cdn.s3.amazonaws.com/' +
          ('crickettogether/units/resources/teacher/CT-TeacherTips.pdf')
        label: t 'teacherHomePage.footer.viewTips'
      ]
    ]


  _pastUnreviewedActionItems: (classroom, messagesForClassroom) ->
    messagesForClassroom = _.values messagesForClassroom.data
    pastUnits = _.orderBy (classroom.data.units.filter ({state}) -> state is 'completed'),
      'startDate', 'desc'
    pastUnitIDs = []
    unreadActionItems = {}
    pastUnits.forEach ({id, name}) ->
      unreadActionItems[id] =
        count: 0
        event: -> events.letterExchange.viewedActionItem {unit: 'past'}
        icon: 'read-letter'
        id: id
        name: name
        redirect: "/units/#{id}/letters"
        text: ''
      pastUnitIDs.push id
    unreadMessages = _.values(messagesForClassroom).filter ({classroomUnitID, from, status, state}) ->
      status is 'pending' and state is 'delivered' and classroomUnitID in pastUnitIDs and
        sentByClassMember(from, classroom.data?.students, classroom.data?.mentors)
    unreadMessages.forEach ({classroomUnitID}) ->
      message = unreadActionItems[classroomUnitID]
      {count, name} = message
      text = t 'teacherHomePage.pastUnits.reviewLetters', name: name, count: count + 1
      unreadActionItems[classroomUnitID] = _.assign message, {count: count + 1, text}
    _.values(unreadActionItems).filter ({count}) -> count > 0

  _rejectedLetterActionItems: (classroom, messagesForClassroom) ->
    messagesForClassroom = (_.values messagesForClassroom.data).filter ({from}) -> sentByClassMember(from, classroom.data?.students, classroom.data?.mentors)
    rejectedMessages = _.orderBy (messagesForClassroom.filter ({status}) -> status is 'rejected'),
      'letterRejected', 'desc'
    studentIDs = classroom.data.students.map ({id}) -> id
    rejectedLetterActionItems = []
    rejectedMessages.forEach ({id, letterRejected, classroomUnitID, from}) ->
      role = if from.id in studentIDs then 'student' else 'eMentor'
      rejectedLetterActionItems.push
        icon: 'write-letter'
        id: id
        redirect: "/units/#{classroomUnitID}/letters?letters=rejected"
        text: t 'teacherHomePage.pastUnits.reviewRevisions', role: role, name: from.displayName, date: moment(letterRejected).fromNow()

    rejectedLetterActionItems



TeacherHome.displayName = "TeacherHome"

module.exports = build {
  component: TeacherHome
  mapStateToProps
}
