_ = require 'lodash'
stateDispatcher = (dispatch) -> (path, payload, opts = {}) ->
  dispatch
    clearState: opts.clearState
    path: path
    payload: payload
    type: 'store'


# precomposes a function with stateDispatcher
exports.makeAction = (action) -> (dispatch, getState) ->
  setState = stateDispatcher dispatch
  action setState, dispatch, -> getState().store
