_ = require 'lodash'
moment = require 'moment'

checkDraftMessageForPrevUnit =  (store) ->
  return false unless store

  currentUnitID = store.classroom?.data?.currentUnit?._id
  draftMessageUnitID = store.letterComposer?.newDraftMessage?.classroomUnitID

  return false unless currentUnitID
  return false unless draftMessageUnitID

  #prevUnitIndex = store.classroom?.data?.units.map ({id}) -> id
    #.indexOf(currentUnitID) - 1
  #prevUnitID = store.classroom?.data?.units[prevUnitIndex]?.id

  return currentUnitID isnt draftMessageUnitID #prevUnitID is draftMessageUnitID


mapRejectedMessages = (store) ->
  messages = store.messages?.data
  userId = store.session?.data?.login?.id

  _.values(messages)
    .filter ({from, status}) ->
      return from?._id is userId and status is 'rejected'
    .sort (left,right) ->
      moment.utc(left.letterRejected).diff(moment.utc(right.letterRejected))
    .map formatLetter('sent')

getRevisionDraftsByThread = (store) ->
  messages = store.messages?.data
  userId = store.session?.data?.login?.id

  drafts = _.values(messages)
    .filter ({from, state}) ->
      return from?._id is userId and state is 'draft'

  _.keyBy drafts, 'thread'

formatLetter = (type) -> (msg) ->
  if msg?
    {classroomUnitID, created, from, id, letterApproved, letterRejected, letterSent,
    modified, questions, questionsLocked, rejectMessage, state, status, subject, text,
    thread, to} = msg
    date = if type is 'inbox' then letterApproved else letterSent #modified ? created
    formattedLetter =
      _id: id
      classroomUnitID: classroomUnitID
      date: moment(date).format('MMM D, YYYY')
      from: from#?.profile?.firstname or from?.firstname or from
      id: id
      letterApproved: letterApproved
      letterRejected: letterRejected
      letterSent: letterSent
      questions: questions
      questionsLocked: questionsLocked
      state: state
      status: status
      subject: subject
      text: text
      thread: thread
      to: to#?.profile?.firstname or to?.firstname or to
      created: created
      modified: date # this field is used to display dates in a LetterCollection...
    if type is 'sent' or type is 'rejected'
      formattedLetter.rejectMessage = rejectMessage ? 'Reject message was not provided.'
    if type is 'draft' or type is 'rejected'
      formattedLetter.to = formattedLetter.to?.profile?.firstname or formattedLetter.to?.firstname
      formattedLetter.from = formattedLetter.from?.profile?.firstname or formattedLetter.from?.firstname
    formattedLetter


mapInboxMessages = (store) ->
  messages = store.messages?.data
  userId = store.session?.data?.login?.id

  inboxMessages = _.values(messages)
    .filter ({status, to}) ->
      return to?._id is userId and status is 'approved'
    .sort (left, right) ->
      moment.utc(right.letterApproved).diff(moment.utc(left.letterApproved))
    .map formatLetter('inbox')

mapSentMessages = (store) ->
  messages = store.messages?.data
  userId = store.session?.data?.login?.id

  _.values(messages)
    .filter ({from, state}) ->
      return from?._id is userId and state not in ['draft', 'newMessageDraft']
    .sort (left, right) ->
      moment.utc(right.letterSent).diff(moment.utc(left.letterSent))
    .map formatLetter('sent')

combineInboxAndSentMessages = (inbox, sent) ->
  inbox.concat(sent).filter ({status}) -> status isnt 'rejected'
    .sort (left, right) ->
      moment.utc(left.letterApproved or left.letterSent)
        .diff(moment.utc(right.letterApproved or right.letterSent))


module.exports =
  checkDraftMessageForPrevUnit: checkDraftMessageForPrevUnit
  mapRejectedMessages: mapRejectedMessages
  formatLetter: formatLetter
  mapInboxMessages: mapInboxMessages
  mapSentMessages: mapSentMessages
  combineInboxAndSentMessages: combineInboxAndSentMessages
  getRevisionDraftsByThread: getRevisionDraftsByThread
