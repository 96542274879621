{Component, createFactory} = require 'react'
{build} = require 'utils/container_helpers'
{redirect} = require 'actions/routing'
{filter, findIndex, includes, map, orderBy, sortBy} = require 'lodash'
{
  getArticleSelection
  getScrollOffset
  selectable
  showDetail
} = require 'utils/article_normalizer'
events = require './events'
articleSelectionActions = require 'actions/article_selections'
style = require './units.styl'
e = require('react-e/bind') style
moment = require 'moment'
Button = createFactory require 'components/shared/button'
ArticleCollection = createFactory require 'components/shared/article_collection'
SelectionModal = createFactory require 'components/shared/selection_modal'
Button = createFactory require 'components/shared/button'
ContentLoader = createFactory require 'components/shared/content_loader'
CurrentUnitCard = createFactory require 'components/shared/current_unit_card'
ModalWrapper = createFactory require 'components/shared/modal/modal_wrapper'
PastUnits = createFactory require 'components/shared/past_units'

UserHelpers = require('utils/user_helpers').default;

mapStateToProps = ({store}) ->
  classroom = store.classroom.data
  classroomIsActive = classroom.status is 'active'
  units = pastUnits = classroom.units ? []
  pastUnits = _.filter(units, ({state}) -> state is 'completed') if classroomIsActive
  pastUnits = _.orderBy(pastUnits, (({startDate}) -> moment startDate), 'desc')
  articleSelections: store.articleSelections
  classroom: classroom
  classroomIsActive: classroomIsActive
  classrooms: store.classrooms
  id: store.session.data.login.id
  mySelections: _.values store.articleSelections.data
  pastUnits: pastUnits
  userState: UserHelpers.getTransformedUser store.session?.data?.login, null, classroom, store.tenant.data


class MyUnits extends Component

  constructor: ->
    super()
    @state = selectedArticle: undefined


  componentDidMount: ->
    @props.dispatch articleSelectionActions.fetch
      student_id: @props.id


  _submitArticleSelection: =>
    events.submit @state.selectedArticle.title
    currentUnitId = @props.classroom?.currentUnit._id
    selectionExists = @props.mySelections.filter (selection) =>
      selection.classroomUnit_id is currentUnitId and selection.student_id is @props.id
    if selectionExists.length > 0
      @props.dispatch articleSelectionActions.update
        _id: selectionExists[0].id
        article_id: @state.selectedArticle.id
        rejectReason: null
        status: 'pending'
    else
      @props.dispatch articleSelectionActions.create
        article_id: @state.selectedArticle.id
        classroomUnit_id: @props.classroom?.currentUnit._id
        student_id: @props.id

  onSelectArticle: (article) =>
    @setState selectedArticle: article

  render: ->
    ContentLoader
      content: @props.classrooms
      name: 'units'
      renderChild: =>
        ContentLoader
          content: @props.articleSelections
          contentIsReadyOverride: =>
            not(@props.articleSelections.loading or @props.articleSelections.error)
          name: 'selected articles'
          renderChild: =>
            unit = @props.classroom?.currentUnit
            articleSelection = getArticleSelection @props.articleSelections.data,unit._id
            e '.units',

              # do not render CurrentUnitCard until further notice...
              #if @props.classroomIsActive
                #CurrentUnitCard {role: 'student', unit}

              if @props.classroomIsActive
                ArticleCollection
                  arrow: yes
                  articleSelection: articleSelection
                  articles: unit.content
                  isDefaultUnit: unit.isDefaultUnit
                  onClick: (articleId, articleTitle) =>
                    events.details articleTitle
                    showDetail {
                      articleId
                      redirectTo: (path) => @props.dispatch redirect path
                      unitTemplateId: @props.classroom?.currentUnit.parent
                    }
                  readEvent: (articleTitle) -> events.read articleTitle
                  role: 'student'
                  palName: @props.userState.pal?.firstname or t 'articleCollection.defaultPalName', prefix: 'your '
                  scrollPosition: getScrollOffset unit, articleSelection
                  selectType: if not unit.isDefaultUnit and selectable articleSelection then 'radio' else undefined
                  selectedArticleId: @state.selectedArticle?.id
                  onSelect: (article) => @onSelectArticle(article)

              if selectable(articleSelection) and @props.classroomIsActive and not unit.isDefaultUnit
                e '.selection-button',
                  SelectionModal
                    closeButtons: [
                      element:
                        Button size: 'large', type: 'regular', t 'studentUnits.confirm'
                      onClick: @_submitArticleSelection
                    ]
                    content: [
                      t 'studentUnits.pending'
                    ,
                      t 'studentUnits.stayTuned'
                    ]
                    enabled: @state.selectedArticle?
                    onModalCrossButtonClick: @_submitArticleSelection
                    title: t 'studentUnits.modalTitle'
                    triggerButtonTitle: t 'studentUnits.submit'

              if @props.pastUnits.length
                PastUnits
                  role: 'student'
                  units: @props.pastUnits

MyUnits.displayName = "MyUnits"

module.exports = build {
  component: MyUnits,
  mapStateToProps
}
