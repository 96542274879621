import React from 'react';
import PropTypes from 'prop-types';
import UserHelpers from "utils/user_helpers";
const style = require('./onboarding.styl');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
const usersService = require('services/users_service');
import {redirect} from 'actions/routing';
import Button from "components/shared/button";

const mapStateToProps = ({store: {user, sessions, classrooms, tenant}}) => ({
  user: UserHelpers.getTransformedUser(user.data, sessions.data, classrooms.data, tenant.data),
  tenant: tenant.data,
});


class MentorOnboardingVerify extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      message: '',
      selected: props.selected || 'profile',
      loadingData: false,
    };
  }

  componentDidMount() {
    const {user, dispatch} = this.props;
    if (user.emailVerified) {
      //dispatch(redirect('/'));
    }
  }

  onClickSendEmail() {
    usersService.sendVerifyEmail(this.props.user.username)
        .then((resp) => {
          this.setState({
            success: true,
            error: false,
            message: "Verification email sent.  Please check your mail and click the link in the message."
          });
        })
        .catch((error) => {
          let message = null;
          if (error instanceof Error) {
            message = error.message;
          } else {
            message = error.reason;
          }
          this.setState({error: true, success: false, message});
        });
  }

  render() {
    const { user } = this.props;
    const {error, success, message} = this.state;
    return (
        <div className={cn('onboarding-page', 'onboarding-verify')}>
          <h2>{t('onboarding.verify.heading')}</h2>
          <div className={cn('container')}>
            {success &&
            <div>{t('onboarding.verify.success', {email: user.email})}</div>
            }
            {!success &&
            <React.Fragment>
              <div className={cn('error', {visible: error})}>{message}</div>
              <div className={cn('success', {visible: success})}>{message}</div>
              {t('onboarding.verify.instructions', {email: user.email})}
              <div className={cn('buttons')}>
                <Button onClick={() => this.onClickSendEmail()} type='regular'>
                  {t('onboarding.verify.link')}
                </Button>
              </div>
            </React.Fragment>
            }
          </div>
        </div>
    );
  }
}

MentorOnboardingVerify.displayName = "MentorOnboardingVerify";

MentorOnboardingVerify.propTypes = {
  user: PropTypes.object,
};


module.exports = build({
  component: MentorOnboardingVerify,
  mapStateToProps
});
