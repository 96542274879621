// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f09b1456f2e81a2bde173f7aedf57a5e-styl {\n  padding-top: 2rem;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://cricket-together/app/components/shared/loading_spinner/loading_spinner.styl"],"names":[],"mappings":"AAEA;EACE,iBAAY;EACZ,kBAAW;AADb","sourcesContent":["@import 'variables'\n\n.loading_spinner\n  padding-top 2rem\n  text-align center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading_spinner": "f09b1456f2e81a2bde173f7aedf57a5e-styl"
};
module.exports = ___CSS_LOADER_EXPORT___;
