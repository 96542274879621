// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._09ff00f11d54b42bc5b1d2e7b5a9c57b-styl {\n  bottom: 1.1875rem;\n  color: var(--theme-blue-light, #00aff2);\n  cursor: pointer;\n  font-size: 0.875rem;\n  left: 1.1875rem;\n  position: absolute;\n}\n", "",{"version":3,"sources":["webpack://cricket-together/app/components/unit_template_preview/unit_template_preview.styl"],"names":[],"mappings":"AAEA;EACE,iBAAO;EACP,uCAA8C;EAC9C,eAAO;EACP,mBAAU;EACV,eAAK;EACL,kBAAS;AADX","sourcesContent":["@import 'variables'\n\n.details-link\n  bottom 1.1875rem\n  color var(--theme-blue-light,$theme-blue-light)\n  cursor pointer\n  font-size .875rem\n  left 1.1875rem\n  position absolute\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details-link": "_09ff00f11d54b42bc5b1d2e7b5a9c57b-styl"
};
module.exports = ___CSS_LOADER_EXPORT___;
