style = require './upload.styl'
e = require('react-e/bind') style
({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
Card = createFactory require 'components/shared/card'
Button = createFactory require 'components/shared/button'
events = require './events'
Modal = createFactory require 'components/shared/modal'
FileUploader = createFactory require 'components/shared/file_uploader'
PapaParse = require 'papaparse'

class Upload extends Component

  @defaultProps:
    modal: no


  @propTypes:
    classroomEmpty: PropTypes.bool
    classroomName: PropTypes.string
    errorHeader: PropTypes.string
    modal: PropTypes.bool
    onClose: PropTypes.func
    onDrop: PropTypes.func
    uploadErrors: PropTypes.object
    uploadStudents: PropTypes.func
    validateStudents: PropTypes.func
    validationStatus: PropTypes.string


  _getErrorMessage: =>
    if @props.uploadMetrics?.failed > 0
      t 'classroomPage.errors', count: @props.uploadMetrics.failed


  _getSuccessMessage: =>
    if @props.uploadMetrics?.succeeded
      t 'classroomPage.success', count: @props.uploadMetrics.succeeded


  _getUploadErrors: =>
    (_.values @props.uploadErrors).map (errorObj) ->
      {index} = errorObj
      errors = _.values(errorObj).filter (entry) -> typeof entry is 'string'
      label: "Error for entry number #{index}: "
      value: errors


  _parseStudents: (acceptedFiles, rejectedFiles) =>
    #windows chrome bug -- files show up in second parameter
    files = if acceptedFiles.length > 0 then acceptedFiles else rejectedFiles
    parsedStudents = []
    Promise.all files.map (file) =>
      PapaParse.parse file, complete: ({data, meta}) =>
        [headers, students...] = data
        parseableStudents = students.filter (studentFields) ->
          not (studentFields.length is 1 and studentFields[0] is '')
        parsedStudents = parseableStudents.map (studentFields, index) ->
          fields = _.fromPairs _.zip headers, studentFields
          Object.assign {}, index: index + 1, fields
        @props.validateStudents parsedStudents, ->
          events.uploadedTemplate()


  _sendDownloadEvent: ->
    events.downloadedTemplate()


  render: ->
    if @props.modal
      Modal onClose: @props.onClose,
        Card {},
          @renderUploadContents()
    else
      @renderUploadContents()


  renderUploadContents: =>
    e '.upload-card',
      if @props.modal
        e '.upload-header', t 'classroomPage.uploadModal.modalHeader'
      e '.upload-body',
        e '.step',
          e '.step-number', '1'
          e 'span.step-message',
            e 'span', t 'classroomPage.uploadModal.downloadRoster'
            e 'a.link', href: '/download/ct-student-roster.csv', onClick: @_sendDownloadEvent,
              e 'span', t 'classroomPage.uploadModal.template'
            e 'span', ':'
        e '.step',
            e '.step-number', '2'
            e 'span.step-message', t 'classroomPage.uploadModal.uploadRoster'
        FileUploader
          errorHeader: @_getErrorMessage()
          onDrop: @_parseStudents
          successMessage: @_getSuccessMessage()
          uploadErrors: @_getUploadErrors()
          validationStatus: @props.validationStatus
        e '.buttons',
          e '.cancel',
            Button
              className: 'cancel'
              onClick: @props.cancelAddStudents
              type: 'regular'
              t 'fileUploader.cancel'
          e '.add',
            Button
              enabled: _.some _.values @props.pendingStudents
              onClick: @props.addStudents
              type: 'regular'
              t 'fileUploader.addStudents'

Upload.displayName = "Upload"

module.exports = Upload
