({Component} = require 'react') and (PropTypes = require 'prop-types')
style = require './flag.styl'
e = require('react-e/bind') style
moment = require('moment')

class Flag extends Component

  @propTypes:
    endDate: PropTypes.string


  render: ->
    {endDate} = @props

    e '.flag-container',
      e '.flag',
        e 'h3.title', 'Current unit'
        if endDate?
          e 'h4.body',
            e 'span.subject',
              if moment().isAfter endDate
                'Ended : '
              else
                'Ends : '
            e 'span.value', moment(endDate).fromNow()
      e '.tail'

Flag.displayName = "Flag"

module.exports = Flag
