const style = require('./reset_password.styl');
const cn = require('classnames/bind').bind(style);
const {Component} = require('react');
const {dispatch} = require('store');
//const {build} = require('utils/container_helpers');
const usersService = require('services/users_service');
const {redirect} = require('actions/routing');

const Card = require('components/shared/card');
const Button = require('components/shared/button');


class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            password2: "",
            userId: props.location.query.u ? props.location.query.u : "",
            token: props.location.query.t ? props.location.query.t : "",
            next: props.location.query.next ? props.location.query.next : "/?show_login=true",
            success: false,
            error: false,
            errorMsg: ""
        };

    }

    navigateToLoginModal() {
        dispatch(redirect(this.state.next));
    }

    updatePassword(ev) {
        this.setState({password: ev.target.value, error: false});
    }

    updatePassword2(ev) {
        this.setState({password2: ev.target.value, error: false});
    }

    submitPassword(ev) {
        ev.preventDefault();
        if (this.state.password === this.state.password2) {
            if (ev.target.form.reportValidity()) {
                const {userId, token} = this.state;
                usersService.changePasswordWithToken(userId, token, this.state.password)
                .then((resp) => {
                    this.setState({success: true, error: false});
                })
                .catch((error) => {
                    this.setState({error: true});
                });
            }
        }
        else {
            this.setState({error: true, errorMsg:"Password fields do not match"});
        }
    }

    render() {
        return (
            <Card>
                <div className={cn('container')}>
                    {!this.state.success &&
                        <form className={cn('form')}>
                            <div className={cn('instructions')}>{t('resetPassword.instructions')}</div>
                            <label htmlFor="password" className={cn('label')}>{t('resetPassword.passwordLabel')}</label>
                            <input name="password" className={cn('input')} type="password" autoFocus="true" onChange={(ev) => this.updatePassword(ev)} minLength="1" value={this.state.password}/><br/>
                            <label htmlFor="password2" className={cn('label')} style={{marginLeft:'4rem'}}>{t('resetPassword.passwordLabel2')}</label>
                            <input name="password2" className={cn('input')} type="password" onChange={(ev) => this.updatePassword2(ev)} minLength="1" value={this.state.password2}/>
                            <Button enabled={this.state.password.length > 0 && this.state.password2.length > 0} onClick={(ev)=> this.submitPassword(ev)} submit={true} type="regular">Submit</Button>
                            {this.state.error &&
                                <div className={cn('error')}>{this.state.errorMsg || t('resetPassword.error')}</div>
                            }
                        </form>

                    }
                    {this.state.success &&
                        <div className={cn('success')}>
                            <div className={cn('instructions')}>{t('resetPassword.success')}</div>
                            <Button onClick={(ev)=> this.navigateToLoginModal()} submit={true} type="regular">{t('resetPassword.signInBtn')}</Button>
                        </div>
                    }
                </div>
            </Card>
        );
    }
}

module.exports = ResetPassword;
