({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './impersonate.styl'
e = (require 'react-e/bind') style
{build} = require 'utils/container_helpers'
{redirect} = require 'actions/routing'
{session} = require 'actions'
Login = createFactory require 'components/shared/login'
Card = createFactory require 'components/shared/card'

class Impersonate extends Component


  componentDidMount: ->
    @props.dispatch session.logout()

  render: ->
    impersonateUsername = @props.params.username
    e '.login',
      if not @props.session.data?
        Card {},
          Login
            error: @props.session.error
            impersonate: true
            impersonateUsername: @props.params.username
            loading: @props.session.loading
            onSubmit: ({password, username}, impersonateUsername) =>
              @props.dispatch session.impersonate {password, username}, impersonateUsername, =>
                @props.dispatch redirect '/'


Impersonate.displayName = "Impersonate"

module.exports = build {
  component: Impersonate
  mapStateToProps: ({store}) -> session: store.session
}
