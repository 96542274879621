require 'global_styles/index.styl'
require 'react-router'
require 'react-redux'
#require 'react-dates/initialize'

{hot} = require 'react-hot-loader/root'
React = require 'react'
{createFactory} = require 'react'
ReactDom = require 'react-dom'

{Provider} = require 'react-redux'
{Router, browserHistory, hashHistory} = require 'react-router'

Provider = createFactory Provider
Router = createFactory Router
#browserHistory = createFactory browserHistory
#hashHistory = createFactory hashHistory

{R, child, index} = require 'react-router-r'
{branches: {adminBranch, mentorBranch, studentBranch, teacherBranch, customerBranch, unauthenticatedBranch}, generateRoutes, ifNotProduction} = require 'routing/'

messagesActions = require 'actions/messages'
sessionActions = require 'actions/session'
{redirect} = require 'actions/routing'

idleTimer = require 'idle-timer'

# Assign React to Window so Chrome React Dev Tools will work.
window.React = React

analytics = require 'utils/analytics'

# HOCs
{withProps} = require 'recompose'

# containers
AppContainer = require 'containers/app_container'
Landing = require 'pages/landing'

{ UserContainer } = require 'containers/user_container'
{ withClassroom } = require 'containers/classroom_container'

# shared pages
LetterComposer = require 'pages/shared/letter_composer'
LetterCenter = require 'pages/shared/letter_center'
Resources = require 'pages/shared/resources'
Survey = require 'pages/shared/survey'

# mentor pages
MentorHome = require 'pages/mentor/home'
MentorClassroom = require 'pages/mentor/classroom'
MentorUnits = require 'pages/mentor/units'
MentorOnboarding = require 'pages/mentor/onboarding'
MentorOnboardingVerify = require 'pages/mentor/onboarding_verify'
MentorOnboardingBackgroundCheck = require 'pages/mentor/onboarding_background'
MentorOnboardingTraining = require 'pages/mentor/onboarding_training'
MentorOnboardingLetter = require 'pages/mentor/onboarding_letter'

# student pages
StudentHome = require 'pages/student/home'
StudentUnits = require 'pages/student/units'

# teacher pages
TeacherHome = require 'pages/teacher/home'
Classroom = require 'pages/teacher/classroom'
ClassroomAddStudents = require 'pages/teacher/classroom_add_students'
ExploreUnits = require 'pages/teacher/explore_units'
UnitPreview = require 'pages/teacher/unit_preview'
ClassroomUnit = require 'pages/teacher/classroom_unit'
TeacherUnits = require 'pages/teacher/units'
TeacherApplication = require 'pages/teacher/application'

# customer pages
SearchEbooks = require 'pages/customer/search_ebooks'
BrowseEbooks = require 'pages/customer/browse_ebooks'

# library pages
ArticleDetail = require 'pages/library/article_detail'
Library = require 'pages/library/library'

# mail pages
Mail = require 'pages/shared/mail'

Impersonate = require 'pages/impersonate'
NotAuthorized = require 'pages/not_authorized'
NotFound = require 'pages/not_found'

ForgotUsername = require 'pages/shared/forgot_username'
ForgotPassword = require 'pages/shared/forgot_password'
ResetPassword = require 'pages/shared/reset_password'

Signup = require 'pages/shared/signup'
Register = require 'pages/shared/register'
RegisterTeacher = require 'pages/teacher/register'

AccountVerify = require 'pages/shared/account_verify'

Forum = require 'pages/shared/forum'

{ setConfig } = require 'react-hot-loader';
setConfig logLevel: 'debug'

# {StoryBook} = require 'storybook' # TODO: make storybook IE/safari compatible

store = require 'store'

store.dispatch sessionActions.fetchLocalToken()


history =
  if 'pushState' of window.history then browserHistory
  else hashHistory

routes = R '/', AppContainer,
  generateRoutes [

    mentorBranch R '', UserContainer,
      index MentorHome
      child R 'classroom', withClassroom MentorClassroom
      child R 'letters', withClassroom LetterCenter, mode: 'mentor'
      child R 'letters/compose', withClassroom LetterComposer
      child R 'library', Library
      child R 'library/:template_id/articles/:article_id', ArticleDetail
      child R 'resources', withClassroom Resources
      child R 'mail', withClassroom Mail
      child R 'units', withClassroom MentorUnits
      child R 'survey/:survey_id/:classroom_id/:user_id', withClassroom Survey
      child R 'signup/:session_id', Signup
      child R 'account/verify', AccountVerify
      child R 'onboarding', MentorOnboarding
      child R 'onboarding/verify', MentorOnboardingVerify
      child R 'onboarding/background', MentorOnboardingBackgroundCheck
      child R 'onboarding/training', withProps(back: '/onboarding') MentorOnboardingTraining
      child R 'onboarding/letter', MentorOnboardingLetter
      child R 'register', Register
      child R 'register/child', withProps(role:'student') Register
      child R FORUM_PATH_PREFIX + '(/**)', withClassroom Forum

    studentBranch R '', UserContainer,
      index withClassroom StudentHome
      child R 'units', withClassroom StudentUnits
      child R 'letters', withClassroom withProps(mode: 'student') LetterCenter
      child R 'letters/compose', withClassroom LetterComposer
      child R 'library', withClassroom Library
      child R 'library/:template_id/articles/:article_id', withClassroom ArticleDetail
      child R 'resources', withClassroom Resources
      child R 'survey/:survey_id/:classroom_id/:user_id', withClassroom Survey
      child R 'training', withProps(back: '/') withClassroom MentorOnboardingTraining
      child R 'register', Register
      child R 'register/child', withProps(role:'student') Register

    teacherBranch R '', UserContainer,
      index withClassroom TeacherHome
      child R 'classroom', withClassroom Classroom
      child R 'classroom/students/add', withClassroom ClassroomAddStudents
      child R 'units', withClassroom TeacherUnits
      child R 'units/explore', withClassroom ExploreUnits
      child R 'units/explore/:template_id', withProps(mode: 'view') withClassroom UnitPreview
      child R 'units/explore/:template_id/new', withProps(mode: 'new') withClassroom UnitPreview
      child R 'units/explore/:template_id/new/sample', withProps(mode: 'new', sample:true) withClassroom UnitPreview
      child R 'units/current', withClassroom ClassroomUnit
      child R 'units/current/focus_articles', withProps(tab: 'focus_articles') withClassroom ClassroomUnit
      child R 'units/current/letters', withProps(tab: 'letters') withClassroom ClassroomUnit
      child R 'units/current/edit', withProps(mode: 'edit') withClassroom UnitPreview
      child R 'units/:classroom_unit_id', withClassroom ClassroomUnit
      child R 'units/:classroom_unit_id/focus_articles', withProps(tab: 'focus_articles') withClassroom ClassroomUnit
      child R 'units/:classroom_unit_id/letters', withProps(tab: 'letters') withClassroom ClassroomUnit
      child R 'units/:classroom_unit_id/edit', withProps(mode: 'edit') withClassroom UnitPreview
      child R 'library', Library
      child R 'library/:template_id/articles/:article_id', ArticleDetail
      child R 'resources', withClassroom Resources
      child R 'mail', Mail
      child R 'survey/:survey_id/:classroom_id/:user_id', Survey
      child R 'register', Register
      child R 'register/child', withProps(role:'student') Register
      child R FORUM_PATH_PREFIX + '(/**)', withClassroom Forum

    unauthenticatedBranch R '', null,
      index Landing
      child R 'welcome', Landing
      child R 'letters*', NotAuthorized
      child R 'units*', NotAuthorized
      child R 'library*', NotAuthorized
      child R 'resources*', NotAuthorized
      child R 'mail*', NotAuthorized
      child R 'forgot/username', ForgotUsername
      child R 'forgot/password', ForgotPassword
      child R 'forgot/password/reset', ResetPassword
      child R 'reset/password', ForgotPassword
      child R 'reset/password/reset', ResetPassword
      child R 'signup/:session_id', Signup
      child R 'apply', TeacherApplication
      child R 'apply/:sample_id', TeacherApplication
      child R 'register', Register
      child R 'register/child', withProps(role:'student') Register
      child R 'register/teacher/:application_id', RegisterTeacher
      child R 'account/verify', AccountVerify

    customerBranch R '', UserContainer,
      index BrowseEbooks
      child R 'ebooks/browse', BrowseEbooks
      child R 'ebooks/search', SearchEbooks
      child R 'account/verify', AccountVerify
      child R 'account/notverified', MentorOnboardingVerify

  ]
  child R 'impersonate(/:username)', Impersonate
  # ifNotProduction child R 'storybook', StoryBook
  child R '*', NotFound


class Main extends React.Component

  shouldComponentUpdate: ->
    false

  render: ->
    Provider {store},
      Router
        history: history
        onUpdate: ->
          analytics.recordPageView()
          window.scrollTo 0, 0
          session = store.getState().store.session
          if session.data?.login.id
            store.dispatch messagesActions.fetchUnreadMailCount session.data?.login.id, session.data?.classroomId
        routes: routes

Main.displayName = "Main"

# Auto-logout.  The user and loginToken information is kept in browser local storage which does
# not include an expiration (by browser design).  However, the loginToken does include a 'when'
# field with the creation time of the token.  We set an interval timer to periodically check the
# login token, and if it has expired AND the user has been idle for long enough, force a logout.
# This should hopefully help minimize the number of weird error reports caused by keeping windows
# on the application across app deploys and other glitches.

pairs = document.cookie.split(';')
cookies = {}
i = 0
while i < pairs.length
  pair = pairs[i].split('=')
  cookies[(pair[0] + '').trim()] = parseInt(unescape(pair[1]))
  i++

idleTimeMs = cookies.idleTimeMs || 1000 * 60 * 30               # user is "idle" if no activity in 30 minutes
tokenExpiresMs = cookies.tokenExpiresMs || 1000 * 60 * 60 * 6   # force token expiration at 6 hours
tokenCheckMs = 1000 * 10                                        # check token this often
isIdle = false                                                  # global flag to track user's idle state
testAutoLogout = cookies.idleTimeMs || cookies.tokenExpiresMs
console.log {idleTimeMs, tokenExpiresMs} if testAutoLogout

timer = idleTimer
  callback: ->
    isIdle = true
  activeCallback: ->
    isIdle = false
  idleTime: idleTimeMs

setInterval (->
  checkAutoLogout false
), tokenCheckMs

checkAutoLogout = (isInitialPageLoad) ->
  # console.log "CHECKING AUTO-LOGOUT", {isInitialPageLoad}, localStorage.cricketTogetherSession
  if localStorage.cricketTogetherSession
    sessionData = JSON.parse(localStorage.cricketTogetherSession)
    loginTokenIssued = sessionData.login.loginToken.when
    if loginTokenIssued
      timeIssued = new Date(loginTokenIssued)
      elapsed = Date.now() - timeIssued
      console.log {elapsed, tokenExpiresMs} if testAutoLogout
      if elapsed > tokenExpiresMs and (isIdle || isInitialPageLoad)
        store.dispatch sessionActions.logout =>
          console.log "Auto-logout" if testAutoLogout
          store.dispatch redirect '/', 0, =>
            console.log "Force-reload" if testAutoLogout
            location.reload(true)

checkAutoLogout true

Main = hot(Main)

ReactDom.render <Main/>, document.getElementById 'app'
