import React from 'react';
import PropTypes from 'prop-types';
const {build} = require('utils/container_helpers');
const style = require('./classroom_view_mentor.styl');
const cn = require('classnames/bind').bind(style);
const events = require('./events.coffee');
const _ = require('lodash');
import usersService from 'services/users_service';

import Card from 'components/shared/card';
import Button from 'components/shared/button';
import LoadingSpinner from 'components/shared/loading_spinner';

import getAppName from 'utils/app_name'

const mapStateToProps = ({store: {classroom, tenant}}) => ({
  classroom: classroom.data,
  tenant: tenant.data,
});

class ClassroomViewMentor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mentor: null,
    };
  }

  componentDidMount() {
    const {userId} = this.props;
    usersService.find(userId)
        .then(({body}) => {
          if (body.cm_services && body.cm_services.length > 0) {
            const service = _.find(body.cm_services, ['service', TENANT]);
            const mentor = _.omit(Object.assign({}, body, service), 'cm_services', 'services');
            this.setState({mentor});
          }
        })
        .catch((error) => {
          console.log({error});
        });
  }

  formatValue(val) {
    return val || '—';
  }

  onClickRequestReplacement() {
    return events.requestedReplacement();
  }

  render() {
    const {classroom, tenant} = this.props;

    if (this.state.mentor === null) {
      return <LoadingSpinner/>;
    }

    const {aboutme, displayName, gender, occupation} = this.state.mentor;
    const classroomIsActive = classroom.status === 'active';
    const contactEmail = tenant.contactEmail || 'support@crickettogether.com';
    const appName = tenant.name || 'CricketTogether';
    const MAILTO = `mailto:${contactEmail}?subject=${t('classroomViewMentor.emailSubject',{appName: getAppName(tenant,classroom)})}`;

    return (
        <Card>
          <div className={cn('body')}>
            <div className={cn('row')}>
              <div className={cn('field')}>
                <div className={cn('label')}>{t('classroomViewMentor.name')}</div>
                <div className={cn('value')}>{this.formatValue(displayName ? `${displayName}.` : undefined)}</div>
              </div>
              <div className={cn('field')}>
                <div className={cn('label')}>{t('classroomViewMentor.gender')}</div>
                <div className={cn('value')}>{this.formatValue(gender)}</div>
              </div>
              <div className={cn('field')}>
                <div className={cn('label')}>{t('classroomViewMentor.occupation')}</div>
                <div className={cn('value')}>{this.formatValue(occupation)}</div>
              </div>
            </div>
            <div className={cn('row')}>
              <div className={cn('field')}>
                <div className={cn('label')}>{t('classroomViewMentor.aboutMe')}</div>
                <div className={cn('value')}>{this.formatValue(aboutme)}</div>
              </div>
            </div>
            {classroomIsActive &&
            <div className={cn('button-row')}>
              <Button size='large' type='regular' href={MAILTO}
                      onClick={() => this.onClickRequestReplacement()}>
                {t('classroomViewMentor.replaceBtn')}
              </Button>
            </div>
            }
          </div>
        </Card>
    );
  }
}


ClassroomViewMentor.propTypes = {
  classroomName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired
};

ClassroomViewMentor.displayName = "ClassroomViewMentor";

module.exports = build({
  component: ClassroomViewMentor,
  mapStateToProps,
});
