import React from "react";
const _ = require('lodash');
const style = require('./onboarding.styl');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
const usersActions = require('actions/users');
const exploreUnitsActions = require('actions/explore_units');
const usersService = require('services/users_service');
import memoize from 'memoize-one';
import UserHelpers from 'utils/user_helpers';
const {redirect} = require('actions/routing');
const events = require('pages/shared/letter_center/events'); // TODO: should this be customized for onboarding
import Button from 'components/shared/button';
import LoadingSpinner from 'components/shared/loading_spinner';
import LetterComposerTips from 'components/letter_composer_tips';
import LetterComposerTabs from 'components/letter_composer_tabs';

const getIntroUnit = memoize((units) => {
    const introUnit = Object.values(units).find(unit => unit.isDefaultUnit );
    if (!introUnit) {
      throw new Error('onboarding_letter: unable to find intro unit');
    }
    return introUnit;
  }
);

const mapStateToProps = ({store}) => {
  const {unitTemplates, user, sessions, classrooms, tenant} = store;

  let introUnit;
  if (unitTemplates.data) {
    introUnit = getIntroUnit(unitTemplates.data);
  }

  return {
    user: UserHelpers.getTransformedUser(user.data, sessions.data, classrooms.data, tenant.data),
    introUnit,
  };
};


class MentorOnboardingLetter extends React.Component {

  constructor(props) {
    super(props);

    const serviceRecord = props.user.cm_services.find((x) => x.service === TENANT);
    this.state = {
      introLetter: serviceRecord.introLetter || false, // false is a sentinel value that indicates default not yet loaded
    };

    this.updateThrottled = _.throttle(args => {
          this.saveIntroLetter(args);
        }
        , 3000, {leading: true});
  }

  componentDidMount() {
    const {user, dispatch} = this.props;
    dispatch(exploreUnitsActions.fetch((units) => {
      const introUnit = getIntroUnit(units);
    }));

    if (!this.state.introLetter) {
      // Set default value for intro letter
      if (user.isReturningMentor) {
        usersService.fetchDefaultIntroLetter(user.id).then((results) => {
          this.setState({
            'introLetter': {
              status: 'draft',
              rejectMessage: undefined,
              answers: [],
              locked: !!results.data.defaultIntroText,
              text: results.data.defaultIntroText || ''
            }
          });
        })
      } else {
        this.setState({
          'introLetter': {
            status: 'draft',
            rejectMessage: undefined,
            answers: [],
            locked: false,
            text: ''
          }
        });
      }
    }
  }

  saveIntroLetter(letter) {
    const {user, dispatch} = this.props;
    const newUserData = {
      id: user.id,
      service: TENANT,
      introLetter: letter,
    };
    dispatch(usersActions.update(newUserData, (error) => {
      if (error) {
        console.log({error});
      }
    }));
  }

  onChangeLetter(letter) {
    this.updateThrottled(letter);
    this.setState({introLetter: letter});
  }

  onClickSent() {
    const {user, dispatch} = this.props;
    dispatch(usersActions.find(user.id, (user) => {
      dispatch(redirect('/onboarding'));
    }));
  }

  onClickCancel() {
    const {dispatch} = this.props;
    dispatch(redirect('/onboarding'));
  }

  render() {
    const {user, introUnit, dispatch} = this.props;
    const mentorFirstName = user.firstname;
    if (!introUnit || this.state.introLetter === false) {
      return <LoadingSpinner/>;
    }

    const instructions = (user.isReturningMentor && this.state.introLetter.text.trim().length > 0) ?
        'onboarding.letter.instructionsReturning' : 'onboarding.letter.instructions';

    return (
        <div className={cn('onboarding-page', 'onboarding-letter')}>
          <h2>
            {t('onboarding.letter.heading')}
            <Button mode='transparent'
                    onClick={() => dispatch(redirect('/onboarding'))}
                    size='small'
                    type='regular'>
              <span className={cn('icon-back')} />Back
            </Button>
          </h2>
          <div className={cn('instructions')}>
            {t(instructions)}
          </div>
          <div className={cn('container')}>
            <div className={cn('sidebar')}>
              <LetterComposerTips role='mentor'
                                  checklist={introUnit.writingChecklist.mentor}
                                  events={events}
                                  importantWords={introUnit.importantWords.map(x => x.name)}
                                  sampleLetter={introUnit.sampleLetter.mentor}
                                  thinkingQuestions={[introUnit.essentialQuestion].concat(introUnit.supportingQuestions)}
                                  tips={introUnit.writingTips.mentor}
              />
            </div>
            <div className={cn('content')}>
              <LetterComposerTabs role='mentor'
                                  allowAttachments={true}
                                  onChange={(letterInfo) => this.onChangeLetter(letterInfo)}
                                  onClickCancel={() => this.onClickCancel()}
                                  onClickSent={() => this.onClickSent()}
                                  events={events}
                                  firstName={mentorFirstName}
                                  questions={introUnit.mentor.questions}
                                  letter={this.state.introLetter}
                                  palFirstName={t('letterComposerTabView.defaultStudent')} />
            </div>
          </div>
        </div>
    );
  }
}

MentorOnboardingLetter.displayName = "MentorOnboardingLetter";

module.exports = build({
  component: MentorOnboardingLetter,
  mapStateToProps
});
