// The Letter Composer can be in one of two modes:
//
//    new - working on a new letter
//    revision - working on a revision to a rejected letter
//
// The mode defaults to 'new' but will be set to 'revision' by componentDidMount() if a
// rejected letter is found when initializing.  The mode is passed to many of the redux
// actions in messages.coffee which uses it to determine which of the letters in the store
// to update - either letterComposer.newDraftMessage or letterComposer.revisionCurrentDraft.
// The letter composer does not use these letters in the store anymore, so this is solely
// done for compatibility with the letter center, which still does.  If the letter center
// is refactored to no longer use these, then we can likely remove these type arguments to
// the redux actions.

import React from 'react';
import moment from 'moment';
const style = require('./letter_composer.styl');
const cn = require('classnames/bind').bind(style);

const {build} = require('utils/container_helpers');
const {formatLetter} = require('pages/shared/letter_center/normalization_helper');
const messagesActions = require('actions/messages');
const {redirect} = require('actions/routing');
const exploreUnitsActions = require('actions/explore_units');
const events = require('pages/shared/letter_center/events');
const _ = require('lodash');

import LetterComposerTips from 'components/letter_composer_tips';
import LetterComposerTabs from 'components/letter_composer_tabs';
import LoadingSpinner from 'components/shared/loading_spinner';

const {getArticleSelection} = require('utils/article_normalizer');
const articleSelectionActions = require('actions/article_selections');

const mapStateToProps = function({store}) {
  let { session, classroom, messages } = store;
  const myID = session.data.login.id;
  const myRole = session.data.login.roles[0];
  const palRole = myRole === 'student' ? 'mentor' : 'student';
  classroom = classroom.data;
  const smgs = classroom.studentMentorGroups.filter(group => group[myRole] === myID);
  let palFirstName = t( (myRole === 'student') ? 'letterComposerTabView.defaultPal' : 'letterComposerTabView.defaultStudent');
  let palID;
  if (smgs.length > 0) {
    const smg = smgs[0];
    palID = smg[palRole];
    palFirstName = classroom[`${palRole}s`].filter(({id}) => id === palID)[0].firstname;
  }

  return {
    classroom,
    firstName: session.data.login.firstname,
    messages: messages ? messages.data : undefined,
    palFirstName,
    role: myRole,
    userID: myID,
    palID,
  };
};

class LetterComposer extends React.Component {

  static getLastReceived(messages, userId) {
    const sortedMessages = _.orderBy(messages, ({modified}) => moment(modified));
    sortedMessages.reverse();
    return sortedMessages.find(({status,to}) => ((to != null ? to._id : undefined) === userId) && (status === 'approved'));
  }

  constructor(props) {
    super(props);

    const referenceLetter = LetterComposer.getLastReceived(this.props.messages, this.props.userID);
    this.state = {
      letter: false,  // false is a special value to indicate letter has not yet loaded
      mode: 'new',    // componentDidMount will set to 'revision' if rejected letter found
      referenceLetter,
      showFocusArticleImmediately: this.props.location.query.show_article === 'true',
      errorMsg: false,
    };
    this.updateThrottled = _.throttle(args => {
      this.saveLetter(args);
    }, 3000, {leading: true, trailing: true});
  }

  componentDidMount() {
    const {role, palID, userID, classroom, dispatch} = this.props;
    // console.log('componentDidMount');
    // Fetch all additional required data and then initialize derived state once.
    dispatch(articleSelectionActions.fetch({student_id: role === 'mentor' ? palID : userID}, (articleSelections) => {
      dispatch(exploreUnitsActions.fetch((unitTemplates) => {
        dispatch(messagesActions.fetchUserClassroomMessages(classroom, true, (messages) => {

          const rejectedLetter = messages.find(({from: {id}, status}) => id === userID && status === 'rejected');
          let currentUnit = classroom.currentUnit;
          let mode;
          let currentLetter;
          if (rejectedLetter) {
            mode = 'revision';
            currentLetter = messages.find(({from: {id}, state}) => id === userID && state === 'draft');
            currentUnit = classroom.units.find(({id}) => id === rejectedLetter.classroomUnitID);
          } else {
            mode = 'new';
            currentLetter = messages.find(({from: {id}, state}) => id === userID && state === 'newMessageDraft');
            if (currentLetter) {
              currentUnit = classroom.units.find(({id}) => id === currentLetter.classroomUnitID);
            }
          }
          const unitTemplate = _.find(unitTemplates, unitTemplate => unitTemplate._id === currentUnit.parent);

          try {
            if (currentLetter) {
              // console.log("Load existing working draft")
              this.initializeDerivedState({
                articleSelections, unitTemplates, messages, currentLetter, rejectedLetter, currentUnit, mode
              });
            } else {
              // console.log("Create new working draft");
              // No working draft yet.  Need to create first, then initializeDerivedState
              const questions = this.serializeAnswers([], unitTemplate);
              dispatch(messagesActions.create({
                from: userID,
                text: (mode === 'revision') ? rejectedLetter.text : '',
                questions,
                state: (mode === 'revision') ? 'draft' : 'newMessageDraft',
                classroomUnitID: currentUnit._id,
                subject: unitTemplate.name,
                thread: rejectedLetter ? rejectedLetter.thread : null,
              }, mode, (data) => {
                this.initializeDerivedState({
                  articleSelections, unitTemplates, messages, currentLetter: data, rejectedLetter, currentUnit, mode,
                });
              }));
            }
          } catch (errorMessage) {
            this.setState({errorMessage} );
          }
        }));
      }));
    }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('componentDidUpdate', this.state);
    if (!prevState.errorMsg && this.state.errorMsg) {
      console.error(this.state.errorMsg);
      throw new Error(this.state.errorMsg);
    }
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    const {letterId, letter} = this.state;
    // console.log('componentWillUnmount', {letterId, letter});
    // If letter composer is unmounted and draft is empty, delete it so they don't have an unfinished
    // draft in the letter center
    if (letterId && letter.text.trim() === '') {
      // console.log('Abandon empty draft');
      dispatch(messagesActions.delete(letterId));
    }
  }

  initializeDerivedState({articleSelections, unitTemplates, messages, currentLetter, currentUnit, rejectedLetter, mode}) {
    const {role, userID} = this.props;
    const myPriorLetterInUnit = messages.find(({classroomUnitID, from, state}) => {
      return from._id === userID && classroomUnitID === currentUnit._id && state !== 'newMessageDraft';
    });

    let plannerIsVisible = !myPriorLetterInUnit;
    // If there is no prior letter in the unit, usually that means that we need to show the planner, except
    // in one particular case.  Suppose a student submits the first letter in a unit for review.  Before that
    // letter is reviewed, the student begins writing a second letter but does not complete it.  Then the first
    // letter is rejected.  When the student returns to the letter center, they will be alerted to modify the
    // rejected letter.  If the student then decides to just delete that letter, the letter center will then
    // prompt to finish the second letter.  Since there is now no prior letter in the unit, the logic above
    // would make the planner visible.  However, the second letter was not written with the planner so if the
    // planner displays, it will potentially overwrite the draft text.  So we need to hide the planner in this
    // case.  To do so we make use of the existence of the questionsLocked field.  If that field is not defined,
    // it indicates that the planner should not be displayed.  This next bit takes care of this.
    if (currentLetter.state === 'newMessageDraft' && currentLetter.text !== "" && currentLetter.questionsLocked === undefined) {
      plannerIsVisible = false;
    }

    const articleSelection = getArticleSelection(articleSelections, currentUnit._id);
    const unitTemplate = _.find(unitTemplates, unitTemplate => unitTemplate._id === currentUnit.parent);

    const questions = unitTemplate[role].questions;
    let answers = [];
    if (currentLetter.questions) {
      answers = questions.map((x, idx) => currentLetter.questions[idx] && currentLetter.questions[idx].answer);
    }

    const focusArticle = currentUnit.content.find(article => (articleSelection.status === 'approved' && article._id === articleSelection.id));
    const letterId = currentLetter._id;
    const letter = {
      from: userID,
      status: 'draft',
      rejectMessage: rejectedLetter ? rejectedLetter.rejectMessage : undefined,
      answers,
      locked: currentLetter.questionsLocked,
      text: currentLetter.text,
      thread: currentLetter.thread,
    };

    // console.log('initializing:', {letterId, currentLetter, rejectedLetter, plannerIsVisible, myPriorLetterInUnit, currentUnit, focusArticle, letter});

    this.setState({
      letter,
      letterId,
      plannerIsVisible,
      articleSelection,
      unitTemplate,
      focusArticle,
      mode,
      currentUnit,
      rejectedLetter,
    });
  }

  onChangeLetter(letter) {
    this.setState({letter});
    this.updateThrottled(letter);
  }

  onClickCancel() {
    this.props.dispatch(redirect('/letters'));
  }

  onClickSend() {
    const {dispatch} = this.props;
    const {mode, letterId, letter, rejectedLetter} = this.state;

    if (mode === 'new') {
      // console.log('Sending new message');
      dispatch(messagesActions.send({_id: letterId, text: letter.text}, (errorMsg) => {
        if (errorMsg) {
          this.setState({errorMsg} );
        } else {
          return dispatch(messagesActions.clearDraft('new', null))
        }
      }));
    } else if (mode === 'revision') {
      // console.log('Sending revision', {rejectedLetter, letter});
      dispatch(messagesActions.update({_id: rejectedLetter._id, status: 'pending', text: letter.text}, mode, true,
        (errorMsg) => {
          if (errorMsg) {
            this.setState({errorMsg});
          } else {
            dispatch(messagesActions.delete(letterId));
          }
        }));
    }
    return true; // true tells LetterComposerTabs to proceed with displaying sent confirmation dialog
  }

  // Called after user has dismissed send confirmation dialog
  onClickSent() {
    this.props.dispatch(redirect('/letters'));
  }

  onClickStartOver(letter) {
    const {dispatch} = this.props;
    const {letterId, unitTemplate, mode} = this.state;

    dispatch(messagesActions.update({
      _id: letterId,
      text: letter.text,
      questions: this.serializeAnswers(letter.answers, unitTemplate),
      questionsLocked: letter.locked,
    }, mode, true, (errorMsg) => {
      if (errorMsg) {
        this.setState({errorMsg});
      }
    }));

    this.setState({letter});
  }

  saveLetter(letter) {
    const {dispatch} = this.props;
    const {unitTemplate, mode} = this.state;
    const letterId = this.state.letterId;
    dispatch(messagesActions.update({
      _id: letterId,
      text: letter.text,
      questions: this.serializeAnswers(letter.answers, unitTemplate),
      questionsLocked: letter.locked,
    }, mode, true, (errorMsg) => {
      if (errorMsg) {
        this.setState({errorMsg} );
      }
    }));
  }

  // Helper method to serialize list of answers along with questions.  Dumb but required to maintain compatibility.
  // Example:
  // {
  //         "0" : {
  //             "question" : "Engaging Opening",
  //             "index" : 0,
  //             "answer" : "hello"
  //         },
  //         "3" : {
  //             "question" : "Begin the Reading Discussion",
  //             "index" : 1,
  //             "answer" : "begin"
  //         }
  // }

  serializeAnswers(answers, unitTemplate) {
    const {role} = this.props;
    const questions = unitTemplate[role].questions;
    const serialized = questions.map((question, index) => {
      return {question: question.title, index, answer: answers[index]}
    }).reduce((acc, x) => {
      if (x.answer && x.answer.length > 0) {
        acc[x.index] = x;
      }
      return acc;
    }, {});
    return serialized;
  }

  render() {
    const {role, firstName, palFirstName} = this.props;
    const {currentUnit, focusArticle, unitTemplate} = this.state;
    if (!unitTemplate || !currentUnit || this.state.letter === false) {
      return <LoadingSpinner/>;
    }

    return (
      <div className={cn('letter-composer')}>
        <div className={cn('tips-resources-container')}>
          <LetterComposerTips
            checklist={unitTemplate.writingChecklist[role]}
            events={events}
            importantWords={_.map(unitTemplate.importantWords, 'name')}
            referenceLetter={formatLetter('draft')(this.state.referenceLetter)}
            sampleLetter={unitTemplate.sampleLetter[role]}
            thinkingQuestions={[unitTemplate.essentialQuestion].concat(unitTemplate.supportingQuestions)}
            tips={unitTemplate.writingTips[role]}
            focusArticle={focusArticle}
            showFocusArticleImmediately={this.state.showFocusArticleImmediately}
            role={role}/>
        </div>
        <div className={cn('letter-composer-container')}>
          <LetterComposerTabs role={role}
                              showPlanner={this.state.plannerIsVisible}
                              allowAttachments={currentUnit.allowAttachments}
                              onChange={(letterInfo) => this.onChangeLetter(letterInfo)}
                              onClickCancel={() => this.onClickCancel()}
                              onClickSend={() => this.onClickSend()}
                              onClickSent={() => this.onClickSent()}
                              onClickStartOver={(letter) => this.onClickStartOver(letter)}
                              events={events}
                              firstName={firstName}
                              questions={unitTemplate[role].questions}
                              letter={this.state.letter}
                              palFirstName={palFirstName}/>
        </div>
      </div>
    );
  }
}

LetterComposer.displayName = "LetterComposer";

LetterComposer.defaultProps = {
  palFirstName: t('letterComposerTabView.defaultPal')
};

module.exports = build({
  component: LetterComposer,
  mapStateToProps
});
