({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
e = require 'react-e'

KalturaVideo = createFactory require 'components/shared/kaltura_video'
ModalWrapper = createFactory require 'components/shared/modal/modal_wrapper'

class ActionItem extends Component

  @propTypes:
    actionItem: PropTypes.object.isRequired
    children: PropTypes.node
    className: PropTypes.string
    dispatchRedirect: PropTypes.func.isRequired


  render: ->
    {actionItem, children, className, dispatchRedirect} = @props

    if actionItem.link?
      e 'a',
        className: className
        href: actionItem.link
        target: '_blank'
        onClick: ->
          actionItem.done?()
        children
    else if actionItem.redirect?
      e 'a',
        className: className
        onClick: ->
          actionItem.event?()
          actionItem.done?()
          dispatchRedirect actionItem.redirect
        children
    else if actionItem.video?
      ModalWrapper
        event: -> actionItem.event?()
        onExit: -> actionItem.done?()
        trigger: e 'a', {className}, children
        KalturaVideo entryId: actionItem.video

ActionItem.displayName = "ActionItem"

module.exports = ActionItem
