({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
{build} = require 'utils/container_helpers'
events = require '../classroom/events'
style = require './classroom_add_students.styl'
e = require('react-e/bind') style
_ = require 'lodash'
{redirect} = require 'actions/routing'
classroomStudentActions = require 'actions/classroom_student'

Card = createFactory require 'components/shared/card'
Button = createFactory require 'components/shared/button'
StudentDataEntry = createFactory require 'components/student_data_entry'

mapStateToProps = ({store: {classroom, classroomStudent}}) -> {classroom, classroomStudent}

class ClassroomAddStudents extends Component

  _goToClassroom: =>
    events.canceledAddIndividual()
    @props.dispatch redirect '/classroom'


  _sendAddEvent: ->
    events.savedIndividual()


  componentDidMount: ->
    @props.dispatch classroomStudentActions.initializeData()


  render: ->
    {dispatch, classroom} = @props
    permissionFormType = classroom.data.permissionFormType
    Card {},
      e 'form.card-content',
        onSubmit: (e) =>
          e.preventDefault()
          fields = @props.classroomStudent.data.fields
          dispatch classroomStudentActions.validateFieldsAndCreate fields: fields, ->
            dispatch redirect '/classroom'
      ,
        e '.card-header',
          e '.card-header-item',
            Button mode: 'secondary', onClick: @_goToClassroom, size: 'large', type: 'regular',
              'Cancel'
          e '.card-header-item',
            Button onClick: @_sendAddEvent, size: 'large', submit: true, type: 'regular', 'Save'
        e '.card-body',
          e '.row',
            StudentDataEntry
              data: @props.classroomStudent.data.fields
              enabled:
                firstname: true
                gender: true
                lastname: true
                parentemail: true
                parentname: true
                password: true
                username: true
              errors: @props.classroomStudent.data.validationErrors
              onChange: (fields) -> dispatch classroomStudentActions.setFields fields
              permissionFormType: permissionFormType
        e '.card-footer'

ClassroomAddStudents.displayName = "ClassroomAddStudents"

module.exports = build
  component: ClassroomAddStudents
  mapStateToProps: mapStateToProps
