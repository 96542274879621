const {build} = require('utils/container_helpers');
const {redirect} = require('actions/routing');
const store = require('store');
const {Component} = require('react');
const Card = require('components/shared/card');



const mapStateToProps = ({store}) => {
    const { tenant, classrooms } = store;
    return {

      tenant: tenant.data,
      classrooms: classrooms.data
    };
  };

class Forum extends Component {

    constructor(props) {
        super(props);

        this.forumPathPrefix = FORUM_PATH_PREFIX;
        this.forumURL = this.props.tenant && this.props.tenant.forumURL
            ? this.props.tenant.forumURL
            : location.protocol + "//forum." + location.hostname + (location.port ? `:${location.port}` : "");

        this.forumParentURL = `${location.protocol}//${location.hostname}${location.port ? ":" + location.port : ""}/${this.forumPathPrefix}`;

        this.handlePathUpdate = (ev) => {
            if (ev.origin === this.forumURL) {
                //console.log('forum message');
                //console.dir(ev);
                if (ev.data.path || ev.data.path === "") {
                    window.history.replaceState(null,'',this.forumParentURL + ev.data.path);
                }
            }
        }
        //console.log(this.forumURL);
        //console.log(this.forumParentURL);
    }

    componentDidMount() {
        if (this.props.tenant && !this.props.tenant.enableForum) {
            store.dispatch(redirect('/'));
        }

        window.addEventListener("message", this.handlePathUpdate);

        //console.log(`path is ${this.props.location.pathname}`);
    }

    componentWillUnmount() {
        //console.log('Unmounting Forum component');
        window.removeEventListener("message", this.handlePathUpdate);
    }

    render() {

        const replaceRegEx = new RegExp('^/' + this.forumPathPrefix);
        const forumPath = this.props.location.pathname.replace(replaceRegEx,'');
        return (
                <iframe src={this.forumURL + forumPath} width="1000px" height="1000px" style={{marginLeft:'-20px'}}></iframe>
        );
    }
}

Forum.displayName = "Forum";

module.exports = build({
    component: Forum,
    mapStateToProps
  });
