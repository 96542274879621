_ = require 'lodash'
api = require '../api'
{makeAction} = require 'utils/action_helpers'
unitsService = require 'services/units_service'

module.exports =

  fetch: (callback) -> makeAction (setState) ->
    setState 'unitTemplates', loading: true
    unitsService.fetch()
      .then ({data}) ->
        unitTemplates = _.keyBy data, '_id'
        setState 'unitTemplates', data: unitTemplates, loading: false
        callback? unitTemplates
      .catch (error) ->
        setState 'unitTemplates', error: error.toString(), loading: false
