import React from "react";
import PropTypes from 'prop-types';
const _ = require('lodash');
const style = require('./resources.styl');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
const exploreUnitsActions = require('actions/explore_units');
import LoadingSpinner from 'components/shared/loading_spinner';
require('details-element-polyfill');

const mapStateToProps = ({store: {user, classroom, tenant, unitTemplates}}) => {
  return {
    classroom: classroom.data,
    tenant: tenant.data,
    role: user.data.role,
    unitTemplates: unitTemplates.data,
  };
}

class Resources extends React.Component {

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(exploreUnitsActions.fetch());
  }

  render() {
    const {tenant, role, classroom, unitTemplates} = this.props;
    const programResources = tenant[role].resources;
    if (!unitTemplates) {
      return <LoadingSpinner/>;
    }
    const currentUnitId = classroom.currentUnit.parent;
    const currentUnit = unitTemplates[ currentUnitId ];
    const currentUnitResources = currentUnit[role].resources;
    let units = _.sortBy(Object.values(unitTemplates), x => x.name);
    const introUnit = units.find(x => x.isDefaultUnit);
    const reflectionUnit = units.find( x => x.isReflectionUnit);
    units = units.filter(x => x.id !== currentUnitId && !x.isDefaultUnit && !x.isReflectionUnit);
    if (introUnit && !currentUnit.isDefaultUnit) {
      units.unshift(introUnit);
    }
    if (reflectionUnit && !currentUnit.isReflectionUnit) {
      units.push(reflectionUnit);
    }

    return (
        <div className={cn('resources-page')}>
          <div className={cn('body')}>
            <section>
              <h1>Current Unit Resources</h1>
              <h2>{currentUnit.name}</h2>
              <ul>
                {currentUnitResources.map((resource, idx) => {
                  return (
                      <li key={`resource-${currentUnit.id}-${idx}`}>
                        <a href={resource.url} target="_blank">{resource.name}</a>
                      </li>
                  );
                })}
              </ul>
              <h1>Program Resources</h1>
              <ul>
                {programResources.map((x, idx) => {
                  return (
                      <li key={`program-resource-${idx}`}>
                        <a href={x.url} target="_blank">{x.name}</a>
                      </li>
                  );
                })}
              </ul>
            </section>
            <section>
              <h1>Other Unit Resources</h1>
              {units.map((unit) => {
                const unitResources = unit[role].resources;
                return (
                    <details className={cn(`section`)} key={`unit-${unit.id}`}>
                      <summary className={cn('caption')}>
                        {unit.name}
                      </summary>
                      <ul>
                        {unitResources.map((resource, idx) => {
                          return (
                              <li key={`resource-${unit.id}-${idx}`}>
                                <a href={resource.url} target="_blank">{resource.name}</a>
                              </li>
                          );
                        })}
                      </ul>
                    </details>
                );
              })}
            </section>
          </div>
          <div className="footer">
            {role !== 'student' &&
            <div className={cn("help")}>
              <p>{t('resourcesPage.help')}</p>
              <a className={cn('link')} href={`mailto:${tenant.contactEmail}`} target="_blank">
                {tenant.contactEmail}
              </a>
            </div>}
          </div>
        </div>
    );
  }
}

Resources.displayName = "Resources";
Resources.propTypes = {
  tenant: PropTypes.object.isRequired,
  back: PropTypes.string,
  mode: PropTypes.string
};

module.exports = build({
  component: Resources,
  mapStateToProps
});
