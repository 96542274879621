({React, Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './modal.styl'
e = (require 'react-e/bind') style
{findDOMNode} = require 'react-dom'
Portal = createFactory (require 'react-portal').PortalWithState
ReactGa = require 'react-ga'
TabView = createFactory require 'components/shared/tab_view'
createClass = require 'create-react-class'
#Portal = createFactory Portal
Modal = createClass

  _preventExit: (e) ->
    e.stopPropagation()


  componentDidMount: ->
    #if @props.children
      #{displayName, name} = @props.children.type
      #ReactGa.modalview displayName ? name


  getDefaultProps: ->
    fullSize: no
    maxHeight: no


  propTypes:
    eReader: PropTypes.bool
    fullSize: PropTypes.bool
    maxHeight: PropTypes.bool
    next: PropTypes.shape
      onClick: PropTypes.func.isRequired
      visible: PropTypes.bool
    onClose: PropTypes.func.isRequired
    previous: PropTypes.shape
      onClick: PropTypes.func.isRequired
      visible: PropTypes.bool



  render: ->
    portalContent = e '.content-container',
                      className: {'e-reader': @props.eReader, 'full-size': @props.fullSize, 'top': @props.top}
                      onClick: @props.onClose
                      if @props.tabViewProps
                        e '.content',
                          className: {'max-height': @props.maxHeight},
                          onClick: @_preventExit,
                          TabView Object.assign {}, @props.tabViewProps, modalOnClose: @props.onClose
                          @renderNextAndPrevious()
                      else
                        e '.content',
                          className: {'max-height': @props.maxHeight},
                          onClick: @_preventExit
                          e '.close', onClick: @props.onClose, '×'
                          @props.children
                          @renderNextAndPrevious()

    Portal closeOnEsc: yes, defaultOpen: yes,
      ({portal}) => portal portalContent

  renderNextAndPrevious: ->
    e '.buttons',
      if @props.previous?.visible
        e '.previous-button',
          onClick: @props.previous.onClick,
          e '.previous-button-text'
      if @props.next?.visible
        e '.next-button',
          onClick: @props.next.onClick,
          e '.next-button-text'

Modal.displayName = "Modal"

module.exports = Modal
