analytics = require 'utils/analytics'

category =
  requestedReplacement: 'Requested Mentor Replacement'

module.exports =

  requestedReplacement: ->
    analytics.recordEvent
      action: "Clicked 'Request Replacement' Button"
      category: category.requestedReplacement
