(React = require 'react') and (PropTypes = require 'prop-types')
e = require 'react-e'
{build} = require 'utils/container_helpers'
_ = require 'lodash'
sessionActions = require 'actions/session'
classroomsActions = require 'actions/classrooms'
routingActions = require 'actions/routing'

mapStateToProps = ({store: {classroom, classrooms, session}}) ->
  {classroom, classrooms, session}

class ClassroomSelector extends React.Component

  _handleClassroomSelect: (e) =>
    {dispatch, session} = @props
    classroomId = e.target.value
    dispatch classroomsActions.setClassroom classroomId, ->
      dispatch routingActions.redirect '/'


  render: ->
    if not @props.classrooms.data?
      e 'span', t 'containerClassroomSelector.notLoaded'
    else
      e 'select',
        onChange: @_handleClassroomSelect
        value: @props.session.data.classroomId
      ,
        @props.classrooms.data.map @renderClassroomOption


  renderClassroomOption: ({_id, name}, index) ->
    e 'option', key: index, value: _id, name

ClassroomSelector.displayName = "ClassroomSelector"

module.exports = build {
  component: ClassroomSelector
  mapStateToProps
}
