// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._702dcb6b19f7d821e85a75050feea4d2-styl {\n  list-style: disc inside;\n}\n", "",{"version":3,"sources":["webpack://cricket-together/app/pages/teacher/home/localization_helper.styl"],"names":[],"mappings":"AAAA;EACE,uBAAW;AACb","sourcesContent":[".bullets\n  list-style disc inside\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bullets": "_702dcb6b19f7d821e85a75050feea4d2-styl"
};
module.exports = ___CSS_LOADER_EXPORT___;
