// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d88c7ce56e241a77ea81da8cd8430e75-styl {\n  background-color: var(--theme-white, #fff);\n  border-radius: 0.125rem;\n  filter: drop-shadow(var(--theme-blue-glow, #6097ba) 0 0 0.35rem);\n  position: absolute;\n}\n._88e6f10ec2e8b50d686becafcd8b106e-styl {\n  display: none;\n}\n.d88c7ce56e241a77ea81da8cd8430e75-styl:after,\n.d88c7ce56e241a77ea81da8cd8430e75-styl:before {\n  border: solid transparent;\n  bottom: 100%;\n  content: \" \";\n  height: 0;\n  left: 50%;\n  pointer-events: none;\n  position: absolute;\n  width: 0;\n}\n.d88c7ce56e241a77ea81da8cd8430e75-styl:after {\n  border-bottom-color: #fff;\n  border-width: 0.375rem;\n  margin-left: -0.375rem;\n}\n.d88c7ce56e241a77ea81da8cd8430e75-styl:before {\n  border-width: 0.5625rem;\n  margin-left: -0.5625rem;\n}\n", "",{"version":3,"sources":["webpack://cricket-together/app/components/shared/popup/popup.styl"],"names":[],"mappings":"AAEA;EACE,0CAA+C;EAC/C,uBAAc;EACd,gEAAsE;EACtE,kBAAS;AADX;AAGA;EACE,aAAQ;AADV;AAGA;;EACE,yBAAO;EACP,YAAO;EACP,YAAQ;EACR,SAAO;EACP,SAAK;EACL,oBAAe;EACf,kBAAS;EACT,QAAM;AAAR;AAEA;EACE,yBAAoB;EACpB,sBAAa;EACb,sBAAY;AAAd;AAEA;EACE,uBAAa;EACb,uBAAY;AAAd","sourcesContent":["@import 'variables'\n\n.popup-container\n  background-color var(--theme-white,$theme-white)\n  border-radius .125rem\n  filter: drop-shadow(var(--theme-blue-glow,$theme-blue-glow) 0 0 .35rem)\n  position absolute\n\n.hidden\n  display none\n\n.popup-container:after, .popup-container:before\n  border solid transparent\n  bottom 100%\n  content \" \"\n  height 0\n  left 50%\n  pointer-events none\n  position absolute\n  width 0\n\n.popup-container:after\n  border-bottom-color white\n  border-width .375rem\n  margin-left -.375rem\n\n.popup-container:before\n  border-width .5625rem\n  margin-left -.5625rem\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup-container": "d88c7ce56e241a77ea81da8cd8430e75-styl",
	"hidden": "_88e6f10ec2e8b50d686becafcd8b106e-styl"
};
module.exports = ___CSS_LOADER_EXPORT___;
