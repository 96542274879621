// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0b1242c0423f9a73750e2c2aade8e96d-styl {\n  margin: 0 auto;\n  width: 28.75rem;\n}\n", "",{"version":3,"sources":["webpack://cricket-together/app/pages/impersonate/impersonate.styl"],"names":[],"mappings":"AAAA;EACE,cAAO;EACP,eAAM;AACR","sourcesContent":[".login\n  margin 0 auto\n  width 28.75rem\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": "_0b1242c0423f9a73750e2c2aade8e96d-styl"
};
module.exports = ___CSS_LOADER_EXPORT___;
