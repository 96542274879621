_ = require 'lodash'
moment = require 'moment'

UNIT_DATES_TYPE = null
EDGE_GAP = .5
INNER_GAP = 1
DATE_FORMAT = 'MMM DD'
PERSONALIZED_DATES = null
DATES = [

  {
    id: 'startDate'
    label: t 'dateNormalizer.startLabel'
    tags: [
      'intro'
      'reflection'
      'standard'
      'extended'
    ]
  }

  {
    id: 'focusArticleDate'
    label: t 'dateNormalizer.focusLabel'
    tags: [
      'standard'
      'extended'
    ]
  }

  {
    id: 'mentorDate'
    label: (name) -> t 'dateNormalizer.letterDue', name: name
    labelExt: (name) -> t 'dateNormalizer.letter1Due', name: name
    tags: [
      'reflection'
      'standard'
      'extended'
    ]
  }

  {
    id: 'studentDate'
    label:  (name) -> t 'dateNormalizer.letterDue', name: name
    labelExt:  (name) -> t 'dateNormalizer.letter1Due', name: name
    tags: [
      'intro'
      'reflection'
      'standard'
      'extended'
    ]
  }

  {
    id: 'mentor2Date'
    label: (name) -> t 'dateNormalizer.letter2Due', name: name
    labelExt: (name) -> t 'dateNormalizer.letter2Due', name: name
    tags: [
      'extended'
    ]
  }

  {
    id: 'student2Date'
    label: (name) -> t 'dateNormalizer.letter2Due', name: name
    labelExt: (name) -> t 'dateNormalizer.letter2Due', name: name
    tags: [
      'extended'
    ]
  }

  {
    id: 'endDate'
    label: t 'dateNormalizer.endLabel'
    tags: [
      'intro'
      'reflection'
      'standard'
      'extended'
    ]
  }

]

_getApprovals = (currentUnitId, role, approvalData) ->
  approvedArticleIds = _idsOfApproved approvalData.articleSelections, 'student_id'
  approvedMessageIds = _idsOfApproved approvalData.messages, 'from._id', currentUnitId
  switch role
    when 'teacher'
      focusArticle: _checkAllUsersContain approvalData.students, approvedArticleIds
      mentorLetter: _checkAllUsersContain approvalData.mentors, approvedMessageIds
      studentLetter: _checkAllUsersContain approvalData.students, approvedMessageIds
      mentor2Letter: _checkAllUsersContain approvalData.mentors, approvedMessageIds, 2
      student2Letter: _checkAllUsersContain approvalData.students, approvedMessageIds, 2
    when 'student'
      focusArticle: approvedArticleIds[approvalData.id]?
      studentLetter: approvedMessageIds[approvalData.id] > 0
      student2Letter: approvedMessageIds[approvalData.id] > 1
    when 'mentor'
      focusArticle: approvedArticleIds[approvalData.studentId]?
      mentorLetter: approvedMessageIds[approvalData.id] > 0
      mentor2Letter: approvedMessageIds[approvalData.id] > 1

_idsOfApproved = (items, id, unitId) ->
  approved = _.filter items, (item) ->
    item?.status is 'approved' and (if unitId? then item.classroomUnitID is unitId else yes)
  _.countBy approved, id

_checkAllUsersContain = (people, items, count = 1) ->
  if people? and people.length isnt 0
    people.every ({_id}) -> items[_id] >= count
  else no

_accumulateProgress = (dates) ->
  _.sum _.map(dates, 'progress')

_getProgressLength = (dates, currentUnitId, isDefaultUnit, role, approvalData) ->
  approvals = _getApprovals currentUnitId, role, approvalData
  _accumulateProgress _.takeWhile dates, ({id, value}) ->
    switch id
      when 'startDate' then moment() >= value
      when 'focusArticleDate' then approvals.focusArticle
      when 'mentorDate' then approvals.mentorLetter
      when 'studentDate' then approvals.studentLetter
      when 'mentor2Date' then approvals.mentor2Letter
      when 'student2Date' then approvals.student2Letter
      when 'endDate' then no

module.exports = {

  DATE_FORMAT

  formatDate: (date) -> moment(date).format(DATE_FORMAT)

  getUnitDates: (unit, role, palFirstName) ->
    searchFor = []
    searchFor.push('intro') if unit.isDefaultUnit
    searchFor.push('reflection') if unit.isReflectionUnit
    searchFor.push(UNIT_DATES_TYPE)
    unitDates = _.cloneDeep(_.filter(DATES, (d) -> _.difference(searchFor, d.tags).length is 0))

    #if unit.unit_type is 'ClassroomUnit'
    dateValues = Object.assign({}, {startDate: unit.startDate, endDate: unit.endDate}, unit.unitDates)
    unitDates.forEach (x) ->
      x.value = if dateValues[x.id] then moment dateValues[x.id]
      x.progress = if x.id is 'startDate' then EDGE_GAP else INNER_GAP
      x.label = x.labelExt if UNIT_DATES_TYPE is "extended" and x.labelExt? and not (unit.isDefaultUnit or unit.isReflectionUnit)
      if (typeof x.label is 'function')
        switch x.id
            when 'mentorDate','mentor2Date'
              if not PERSONALIZED_DATES or not role or role is 'teacher'
                name = t 'dateNormalizer.defaultMentorName'
              else
                name = if role is 'mentor' then t 'dateNormalizer.my' else (palFirstName ? t 'dateNormalizer.defaultMentorName')+"'s"
            when 'studentDate', 'student2Date'
              if  not PERSONALIZED_DATES or not role or role is 'teacher'
                name = t 'dateNormalizer.defaultStudentName'
              else
                name = if role is 'student' then t 'dateNormalizer.my' else (palFirstName ? t 'dateNormalizer.defaultChildName')+"'s"
        x.label = x.label name
    unitDates

  progressPercent: ({approvalData, currentUnitId, dates, isDefaultUnit, role}) ->
    totalLength = _accumulateProgress(dates) + EDGE_GAP
    progressLength = _getProgressLength dates, currentUnitId, isDefaultUnit, role, approvalData
    t 'dateNormalizer.progressPercent', percent: Math.round(progressLength * 100 / totalLength, 0)

  init: (tenant) ->
    UNIT_DATES_TYPE = tenant.unitDatesType ? "standard" unless UNIT_DATES_TYPE
    PERSONALIZED_DATES = tenant.usePersonalizedTimelineDates ? false unless PERSONALIZED_DATES
}
