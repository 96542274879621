{Component} = require 'react'
e = require('react-e')

class KalturaVideo extends Component

  render: ->
    {entryId} = @props

    e 'iframe',
      allowFullScreen: yes
      height: 480
      src: 'https://www.kaltura.com/p/1767811/sp/176781100/embedIframeJs/uiconf_id/38052051' +
           "/partner_id/1767811?iframeembed=true&entry_id=#{entryId}"
      width: 640

KalturaVideo.displayName = "KalturaVideo"

module.exports = KalturaVideo
