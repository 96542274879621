({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
{build} = require 'utils/container_helpers'
{checkDraftMessageForPrevUnit,
mapInboxMessages,
mapSentMessages,
formatLetter,
combineInboxAndSentMessages,
mapRejectedMessages,
getRevisionDraftsByThread} = require './normalization_helper'
{cardButtonTitleForRole,
cardContentForRole,
cardSubtitleForRole,
cardTitleForRole,
inboxPlaceholderForRole,
sentPlaceholderForRole} = require './localization_helper.coffee'
events = require 'pages/shared/letter_center/events'
messagesActions = require 'actions/messages'
routing = require 'actions/routing'
style = require './letter_center.styl'
e = require('react-e/bind') style

LetterCenterCard = createFactory require 'components/letter_center_card'
LetterModal = createFactory require 'components/letter_modal'
Modal = createFactory require 'components/shared/modal'
Placeholder = createFactory require 'components/shared/placeholder'
Table = createFactory require 'components/shared/table'
TabView = createFactory require 'components/shared/tab_view'
Button = createFactory require 'components/shared/button'

ClassroomLetterModal = createFactory require 'pages/teacher/classroom/classroom_letter_modal'
UserHelpers = require('utils/user_helpers').default

mapStateToProps = ({store}) ->
  hasPrevDraft = checkDraftMessageForPrevUnit store
  rejectedMessages = mapRejectedMessages store
  revisionDrafts = getRevisionDraftsByThread store

  classroom: store.classroom?.data
  classroomIsActive: store.classroom?.data?.status is 'active'
  classroomName: store.classroom?.data?.name
  currentUnitID: store.classroom?.data?.currentUnit?._id
  dueDates:
    mentor: store.classroom?.data?.currentUnit?.unitDates?.mentorDate
    student: store.classroom?.data?.currentUnit?.unitDates?.studentDate
  firstname: store.session?.data?.login?.firstname
  hasDraft: store.letterComposer?.newDraftMessage?
  draftMessage: store.letterComposer?.newDraftMessage
  hasPrevDraft: hasPrevDraft
  prevDraftMessage: hasPrevDraft and store.letterComposer?.newDraftMessage
  hasRejectedMessage: rejectedMessages.length > 0
  rejectedMessages: rejectedMessages
  revisionDrafts: revisionDrafts
  inboxMessages: mapInboxMessages store
  messages: store.messages
  role: store.session?.data?.login?.roles?[0]
  sentMessages: mapSentMessages store
  unitName: store.classroom?.data?.currentUnit?.name
  userId: store.session?.data?.login?.id
  userState: UserHelpers.getTransformedUser store.session?.data?.login, null, store.classroom?.data, store.tenant.data

class LetterCenter extends Component

  @propTypes:
    mode: PropTypes.oneOf ['mentor', 'student']

  _deletePrevDraftConfModal: ->
    # NOTE - this was borrowed from the Letter Composer,
    # could probably make a reusable "ConfirmationModal" component, will
    # do that if I get a chance...
    if @state.showDeletePrevDraftConfModal
      Modal
        onClose: => @setState showDeletePrevDraftConfModal: false
        top: false
        e '.conf-modal',
          e '.conf-modal-header', t 'pageLetterCenter.deletePrevDraftConfHeader'
          e '.conf-modal-text', t 'pageLetterCenter.deletePrevDraftConfText'
          e '.conf-modal-buttons',
            e '.conf-modal-button',
              Button
                onClick: =>
                  if @props.hasPrevDraft
                    @props.dispatch messagesActions.delete @props.prevDraftMessage.id,
                      @props.dispatch messagesActions.clearDraft 'new',
                        @setState showDeletePrevDraftConfModal: false
                size: 'large'
                type: 'regular',
                t 'pageLetterCenter.deleteConfYesButtonTitle'
            e '.conf-modal-button',
              Button
                onClick: => @setState showDeletePrevDraftConfModal: false
                size: 'large'
                type: 'regular',
                t 'pageLetterCenter.deleteConfNoButtonTitle'

  _viewPrevDraftModal: ->
    if @state.prevDraftMessage
      e '.modal',
        Modal
          maxHeight: yes
          onClose: => @setState prevDraftMessage: null
          LetterModal
            events: events
            indexOfMessageInConversation: 1
            message: @state.prevDraftMessage
            print: yes
            totalMessagesInConversation: 1
            isTotalNumberInfoBoxHidden: yes

  _deleteDraftConfModal: ->
    # NOTE - this was borrowed from the Letter Composer,
    # could probably make a reusable "ConfirmationModal" component, will
    # do that if I get a chance...
    if @state.showDeleteDraftConfModal
      Modal
        onClose: => @setState showDeleteDraftConfModal: false
        top: false
        e '.conf-modal',
          e '.conf-modal-header', t 'pageLetterCenter.deleteDraftConfHeader'
          e '.conf-modal-text', t 'pageLetterCenter.deleteDraftConfText'
          e '.conf-modal-buttons',
            e '.conf-modal-button',
              Button
                onClick: =>
                  if @props.hasDraft
                    @props.dispatch messagesActions.delete @props.draftMessage.id,
                      @props.dispatch messagesActions.clearDraft 'new',
                        @setState showDeleteDraftConfModal: false
                size: 'large'
                type: 'regular',
                t 'pageLetterCenter.deleteConfYesButtonTitle'
            e '.conf-modal-button',
              Button
                onClick: => @setState showDeleteDraftConfModal: false
                size: 'large'
                type: 'regular',
                t 'pageLetterCenter.deleteConfNoButtonTitle'

  _viewDraftModal: ->
    if @state.draftMessage
      e '.modal',
        Modal
          maxHeight: yes
          onClose: => @setState draftMessage: null
          LetterModal
            events: events
            indexOfMessageInConversation: 1
            message: @state.draftMessage
            print: yes
            totalMessagesInConversation: 1
            isTotalNumberInfoBoxHidden: yes

  _deleteRejectedMessageConfModal: ->
    # NOTE - this was borrowed from the Letter Composer,
    # could probably make a reusable "ConfirmationModal" component, will
    # do that if I get a chance...
    if @state.showDeleteRejectedMessageConfModal
      Modal
        onClose: => @setState showDeleteRejectedMessageConfModal: false
        top: false
        e '.conf-modal',
          e '.conf-modal-header', t 'pageLetterCenter.deleteRejectedMessageConfHeader'
          e '.conf-modal-text', t 'pageLetterCenter.deleteRejectedMessageConfText'
          e '.conf-modal-buttons',
            e '.conf-modal-button',
              Button
                onClick: =>
                  if @props.hasRejectedMessage
                    rejectedMessage = @props.rejectedMessages[0]
                    if @props.revisionDrafts[rejectedMessage.thread]
                      @props.dispatch messagesActions.delete @props.revisionDrafts[rejectedMessage.thread]._id
                    @props.dispatch messagesActions.delete rejectedMessage._id, =>
                      @setState showDeleteRejectedMessageConfModal: false
                      @props.dispatch messagesActions.fetchUserClassroomMessages @props.classroom, true
                size: 'large'
                type: 'regular',
                t 'pageLetterCenter.deleteConfYesButtonTitle'
            e '.conf-modal-button',
              Button
                onClick: => @setState showDeleteRejectedMessageConfModal: false
                size: 'large'
                type: 'regular',
                t 'pageLetterCenter.deleteConfNoButtonTitle'


  _viewRejectedMessageModal: ->
    if @state.rejectedMessage
      rejectedMessage = Object.assign {}, @state.rejectedMessage
      if @props.revisionDrafts[rejectedMessage.thread]
        rejectedMessage.text = @props.revisionDrafts[rejectedMessage.thread].text
      e '.modal',
        Modal
          maxHeight: yes
          onClose: => @setState rejectedMessage: null
          LetterModal
            events: events
            indexOfMessageInConversation: 1
            message: rejectedMessage
            print: yes
            totalMessagesInConversation: 1
            isTotalNumberInfoBoxHidden: yes

  _viewLettersModal: ->
    # The .slice().reverse() operations are because the ClassroomLetterModal expects the
    # ordering to be the reverse of what is displayed in the Letter Exchange...
    # combineInboxAndSentMessages() is only used (for now) for ClassroomLetterModal and returns
    # messages in the order it expects...
    # similarly the letterIndex is transformed (using lastMessageIndex) because of the reversal
    if @state.messageIndex?
      sentMessages = @props.sentMessages.filter ({status}) -> status isnt 'rejected'
        .slice().reverse()
      inboxMessages = @props.inboxMessages.slice().reverse()
      lastMessageIndex = if @state.modalKey is 'inboxMessages'
        inboxMessages.length - 1
      else
        sentMessages.length - 1
      ClassroomLetterModal
        bothLetters: combineInboxAndSentMessages(@props.inboxMessages, @props.sentMessages)
        penpal1Letters: inboxMessages
        penpal2Letters: sentMessages
        penpal1Name: if @props.mode is 'mentor' then t 'letterComposerTabView.defaultStudent' else t 'letterComposerTabView.defaultPal'
        penpal2Name: 'Me'
        onClose: => @setState modalKey: null, messageIndex: null
        tabIndex: if @state.modalKey is 'inboxMessages' then 0 else 1
        letterIndex: lastMessageIndex - @state.messageIndex # because lists are reversed...

  constructor: (props) ->
    super()
    props
    @state =
      modalKey: null
      selectedTabIndex: 0
      messageIndex: null
      draftMessage: null
      prevDraftMessage: null
      rejectedMessage: null


  componentDidMount: ->
    @setState selectedTabIndex: 1 if @props.location.query?.tab is 'sent'
    @props.dispatch messagesActions.setRejectedLetter undefined
    @props.dispatch messagesActions.fetchUserClassroomMessages @props.classroom, true
    args = classroomID: @props.classroom._id, from: @props.userId
    @props.dispatch messagesActions.getNewDraftMessage args


  onMessageClick: (key) -> (message) =>
    return unless message
    if message.status is 'rejected'
      events.letter.began {type: 'revision'}
      return @props.dispatch messagesActions.setRejectedLetter message,
        @props.dispatch routing.redirect 'letters/compose'

    if key is 'inboxMessages' and message.state is 'delivered'
      @props.dispatch messagesActions.readMessage message

    events.write.viewLetter()
    messages = @props[key].filter ({status}) -> status isnt 'rejected'
    messageIndex = _.findIndex(messages, {'id': message.id})
    @setState messageIndex: messageIndex, modalKey: key


  render: ->
    e '.student-letter-center-container',
      @_deleteRejectedMessageConfModal()
      @_deletePrevDraftConfModal()
      @_deleteDraftConfModal()
      @_viewLettersModal()
      @_viewRejectedMessageModal()
      @_viewPrevDraftModal()
      @_viewDraftModal()
      e '.student-letter-center-message'
        @renderLetterCenterCard()
      e '.student-letter-center-tab',
        @renderLetterCenterTabView()


  renderLetterCenterCard: =>
    isInitialState = @props.sentMessages.length is 0
    isIntroUnit = @props.classroom?.currentUnit?.isDefaultUnit
    deleteButtonTitle =
      if @props.hasRejectedMessage
        t 'pageLetterCenter.cardDeleteRejectedMessageButtonTitle'
      else
        t 'pageLetterCenter.cardDeleteDraftButtonTitle'
    viewButtonTitle =
      if @props.hasRejectedMessage
        t 'pageLetterCenter.cardViewRejectedLetterButtonTitle'
      else
        t 'pageLetterCenter.cardViewDraftButtonTitle'
    LetterCenterCard
      showButton: @props.userState.pal?
      showDeleteButton: @props.hasRejectedMessage or @props.hasPrevDraft or @props.hasDraft
      deleteButtonOnClick: =>
        if @props.hasRejectedMessage
          @setState showDeleteRejectedMessageConfModal: true
        else if @props.hasPrevDraft
          @setState showDeletePrevDraftConfModal: true
        else
          @setState showDeleteDraftConfModal: true
      deleteButtonTitle: deleteButtonTitle
      viewButtonTitle: viewButtonTitle
      viewButtonOnClick: =>
        if @props.hasRejectedMessage
          @setState rejectedMessage: formatLetter('rejected') @props.rejectedMessages[0]
        else if @props.hasPrevDraft
          @setState prevDraftMessage: formatLetter('draft') @props.prevDraftMessage
        else
          @setState draftMessage: formatLetter('draft') @props.draftMessage
        #@onMessageClick('prevDraftMessages') formatLetter()@props.prevDraftMessage)
      buttonOnClick: =>
        type = if isInitialState then 'first' else 'new'
        events.letter.began {type}
        if @props.hasRejectedMessage
          @props.dispatch messagesActions.setRejectedLetter @props.rejectedMessages[0],
              @props.dispatch routing.redirect 'letters/compose'
        else
          @props.dispatch routing.redirect 'letters/compose'
      buttonTitle: cardButtonTitleForRole @props.mode, @props.hasRejectedMessage, @props.hasDraft, @props.hasPrevDraft, isInitialState, @props.userState
      classroomIsActive: @props.classroomIsActive
      content: cardContentForRole @props.mode, @props.hasRejectedMessage, @props.hasDraft, @props.hasPrevDraft, isInitialState, isIntroUnit, @props.userState
      loaded: @props.messages.data?
      subtitle: cardSubtitleForRole @props.mode, isInitialState, @props.dueDates, @props.userState
      title: cardTitleForRole @props.mode, @props.hasRejectedMessage, @props.hasDraft, isInitialState, @props.firstname, @props.userState


  renderLetterCenterContent: (data, messagesKey, columnKey, placeholder) ->
    columns = [
        flex: 1
        header: 'Date'
        isSortingDisabled: true
        key: 'date'
        onValueClick: @onMessageClick messagesKey
        transformValue: (data) ->
          e '.letter-list-column',
            className: bold: messagesKey is 'inboxMessages' and data['state'] is 'delivered'
            data.date
      ,
        flex: 1
        header: columnKey
        isSortingDisabled: true
        key: columnKey
        onValueClick: @onMessageClick messagesKey
        transformValue: (data) ->
          e '.letter-list-column',
            className: bold: messagesKey is 'inboxMessages' and data['state'] is 'delivered'
            data[columnKey]?.profile?.firstname or data[columnKey]?.firstname
      ,
        flex: 3
        header: 'Subject'
        isSortingDisabled: true
        key: 'subject'
        onValueClick: @onMessageClick messagesKey
        transformValue: (data) ->
          e '.letter-list-column',
            className: bold: messagesKey is 'inboxMessages' and data['state'] is 'delivered'
            data['subject']
    ]

    statusColumn =
      flex: 1
      header: 'Status'
      isSortingDisabled: true
      key: 'status'
      onValueClick: @onMessageClick messagesKey
      transformValue: (data) =>
        status = data.status
        unless status is 'rejected'
          if data.letterRejected?
            status =
              if status is 'approved'
                t 'pageLetterCenter.statusRevisionApproved'
              else
                t 'pageLetterCenter.statusRevisionSent'
          else
            status =
              if status is 'approved'
                t 'pageLetterCenter.statusApproved'
              else
                t 'pageLetterCenter.statusSent'
        else
          if @props.revisionDrafts[data.thread]
            status = t 'pageLetterCenter.statusRevisionInProgress'
          else
            status = t 'pageLetterCenter.statusNeedsRevision'
        #else if status is 'tobereviewed'
          #status = t 'pageLetterCenter.statusPendingMatch'
        e '.letter-list-sent-status',
          className: isRejected: data.status is 'rejected'
          status

    # @props.mode isnt 'mentor' and
    columns.push statusColumn if  messagesKey is 'sentMessages'

    if data.length is 0
      e '.letter-center-placeholder',
        Placeholder
          icon:
            e '.placeholder-icon-container',
              e '.placeholder-icon'
          text: placeholder
    else
      e '.letter-center-letter-list',
        Table
          columns: columns
          data: data
          isPagingEnabled: false


  renderLetterCenterTabView: =>
    TabView
      onSelect: (index) => @setState selectedTabIndex: index
      selectedIndex: @state.selectedTabIndex
      tabs: [
        content: @renderLetterCenterContent @props.inboxMessages, 'inboxMessages', 'from',
          inboxPlaceholderForRole @props.mode
        name:
          e '.inbox-title',
            className: hasUnread: _.find(@props.inboxMessages, ({state}) -> state is 'delivered')?
            t 'pageLetterCenter.tabInbox'
      ,
        content: @renderLetterCenterContent @props.sentMessages, 'sentMessages', 'to',
          sentPlaceholderForRole @props.mode
        name:
          e '.reject-title',
            className: hasRejected: @props.hasRejectedMessage
            t 'pageLetterCenter.tabSent'
      ]

LetterCenter.displayName = "LetterCenter"

module.exports = build {
  component: LetterCenter
  mapStateToProps
}
