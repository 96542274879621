{Component, createElement, createFactory} = require 'react'
PropTypes = require 'prop-types'
e = require 'react-e'
editorUploadActions = require 'actions/editor_upload'
connect = require 'utils/hocs/connect'
ContentLoader = createFactory require 'components/shared/content_loader'
Editor = createFactory require 'components/shared/editor'

mapStateToProps = ({store}) -> uploadPolicyStore: store.editorUploadPolicy

class EditorContainer extends Component

  @propTypes:
    allowAttachments: PropTypes.bool
    uploadPolicyStore: PropTypes.shape
      data: PropTypes.object
      error: PropTypes.string
      loading: PropTypes.bool

  componentDidMount: ->
    {dispatch} = @props
    dispatch editorUploadActions.getUploadPolicy()


  render: ->
    {data, error, loading} = @props.uploadPolicyStore
    ContentLoader
      error: error
      loading: loading
      name: 'editorUploadPolicy'
      ready: data?
      renderChild: => Editor _.assign {}, @props, uploadPolicy: data

EditorContainer.displayName = "EditorContainer"

module.exports = connect(mapStateToProps) EditorContainer
