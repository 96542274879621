style = require './date_picker.styl'
require 'react-dates/lib/css/_datepicker.css'
e = require('react-e/bind') style
{DATE_FORMAT} = require 'utils/date_normalizer'
{assign} = require 'lodash'
({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')

{SingleDatePicker} = require 'react-dates'
SingleDatePicker = createFactory SingleDatePicker

moment = require 'moment'

class DatePicker extends Component

  constructor: (props) ->
    super()
    @state =
      date: if props.date? then moment props.date else undefined
      focused: props.focused


  @defaultProps:
    dateFormat: DATE_FORMAT
    focused: no
    initialVisibleMonthFallback: moment()


  @propTypes:
    date: PropTypes.any
    dateFormat: PropTypes.string
    focused: PropTypes.bool
    id: PropTypes.string.isRequired
    initialVisibleMonthFallback: PropTypes.instanceOf moment
    isOutsideRange: PropTypes.func
    onSelected: PropTypes.func
    readOnly: PropTypes.bool.isRequired


  onDateChange: (date) =>
    @setState {date}
    @props.onSelected date


  onFocusChange: ({focused}) =>
    @setState {focused}


  render: ->
    e '.datepicker',
      if @props.readOnly
        e '.read-only',
          if @state.date?
            @state.date.format @props.dateFormat
          else
            e '.not-chosen', t 'datePicker.notChosen'
      else
        SingleDatePicker
          date: @state.date
          displayFormat: @props.dateFormat
          focused: @state.focused
          id: @props.id
          initialVisibleMonth: => @state.date or @props.initialVisibleMonthFallback
          isOutsideRange: @props.isOutsideRange
          numberOfMonths: 2
          onDateChange: @onDateChange
          onFocusChange: @onFocusChange
          placeholder: @props.placeholder

DatePicker.displayName = "DatePicker"

module.exports = DatePicker
