import getAppName from 'utils/app_name'

events = require 'pages/shared/utils/events'
style = require './localization_helper.styl'
e = (require 'react-e/bind') style

usersActions = require 'actions/users'

welcomeVideoIdDefault = '1_7uqeqzfz'
studentWelcomeVideoIdDefault = '1_2xozh7y9'
allAboutMeLinkDefault = 'https://cm-cdn.s3.amazonaws.com/crickettogether/units/GettingStarted/resources/student' +
      '/CT-AllAboutMe-student.pdf'

welcomeVideoCoverDefault = 'https://cm-cdn.s3.amazonaws.com/crickettogether/images/Cricket-Together' +
  '-still2-retina.png'

module.exports = ({dispatch, userId, classroom, tenant}) ->

  updateAppState = (appState) ->
    dispatch usersActions.updateAppState {id: userId, classroomId: classroom._id, appState}

  actionItems: [
    active: ({teacherMessagesUnread}) ->
      teacherMessagesUnread
    icon: 'message'
    redirect: '/mail'
    text: t 'actionItems.teacher.action1.text'
  ,
    active: ({introUnit, initialSurveyComplete, initialSurveyId}) ->
      introUnit and not initialSurveyComplete and initialSurveyId
    icon: 'write-letter'
    redirect: "/survey/#{classroom.initialSurveyIdTeacher}/#{classroom._id}/#{userId}?type=initial"
    text: t 'actionItems.teacher.action2.text', appName: getAppName(tenant.data, classroom)
  ,
    active: ({introUnit, welcomeVideoViewed}) ->
      introUnit and not welcomeVideoViewed #not letterSent
    event: -> events.clickedVideo 'action item'
    done: -> updateAppState welcomeVideoViewed: true
    icon: 'watch-video'
    text: t 'actionItems.teacher.action3.text'
    video: tenant.data.teacher?.welcomeVideoId ? welcomeVideoIdDefault
  ,
    active: ({introUnit, teacherGuideRead}) ->
      introUnit and not teacherGuideRead
    done: -> updateAppState teacherGuideRead: true
    icon: 'guide'
    link: ({currentUnitTeacherGuide}) ->
            currentUnitTeacherGuide
    text: t 'actionItems.teacher.action4.text', appName: getAppName(tenant.data, classroom)
  ,
    active: ({introUnit, studentWelcomeVideoViewed}) ->
      introUnit and not studentWelcomeVideoViewed #not letterSent
    done: -> updateAppState studentWelcomeVideoViewed: true
    icon: 'watch-video'
    text: t 'actionItems.teacher.action5.text', appName: getAppName(tenant.data, classroom)
    video: tenant.data.student?.welcomeVideoId ? studentWelcomeVideoIdDefault
  ,
    active: ({dateBlank, introUnit}) ->
      introUnit and dateBlank
    icon: 'calendar'
    redirect: '/units/current/edit'
    text: t 'actionItems.teacher.action6.text'
  ,
    active: ({introUnit, studentWritingGuideRead}) ->
      introUnit and not studentWritingGuideRead #not letterSent
    done: -> updateAppState studentWritingGuideRead: true
    icon: 'pdf'
    link: tenant.data.teacher?.allAboutMe ? allAboutMeLinkDefault
    text: t 'actionItems.teacher.action7.text'
  ,
    actionText: 'Review'
    active: ({introUnit, studentLetterPending}) ->
      introUnit and studentLetterPending
    icon: 'read-letter'
    redirect: '/units/current/letters'
    text: t 'actionItems.teacher.action8.text'
  ,
    actionText: 'Review'
    active: ({introUnit, mentorLetterPending}) ->
      introUnit and mentorLetterPending
    icon: 'read-letter'
    redirect: '/units/current/letters'
    text: t 'actionItems.teacher.action9.text'
  ,
    active: ({introUnit}) ->
      #TODO: remove "no and" add a condition for:
      # - checking messages sent by teacher
      # - students and mentors are matched
      no and introUnit
    icon: 'message'
    text: t 'actionItems.teacher.action10.text'
    video: '???'  # TODO "Show modal with Message Center screencast" - pending video
  ,
    active: ({introUnit, letterApprovedForAllStudents}) ->
      no and introUnit and letterApprovedForAllStudents  # TODO - pending video
    icon: 'watch-video'
    text: t 'actionItems.teacher.action11.text'
    video: '???'  # TODO "Show modal with Selecting and Starting a New Unit video" - pending video
  ,
    active: ({introUnit, letterApprovedForAllMentors, pendingUnits}) ->
      introUnit and letterApprovedForAllMentors and not pendingUnits
    icon: 'unit'
    redirect: '/units/explore'
    text: t 'actionItems.teacher.action12.text'
  ,
    active: ({introUnit, unitVideoViewed, currentUnit}) ->
      not introUnit and currentUnit.unitVideoTeacher?.id and not unitVideoViewed
    event: -> events.clickedVideo 'action item'
    done: -> updateAppState {"#{classroom?.currentUnit?.id}": {unitVideoViewed: true}}
    icon: 'watch-video'
    text: t 'actionItems.teacher.action13.text', unitVideoDesc: classroom?.currentUnit?.unitVideoTeacher?.description ? 'Watch a video'
    video: classroom?.currentUnit?.unitVideoTeacher?.id
  ,
    active: ({introUnit, unitGuidesRead, currentUnitId}) ->
      not introUnit and not unitGuidesRead.includes currentUnitId
    done: ({unitGuidesRead, currentUnitId}) ->
        readUnits = unitGuidesRead[..]
        readUnits.push currentUnitId unless readUnits.includes currentUnitId
        updateAppState unitGuidesRead: readUnits
    icon: 'guide'
    link: ({currentUnitTeacherGuide}) ->
      currentUnitTeacherGuide
    text: t 'actionItems.teacher.action14.text'
  ,
    active: ({dateBlank, introUnit}) ->
      dateBlank and not introUnit
    icon: 'calendar'
    redirect: '/units/current/edit'
    text: t 'actionItems.teacher.action15.text'
  ,
    actionText: 'Review'
    active: ({articleSelectionPending, introUnit}) ->
      articleSelectionPending and not introUnit
    icon: 'article'
    redirect: '/units/current/focus_articles'
    text: t 'actionItems.teacher.action16.text'
  ,
    actionText: 'Review'
    active: ({introUnit, mentorLetterPending}) ->
      mentorLetterPending and not introUnit
    icon: 'read-letter'
    redirect: '/units/current/letters'
    text: t 'actionItems.teacher.action17.text'
  ,
    actionText: 'Review'
    active: ({introUnit, studentLetterPending}) ->
      studentLetterPending and not introUnit
    icon: 'read-letter'
    redirect: '/units/current/letters'
    text: t 'actionItems.teacher.action18.text'
  ,
    active: ({introUnit, reflectionUnit, letterApprovedForAllMentors, pendingUnits}) ->
      letterApprovedForAllMentors and not introUnit and not reflectionUnit and not pendingUnits
    icon: 'unit'
    redirect: '/units/explore'
    text: t 'actionItems.teacher.action19.text'
  ,
    active: ({currentUnit, finalSurveyComplete, finalSurveyId}) ->
      currentUnit.isReflectionUnit and not finalSurveyComplete and finalSurveyId
    icon: 'write-letter'
    redirect: "/survey/#{classroom.finalSurveyIdTeacher}/#{classroom._id}/#{userId}?type=final"
    text: t 'actionItems.teacher.action20.text', appName: getAppName(tenant.data, classroom)

  ]

  topContents: [
    # "Intro State 1"
    action: 'Start Unit'
    active: ({introUnit}) ->
      introUnit
    description: t 'topContents.teacher.top1.desc', appName: getAppName(tenant.data, classroom)
    event: -> events.clickedVideo 'top card'
    hideFlag: yes
    image: tenant.data.teacher?.welcomeVideoCover ? welcomeVideoCoverDefault
    redirect: '/units/current'
    video: tenant.data.teacher?.welcomeVideoId ? welcomeVideoIdDefault
    welcome: t 'topContents.teacher.top1.welcome', appName: getAppName(tenant.data, classroom)
  ,
    # "Intro State 2"
    active: ({introUnit, mentorLetterSent}) ->
      introUnit and mentorLetterSent
    welcome: t 'topContents.teacher.top2.welcome'
  ,
    # "Intro State 3"
    active: ({introUnit, mentorLetterPending}) ->
      introUnit and mentorLetterPending
    welcome: t 'topContents.teacher.top3.welcome'
  ,
    # "Non-Intro State 1"
    active: ({hasFocusArticle}) -> hasFocusArticle
    welcome: e 'div',
      t 'topContents.teacher.top4.welcome.line1'
      e 'br'
      t 'topContents.teacher.top4.welcome.line2'
      e 'ul.bullets',
        e 'li', t 'topContents.teacher.top4.welcome.bullet1'
        e 'li', t 'topContents.teacher.top4.welcome.bullet2'
        e 'li', t 'topContents.teacher.top4.welcome.bullet3'
  ,
    # "Non-Intro State 2"
    active: ({articleSelectionApproved, introUnit}) ->
      articleSelectionApproved and not introUnit
    welcome: e 'span',
      t 'topContents.teacher.top5.welcome.line1'
      e 'br'
      t 'topContents.teacher.top5.welcome.line2'
  ,
    # "Non-Intro State 3"
    active: ({articleSelectionApproved, introUnit, mentorLetterSent}) ->
      articleSelectionApproved and mentorLetterSent and not introUnit
    welcome: t 'topContents.teacher.top6.welcome'
  ,
    # "Completed State 1"
    active: ({classroomStatusIsCompleted}) ->
      classroomStatusIsCompleted
    hideFlag: yes
    image: 'http://cm-cdn.s3.amazonaws.com/crickettogether/images/CT_completed.jpg'
    welcome: t 'topContents.teacher.top7.welcome', appName: getAppName(tenant.data, classroom), email: tenant.data.contactEmail ? 'support@crickettogether.com'
  ]
