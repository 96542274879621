({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './tab_view.styl'
e = (require 'react-e/bind') style
classNames = require 'classnames/bind'
bindStyles = classNames.bind style
{findIndex, includes, map} = require 'lodash'
#{TabPanel} = require 'create-factory!react-tabs'
{Tabs,TabList, TabPanel, Tab} = require 'react-tabs'

#Tabs.setUseDefaultStyles no
TabsComponent = createFactory(Tabs)

TabView = ({modalOnClose, onSelect, selectedIndex, tabs, withPadding = yes}) ->
  selected = selectedIndex or 0 #if selectedIndex
  TabsComponent
    className: bindStyles 'tab-view'
    onSelect: onSelect
    selectedIndex: if onSelect? then selected else undefined
    selectedTabPanelClassName: bindStyles 'selected'
    <TabList className={bindStyles 'tab-view'}>
      {map tabs, (tab, i) ->
        <Tab
          className={classNames [
            bindStyles 'tab-view'
            uneditable: if tab.editable? then not tab.editable else no
          ]}

          key={i}>
          {tab.name}
        </Tab>}
    </TabList>
    map tabs, ({content}, i) ->
      <TabPanel
        className={bindStyles {'with-padding': withPadding}, 'tab-view'}
        key={i}>
        {if modalOnClose
          <div className={bindStyles 'content'}>
            <div className={bindStyles 'close'} onClick={modalOnClose}>×</div>
            {content}
          </div>
        }
        { unless modalOnClose
            content
        }
      </TabPanel>

TabView.displayName = "TabView"

module.exports = TabView
