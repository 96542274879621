({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
moment = require 'moment'
style = require './focus_article_status_cell.styl'
e = require('react-e/bind') style
{formatRejectionDate} = require 'pages/teacher/classroom_unit/normalization_helper'
events = require 'pages/teacher/classroom_unit/events'

Button = createFactory require 'components/shared/button'
Popup = createFactory require 'components/shared/popup'

class FocusArticleStatusCell extends Component

  constructor: (props) ->
    super props
    @state =
      rejectPopupLeft: 0
      rejectPopupTextfieldValue: ''
      rejectPopupTop: 0
      rejectReasonPopupLeft: 0
      rejectReasonPopupTop: 0


  @propTypes =
    article: PropTypes.string
    onApproveButtonClick: PropTypes.func
    onRejectButtonClick: PropTypes.func
    readOnly: PropTypes.bool
    rejectDate: PropTypes.string
    rejectReason: PropTypes.string
    status: PropTypes.oneOf(['approved', 'pending', 'rejected', 'missing'])


  _pendingCell: ->
    e '.status-cell-buttons',
      e '.approved',
        onClick: => @props.onApproveButtonClick?()
      e '.rejected',
        onClick: @onRejectButtonClick


  _popup: =>
    Popup
      bindPopup: (popup) => @popup = popup
      left: @state.rejectPopupLeft
      offsetLeft: -142
      offsetTop: 10
      top: @state.rejectPopupTop,
      e '.rejected-popup-container',
        e '.rejected-caption', t 'focusArticleStatusCell.explain'
        e 'textarea.rejected-textfield', onBlur: @onRejectedTextfieldValueChange
        e '.rejected-buttons',
          e '.buttons-container',
            Button mode: 'secondary', onClick: @onRejectPopupCancelClick, type: 'regular',
              t 'focusArticleStatusCell.cancel'
            Button mode: 'primary', onClick: @onRejectPopupSendNoteClick, type: 'regular',
              t 'focusArticleStatusCell.sendNote'


  _rejectReasonPopup: =>
    Popup
      bindPopup: (popup) => @rejectReasonPopup = popup
      left: @state.rejectReasonPopupLeft
      offsetLeft: -130
      offsetTop: 10
      top: @state.rejectReasonPopupTop,
      e '.reject-reason-popup-container',
        e '.reject-reason-popup-content',
          e '.reject-reason-caption', t 'focusArticleStatusCell.rejectReasonCaption'
          e '.reject-reason', @props.rejectReason
          e '.reject-date', formatRejectionDate @props.rejectDate


  _rejectedCell: =>
    e '.status-rejected',
      e '.status', t 'focusArticleStatusCell.rejected'
      e '.rejected-details',
        onMouseEnter: @onRejectReasonHover
        onMouseLeave: => @rejectReasonPopup?.closePopup()


  _statusCellForStatus: (status) ->
    switch status
      when 'approved' then e '.status-approved', t 'focusArticleStatusCell.approved'
      when 'pending'
        if @props.readOnly
          e '.status-pending', t 'focusArticleStatusCell.pending'
        else
          @_pendingCell()
      when 'rejected' then @_rejectedCell()
      when 'missing' then e '.status-missing', t 'focusArticleStatusCell.missing'
      else '-'


  onRejectButtonClick: (e) =>
    events.focusArticle.clickedRejected {article: @props.article}
    bodyRect = document.body.getBoundingClientRect()
    targetRect = e.target.getBoundingClientRect()
    @setState
      rejectPopupLeft: targetRect.left - bodyRect.left
      rejectPopupTop: targetRect.bottom - bodyRect.top,
      =>
        @popup?.openPopup()


  onRejectPopupCancelClick: =>
    events.focusArticle.canceledRejection {article: @props.article}
    @popup?.closePopup()


  onRejectPopupSendNoteClick: ({articleTitle, characterCount}) =>
    events.focusArticle.rejected
      article: @props.article
      value: @state.rejectPopupTextfieldValue.length
    @props.onRejectButtonClick? @state.rejectPopupTextfieldValue
    @popup?.closePopup()


  onRejectReasonHover: (e) =>
    bodyRect = document.body.getBoundingClientRect()
    targetRect = e.target.getBoundingClientRect()
    @setState
      rejectReasonPopupLeft: targetRect.left - bodyRect.left
      rejectReasonPopupTop: targetRect.bottom - bodyRect.top,
      =>
        @rejectReasonPopup?.openPopup()


  onRejectedTextfieldValueChange: (target) =>
    @setState rejectPopupTextfieldValue: target.target.value


  render: ->
    e '.focus-article-status-cell',
      @_statusCellForStatus(@props.status)
      @_popup()
      @_rejectReasonPopup()

FocusArticleStatusCell.displayName = "FocusArticleStatusCell"

module.exports = FocusArticleStatusCell
