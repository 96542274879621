({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './footer_cards.styl'
e = require('react-e/bind') style

Card = createFactory require 'components/shared/card'
Button = createFactory require 'components/shared/button'
Link = createFactory require 'containers/link'

class FooterCards extends Component

  @propTypes = do ->
    LinkType = PropTypes.shape
      label: PropTypes.string.isRequired
      to: PropTypes.string
      href: PropTypes.string
    cards: PropTypes.arrayOf( PropTypes.shape
      iconClass: PropTypes.string.isRequired
      label: PropTypes.string.isRequired
      link: LinkType
      message: PropTypes.string
      quickLinks: PropTypes.arrayOf LinkType
    ).isRequired
    dispatchRedirect: PropTypes.func.isRequired


  render: ->
    {cards, dispatchRedirect} = @props

    e '.footer-cards', cards.map ({iconClass, label, link, message, quickLinks}, i) ->
      e '.card-outer', key: i,
        Card
          icon: e 'div', className: iconClass
          e '.card-inner',
            e '.label', label
            e '.body',
              if message?
                e 'p.message', message
              if quickLinks?
                e 'ul.full-width',
                  quickLinks.map ({href, label, to}, i) ->
                    e 'li', key: i, Link {href, to}, label
            if link?
              e '.card-bottom',
                Button
                  onClick: -> dispatchRedirect link.to
                  size: 'large'
                  type: 'regular'
                  link.label

FooterCards.displayName = "FooterCards"

module.exports = FooterCards
