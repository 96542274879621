analytics = require 'utils/analytics'

category =
  addStudent: 'Add Student'
  uploadRoster: 'Upload Roster'

module.exports =

  addingIndividual: ->
    analytics.recordEvent
      action: "Clicked 'Add Student' Button"
      category: category.addStudent


  canceledAddIndividual: ->
    analytics.recordEvent
      action: 'Canceled Add Student'
      category: category.addStudent


  clickedUploadRoster: ->
    analytics.recordEvent
      action: "Clicked 'Upload Roster' Button"
      category: category.uploadRoster


  downloadedTemplate: ->
    analytics.recordEvent
      action: "Downloaded 'Class Roster Template'"
      category: category.uploadRoster


  savedIndividual: ->
    analytics.recordEvent
      action: 'Added Students'
      category: category.addStudent


  uploadAddedStudents: ->
    analytics.recordEvent
      action: 'Added Students'
      category: category.uploadRoster


  uploadCanceledAdd: ->
    analytics.recordEvent
      action: 'Canceled Upload'
      category: category.uploadRoster


  uploadedTemplate: ->
    analytics.recordEvent
      action: 'Uploaded Template'
      category: category.uploadRoster
