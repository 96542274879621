import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import UserHelpers from "utils/user_helpers";
import Modal from 'components/shared/modal';
import Button from 'components/shared/button';
import MentorProfileForm from './profile-form';
const style = require('./onboarding.styl');
const store = require('store');
const usersActions = require('actions/users');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
const {redirect} = require('actions/routing');
const countries = require('utils/countries.json');

const mapStateToProps = ({store: {user, sessions, classrooms, tenant}}) => ({
  user: UserHelpers.getTransformedUser(user.data, sessions.data, classrooms.data, tenant.data),
});

class MentorOnboardingBackgroundCheck extends React.Component {

  constructor(props) {
    super(props);

    const hasAllRequiredProfileFields = UserHelpers.hasAllRequiredProfileFields(props.user);
    this.state = {
      confirmed: false,
      success: false,
      error: false,
      message: '',
      showProfileModal: !hasAllRequiredProfileFields,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitProfile = this.onSubmitProfile.bind(this);
    this.onClickEditProfile = this.onClickEditProfile.bind(this);
  }

  onChange(field, val) {
    this.setState((state) => {
      state[field] = val;
      return state;
    });
  }

  onClickEditProfile() {
    this.setState({showProfileModal: true});
  }

  onSubmitProfile(newUserData) {
    const {user, dispatch} = this.props;
    dispatch(usersActions.update(newUserData, (error) => {
      if (error) {
        console.log({error});
        let message = null;
        if (error instanceof Error) {
          message = error.message;
        } else {
          message = error.reason;
        }
        this.setState({error: true, success: false, message});
      } else {
        this.setState({showProfileModal: false});
        const newStore = store.getState().store;
        const userState = UserHelpers.getTransformedUser(
            newStore.user.data, newStore.sessions.data, newStore.classrooms.data, newStore.tenant.data);

        if (!userState.emailVerified) {
          dispatch(redirect('/onboarding/verify'));
          return;
        }

        const missingFields = UserHelpers.getMissingProfileFields(userState);
        if (missingFields.length === 0) {
          dispatch(redirect('/onboarding/background'));
        }
      }
    }));
  }

  onSubmit(ev) {
    const {user, dispatch} = this.props;
    const userId = user.id;
    const test = ev.ctrlKey;
    dispatch(usersActions.backgroundcheck(userId, test, (error) => {
      if (error) {
        console.log({error});
        this.setState({error: true, message: error.statusText});
      }
    }));
  }

  onCloseProfileModal() {
    const {user} = this.props;
    if (UserHelpers.hasAllRequiredProfileFields(user)) {
      this.setState({showProfileModal: false})
    }
  }

  renderExpired() {
    return (
        <React.Fragment>
          Your background check has expired.
        </React.Fragment>
    );
  }

  renderMissingProfileData() {
    return (
        <React.Fragment>
          {t('onboarding.background.editprofile1')}&nbsp;
          <a onClick={this.onClickEditProfile}>{t('onboarding.background.editprofileLink')}</a>&nbsp;
          {t('onboarding.background.editprofile2')}
        </React.Fragment>
    );
  }

  renderProfileModal() {
    const {user} = this.props;
    if (this.state.showProfileModal) {
      return (
          <Modal onClose={() => this.onCloseProfileModal()} top={true}>
            <div className={cn('modal')}>
              <div className={cn('modal-header')}>
                {t('onboarding.background.editHeading')}
              </div>
              <div className={cn('modal-text')}>
                {t('onboarding.background.instructionsModal')}
              </div>
              <MentorProfileForm user={user}
                                 type='light'
                                 showDemographics={false}
                                 onSubmit={this.onSubmitProfile}/>
            </div>
          </Modal>
      );
    }
    return null;
  }

  renderRequest() {
    const {user} = this.props;
    const countryName = countries[user.country] || 'No country specified';
    return (
        <React.Fragment>
          <div className={cn('instructions')}>
            {t('onboarding.background.instructions')}
          </div>
          <ul>
            <li>{user.firstname} {user.lastname}</li>
            {user.country === 'US' &&
            <li>{user.city}, {user.state} {user.zipcode}</li>
            }
            {user.country !== 'US' &&
            <li>{user.city}, {user.state}</li>
            }
            <li>{countryName}</li>
            <li>Email: {user.email}</li>
          </ul>
          <i>{t('onboarding.background.editInformationPrompt')}</i>
          <a onClick={this.onClickEditProfile}>{t('onboarding.background.editInformationLink')}</a>
          <div className={cn('confirmation')}>
            <input type="checkbox" id="confirmed"
                   value="yes" onChange={(ev) => this.onChange('confirmed', ev.target.checked)}/>
            <label htmlFor="confirmed">
              {t('onboarding.background.checkboxLabel')}
            </label>
          </div>
          <div className={cn('instructions2')}>{t('onboarding.background.instructions2')}</div>
          <div>
            <Button enabled={this.state.confirmed} onClick={(ev) => this.onSubmit(ev)} submit={true} type="regular">
              Submit
            </Button>
          </div>
        </React.Fragment>
    );
  }

  renderSubmitted() {
    const {user, dispatch} = this.props;
    let message = t('onboarding.background.submittedUS');
    if( user.country !== 'US') {
      message = t('onboarding.background.submittedIntl');
    }
    return (
        <React.Fragment>
          {message}
          <div>
            <Button type='regular'
                    onClick={() => dispatch(redirect('/onboarding'))}>
              Continue
            </Button>
          </div>
        </React.Fragment>
    );
  }

  renderValid() {
    const {user} = this.props;
    const backgroundCheck = user.backgroundCheck;
    if (backgroundCheck.status === 'passed') {
      const expiresFormatted = moment(backgroundCheck.expires).format('MMMM Do, YYYY');
      return (
          <React.Fragment>
            Your background check is valid until {expiresFormatted}
          </React.Fragment>
      );
    }
  }

  render() {
    const {user, dispatch} = this.props;
    const hasAllRequiredFields = UserHelpers.hasAllRequiredProfileFields(user);
    const backgroundCheck = user.backgroundCheck;
    return (
        <div className={cn('onboarding-page', 'onboarding-background')}>
          {this.renderProfileModal()}
          <h2>
            {t('onboarding.background.heading')}
            <Button mode='transparent'
                    onClick={() => dispatch(redirect('/onboarding'))}
                    size='small'
                    type='regular'>
              <span className={cn('icon-back')} />Back
            </Button>
          </h2>
          <div className={cn('back-button')}>
          </div>
          <div className={cn('container')}>
            <div className={cn('error', {visible: !!this.state.error})}>{this.state.message}</div>
            {backgroundCheck && backgroundCheck.isValid && this.renderValid()}
            {backgroundCheck && backgroundCheck.isSubmitted && this.renderSubmitted()}
            {!backgroundCheck && !hasAllRequiredFields && this.renderMissingProfileData()}
            {(!backgroundCheck || backgroundCheck.status === 'cancelled' || backgroundCheck.isExpired) && hasAllRequiredFields && this.renderRequest()}
          </div>
        </div>
    );
  }
}

MentorOnboardingBackgroundCheck.displayName = "MentorOnboardingBackgroundCheck";

MentorOnboardingBackgroundCheck.propTypes = {
  user: PropTypes.object,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
};

module.exports = build({
  component: MentorOnboardingBackgroundCheck,
  mapStateToProps
});
