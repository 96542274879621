import React from 'react';
const PropTypes = require('prop-types');
const {arrayOf, bool, element, func, oneOf, shape, string} = PropTypes;
const {formatValue} = require('utils/article_normalizer');
const _ = require('lodash');
const style = require('./content_preview.styl');
const cn = require('classnames/bind').bind(style);

class ContentPreview extends React.Component {

  render() {
    const {description, image, isSelected, links, metadata, onClick, onSelect, scheme, selectType, title, type, focus} = this.props;
    const metadataWithValues = _.filter(metadata, item => item.value);
    const imageSize = this.props.imageSize || 'cover';

    return (
        <div className={cn('content-preview', [scheme, {rejected: ['disabled', 'rejected'].includes(focus)}])}>
          <div className={cn('image')}>
            <a className={cn('content-image')} onClick={onClick} style={{
              'backgroundImage': `url(${image})`,
              'backgroundSize': imageSize
            }}>
            </a>
            {focus === 'approved' &&
            <div className={cn('focus')}>
              <p className={cn('focus-article')}>{t('contentPreview.approved')}</p>
            </div>
            }
            {focus === 'disabled' &&
            <div className={cn('focus')}>
              <p className={cn('focus-article')}>{t('contentPreview.disabled')}</p>

            </div>
            }
            {focus === 'pending' &&
            <div className={cn('focus')}>
              <p className={cn('focus-article')}>{t('contentPreview.pending')}</p>
            </div>
            }
          </div>
          <div className={cn('content-container', type)}>
            {selectType === 'check' &&
            <div className={cn('checkbox')} onClick={onSelect}>
              <div className={cn('check', 'icon-check', {selected: isSelected})}/>
            </div>
            }
            {selectType === 'radio' &&
            <div className={cn('radio-button')} onClick={onSelect}>
              <div className={cn('fill', {selected: this.props.isSelected})}/>
            </div>
            }

            <div className={cn('content-header')}>
              <a className={cn('content-title')} onClick={onClick}>{title}</a>
              <div className={cn('content-metadata-container')}>
                {metadataWithValues.map(({name, value}, i) => {
                  return (
                      <div className={cn('content-metadata-information')} key={i}>
                        <div className={cn('content-metadata-information')}>
                          <div className={cn('content-metadata-name')}>{`${name}: `}</div>
                          <div className={cn('content-metadata-value')}>{formatValue(value)}</div>
                        </div>
                      </div>
                  );
                })}
              </div>
              <div className={cn('content-description')} title={description}>{description}</div>
              <div className={cn('links', type)}>{links}</div>
            </div>
          </div>
        </div>
    );
  }
}

ContentPreview.displayName = "ContentPreview";
ContentPreview.propTypes = {
  description: string.isRequired,
  focus: oneOf(['approved', 'disabled', 'pending', 'rejected', 'none']),
  image: string.isRequired,
  links: element,
  metadata: arrayOf(shape({
        name: string,
        value: string
      })
  ),
  scheme: (oneOf(['plain', 'shadow'])).isRequired,
  selectType: oneOf(['radio', 'check']),
  isSelected: bool,
  onSelect: func,
  title: string.isRequired,
  type: oneOf(['article', 'unit', 'training']).isRequired,
  imageSize: oneOf(['cover', 'contain'])
};

module.exports = ContentPreview;
