moment = require 'moment'
({Component} = require 'react') and (PropTypes = require 'prop-types')
style = require './unit_preview.styl'
e = require('react-e/bind') style
{formatDate} = require 'utils/date_normalizer'

class UnitPreview extends Component

  @propTypes:
    endDate: PropTypes.string
    subjects: PropTypes.array,
    strands: PropTypes.array,
    id: PropTypes.string
    image: PropTypes.string.isRequired
    name: PropTypes.string.isRequired
    onClick: PropTypes.func.isRequired
    startDate: PropTypes.string.isRequired

  _renderDate: (date) ->
    return e '.not-chosen', 'Not Chosen' unless date
    formatDate date


  render: ->
    e '.unit-preview',
      e '.unit-header',
        onClick: @props.onClick
        style: backgroundImage: "url(#{@props.image})"
      e '.unit-body',
        e '.unit-title', onClick: @props.onClick, @props.name
        e '.unit-meta',
          e 'span.key', t 'unitPreview.subjects'
          e 'span.val', @props.subjects.map((x) -> x.name).join(', ')
        e '.unit-meta',
          e 'span.key', t 'unitPreview.strands'
          e 'span.val', @props.strands.map((x) -> x.name).join(', ')
      e '.unit-footer',
        e '.line-accent'
        e '.unit-date',
          e '.label', t 'unitPreview.startDate'
          e '.date', @_renderDate(@props.startDate)
        e '.unit-date',
          e '.label', t 'unitPreview.endDate'
          e '.date', @_renderDate(@props.endDate)

UnitPreview.displayName = "UnitPreview"

module.exports = UnitPreview
