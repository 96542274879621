_ = require 'lodash'
{makeAction} = require 'utils/action_helpers'
editorUploadService = require '../services/editor_upload_service.coffee'

module.exports =
  getUploadPolicy: -> makeAction (setState) ->
    setState 'editorUploadPolicy', loading: true
    editorUploadService.getUploadPolicy()
      .then (data) ->
        setState 'editorUploadPolicy', data: JSON.parse(data), loading: false
      .catch (error) ->
        setState 'editorUploadPolicy', error: error, loading: false
