createClass = require 'create-react-class'
PropTypes = require 'prop-types'
{assign} = require 'lodash'
e = require 'react-e'

EReader = createClass


  displayName: 'PDF Viewer'


  getConfig: ->
    AnnotationToolsVisible: false
    AutoAdjustPrintSize: true
    CursorToolsVisible: false
    EnableWebGL: true
    FitPageOnLoad: true
    FitWidthOnLoad: true
    FullScreenAsMaxWindow: false
    IMGFiles: "#{@props.filename}/Paper.pdf_{page}.png"
    InitViewMode: ''
    JSONFile: "#{@props.filename}/Paper.js"
    MaxZoomSize: 5
    MinZoomSize: 0.2
    NavToolsVisible: true
    PrintPaperAsBitmap: true
    ProgressiveLoading: false
    RenderingOrder: 'htmlflash'
    Scale: 0.6
    SearchMatchAll: false
    SearchToolsVisible: false
    StartAtPage: ''
    ViewModeToolsVisible: true
    WMode: 'transparent'
    ZoomInterval: 0.1
    ZoomTime: 0.5
    ZoomToolsVisible: true
    ZoomTransition: 'easeOut'
    key: FLOWPAPERKEY
    localeChain: 'en_US'


  propTypes:
    filename: PropTypes.string.isRequired


  render: ->
    e 'iframe',
      height: '100%'
      src: "#{require('./frame.html')}?#{JSON.stringify @getConfig()}"
      width: '100%'

EReader.displayName = "EReader"

module.exports = EReader
