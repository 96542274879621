({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './print_letters.styl'
e = require('react-e/bind') style
{sanitizeHtml} = require 'utils/sanitize_html'
{printLetter} = require './print_helper'
Frame = createFactory require('react-frame-component').default
{build} = require 'utils/container_helpers'

import getAppName from 'utils/app_name'

mapStateToProps = ({store: {tenant, classroom}}) -> {tenant, classroom}

class PrintLetters extends Component

  @propTypes =
    bulk: PropTypes.bool
    className: PropTypes.string.isRequired
    events: PropTypes.object
    letters: PropTypes.arrayOf PropTypes.shape
      date: PropTypes.string.isRequired
      from: PropTypes.string.isRequired
      subject: PropTypes.string.isRequired
      text: PropTypes.string
      to: PropTypes.string
    reduced: PropTypes.bool
    role: PropTypes.string
    unitType: PropTypes.string


  render: ->
    e '.container',
      Frame
        className: @props.className
        style:
          height: '0px'
          position: 'absolute'
          width: '0px'
        e '.print-letters',
          @props.letters.map @renderPrintMode
      e 'a.print-link',
        onClick: =>
          if @props.events?.write?
            @props.events.write.print()
          else if @props.events?.letterExchange?
            @props.events.letterExchange.print
              role: @props.role
              unit: @props.unitType
          printLetter(@props.className)
        if @props.bulk then t 'letterModal.printLetters' else t 'letterModal.print'


  renderPrintMode: ({date, from, subject, text, to}, i) =>
    e '.printed-letter-content-column', key: i,
      e 'img.logo',
        alt: getAppName(@props.tenant?.data, @props.classroom?.data) || t 'printLetter.logo'
        src: '/assets/' + TENANT + '/logo.svg'
        style:
          margin: '0 0 0.25in 0'
          width: '2in'
      e '.letter-content-container',
        e '.letter-content-header',
          e '.content-row',
            t 'letterModal.printTo',
              to: to or ''
          e '.content-row',
            t 'letterModal.printFrom',
              from: from
          e '.content-row',
            t 'letterModal.printDate',
              date: date
          e '.content-row',
            t 'letterModal.printUnitName',
              unitName: subject
        e 'hr',
          style:
            margin: '0.25in 0 0.25in 0'
        e '.letter-content',
          className: {'content-reduced': @props.reduced}
          dangerouslySetInnerHTML: __html: sanitizeHtml text or ''

PrintLetters.displayName = "PrintLetters"

module.exports = build {
  component: PrintLetters
  mapStateToProps
}
