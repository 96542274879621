({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './home.styl'
e = require('react-e/bind') style

events = require './events.coffee'
ActionItems = createFactory require './action_items'
FooterCards = createFactory require './footer_cards'
TopContent = createFactory require './top_content'
UnitProgressBar = createFactory require 'components/shared/unit_progress_bar'

class Home extends Component

  @defaultProps =
    extraActionItems: []


  @propTypes:
    actionItems: PropTypes.arrayOf( PropTypes.shape
      active: PropTypes.any.isRequired
    ).isRequired
    classroom: PropTypes.object.isRequired
    context: PropTypes.object.isRequired
    currentUnit: PropTypes.object.isRequired
    extraActionItems: PropTypes.array
    progress: PropTypes.object
    role: PropTypes.string
    teacherCards: PropTypes.arrayOf(PropTypes.any)
    topContents: PropTypes.arrayOf( PropTypes.shape
      active: PropTypes.any
    ).isRequired
    unitPage: PropTypes.string



  componentDidMount: ->
    events.setDimensions
      classroom: @props.classroom.data?.name
      unit: @props.classroom.data?.currentUnit?.name


  render: ->
    {actionItems, currentUnit, dispatchRedirect, firstName, palFirstName, footerCards, progress, role,
     showOwl, teacherCards, teacherLinks, topContents, unitPage} = @props

    activeActionItems = @_evaluateAndFilter {actionItems}
    activeTopContents = @_evaluateAndFilter {topContents}
    classroomIsActive = @props.classroom.data.status is 'active'

    e '.home', className: "#{role}-home",
      e '.top-content', TopContent
        classroomIsActive: classroomIsActive
        currentUnit: currentUnit
        dispatchRedirect: dispatchRedirect
        firstName: firstName
        showOwl: showOwl
        teacherCards: teacherCards
        topContent: _.last(activeTopContents) ? {}
        unitPage: unitPage
      if classroomIsActive
        [
          e '.progress-bar', key: 'progress-bar', UnitProgressBar
            unit: currentUnit
            progress: progress
            role: role
            palFirstName: palFirstName
            teacherLinks: teacherLinks
          e '.action-items', key: 'action-items', ActionItems
            actionItems: activeActionItems
            dispatchRedirect: dispatchRedirect
          e '.footer-cards', key: 'footer-cards', FooterCards
            cards: footerCards
            dispatchRedirect: dispatchRedirect
        ]

  _evaluateAndFilter: (myItems) ->
    {context, extraActionItems} = @props
    items = _.values(myItems)[0]
      .map (item) ->
        _.mapValues item, (value, key) ->
          if typeof(value) isnt 'function' or key is 'event'
            item[key]
          else if key is 'done'
            -> item[key] context
          else
            item[key] context
      .filter (item) -> item.active
    items = items.concat extraActionItems if 'actionItems' in Object.keys(myItems)
    items

Home.displayName = "Home"

module.exports = Home
