module.exports =
  store:
    articleSelections:
      data: undefined
      error: undefined
      loading: false
    classroom:
      data: undefined
      error: undefined
      loading: false
    classroomStudent:
      data:
        fields:
          firstname: undefined
          gender: undefined
          lastname: undefined
          password: undefined
          username: undefined
        uploadErrors: {}
        uploadMetrics: undefined
        uploadStatus: undefined
        validationErrors:
          firstname: undefined
          gender: undefined
          lastname: undefined
          password: undefined
          username: undefined
      error: undefined
      loading: false
    classroomUnit:
      data: undefined
      error: undefined
      loading: false
    classroomUnits:
      data: undefined
      error: undefined
      loading: false
    classrooms:
      data: undefined
      error: undefined
      loading: false
    editorUploadPolicy:
      data: undefined
      error: undefined
      loading: false
    letterComposer:
      newDraftMessage: undefined
      rejectedLetter: undefined
    mailCount:
      data: undefined
      error: ''
      loading: false
    mailHtml:
      data: undefined
      error: undefined
      loading: false
    messages:
      data: undefined
      error: ''
      loading: false
    messagesForClassroom:
      data: undefined
      error: ''
      loading: false
    messagesForClassroomUnit:
      data: undefined
      error: ''
      loading: false
    resourcesHtml:
      data: undefined
      error: undefined
      loading: false
    session:
      data: undefined
      error: undefined
      loading: false
    sessions:
      data: undefined
      error: undefined
      loading: false
    tenant:
      data: undefined
      error: undefined
      loading: false
    unitLocked:
      data: undefined
      error: undefined
      loading: false
    unitTemplate:
      data: undefined
      error: undefined
      loading: false
    unitTemplates:
      data: undefined
      error: undefined
      loading: false
    user:
      data: undefined
      error: undefined
      loading: false
    userManagement:
      users:
        data: []
        error: undefined
        loading: no
