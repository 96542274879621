import React from 'react';
import PropTypes from 'prop-types';
const style = require('./login.styl');
const cn = require('classnames/bind').bind(style);
const events = require('containers/header_nav_container/events');
const {some} = require('lodash');
import sessionActions from 'actions/session';
import { redirect } from 'actions/routing';
import Button from '../button';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      impersonateUsername: this.props.impersonateUsername || '',
      password: '',
      userError: this.props.error,
      username: '',
      modified: { username: false, password: false },
    };
  }

  onChange(field, val) {
    this.setState((state) => {
      state[field] = val;
      state.modified[field] = true;
      state.userError = null;
      return state;
    });
  }

  onSubmit(event) {
    event.preventDefault();
    const {loading, onSubmit, impersonate} = this.props;
    const {impersonateUsername, password, username, userError} = this.state;
    const impersonateUsernameError = impersonate && (impersonateUsername.length === 0);
    const usernameError = username.length === 0;
    const passwordError = password.length === 0;
    const invalid = some([usernameError, passwordError, impersonateUsernameError, userError]);
    this.setState({username});
    if (!invalid && !loading) {
      return onSubmit({password, username}, impersonateUsername);
    }
  }

  static getDerivedStateFromProps({error}, state) {
    if (state.userError === null) {
      return {userError: undefined};
    }
    return {userError: error};
  }

  render() {
    const { username, password, impersonateUsername, userError, modified } = this.state;

    const tenant = this.props.tenant;
    const impersonateUsernameError = this.props.impersonate && (impersonateUsername.length === 0);
    const usernameError = username.length === 0;
    const passwordError = password.length === 0;

    let errorMessage;
    if (usernameError && passwordError) {
      errorMessage = t('login.enterCredentialsPrompt');
    } else if (usernameError) {
      errorMessage = t('login.enterUsernamePrompt');
    } else if (passwordError) {
      errorMessage = t('login.enterPasswordPrompt');
    } else if (impersonateUsernameError) {
      errorMessage = t('login.enterImpersonateUsername');
    } else if (userError && userError.status === 404) {
      errorMessage = (
          <p>
            {t('login.errorForgotUsername')}&nbsp;
            <a className={cn('link')} href={Login.forgotUsername}>{t('login.errorForgotUsernameLink')}</a>
          </p>
      );
    } else if (userError && userError.status === 403) {
      errorMessage = (
          <p>
            {t('login.errorForgotPassword')}&nbsp;
            <a className={cn('link')} href={Login.forgotPassword}>{t('login.errorForgotPasswordLink')}</a>
          </p>
      );
    } else if (userError && userError.status === 406) {
      errorMessage = (
          <p>
            {t('login.errorAdminLogin')}&nbsp;
            <a className={cn('link')} href={Login.impersonate}>{t('login.errorForgotImpersonateLink')}</a>
          </p>
      );
    } else if (userError && userError.status === 412) {
      errorMessage = <p>{t('login.errorNotInClassroom',{supportEmail: tenant.contactEmail})}</p>;
    } else if (userError && userError.status === 417) {
      const textId = (tenant.id === 'epub') ? 'login.errorTrialComplete' : 'login.errorUserInactive';
      errorMessage = (
          <p>
            {t(textId, {appName: tenant.name, supportEmail: tenant.contactEmail})}&nbsp;
          </p>
      );
    } else if (userError) {
      errorMessage = userError.statusText;
    }

    const showErrorUsername = modified.username && (usernameError || (userError && userError.status === 404));
    const showErrorPassword = modified.password && (passwordError || (userError && userError.status === 403));
    const showErrorImpersonate = impersonateUsernameError;
    return (
        <form className={cn('login-form')} onSubmit={this.onSubmit}>
          <div className={cn('login-container')}>
            <h2 className={cn('login')}>{this.props.loginText || t('login.logIn')}</h2>
            {this.props.loading &&
            <div className={cn('loading', {active: this.props.loading})}>{t('login.loading')}</div>
            }
            {modified.username && modified.password && errorMessage &&
            <div className={cn('message')}>{errorMessage}</div>
            }
            {this.props.impersonate &&
            <div className={cn('impersonating')}>
              <h4 className={cn('label')}>{t('login.impersonatingUser')}</h4>
              <input className={cn('input', {error: showErrorImpersonate})}
                     autoFocus={this.props.impersonate && !this.props.impersonateUsername}
                     onChange={(event) => this.onChange('impersonateUsername', event.target.value )}
                     tabIndex={1}
                     type='text'
                     value={this.state.impersonateUsername}/>
            </div>
            }
            <h4 className={cn('label')}>
              {t(this.props.impersonate ? 'login.administrator' : 'login.username')}
            </h4>
            <input className={cn('input', {error: showErrorUsername})}
                   autoFocus={!this.props.impersonate || this.props.impersonateUsername}
                   onChange={(event) => this.onChange( 'username', event.target.value)}
                   tabIndex={2}
                   type='text'
                   value={this.state.username}/>
            <a className={cn('forgot')} href={Login.forgotUsername}>{t('login.forgotUsernameLink')}</a>
            <h4 className={cn('label')}>{t('login.password')}</h4>
            <input className={cn('input', {error: showErrorPassword})}
                   onChange={(event) => this.onChange( 'password', event.target.value )}
                   tabIndex={3}
                   type='password'
                   value={this.state.password}/>
            <a className={cn('forgot')} href={Login.forgotPassword}>{t('login.forgotPasswordLink')}</a>
            <div className={cn('remember-me')}>
              <input className={cn('remember-input')} id='remember-me' type='checkbox' value='None'/>
              <label className={cn('remember-label')} htmlFor='remember-me'>{''}</label>
              <p className={cn('remember-caption')}>{t('login.rememberMeCaption')}</p>
            </div>
            {this.props.showRegLink &&
              <a className={cn('forgot')} href={Login.register}>{t('login.registerLink')}</a>
            }
            <div className={cn('submit')}>
              <Button fullWidth={true} onClick={this.onSubmit} submit={true} type={'regular'}>
                {t('login.submitButtonLabel')}
              </Button>
            </div>
          </div>
        </form>
    );
  }
}

Login.forgotPassword = '/forgot/password';
Login.forgotUsername = '/forgot/username';
Login.impersonate = '/impersonate';
Login.register = '/register';
Login.displayName = "Login";
Login.propTypes = {
  error: PropTypes.shape({
    status: PropTypes.number.isRequired,
    statusText: PropTypes.string.isRequired
  }),
  impersonate: PropTypes.bool,
  impersonateUsername: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  loginText: PropTypes.string,
  showRegLink: PropTypes.bool,
  tenant: PropTypes.object.isRequired,
};

module.exports = Login;
