React = {component, createFactory, createElement} = require 'react'
style = require './units_dropdown.styl'
e = (require 'react-e/bind') style
events = require 'pages/teacher/units/events'

Link = createFactory require 'containers/link'


UnitsDropdown = ->
  e '.hover-menu-container',
    e '.hover-menu',
      e '.hover-menu-spacer'
      e '.hover-menu-items',
        e '.hover-menu-item',
          Link onClick: (-> events.exploringUnits 'Navigation Bar'), to: '/units/explore',
            t 'unitsDropdown.explore'
        e '.hover-menu-item', Link to: '/units', t 'unitsDropdown.units'
        e '.hover-menu-item',
          Link onClick: (-> events.editUnit 'current', 'Navigation Bar'), to: '/units/current/edit',
            t 'unitsDropdown.editCurrent'
        e '.hover-menu-item', Link to: '/units/current/focus_articles',
          t 'unitsDropdown.focusArticlesCurrent'
        e '.hover-menu-item', Link to: '/units/current/letters', t 'unitsDropdown.lettersCurrent'

UnitsDropdown.displayName = "UnitsDropdown"

module.exports = UnitsDropdown
