import React from 'react';
import PropTypes from 'prop-types';
const _ = require('lodash');
const style = require('./letter_composer_tips.styl');
const cn = require('classnames/bind').bind(style);
const {sanitizeHtmlWithoutImageStyle: sanitizeHtml} = require('utils/sanitize_html');
require('details-element-polyfill');

import Card from 'components/shared/card';
import ModalWrapper from 'components/shared/modal/modal_wrapper';
import LetterModal from 'components/letter_modal';

import EReader from 'components/shared/e_reader';
import Button from 'components/shared/button';

// not sure why both the mentor and students use the student events
// when they record the reading of an article in other parts of the
// code, but doing the same here until I can determine whether this is
// a bug or not...
const readArticleEvent = (require('pages/student/units/events')).read;

export default class LetterComposerTips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      openStates: {
        checklist: false,
        importantWords: false,
        referenceLetter: (props.referenceLetter != null),
        sampleLetter: (props.referenceLetter == null),
        thinkingQuestions: false,
        tips: false
      }
    };

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.openPanel = this.openPanel.bind(this);
    this._makeSection = this._makeSection.bind(this);
    this._makeListSection = this._makeListSection.bind(this);
    this._makeLetterSection = this._makeLetterSection.bind(this);
  }

  _listItems(name, itemName, listItems = null) {
    const items = this.props[name] != null ? this.props[name] : listItems;
    return (
        <ul className={cn(`content`, `${itemName}s`)}>
          {items.map((item, index) => {
            return (
                <li className={cn(itemName)} key={index}>
                  <div className={cn('text')}>
                    {item.tip != null ? item.tip : item}
                    {item.subTips && item.subTips.length && this._listItems(null, 'subTip', item.subTips)}
                  </div>
                </li>
            );
          })}
        </ul>
    );
  }

  _makeLetterSection(name, fullContent, print) {
    return this._makeSection(name,
        <div className={cn('content')}>
          {fullContent &&
          <ModalWrapper fullSize={true}
                        trigger={
                          <img className={cn('fullscreen')}
                               onClick={() => {
                                 if (name === 'referenceLetter') {
                                   return this.props.events.tips.expandedPenPalLetter();
                                 } else {
                                   return this.props.events.tips.expandedSampleLetter();
                                 }
                               }}
                               src={'/assets/open-modal.svg'}/>
                        }>
            <LetterModal events={this.props.events}
                         isInReviewMode={false}
                         isTotalNumberInfoBoxHidden={true}
                         message={fullContent}
                         contentClass={name}
                         print={print}/>
          </ModalWrapper>
          }
          <div className={cn('inner-content')}
               dangerouslySetInnerHTML={{__html: sanitizeHtml(fullContent ? fullContent.text : '')}}/>
        </div>
    );
  }

  _makeListSection(name, itemName) {
    if ((this.props[name] != null ? this.props[name].length : undefined) > 0) {
      return this._makeSection(name, this._listItems(name, itemName));
    }
  }

  _makeSection(name, content) {
    return (
        <details open={this.state.openStates[name]} className={cn(`section`, name)}>
          <summary className={cn('caption')}
                   onClick={(e) => {
                     e.preventDefault();
                     if (!this.state.openStates[name]) {
                       if (name === 'referenceLetter') {
                         this.props.events.tips.penPalLetter();
                       } else {
                         this.props.events.tips[name]();
                       }
                     }
                     return this.openPanel(name);
                   }}>
            {t(`letterComposerTips.${name}`)}
          </summary>
          {content}
        </details>
    );
  }


  componentDidUpdate(prevProps) {
    if (prevProps.referenceLetter !== this.props.referenceLetter) {
      return this.setState({
        openStates: Object.assign({}, this.state.openStates, {
              isReferenceLetterOpen: (this.props.referenceLetter != null),
              isSampleLetterOpen: (this.props.referenceLetter == null)
            }
        )
      });
    }
  }


  openPanel(section) {
    const {
      openStates
    } = this.state;
    const newState = !openStates[section];
    const allKeys = Object.keys(openStates);
    allKeys.forEach(key => openStates[key] = false);
    openStates[section] = newState;
    return this.setState({openStates});
  }


  render() {
    return (
        <div className={cn('letter-composer-tips')}>
          <Card icon={<div className={cn('tips-resources-icon')}/>}>
            <article className={cn('tips-resources-container')}>
              <div className={cn('tips-resources-title')}>{t('letterComposerTips.tipsAndResources')}</div>
              {this.props.focusArticle &&
              <div className={cn('focus-article-button')}>
                <ModalWrapper show={this.props.showFocusArticleImmediately}
                              eReader={true}
                              trigger={
                                <Button
                                    onClick={(articleTitle) => {
                                      return readArticleEvent(articleTitle)
                                    }}
                                    type='regular'>
                                  {t('articlePreview.readFocusArticle')}
                                </Button>
                              }>
                  <EReader filename={this.props.focusArticle.filename}/>
                </ModalWrapper>
              </div>
              }
              {this._makeListSection('thinkingQuestions', 'question')}
              {this._makeListSection('importantWords', 'important-word')}
              {this._makeListSection('checklist', 'to-do')}
              {this._makeListSection('tips', 'tip')}
              {this._makeLetterSection('sampleLetter', {text: this.props.sampleLetter}, false)}
              {this.props.referenceLetter &&
              this._makeLetterSection('referenceLetter', this.props.referenceLetter, true)
              }
            </article>
          </Card>
        </div>
    );
  }
}

LetterComposerTips.displayName = "LetterComposerTips";

LetterComposerTips.propTypes = {
  checklist: PropTypes.arrayOf(PropTypes.string),
  events: PropTypes.object.isRequired,
  importantWords: PropTypes.arrayOf(PropTypes.string),
  referenceLetter: PropTypes.object,
  sampleLetter: PropTypes.string,
  thinkingQuestions: PropTypes.arrayOf(PropTypes.string),
  tips: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  unitName: PropTypes.string,
  focusArticle: PropTypes.object,
  showFocusArticleImmediately: PropTypes.bool,
  role: PropTypes.string
};
