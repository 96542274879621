api = require '../api'
_ = require 'lodash'

module.exports =

  buildFetchPath: (args = {}, path) ->
    newPath = path
    filters = Object.keys args
    _.forEach filters, (filter, i) ->
      if args[filter]?
        newPath += "#{unless i and newPath isnt path then '?' else '&'}#{filter}=#{args[filter]}"
    newPath
