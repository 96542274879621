api = require 'api'
{buildFetchPath} = require 'utils/service_helpers'


module.exports =

  create: (data) ->
    api.send
      method: 'POST'
      path: '/api/v2/messages'
      payload: data


  delete: (data) ->
    api.send
      method: 'DELETE'
      path: "/api/v2/messages/#{data._id}"


  fetch: (args = {}) ->
    api.send
      method: 'GET'
      path: buildFetchPath args, '/api/v2/messages'


  fetchNumUnreadMessages: (id, classroomID) ->
    api.send
      method: 'GET'
      path: buildFetchPath {classroomID}, '/api/v1/messages/count'


  fetchUserClassroomMessages: (classroomId) ->
    api.send
      method: 'GET'
      path: "/api/v2/messages/user/classroom/#{classroomId}"


  get: (data) ->
    api.send
      method: 'GET'
      path: "/api/v2/messages/#{data._id}"


  update: (data) ->
    api.send
      method: 'PUT'
      path: "/api/v2/messages/#{data._id}"
      payload: data
      
