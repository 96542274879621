analytics = require 'utils/analytics'

category = 'Units'
teacherCategories =
  activeUnit: 'Current Unit'
  addUnit: 'Add Unit'
  editUnit: 'Edit Unit'
  completedUnit: 'Past Unit'
  currentUnit: 'Current Unit'
  newUnit: 'Explore Units'
  pendingUnit: 'Upcoming Unit'
  viewUnit: 'Explore Units'

module.exports =

  details: (article, mode) ->
    analytics.recordEvent
      action: 'Viewed Article Details'
      category: if mode then teacherCategories["#{mode}Unit"] else category
      label: article


  read: (article, mode) ->
    analytics.recordEvent
      action: 'Read Article'
      category: if mode then teacherCategories["#{mode}Unit"] else category
      label: article


  submit: (article) ->
    analytics.recordEvent
      action: 'Submit FA Selection'
      category: category
      label: article
