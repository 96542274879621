const _ = require('lodash');
const style = require('./register.styl');
const cn = require('classnames/bind').bind(style);
const store = require('store');
const {redirect} = require('actions/routing');
const {Component, Fragment} = require('react');

const Recaptcha = require('react-recaptcha');

const Button = require('components/shared/button');

const usersService = require('services/users_service');
const countries = require('utils/countries.json');

const {isUnauthenticated} = require('routing');
const {logout} = require('actions/session');
import ZipcodePicker from 'components/shared/zipcode_picker';
import { t } from 'i18n-js';


class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      firstname: "",
      lastname: "",
      parentfirstname: "",
      parentlastname: "",
      email: "",
      password: "",
      password2: "",
      country: "US",
      zipcode: "",
      city: "",
      occupation: "",
      gender: "",
      gender2: "",
      ethnicity: "",
      spanish: false,
      verified: false,
      success: false,
      error: false,
      eula: false,
      company: "",
      department: "",
      title: "",
      phone: "",
      mentorId: this.props.location.query.mid || null,
      classroomId: this.props.location.query.cid || null
    };

    this._refs = {};

    this.countryList = _.sortBy(countries);
    this.countryMap = _.invert(countries);

    this.countryOptions = this.countryList.map((c) =>
      <option key={this.countryMap[c]} value={this.countryMap[c]}>{c}</option>
    );

    if (!isUnauthenticated()) {
      store.dispatch(logout(() => {
        window.location.reload()
      }));
    }
  }


  setRef(field, e) {
    this._refs[field] = e;
  }

  navigateToSessionSignupPage() {
    let signupPageUrl = `/signup/${this.props.location.query.sessionId}?regDone=true`;
    if (this.props.location.query.regID) {
      signupPageUrl += `&regID=${this.props.location.query.regID}`;
    }
    store.dispatch(redirect(signupPageUrl));
  }

  navigateToLoginPage() {
    store.dispatch(redirect('/'));
  }

  updateField(field, ev, checkbox = false) {
    let state = {error: false, errorMsg: ""};
    state[field] = checkbox ? ev.target.checked : ev.target.value;
    this.setState(state);
    this._refs[field] && this._refs[field].setCustomValidity("");
  }

  onChangeZipcode(zipcode, zipcodeData) {
    const {city, state} = zipcodeData ? zipcodeData : {};
    this.setState({zipcode, city, error: false, errorMsg: ""})
  }

  verifyCallback(resp) {
    this.setState({verified: true});
  }

  expiredCallback(resp) {
    this.setState({verified: false});
  }

  loadCallback(resp) {
  }

  submitForm(ev) {

    ev.preventDefault();
    if (this.state.password === this.state.password2) {
      if (ev.target.form.reportValidity()) {

        const {
          username,
          firstname,
          lastname,
          parentfirstname,
          parentlastname,
          password,
          country,
          zipcode,
          city,
          gender2,
          occupation,
          ethnicity,
          company,
          department,
          title,
          phone,
          mentorId,
          classroomId
        } = this.state;
        let {email, gender, spanish} = this.state;
        let languages = [];
        const tenant = TENANT; // should be the same as the "id" field in tenant data
        const role = this.props.role || "mentor";
        const mode = role === 'student' ? "childregistration" : "registration"; // needed to distinguish from other user creation scenarios
        const parentname = role === 'student' ? `${parentfirstname} ${parentlastname}` : undefined;
        const parentemail = role === 'student' ? email : undefined;
        if (gender === "Custom") {
          gender = gender2;
        }

        if (spanish) {
          languages = ["es"];
        }

        if (role === 'student') {
          email = undefined;
        }

        usersService.create({
          username,
          firstname,
          lastname,
          parentname,
          parentemail,
          email,
          password,
          country,
          zipcode,
          city,
          occupation,
          gender,
          ethnicity,
          languages,
          company,
          department,
          title,
          phone,
          tenant,
          role,
          mode,
          mentorId,
          classroomId
        }, true).then((resp) => {
          this.setState({error: false, success: true});
        }).catch((error) => {
          console.dir(error);
          let msg = null;
          if (error instanceof Error) {
            msg = error.message;
          } else {
            msg = error.reason || error.statusText;
          }
          if (msg) {
            window.scrollTo({ top: 0, behavior: 'smooth'} );
          }
          if (role == 'student' && error.status === 403) {
            //special case for child accounts that have already been matched
            this.setState({error: true, success: true, errrorMsg: t('register.studentCreateError') });
          }
          else {
            this.setState({error: true, errorMsg: msg});
          }
        });
      }
    } else {
      this._refs['password2'].setCustomValidity("Password fields do not match");
      ev.target.form.reportValidity();
    }
  }

  renderRegisterForm(appName, hideOptionalFields, hideLocFields, hideBusinessFields, eulaUrl) {
    const role = this.props.role || "mentor";

    const enableSubmit = this.state.username.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password2.length > 0 &&
      this.state.firstname.length > 0 &&
      this.state.lastname.length > 0 &&
      (role !== 'student' || this.state.parentfirstname.length > 0 &&
        this.state.parentlastname.length > 0) &&
      this.state.email.length > 0 &&
      (!eulaUrl || this.state.eula) &&
      this.state.verified === true;


    const Star = () => <span className={cn('required')}>*</span>;

    return (
      <form>
        <div className={cn('error', {visible:this.state.error})}>{this.state.errorMsg || "Error in form data"}</div>
        <fieldset>
          <label htmlFor="firstname">{t(`register.${role}.firstName`)}<Star/></label>
          <input placeholder={t(`register.${role}.firstNamePlaceholder`)} id="firstname" className={cn('input')}
                 type="text" autoFocus={true} minLength="1" required={true} value={this.state.firstname}
                 onChange={(ev) => this.updateField('firstname', ev)}/><br/>

          <label htmlFor="lastname">{t(`register.${role}.lastName`)}<Star/></label>
          <input placeholder={t(`register.${role}.lastNamePlaceholder`)} id="lastname" className={cn('input')}
                 type="text" onChange={(ev) => this.updateField('lastname', ev)}
                 minLength="1" required={true} value={this.state.lastname}/><br/>

          <label htmlFor="username">{t('register.username')}<Star/></label>
          <input ref={(e) => this.setRef('username', e)}
                 placeholder={t('register.usernamePlaceholder')} id="username" className={cn('input')} type="text"
                 onChange={(ev) => this.updateField('username', ev)}
                 minLength="5" required={true} pattern="[_a-zA-Z0-9]+" value={this.state.username}/><br/>

          {role === 'student' &&
          <Fragment>
            <label htmlFor="parentfirstname">{t('register.parent.firstName')}<Star/></label>
            <input placeholder={t('register.parent.firstNamePlaceholder')} id="parentfirstname" className={cn('input')}
                   type="text" onChange={(ev) => this.updateField('parentfirstname', ev)} minLength="1" required={true}
                   value={this.state.parentfirstname}/><br/>
            <label htmlFor="parentlastname">{t('register.parent.lastName')}<Star/></label>
            <input placeholder={t('register.parent.lastNamePlaceholder')} id="parentlastname" className={cn('input')}
                   type="text" onChange={(ev) => this.updateField('parentlastname', ev)} minLength="1" required={true}
                   value={this.state.parentlastname}/><br/>
          </Fragment>
          }


          <label htmlFor="email">{t('register.email', {parent: role === 'student' ? "Parent/Guardian" : ""})}<Star/></label>
          <input placeholder={t('register.emailPlaceholder')} id="email" className={cn('input')} type="email"
                 onChange={(ev) => this.updateField('email', ev)} minLength="6" required={true}
                 value={this.state.email}/><br/>

          <label htmlFor="password">{t('register.password')}<Star/></label>
          <input placeholder={t('register.passwordPlaceholder')} id="password" className={cn('input')} type="password"
                 onChange={(ev) => this.updateField('password', ev)} minLength="1" required={true}
                 value={this.state.password}/><br/>

          <label htmlFor="password2">{t('register.password2')}<Star/></label>
          <input ref={(e) => this.setRef('password2', e)} placeholder={t('register.password2Placeholder')}
                 id="password2" className={cn('input')} type="password"
                 onChange={(ev) => this.updateField('password2', ev)} minLength="1" required={true}
                 value={this.state.password2}/><br/>

          {hideBusinessFields ||
          <Fragment>
            <label htmlFor="company">{t('register.company')}<Star/></label>
            <input placeholder={t('register.companyPlaceholder')} id="company" className={cn('input')} type="text"
                   onChange={(ev) => this.updateField('company', ev)} minLength="1" required={true}
                   value={this.state.company}/><br/>

            <label htmlFor="department">{t('register.dept')}<Star/></label>
            <input placeholder={t('register.deptPlaceholder')} id="department" className={cn('input')} type="text"
                   onChange={(ev) => this.updateField('department', ev)} minLength="1" required={true}
                   value={this.state.department}/><br/>

            <label htmlFor="title">{t('register.jobTitle')}<Star/></label>
            <input placeholder={t('register.jobTitlePlaceholder')} id="title" className={cn('input')} type="text"
                   onChange={(ev) => this.updateField('title', ev)} minLength="1" required={true}
                   value={this.state.title}/><br/>

            <label htmlFor="phone">{t('register.phone')}<Star/></label>
            <input placeholder={t('register.phonePlaceholder')} id="phone" className={cn('input')} type="tel"
                   onChange={(ev) => this.updateField('phone', ev)} minLength="1" required={true}
                   value={this.state.phone}/><br/>
          </Fragment>
          }
          {hideLocFields ||
          <Fragment>
            <label htmlFor="country">{t('register.country')}<Star/></label>
            <select id="country" value={this.state.country} className={cn('input', 'country', 'value')}
                    onChange={(ev) => this.updateField('country', ev)}>
              {this.countryOptions}
            </select><br/>

            {this.state.country === "US" &&
            <Fragment>
              <label htmlFor="zipcode" id="zipcode">{t('register.zipcode')}<Star/></label>
              <ZipcodePicker className={cn('zipcode-picker')}
                             inputClassName={cn('input')}
                             value={this.state.zipcode} required
                             onChange={(zipcode, zipcodeData) => this.onChangeZipcode(zipcode, zipcodeData)}/><br/>
            </Fragment>
            }
            <label htmlFor="city">{t('register.city')}<Star/></label>
            <input placeholder={t('register.cityPlaceholder')} id="city" className={cn('input')} type="text"
                   onChange={(ev) => this.updateField('city', ev)} minLength="2" required={true}
                   value={this.state.city}/><br/>
          </Fragment>
          }
          {hideOptionalFields ||
          <Fragment>
            <div className={cn('instructions', 'wide')}>{t('register.demo')}</div>

            <label htmlFor="occupation">{t('register.occupation')}</label>
            <input placeholder={t('register.occupationPlaceholder')} id="occupation" className={cn('input')} type="text"
                   onChange={(ev) => this.updateField('occupation', ev)} minLength="1"
                   value={this.state.occupation}/><br/>

            <label htmlFor="gender">{t('register.gender.title')}</label>
            <select id="gender" value={this.state.gender}
                    className={cn('gender', {value: this.state.gender !== ""})}
                    onChange={(ev) => this.updateField('gender', ev)}>
              <option value="">{t('register.gender.option1')}</option>
              <option value="Male">{t('register.gender.option2')}</option>
              <option value="Female">{t('register.gender.option3')}</option>
              <option value="Decline">{t('register.gender.option4')}</option>
              <option value="Custom">{t('register.gender.option5')}</option>
            </select><br/>

            {this.state.gender === "Custom" &&
            <Fragment>
              <input placeholder={t('register.gender.customPlaceholder')} id="gender2"
                     className={cn('gender2')} type="text" onChange={(ev) => this.updateField('gender2', ev)}
                     minLength="1" value={this.state.gender2} required /><br/>
            </Fragment>
            }

            <label htmlFor="ethnicity">{t('register.ethnicity.title')}</label>
            <select id="ethnicity" value={this.state.ethnicity}
                    className={cn( 'ethnicity', {value: this.state.ethnicity !== ""})}
                    onChange={(ev) => this.updateField('ethnicity', ev)}>
              <option value="">{t('register.ethnicity.option1')}</option>
              <option value="American Indian or Alaskan Native">{t('register.ethnicity.option2')}</option>
              <option value="Asian or Pacific Islander">{t('register.ethnicity.option3')}</option>
              <option value="Black, not of Hispanic origin">{t('register.ethnicity.option4')}</option>
              <option value="Hispanic">{t('register.ethnicity.option5')}</option>
              <option value="Middle Eastern">{t('register.ethnicity.option6')}</option>
              <option value="White, not of Hispanic origin">{t('register.ethnicity.option7')}</option>
            </select><br/>

            <div className={cn('wide')}>
              <input type="checkbox" className={cn('spanish')} id="spanish" value="es"
                     onChange={(ev) => this.updateField('spanish', ev, true)}/>
              <label htmlFor="spanish">
                I am proficient in Spanish, and willing to participate in a bilingual correspondence.
              </label>
            </div>
          </Fragment>
          }
        </fieldset>

        {eulaUrl &&
        <Fragment>
          <input type="checkbox" className={cn('eula')} id="eula" value="true"
                 onChange={(ev) => this.updateField('eula', ev, true)}/>
          <label htmlFor="eula">
            {t('register.eulaPre')}
            <a href={eulaUrl} target="_blank">{t('register.eulaLink', {appName})}</a>
          </label>
        </Fragment>
        }
        <Recaptcha sitekey={RECAPTCHA_SITEKEY} render="explicit" verifyCallback={(resp) => this.verifyCallback(resp)}
                   expiredCallback={() => this.expiredCallback()} onloadCallback={() => this.loadCallback()}/>
        <Button enabled={enableSubmit} onClick={(ev) => this.submitForm(ev)} submit={true} type="regular">Submit</Button>

        <div className={cn('instructions')}>{t('register.required')}</div>

        <div className={cn('instructions', 'privacy')}>{t('register.privacyPre')}<a
          href="https://cricketmedia.com/privacy/" target="_blank">{t('register.privacyLink')}</a>.
        </div>
      </form>
    );
  }

  renderSuccess(appName, contactEmail) {
    const role = this.props.role || "mentor";
    const sessionName = this.props.location.query.sessionName || "new";

    return (
      <div className={cn('success')}>
        <div className={cn('instructions')}>{(role === 'student' && this.state.error) ? t('register.studentCreateError') : t('register.success', {appName})}
          {role === 'student' && this.state.error && <span><a href={`mailto:${contactEmail}`}>{contactEmail}</a>.</span>}
        </div>
        {role === 'student' && !this.state.error &&
        <Fragment>
          <div className={cn('instructions')}>{t('register.studentLogin.message')}</div>
          <Button onClick={(ev) => this.navigateToLoginPage()}
                  type="regular">{t('register.studentLogin.btn')}</Button>
        </Fragment>
        }
        {role !== 'student' && this.props.location.query.sessionId &&
        <Fragment>
          <div className={cn('instructions')}>{t('register.session.signup', {appName, sessionName})}</div>
          <Button onClick={(ev) => this.navigateToSessionSignupPage()}
                  type="regular">{t('register.session.btn')}</Button>
        </Fragment>
        }
        {role !== 'student' && !this.props.location.query.sessionId &&
        <Fragment>
          <div className={cn('instructions')}>{t('register.emailVerify')}</div>
        </Fragment>
        }
      </div>
    );
  }

  render() {
    const tenant = store.getState().store.tenant.data;
    let contactEmail = "support@crickettogether.com";
    let appName = "Cricket Together";
    let hideOptionalFields = tenant.hideDemographicRegistrationFields !== undefined ? tenant.hideDemographicRegistrationFields : false;
    let hideLocFields = (tenant.hideLocationRegistrationFields !== undefined) ? tenant.hideLocationRegistrationFields : false;
    let hideBusinessFields = tenant.hideBusinessRegistrationFields !== undefined ? tenant.hideBusinessRegistrationFields : true;
    const eulaUrl = tenant.eulaUrl || null;

    if (this.props.role === 'student') {
      hideOptionalFields = hideLocFields = hideBusinessFields = true;
    }

    if (tenant) {
      contactEmail = tenant.contactEmail || contactEmail;
      appName = tenant.name || appName;
    }

    return (
      <div className={cn('register-page')}>
        <h2>{t('register.title', {appName})}</h2>
        <div className={cn('container', {success: this.state.success})}>
          {this.state.success ?
            this.renderSuccess(appName, contactEmail) :
            this.renderRegisterForm(appName, hideOptionalFields, hideLocFields, hideBusinessFields, eulaUrl)}
        </div>
        <div className={cn('contact')}>{t('register.contact')}&nbsp;
          <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
        </div>
      </div>
    );
  }
}

module.exports = Register;
