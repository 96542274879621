import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import countryRegions from 'country-region-data';

export default class RegionPicker extends React.Component {

  constructor(props) {
    super(props);
    this.countries = _.keyBy(countryRegions, 'countryShortCode');
  }

  render() {
    const {value, country, className, onChange, required} = this.props;
    if (country) {
      const countryInfo = this.countries[country];
      const regions = countryInfo['regions'];
      return (
          <select id="region" className={className}
                  value={value} required={required}
                  onChange={(ev) => {
                    onChange(ev.target.value)
                  }}>
            <option key='select' value={''}>-- Please select from list --</option>
            {regions.map((x) => {
              return (
                  <option key={x.shortCode} value={x.name}>{x.name}</option>
              );
            })}
          </select>
      );
    }
    return (
        <select id="region" className={className}
                value={value} required={required}
                onChange={(ev) => {
                  onChange(ev.target.value)
                }}>
          <option key='select' value={''}>-- Please select a country first --</option>
        </select>
    );
  }
};

RegionPicker.displayName = "RegionPicker";
RegionPicker.propTypes = {
  value: PropTypes.string,
  country: PropTypes.string.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

RegionPicker.defaultProps = {
  required: false,
};
