_ = require 'lodash'
{makeAction} = require 'utils/action_helpers'
classroomsService = require 'services/classrooms_service'

sessionActions = require 'actions/session'
articleSelectionsActions = require 'actions/article_selections'
messagesActions = require 'actions/messages'

classroomsActions =

  fetch: (args, callback) -> makeAction (setState) ->
    setState 'classrooms', loading: true
    classroomsService.fetch(args)
      .then ({data: classrooms}) ->
        setState 'classrooms', data: classrooms, loading: false
        callback? classrooms
      .catch (error) ->
        console.log 'EXCEPTION', error
        setState 'classrooms', error: error.statusText, loading: false


  find: (id, callback) -> makeAction (setState) ->
    # TODO: if classroom exists in classrooms, get it from there and return
    classroomsService.find(id)
      .then ({data: classrooms}) ->
        setState 'classroom', data: classrooms[0], loading: false
        callback? classrooms[0]
      .catch (error) ->
        setState 'classroom', error: error.statusText, loading: false


  loadCurrentClassroom: (callback) -> makeAction (setState, dispatch, getState) ->
    {session} = getState()
    dispatch classroomsActions.find session.data.classroomId, callback


  setClassroom: (classroomId, callback) -> makeAction (setState, dispatch, getState) ->
    {session, classrooms} = getState()
#    console.log "setClassroom #{classroomId}", {classrooms}
    dispatch sessionActions.set (_.merge {}, session.data, {classroomId}), ->
      if classrooms and classrooms.data.length > 0
        classroom = classrooms.data.find (x) -> x._id is classroomId
        setState 'classroom', data: classroom, loading: false
        setState 'articleSelections', data: undefined, loading: false
        callback?()
#        currentUnitId = classroom.currentUnit.id
#        dispatch articleSelectionsActions.fetch classroomUnit_id: currentUnitId, ->
##          dispatch messagesActions.fetchForClassroom classroom


  setError: (errorMessage, callback) -> makeAction (setState) ->
    setState 'classroom', error: errorMessage, loading: false


  update: (data, callback) -> makeAction (setState) ->
    classroomsService.update(data)
      .then(if callback? then callback)
      .catch (error) ->
        setState 'classrooms', error: error.statusText


module.exports = classroomsActions
