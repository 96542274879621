import React from 'react';
const {build} = require('utils/container_helpers');
import Footer from 'components/footer';
import HeaderNavContainer from 'containers/header_nav_container';
import ErrorCard from 'components/shared/error_card';

const tenantActions = require('actions/tenant');
const errorService = require('services/error_service');
const style = require('./container.styl');
const cn = require('classnames/bind').bind(style);
const {init: dateInit} = require('utils/date_normalizer');
const Bowser = require('bowser');

import getAppName from 'utils/app_name'

const mapStateToProps = ({store: {session, tenant, classroom}}) => ({
      session,
      tenant,
      classroom
    }
);

// Main app container
//   - captures exceptions and logs to backend
//   - fetches tenant and then
//   - renders main layout of app with header nav, body, and footer

class AppContainer extends React.Component {

  constructor(props) {
    super(props);

    const parser = Bowser.getParser(window.navigator.userAgent).parse();
    const browserInfo = parser ? parser.parsedResult : {};

    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      errorLocation: null,
      browserInfo
    };
  }

  componentDidMount() {
    const {tenant, dispatch} = this.props;
    if (tenant.data === undefined) {
      dispatch(tenantActions.fetch((tenant) => {
        if (tenant) {
          dateInit(tenant);
        }
      }));
    }
  }

  componentDidCatch(error, info) {
    // hopefully it's okay to send a fire-and-forget call to the backend to log the error
    // here...
    const {session} = this.props;
    const loginToken = (session.data && session.data.login) ? session.data.login.loginToken : null;
    const hashedToken = loginToken && loginToken.hashedToken;
    const loginTime = loginToken && loginToken.when;
    const message = error instanceof Error ? error.message : null;
    const userId = (session.data && session.data.login) ? session.data.login.id : null;
    const {browser, os, platform} = this.state.browserInfo;
    errorService.send({
      source: TENANT + '-frontend',
      tenantID: TENANT,
      stack: info.componentStack.split("\n").slice(1),
      message,
      browser,
      os,
      platform,
      user: {
        id: userId,
        login: {
          token: hashedToken,
          time: loginTime
        }
      }
    }).catch(err => null); // do nothing, just swallow it

    return this.setState({hasError: true, error, errorInfo: info, errorLocation: this.props.location});
  }

  static getDerivedStateFromProps(nextProps,state) {
    // reset state when props change (i.e. location prop) so that
    // we can try going to other pages or logging out
    if (state.errorLocation && nextProps.location.pathname !== state.errorLocation.pathname) {
      return {hasError: false, error: null, errorInfo: null, errorLocation: null};
    }
    return null;
  }

  render() {
    const {tenant, classroom} = this.props;

    if (tenant.loading || !tenant.data) {
      return null;
    }

    $('head > title').text(getAppName(tenant.data, classroom && classroom.data));

    return (
        <div className={cn('app-container')}>
          <div className={cn('nav')}>
            <HeaderNavContainer/>
          </div>
          <div className={cn('body')}>
            <div className={cn('rays')}>
              <div className={cn('app-contents')}>
                {this.state.hasError &&
                <ErrorCard
                    error={this.state.error}
                    errorStack={this.state.errorInfo.componentStack}
                    email={tenant.data.contactEmail}/>
                }
                {!this.state.hasError && this.props.children}
              </div>
            </div>
          </div>
          <div className={cn('footer')}>
            <Footer session={this.props.session} tenant={tenant} classroom={classroom}/>
          </div>
        </div>
    );
  }
}

AppContainer.displayName = "AppContainer";

module.exports = build({
  component: AppContainer,
  mapStateToProps
});

