{dynamic} = require 'react-router-r'

USER_ROLES = ['admin', 'mentor', 'student', 'teacher','customer']

_getSession = -> JSON.parse(localStorage.getItem 'cricketTogetherSession') or {}

isUserType = (role) -> -> _getSession().login?.roles.includes role

isUnauthenticated = -> not _getSession().login?

module.exports =
  branches: do ->
    branches = {}
    USER_ROLES.forEach (role) ->
      branches["#{role}Branch"] = (route) ->
        predicate: isUserType role
        route: route
    branches.unauthenticatedBranch = (route) ->
      predicate: isUnauthenticated
      route: route
    branches

  generateRoutes: (branches) -> dynamic getRoute: (returnRoute) ->
    for {predicate, route} in branches
      return returnRoute route if predicate()

  ifNotProduction: (routeModifier) -> (route) ->
    if process.env.NODE_ENV is 'production'
      route
    else
      routeModifier route

  isAdmin: isUserType 'admin'

  isMentor: isUserType 'mentor'

  isStudent: isUserType 'student'

  isTeacher: isUserType 'teacher'

  isCustomer: isUserType 'customer'

  isUnauthenticated: isUnauthenticated
