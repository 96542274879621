module.exports =
  articleCollection:
    defaultPalName: "{{prefix}} pen pal"
    approvedMentor: 'Your student has chosen a Focus Article. Every unit your
      student selects a Focus Article for close reading and discussion. Please
      read the Focus Article and be sure to discuss it in your letter to your
      student.'
    approvedMentorTitle: 'Read your Focus Article'
    approvedStudent: 'Begin by reading your Focus Article but don’t stop there!'
    approvedStudentTitle: 'Read your Focus Article'
    choose: 'Choose your Focus Article!'
    click: 'Click on the circle to choose a Focus Article.'
    defaultMentor: 'Read articles about exchanging letters. Discover what makes or breaks
      a good correspondence.  For this unit, it’s optional to reference
      what you’ve read in your letter to your student.  In all other Units,
      students will select a Focus Article and that is what you’ll prioritize
      reading and discussing in your letters.'
    defaultTitle: 'Article Collection'
    easier: 'Easier'
    harder: 'Harder'
    introUnitInstructions: 'Have fun reading articles about exchanging letters. ' + '
        Discover what makes or breaks a good correspondence.'
    ordered: 'The articles are ordered from easier to harder.'
    peek: 'Take a peek at all of the articles to find the one that interests you the most.'
    pendingStudent: "While you're waiting for your teacher to approve your
      Focus Article, check out any of the articles below and their 'Find Out
      More' pages to learn more about the topic."
    pendingStudentTitle: 'Explore the Collection'
    rejectReason: "Teacher's Suggestions:"
    selectStudent: "It's your choice! The articles are ordered from easier to
      harder. Click the circle
      to choose one article for you and your eMentor to read and
      discuss in your letters."
    selectableTitle: 'Choose your Focus Article now'
    submit: 'Then, click ‘Submit Selection’ so your teacher knows the article you’ve chosen.'
    unapprovedMentor: 'Your student is currently working on selecting a Focus
      Article.'
  articleDetail:
    articleContext: 'Article Context'
    definition: 'Definition'
    essentialQuestion: 'Essential Question'
    importantWords: 'Important Words'
    readIt: 'Read It!'
    relatedArticles: 'Related Articles'
    supportingQuestions: 'Supporting Questions'
    thinkingQuestions: 'Thinking Questions'
    unitsIn: 'Units this article appears in'
  articlePreview:
    subjects: 'Subjects'
    strands: 'Strands'
    grade: 'Grades'
    lexile: 'Lexile'
    lexileValue: '{{lexile}}L'
    more: 'Find Out More'
    readIt: 'Read It!'
    readFocusArticle: 'Read Focus Article'
    strand: 'Strand'
    topic: 'Topic'
    age: 'Age'
    author: 'Author'
  onboarding:
    nosession: 'Looks like you are not currently in a session. To get information about current ' +
               'sessions of {{appName}}, contact {{contactEmail}}.'
    overview:
      heading: 'Welcome,  {{name}}!'
      message: 'Get ready to eMentor by completing the checklist below by {{date}}:'
      titleNew: 'New eMentor Checklist'
      titleReturning: 'Returning eMentor Checklist'
      completeBy: 'Complete Checklist by'
      classroomButton: 'Go to my Classroom'
    message:
      allComplete: 'Congratulations, you have completed your eMentor checklist. Stay tuned, ' +
                   'you’ll  receive an email notification once you have been connected with ' +
                   'a student. '
      checkRequestedUS: 'We\'ve received your background check request. You will be receiving an email invitation ' +
                        'from Accurate.com (customer_service@accurate.com) with instructions on how to complete ' +
                        'your background check shortly.  If you do not receive that email within 48 hours, ' +
                        'please reach out to {{contactEmail}}.'
      checkRequestedIntl: 'We\'ve received your background check request! You will receive an invitation via email ' +
                          'with instructions on how to start the process. It may take up to a week to receive the invitation. '
      checkInvitedIntl: 'Please check your email for an invitation from Accurate.com (customer_service@accurate.com) with ' +
        'instructions on how to complete your background check. If you have not received ' +
        'the invitation, please reach out to {{contactEmail}}.'
      checkStillValid: 'Woohoo, we still have a current background check on file for you! Click \'Go\' to watch ' +
                       'your Returning eMentor Training.'
      needsinfo: 'Some additional information is required to complete your background check. Please reach out to {{contactEmail}}.'
      cancelled: 'Your background check invitation has either been cancelled or has expired. Please click \'Go\' to ' +
                 'request a new background check invitation.'
      start: 'Please click \'Go\' to request a background check'
      letterSubmitted: 'Thanks for submitting your Intro Letter! Once your letter has been approved, you will be ready ' +
                       'to be connected with a student.'
      letterRejected: 'Oops! Looks like your Intro Letter needs to be revised. Please review the feedback and make the necessary changes.'
      expired: 'Your background check has expired. Please click \'Go\' to request a new background check invitation.'
    verify:
      heading: 'Verify your Email Address'
      instructions: 'Your email address, {{email}}, has not yet been verified. Please click to send a verification email. '
      success: 'A verification email has been sent to {{email}}.  Please check your email and click the link in the message.'
      link: 'Verify my Email'
      instructions2: 'to your account.  Then check your email and click the link in the message.'
    background:
      itemText: 'Complete your Background Check'
      heading: 'Complete a Background Check'
      instructions: 'Please confirm your information:'
      instructions2: 'Clicking \'Submit\' will request a background check invitation.  Once your request has been ' +
                     'processed, you will receive an email containing instructions about how to complete your ' +
                     'background check.'
      instructionsModal: 'Please review the following information closely. In order to run a background check and ' +
                         'participate in the program, it is imperative that this information is accurate – any ' +
                         'discrepancies may cause delays and/or prevent completion.  '
      editHeading: 'Edit Information'
      editInformationPrompt: 'Need to make a change? '
      editInformationLink: 'Edit your information'
      editprofile1: "Your profile information is incomplete. Please"
      editprofile2: "and provide all required information."
      editprofileLink: 'review your profile information'
      checkboxLabel: 'My information is correct/current.'
      submittedUS: 'Thank you for requesting a background check! Please check your email for an ' +
                   'invitation from Accurate.com (customer_service@accurate.com).'
      submittedIntl: 'Thank you for requesting a background check! You should receive an email invitation ' +
                     'to start your background check.  It may take up to a week to receive this email. '
    training:
      itemText: 'Watch Training Videos'
      mentor:
        heading: 'Watch Training Videos'
        instructions: 'Please watch the videos below to learn more about being an eMentor.'
      student:
        heading: 'Watch Videos'
        instructions: 'Learn more about {{appName}}.'
      watchButton: 'Watch'
      watchAgainButton: 'Watch Again'
    letter:
      itemText: 'Write your Intro Letter'
      itemTextFinish: 'Finish your Intro Letter'
      heading: 'Write your Intro Letter'
      instructions: 'Use tabs below to move through the process of writing your first letter to your student!'
      instructionsReturning: 'We found an Intro Letter you submitted previously! Review and update your letter ' +
                             'in the Draft tab or click the \'Start Over\' button in the Plan tab, to start from scratch.';
  classroomPage:
    addStudent: 'Add Student'
    both: 'Both'
    emptyMessage: 'You have no students in your roster.  Please click "Add Student" to get started.'
    errors:
      one: '1 student contains errors'
      other: '{{count}} students contain errors'
    letters: 'Letters'
    penpal: 'eMentor'
    permission:
      declined: 'Permission has been declined'
      delivered: 'Permission form has been sent to the guardian'
      paperPending: 'Pending receipt of paper permission form'
      pending: 'Thank you for providing guardian information. We will send them an electronic permission form soon.'
      review: 'Please provide guardian name and email to allow us to send them an electronic permission form'
      sent: 'Permission form has been sent to the guardian'
      signed: 'Permission form has been signed and student is ready to participate'
      title: 'Permission'
    student: 'Student'
    success:
      one: '1 student found'
      other: '{{count}} students found'
    unexpectedType: 'Unexpected type {{type}}'
    uploadModal:
      addClass: 'Add Class'
      closeModal: 'Close'
      downloadRoster: 'Download our '
      modalHeader: 'Add Class Roster'
      template: 'Class Roster Template'
      uploadRoster: 'Upload Roster Template to add to your Classroom'
    uploadRoster: 'Upload Roster'
  classroomStudent:
    missingField: 'Please enter a {{field}}'
    badUsernameField: 'Username cannot contain spaces'
  classroomUnit:
    cancelStart: 'Cancel'
    selectArticle: 'Select'
    confirmStartSubtext: 'Make sure you have approved any ' +
      'outstanding focus articles and letters in the current unit before starting a new unit.'
    confirmStartSubtitle: 'This will end your current unit.'
    confirmStartTitle: 'Are you sure you want to start a new unit?'
    startUnit: 'Start Unit'
    submitStart: 'Yes, Start New Unit'
    unfinishedItem:
      preText: 'You still have:'
      postText: 'Do you still wish to start a new unit?'
      missingStudent: "{{count}} student(s) missing letters"
      missingMentor: "{{count}} eMentor(s) missing letters"
      draft: "{{count}} unfinished letter(s)"
      revision: "{{count}} revision(s) in progress"
      pending: "{{count}} letter(s) pending review"
  classroomUnitPage:
    articleSelection: 'Article Selection'
    classroomUnit: 'classroom unit'
    date: 'Date'
    dueDate: 'Due {{date}}'
    editUnit: 'Edit Unit'
    filter:
      all: 'All'
      approved: 'Approved'
      missing: 'Missing'
      pending: 'Pending'
      rejected: 'Needs Revision'
      status: 'Status'
      draft: 'Draft'
      revisionPending: 'Revision Pending'
      revisionApproved: 'Revision Approved'
    focusArticles: 'FOCUS ARTICLES'
    grades: 'Grades: {{grades}}'
    hideUnitDetails: 'Hide Unit Details'
    letter: 'Letter'
    letterExchange: 'LETTER EXCHANGE'
    lexile: 'Lexile level: {{lexile}}L'
    missingFocusArticle: '{{missingCount}} of {{totalCount}} students have not chosen a focus ' +
      'article yet.'
    missingLetters: '{{missingCount}} of {{studentsCount}} students have not finished their ' +
      'letter yet.'
    noFocusArticles: 'There are no focus articles for this unit.'
    notInitialized: 'classroomUnit not initialized'
    penpal: 'eMentor'
    showUnitDetails: 'Show Unit Details'
    status: 'Status'
    student: 'Student'
    unitActive: 'Unit Started'
    whosMissing: "Who's missing?"
    whosDraft: "Who has a draft?"
    whosRejected: "Who needs revision?"
  classroomViewMentor:
    name: 'Name'
    gender: 'Gender'
    occupation: 'Occupation'
    aboutMe: 'About Me'
    replaceBtn: 'Request Replacement'
    emailSubject: "{{appName}} eMentor Replacement Request"
  containerClassroomSelector:
    notLoaded: 'Not Loaded...'
  containerResolve:
    notAssociated: 'You are not associated with any classroom.'
  contentLoader:
    empty: 'No {{name}} yet!'
    error: 'There was an error while trying to load {{error}}'
  contentPreview:
    approved: 'Your Focus Article'
    disabled: 'Already Selected by Students'
    pending: 'Focus Article Awaiting Approval'
  dateNormalizer:
    endLabel: 'Unit End'
    focusLabel: 'Focus Article Due'
    letterDue: '{{name}} Letter Due'
    letter1Due: '{{name}} Letter 1 Due'
    letter2Due: '{{name}} Letter 2 Due'
    mentorLetterDue: 'eMentor Letter Due'
    mentorLetterDueExt: 'eMentor Letter 1 Due'
    mentorLetter2Due: 'eMentor Letter 2 Due'
    mentorFocusLabel: 'Student Selects Focus Article'
    progressPercent: '{{percent}}%'
    startLabel: 'Unit Start'
    studentFocusLabel: 'Focus Article Due'
    studentLetterDue: 'Student Letter Due'
    studentLetterDueExt: 'Student Letter 1 Due'
    studentLetter2Due: 'Student Letter 2 Due'
    defaultStudentName: 'Student'
    defaultMentorName: 'eMentor'
    my: "My"
  datePicker:
    notChosen: 'Not Chosen'
  editor:
    placeholder: 'Start writing your letter…'
  errorCard:
    title: 'We\'re sorry, an unexpected error occurred...'
    steps: 'Try one or more of these steps and see if this resolves your error:'
    step1: 'Logging out then logging back in'
    step2: 'Reloading the browser page'
    step3: 'Clearing your browser cache'
    step4: 'Closing your browser window/tab and reloading the site'
    contact1: 'If after trying the steps above the problem persists, please contact us at '
    showErrBtn: 'Show Error Details'
    hideErrBtn: 'Hide Error Details'
    stackTrace: "Component Trace: "
  fileUploader:
    addStudents: 'Add Students'
    cancel: 'Cancel'
  focusArticleStatusCell:
    approved: 'Approved'
    cancel: 'Cancel'
    explain: 'Add a note of explanation'
    missing: 'Missing'
    pending: 'Pending'
    rejectReasonCaption: 'REJECTION REASON'
    rejected: 'Needs Revision'
    sendNote: 'Send Note'
  impersonateBar:
    warningMessage: 'You are an Admin impersonating '
  letterComposerTabView:
    defaultPal: 'eMentor'
    defaultStudent: 'Student'
    draft: 'DRAFT'
    draftButtonTitle: 'Next Step'
    feedbackTitleText: "Teacher's Suggestions:"
    greeting: 'Dear {{name}},'
    next: 'Next'
    plan: 'PLAN'
    planAnswerPlaceholder: 'Answer the questions below to fill in your letter'
    planButtonTitle: 'Next Step'
    resetButtonTitle: 'Start Over'
    reviseAndSend: 'REVISE & SEND'
    reviseAndSendButtonTitle: 'Send'
    reviseAndSendModalCloseButtonTitle: 'Sounds Great!'
    reviseAndSendModalTitle: 'Your letter has been submitted!'
    saveAndExit: 'Save & Exit'
    sincerely: 'Sincerely,'
  letterComposerTips:
    checklist: 'Writing Checklist'
    importantWords: 'Important Words'
    referenceLetter: 'Most Recent Letter'
    sampleLetter: 'Sample Letter'
    thinkingQuestions: 'Thinking Questions'
    tips: 'Writing Tips'
    tipsAndResources: 'Tips & Resources'
  letterExchangeLetterCell:
    dateDue: 'Due {{date}}'
    needsRevision: 'Needs Revision'
    revisionPending: 'Revision Pending'
    revisionApproved: 'Revision Approved'
    review: 'Review'
    view: 'View'
  letterModal:
    approve: 'APPROVE'
    askForRevision: 'ASK FOR REVISION'
    cancel: 'Cancel'
    headerDate: 'Date: '
    headerFrom: 'From: '
    headerTo: 'To: '
    headerUnitName: 'Unit Name: '
    lettersInConversation: 'Letters in this conversation'
    lettersInConversationOf: 'of'
    print: 'Print'
    printDate: 'Date: {{date}}'
    printFrom: 'From: {{from}}'
    printLetters: 'Print Letters'
    printTo: 'To: {{to}}'
    printUnitName: 'Unit Name: {{unitName}}'
    reply: 'REPLY'
    reviseInstruction: 'Use this space to explain how you would like the letter revised.'
    submit: 'Submit'
    rejectMessage: "Teacher's Suggestion ({{rejectedDate}}): {{rejectedMsg}}"
  login:
    adminAccess: 'Sorry, administrators cannot log directly into the application.'
    administrator: 'Administrator Username'
    enterCredentialsPrompt: 'Please enter a username and password.'
    enterImpersonateUsername: 'Please enter a username to impersonate'
    enterPasswordPrompt: 'Please enter a password.'
    enterUsernamePrompt: 'Please enter a username.'
    errorForgotPassword: 'Sorry, the password you entered was incorrect. Did you'
    errorForgotPasswordLink: 'forget your password?'
    errorForgotUsername: "Sorry, we don't recognize the username you entered. Did you"
    errorForgotUsernameLink: 'forget your username?'
    errorAdminLogin: 'You cannot log in as an administrator. Did you want to'
    errorForgotImpersonateLink: 'impersonate a user?'
    errorUserInactive: 'Your account is no longer active.  If you believe this is an error, or would like to ' +
                       'reactivate your account, please reach out to us at {{supportEmail}}.'
    errorTrialComplete: 'Your {{appName}} trial is no longer active.  If you believe this message is in error, ' +
                        'please contact {{supportEmail}}.'
    errorNotInClassroom: 'You’ll be able to log in once you’re assigned to a classroom. Watch your email for a notification ' +
      'that you have a classroom assignment and then you can log in and get started!'
    forgotPasswordLink: 'Forgot password?'
    forgotUsernameLink: 'Forgot username?'
    impersonatingUser: 'Impersonating User'
    learnMoreLink: 'Learn More'
    loading: 'Loading'
    logIn: 'Sign In'
    signIn: 'Sign In'
    logOut: 'Sign Out'
    signOut: 'Sign Out'
    newUserHeader: 'New to the site?'
    password: 'Password'
    rememberMeCaption: 'Remember me'
    registerLink: "Don't have an account? Sign up here!"
    submitButtonLabel: 'Sign In'
    username: 'Username'
  pageLetterCenter:
    cardButtonTitle:
      default:
        mentor: 'WRITE ANOTHER LETTER'
        student: 'WRITE ANOTHER LETTER'
      hasDraft:
        mentor: 'FINISH MY LETTER'
        student: 'FINISH MY LETTER'
      hasPrevDraft: 'FINISH MY LETTER'
      hasRejectedMessage: 'REVISE MY LETTER'
      isInitialState:
        mentor: 'WRITE TO YOUR STUDENT'
        student: 'WRITE TO YOUR eMentor'
    cardDeleteDraftButtonTitle: 'DELETE MY UNFINISHED LETTER'
    cardViewDraftButtonTitle: 'VIEW MY UNFINISHED LETTER'
    cardDeleteRejectedMessageButtonTitle: 'DELETE LETTER'
    cardViewRejectedLetterButtonTitle: 'VIEW LETTER'
    cardContent:
      default:
        mentor: 'Continue the conversation with your student and write again!{{suffix}}'
        student: 'Read new letters from your eMentor and write back soon! Click on ' +
          'the "Write Another Letter!" button to get started.'
      hasDraft:
        mentor: "We've saved your draft. Come back soon to complete your letter!"
        student: 'Great start! We will save your writing. Come back soon to finish ' +
          'your letter.'
      hasPrevDraft:
        mentor: "We've saved your unfinished letter from a previous unit. Please complete your unfinished " +
                "letter or delete it to start a new letter for the current unit."
        student: "Looks like you have a letter from a previous unit that isn't finished. You can " +
                "finish that letter by clicking 'Finish My Letter' above or you can start over on a new letter " +
                "by clicking 'Delete My Unfinished Letter' below."
      hasRejectedMessage:
        mentor: "Oops! There's something in your letter that your Teacher needs you to revise!"
        student: "Oops! There's something in your letter that your Teacher needs you to revise!"
      isInitialState:
        mentor: 'Use this page to read letters from your student or compose a letter to your ' +
          'student. Click on the "Write to Your Student" button to get started.'
        student: 'Use this page to read letters from your eMentor or write an awesome ' +
          'letter of your very own! Click on the "Write to Your eMentor!"  button to get started.'
        studentMatched: 'This is where you will read and write letters, once you are matched.'
      nonIntroUnitSuffix: ' We encourage you to read and discuss the articles in this unit’s collection.'
    cardSubtitle: 'Due Date to write your letter is {{date}}'
    cardTitle:
      default: 'Welcome Back, {{name}}!'
      hasDraft: 'Welcome Back, {{name}}!'
      hasRejectedMessage: 'Revision Needed, {{name}}!'
      isInitialState: 'Write Your First Letter, {{name}}!'
      notMatched: 'Welcome to the Letter Center'
    deleteConfYesButtonTitle: 'Yes, Delete Letter'
    deleteConfNoButtonTitle: 'No, Keep Letter'
    deletePrevDraftConfHeader: 'Are you sure you want to delete your unfinished letter?'
    deletePrevDraftConfText: 'Once you delete your unfinished letter you cannot recover it.'
    deleteDraftConfHeader: 'Are you sure you want to delete your unfinished letter?'
    deleteDraftConfText: 'Once you delete your unfinished letter you cannot recover it.'
    deleteRejectedMessageConfHeader: 'Are you sure you want to delete your letter?'
    deleteRejectedMessageConfText: 'Once you delete your letter you cannot recover it.'
    placeholder:
      inbox:
        mentor: "Once you write your first letter, this is where you'll see your " +
          "student's response."
        student: "Once you write your first letter, this is where you'll
        see your eMentor's response."
      sent:
        mentor: "This is where you'll see the letters you send to your student."
        student: "This is where you'll see the letters you send to your eMentor."
    statusApproved: 'Approved'
    statusNeedsRevision: 'Needs Revision'
    statusPendingMatch: 'Pending Match'
    statusRevisionApproved: 'Approved Revision'
    statusRevisionInProgress: 'Revision In Progress'
    statusRevisionSent: 'Submitted Revision'
    statusSent: 'Awaiting Review'
    tabInbox: 'INBOX'
    tabSent: 'SENT'
  pageLetterComposer:
    continue: 'Continue'
    introduction:
      mentor: 'Draft your letter to your student below!'
      reply: "Remember to refer to your ‘Most Recent Letter’ in
        the Tips & Resources section when responding. Expand your
        conversation by reading and writing about other articles in the unit!"
      revise: 'Reread your letter. Fix any spelling
        and grammar mistakes you find. Make sure your letter says what you want it to say.'
      student: "Complete your letter, by filling in details and checking for complete sentences. "
    modalPlanCompleteHeader: 'Are you sure your plan is complete?'
    modalPlanCompleteKeepPlanning: 'Keep Planning'
    modalPlanCompleteText: 'Once you move forward you can no longer add to your plan.'
    modalStartOverNo: 'No, Keep Writing'
    modalStartOverText: 'Are you sure you want to start over? This will delete any writing ' +
      'in your plan and draft.'
    modalStartOverYes: 'Yes, Start Over'
    placeholder: 'Your answer goes here...'
    planLocked: 'You finished your plan! Look back at the questions and answers to help you ' +
     'continue drafting.'
    planUnlocked: 'Let\'s plan out your letter together below. If you\'d rather write without ' +
      'prompts, click on the Draft tab above.'
    reviseAndSendModalContentText1: 'It will be reviewed soon. '
    reviseAndSendModalContentText2: ' Stay tuned!'
  pagingNavigation:
    range: '{{currentRange}} of {{totalItems}}'
  printLetter:
    logo: 'Cricket Together'
  resourcesPage:
    help: 'For technical support, please send a detailed message of your question or concern to:'
  sharedComponents:
    fileUploader:
      browse: 'Browse'
      dragAndDrop: 'Drag & Drop'
      or: 'or'
  studentDataEntry:
    female: 'she/her'
    firstName: 'First Name'
    firstname: 'First Name'
    gender: 'Pronouns'
    lastName: 'Last Initial'
    lastname: 'Last Name'
    male: 'he/him'
    nonbinary: 'they/them'
    other: 'other'
    parentEmail: 'Guardian Email'
    parentName: 'Guardian Name'
    parentreason: 'Please provide guardian name and email to enable us to send a digital permission form.'
    password: 'Password'
    username: 'Username'
  teacherHomePage:
    contentLoaderName: 'article selections and messages'
    footer:
      calendar: 'Update My Unit Calendar'
      explore: 'Explore Resources'
      faq: 'View FAQs'
      focusArticles: 'Focus Articles'
      focusArticlesApprove:
        other: 'You have {{count}} Focus Articles to review and approve.'
        one: 'You have {{count}} Focus Article to review and approve.'
      letterApprove:
        other: 'You have {{count}} letters to review and approve.'
        one: 'You have {{count}} letter to review and approve.'
      letterCenter: 'Letter Center'
      quickLinks: 'Quick Links'
      view: 'View'
      viewTips: 'View Tips'
    headings:
      focusArticle: 'Student focus articles selected'
      mentorLetters: 'eMentor letters received'
      studentLetters: 'Student letters'
    studentLetters:
      missing:
        one: 'Letter Missing'
        other: 'Letters Missing'
        link: "Who's missing?"
      inprocess:
        one: 'Letter In Process'
        other: 'Letters In Process'
        link: 'Who has drafts?'
      inrevision:
        one: 'Letter In Revision'
        other: 'Letters In Revision'
        link: "Who has revisions?"
      pending:
        one: 'Letter Awaiting Approval'
        other: 'Letters Awaiting Approval'
        link: "Who's awaiting approval?"
      sent:
        one: 'Letter Sent'
        other: 'Letters Sent'
    pastUnits:
      reviewLetters: "Review letters from Previous Unit \"{{name}}\" ({{count}} pending)"
      reviewRevisions: "Letter revision for {{role}} '{{name}}' was requested {{date}}."
  studentUnits:
    confirm: 'Sounds Great!'
    subjects: 'Subjects:'
    modalTitle: 'Your Focus Article Has Been Submitted!'
    pastUnits: 'Past Units'
    pending: 'Your teacher will take a look and approve your selection soon.'
    stayTuned: 'Stay tuned!'
    submit: 'Submit Selection'
    strands: 'Strands:'
  teacherUnits:
    addNewUnit: 'Add New Unit'
    current: 'Current Unit'
    end:
      one: '{{currentName}} ends in {{daysBetween}} day. Please add your next unit!'
      other: '{{currentName}} ends in {{daysBetween}} days. Please add your next unit!'
    final:
      one: '{{currentName}} ends in {{daysBetween}} day.'
      other: '{{currentName}} ends in {{daysBetween}} days.'
    noEndDate: 'Please add your next unit!'
    manager: 'Unit Manager'
    next:
      one: "{{nextName}} is scheduled to start in {{daysTill}} day. \
        Need more time for {{currentName}}? Click on your current Unit to access your schedule. \
        Then click on the pencil icon in the timeline to adjust the end date. \
        After you adjust your schedule, we'll let your eMentors know!"
      other: "{{nextName}} is scheduled to start in {{daysTill}} days. \
        Need more time for {{currentName}}? Click on your current Unit to access your schedule. \
        Then click on the pencil icon in the timeline to adjust the end date. \
        After you adjust your schedule, we'll let your eMentors know!"
    notSet: 'Current unit has not been set up yet.'
    pastUnits: 'Past Units'
    pendingLetters:
      one: '{{count}} letter pending'
      other: '{{count}} letters pending'
    select: 'Select and manage units here!'
    upcoming: 'Upcoming Units'
  surveyPage:
    surveyCompleteModalTitle: 'Survey Complete'
    surveyCompleteModalText: 'Thank you for submitting your survey!'
    surveyCompleteModalButton: "You're Welcome"
  unitDetail:
    essentialQuestion: 'Essential Question'
    subjects: 'subjects'
    grades: 'grades'
    learningStandards: 'Learning Standards'
    letterPrompts: 'Letter Prompts'
    lexileBand: 'Lexile Band'
    mentor: 'eMentor'
    missing: 'Missing'
    printPrompts: 'Print Prompts'
    promptsTitle: 'Letter Prompts for {{unitName}}'
    strands: 'Strands'
    student: 'Student'
    teachersGuide: "Teacher's Guide"
  unitDetailDatePicker:
    addUnit: 'Add unit to select dates'
  unitPreview:
    allow: 'Yes'
    allowAttachments: 'Allow images and file attachments?'
    attachmentsDescription: 'Allow Students and eMentors to embed images and
      attach files to their letters for this unit. You will need to review all
      images and attachments during letter approval.'
    disallow: 'No'
    endDate: 'Unit End'
    strands: 'Strands: '
    subjects: 'Subjects: '
    startDate: 'Unit Start'
    previewDescHeader: 'Preview The Unit'
    desc:
      line1: 'Preview the Teacher’s Guide.'
      line2: '''
       Preview the articles below. \
            If you choose to add this unit (by clicking on the ‘Add Unit’ button above), \
            you will be able to select articles you'd like to include.
            '''
  unitPreviewEdit:
    chooseArticles: 'Choose Articles'
    deleteUnit: 'Delete Unit'
    description: '''
    You select the articles you want your students to read. \
    The readings in each collection are on a variety of reading \
    levels and subjects related to the unit topic. \
    Preview the articles and related Find Out More pages. \
    Deselect any readings you don’t want visible for your class. \
    If you leave articles checked, \
    they will be available for your students to choose as a Focus Article.
    '''
    saveUnit: 'Save Unit'
  unitProgressBar:
    currentUnitProgress: 'Current Unit Dates'
    editUnit: 'Edit Unit'
    not: 'Not Chosen'
    teacherGuide: "Teacher's Guide"
  unitsDropdown:
    editCurrent: 'Edit Unit Schedule'
    explore: 'Explore Units'
    focusArticlesCurrent: 'Approve Focus Articles'
    lettersCurrent: 'Approve Letters'
    units: 'Manage Units'
  topContents:
    focusArticleMissing:
      zero: 'Woo-hoo!'
      one: "Who's missing?"
      other: "Who's missing?"
    mentor:
      top1:
        unitDesc: "In this unit, you'll exchange introductory letters and begin your learning friendship."
        welcome: "Enjoy getting to know your student."
      top2:
        welcome: "Thank you for sending your letter! We'll let you know when your student has written you back."
      top3:
        welcome: "Great news! You've been matched! Meet your student by reading their letter and" +
                  " write back soon to continue the conversation."
      top4:
        welcome: "Enjoy writing back and forth with your student until the next unit starts!"
      top5:
        welcome:  "Welcome to the new unit!  Enjoy exploring the article collection while you wait for" +
                  " your student to choose a Focus Article."
      top6:
        welcome: "Enjoy reading the Focus Article and sharing your ideas about it in a letter to your" +
                  " student! Remember you write first!"
      top7:
        welcome: "Thanks for writing to your student! While you’re waiting" +
                  " for your student to write back, you can read more articles on the topic!"
      top8:
        welcome: "Keep the conversation going by reading more and exchanging more letters!"
      top9:
        welcome: "Exciting News! You have a letter from your student!"
      top10:
        welcome: "Oops! Your teacher needs you to revise your letter." +
                  " Please revise and resend based on your teacher’s recommendations."
      top11:
        welcome:   "Thank you for being an eMentor!  Your letters made a difference in the life of a " +
                    "child !  Interested in being an eMentor again with {{appName}}?  Contact us " +
                    "at {{email}}."
    student:
      top1:
        unitDesc: "Wondering what {{appName}} is all about? In this unit, " +
                  "you’ll get to know your eMentor by exchanging your first letters!"
        welcome: "We're so happy you're here. We know you're going to enjoy {{appName}} and" +
                  " exchanging letters!"
      top2:
        welcome:  "Great job writing your letter! You'll be matched soon. Meanwhile," +
                  " have fun reading stories!"
      top3:
        welcome: "Way to go! Thanks for sending your first letter to your eMentor!"
      top4:
        welcome:  "Enjoy writing back and forth with your eMentor until the next unit starts!"
      top5:
        welcome:  "We're happy you're here. Have fun choosing your next Focus Article" +
                  " after exploring the new set of articles."
      top6:
        welcome: "Enjoy reading and discussing your Focus Article in class.  Read more of the" +
                  " collection if you have time. Your eMentor is reading now and will soon be sending" +
                  " you a letter about the Focus Article and topic."
      top7:
        welcome: "Keep the conversation going by reading more and exchanging more letters!"
      top8:
        welcome: "Exciting News! You have a letter from your eMentor!"
      top9:
        welcome: "Oops! Your teacher needs you to revise your letter." +
                  " Please revise and resend based on your teacher’s recommendations."
      top10:
        welcome: "We loved having you participate in {{appName}}!  Keep reading!"
    teacher:
      top1:
        desc: "Matching your students with caring adults to learn together is magical for" +
              " everyone involved. There are a just few easy steps to do before the magic" +
              " begins. Read the Getting Started Teacher Guide. It is a practical step-by-step" +
              " guide for introducing the {{appName}} learning experience to your" +
              " students. You'll find great tips for guiding your students to write their" +
              " 'All About Me' letters."
        welcome: "We're delighted you're here. Watch the 'Welcome to {{appName}}' video as a fun" +
                  " first step!"
      top2:
        welcome: "Encourage students and eMentors to continue exchanging letters to get to know each other!"
      top3:
        welcome: "Great news! You have eMentor letters to review!"
      top4:
        welcome:
          line1: "Hope you're enjoying diving into the Unit with your students!"
          line2: "To keep the momentum going:"
          bullet1: "have your students choose their Focus Articles."
          bullet2: "refer to the Teacher's Guide for a variety of practical and engaging" +
                    " lessons relating to the topic."
          bullet3: "keep your eMentors in the loop by updating your calendar if dates change."
      top5:
        welcome:
          line1: "We hope your students are enjoying reading and discussing their Focus Articles! Keep your" +
                  " eMentors in the loop by updating your calendar if dates change."
          line2:  "If you have a moment, send a message to your eMentors just to share what's going on" +
                  " in your class. They'll really appreciate it."
      top6:
        welcome: "Keep the conversations going by reviewing and approving letters promptly! " +
                  " Celebrate the arrival of eMentor Letters with your students! " +
                  " Consider printing them out for easy sharing. Your eMentors are eagerly awaiting responses from " +
                  "your students."
      top7:
        welcome:  "Thank you for participating in {{appName}}!  We hope you and your students " +
                  "enjoyed this learning adventure.  To register for next year, please contact " +
                  "{{email}}."
  actionItems:
    mentor:
      action1:
        text: "Read message from your teacher."
      action2:
        text: "Take the {{appName}} pre-survey. Your feedback is important to us!"
      action3:
        text: "Meet your student by reading their 'All About Me' letter."
      action4:
        text: "Enjoy reading your student's letter."
      action5:
        text: "Write back to your student to continue the conversation."
      action6:
        text: "Please revise and resend your letter based on your teacher's recommendations."
      action7:
        text: "{{unitVideoDesc}}"
      action8:
        text: "Read the Focus Article selected by your student. " +
              "Write your letter soon and start a thought-provoking discussion."
      action9:
        text:  "You have an unfinished letter from the previous unit. Go to the Letter Center " +
                "to complete your letter or delete it."
      action10:
        text: "Enjoy reading your student's letter and write back soon."
      action11:
        text: "Please revise and resend your letter based on your teacher's recommendations."
      action12:
        text: "Write your reflection letter to your student."
      action13:
        text: "Take the {{appName}} survey. Your feedback is important to us!"
    student:
      action1:
        text: "Take the {{appName}} pre-survey. We can't wait to hear from you!"
      action2:
        text: "Watch videos to get started!"
      action3:
        text: "Get inspired! Read sample introductory letters."
      action4:
        text: "Meet your eMentor by reading their 'All About Me' letter."
      action5:
        text: "Exciting News! You have a letter from your eMentor!"
      action6:
        text: "Write back to your eMentor and introduce yourself."
      action7:
        text: "Please fix your letter based on your teacher's comments."
      action8:
        text: "Choose a Focus Article for you and your eMentor to read together!"
      action9:
        text: "{{unitVideoDesc}}"
      action10:
        text: "Exciting News! You have a letter from your eMentor!"
      action11:
        text: "Your eMentor is eagerly waiting for your reply! Write back soon!"
      action12:
        text:  "You have an unfinished letter from the previous unit. Go to the Letter Center " +
                "to complete your letter or delete it."
      action13:
        text: "Please change your Focus Article selection. Your teacher wants you to select a new article."
      action14:
        text: "Please fix your letter based on your teacher's comments."
      action15:
        text: "Take the {{appName}} survey. We can't wait to hear from you!"
    teacher:
      action1:
        text: "Read message from an eMentor."
      action2:
        text: "Take the {{appName}} pre-survey. Your feedback is important to us!"
      action3:
        text: "Watch video to learn more about your role."
      action4:
        text: "Read the Teacher's Guide, a practical step-by-step way to maximize the {{appName}} experience."
      action5:
        text: "Watch Student 'Welcome to {{appName}}' Video with your class."
      action6:
        text: "Complete Unit Calendar"
      action7:
        text: "Introduce friendly letter writing to students and help them write their 'All About Me'  Letters."
      action8:
        text: "Review and approve student letters."
      action9:
        text: "Review and approve eMentor letters."
      action10:
        text: "Write Welcome Message to eMentors."
      action11:
        text: "Watch 'Selecting and Starting a New Unit' video."
      action12:
        text: "Select your next unit."
      action13:
        text: "{{unitVideoDesc}}"
      action14:
        text: "Read Teacher's Guide"
      action15:
        text: "Complete Unit Calendar"
      action16:
        text: "Review and Approve Student Focus Article Selections"
      action17:
        text: "Review and Approve eMentor Letters"
      action18:
        text: "Review and Approve Student Letters"
      action19:
        text: "Select your next unit."
      action20:
        text: "Take the {{appName}} survey. Your feedback is important to us!"
  ebooks:
    browse:
      contact: "If you have any questions, please contact us at"
    search:
      placeholder: "Enter search terms to find ebooks"
  landing:
    welcome: "Welcome To {{appName}}"
  accountVerify:
    verifying: "Verifying your account..."
    error: "We're sorry, your verification email has expired. Please sign in to request a new verification email."
    success: "Success! Your account has been verified. Sign in to get started!"
    returnBtn: "Return to Sign in"
  forgotPassword:
    instructions: "Please enter your {{appName}} username below, so that we can send you password reset instructions."
    error: "No account is associated with the username provided. Please try again."
    success1: "Keep your eyes peeled -- password reset instructions have been sent to the email address associated with your account. If you don't receive an email with instructions within the next 5 minutes, please contact us, at"
    success2: "(Don't forget to check your Spam and Promotions folders.)"
  forgotUsername:
    instructions: "Please enter the email address associated with your {{appName}} account."
    error: "No account is associated with the email address provided. Please try again."
    success1: "Success! A message containing your username has been sent to the email address provided. If you don't receive it within the next 5 minutes, please reach out to us, at"
    success2: "(Don't forget to check your SPAM and/or Promotion folders.)"
    signInBtn: "Sign In Now"
  resetPassword:
    instructions: "Provide your new password below to update the password for this account:"
    error: 'There was an error changing your password. Try again.'
    success: "Thank you. Your password has been changed. You can sign in now with your new password."
    passwordLabel: "Enter Password"
    passwordLabel2: "Re-Enter Password"
    signInBtn: "Sign In Now"
  signup:
    welcome: "Welcome, {{sponsorName}} Volunteers!"
    instructions1a: "Interested in eMentoring for the {{sessionName}} Session of {{appName}}?"
    instructions1b: ""
    instructions2: "To sign up, enter your {{appName}} username and password below."
    instructions3pre: "Don't have a {{appName}} account?"
    instructions3link: "Create An Account"
    instructions3post: ", first!"
    instructions4: "You're almost there!"
    instructions5: "Enter your username and password below, to sign up for the session."
    terms1: "I agree to the terms and conditions of this"
    termsLink: "eMentor Volunteer Commitment and Release"
    terms2: ", and am willing to undergo a background check."
    success1: "Thanks for signing up! You'll receive an email invitation onto the platform shortly."
    success2: "(Having trouble locating the invitation? Be sure to check your Spam and Promotion folders.)"
    contact: "If you have any questions, please contact us at"
  register:
    title: "Create a {{appName}} Account"
    mentor:
      firstName: "First Name"
      firstNamePlaceholder: "Enter your first name"
      lastName: "Last Name"
      lastNamePlaceholder: "Enter your last name"
    student:
      firstName: "Child First Name"
      firstNamePlaceholder: "Enter your child's first name"
      lastName: "Child Last Name"
      lastNamePlaceholder: "Enter your child's last name"
    parent:
      firstName: "Parent/Guardian First Name"
      firstNamePlaceholder: "Enter your first name"
      lastName: "Parent/Guardian Last Name"
      lastNamePlaceholder: "Enter your last name"
      email: "Parent/Guardian Email Address"
    teacher:
      instructions: "Welcome, {{name}}! Create your teacher account by selecting a username and password below:"
      success: "Your {{appName}} teacher account has been created.  We'll be in touch shortly with next steps."
    username: "Username"
    usernamePlaceholder: "Select a username (minimum 5 letters, no spaces)"
    email: "{{parent}} Email Address"
    emailPlaceholder: "Enter your email address"
    password: "Password"
    passwordPlaceholder: "Create a new password"
    password2: "Re-enter Password"
    password2Placeholder: "Re-enter your new password"
    country: "Country of Residence"
    zipcode: "Zipcode"
    zipcodePlaceholder: "Enter your 5-digit zipcode"
    city: "City"
    cityPlaceholder: "Enter city/town of residence"
    company: "Company/Organization"
    companyPlaceholder: "Enter your company/organization name"
    dept: "Department"
    deptPlaceholder: "Enter your department"
    jobTitle: "Title"
    jobTitlePlaceholder: "Enter your job title"
    phone: "Phone Number"
    phonePlaceholder: "Enter your phone number"
    demo: "Some schools look for specific eMentor matches to reflect their population. In an effort to" +
          " help provide our schools with the best matches, please share information about your occupation, gender, and ethnicity."
    occupation: "Occupation"
    occupationPlaceholder: "Enter your occupation"
    gender:
      title: "Gender"
      option1: "Select your gender"
      option2: "Male"
      option3: "Female"
      option4: "Prefer Not To Say"
      option5: "Add Gender"
      customPlaceholder: "Enter your gender here"
    ethnicity:
      title: "Ethnicity"
      option1: "Select your ethnicity"
      option2: "American Indian or Alaskan Native"
      option3: "Asian or Pacific Islander"
      option4: "Black, not of Hispanic origin"
      option5: "Hispanic"
      option6: "Middle Eastern"
      option7: "White, not of Hispanic origin"
    eulaPre: "I certify that I am at least thirteen years of age and agree to the terms of the "
    eulaLink: "{{appName}} End-User License Agreement."
    privacyPre: "Cricket Media will protect your data, subject to our "
    privacyLink: "Privacy Policy"
    success: "Your {{appName}} account creation was successful!"
    studentLogin:
      message: "Your child can sign in by clicking the button below"
      btn: "Sign In Now"
    session:
      signup: "Click 'Next' to sign up for the {{sessionName}} session of {{appName}}."
      btn: 'Next'
    emailVerify: "Check your email for information on verifying your account so you can sign in."
    contact: "If you have any questions, please contact us at"
    required: "* Indicates required field"
    studentCreateError: ""
  notFound:
    redirect: 'Page not found, redirecting to home page.'
  headerNav:
    classroom: 'My Classroom'
    home: 'Home'
    letterCenter: 'Letter Center'
    library: 'Library'
    focusArticle: 'Focus Article'
    teacherUnits: 'Units'
    resources: 'Resources'
    browseEbooks: 'Browse Ebooks'
    searchEbooks: 'Search Ebooks'
  unitExplore:
    heading: "Explore Units"
    message: "Search here to select and schedule a Unit for your class."
  home:
    defaultPalName: "{{prefix}}pen pal"

