export default class ComponentHelpers {

  // This change handler is the most recent implementation as of 11/26/2019 which
  // does a full nested state update.  For example if a change is made to
  // foo.bar.baz it will correctly construct the merged update using the existing
  // state and the new changes.  This is very tricky because React's setState does not handle
  // nested state updates so you have to update the entire toplevel attribute and ensure that all
  // the nested object fields are preserved including the one that was changed.

  static onChangeHandler(field, val) {
    return (prevState) => {
      const fields = field.split('.');
      const field1 = fields[0];
      const fields2 = field.split('.');
      fields2.pop();
      let updates = fields.reverse()
          .reduce((acc, key) => {
            if (acc === null) {
              const prevObject = fields2.reduce((o, i) => o[i], prevState);
              return Object.assign({}, prevObject, {[key]: val});
            }
            fields2.pop();
            const prevObject = fields2.reduce((o, i) => o[i], prevState);
            return Object.assign(prevObject, {[key]: acc});
          }, null);

      updates = _.pick(updates, field1);
      // console.log({updates});
      return updates;
    }
  }
};

