({Component} = require 'react') and (PropTypes = require 'prop-types')
style = require './table_filter.styl'
e = require('react-e/bind') style

class TableFilter  extends Component

  @defaultProps:
    sortOrder: 'none'

  @propTypes =
    column: PropTypes.string
    onClickHeader: PropTypes.func
    didClickAscending: PropTypes.func
    didClickDescending: PropTypes.func
    sortOrder: PropTypes.oneOf ['ascending', 'descending', 'none']

  onClickSort: (order) ->
    if @props.onClickHeader
      @props.onClickHeader @props.column, order
    else
      if order is 'descending'
        @props.didClickDescending()
      else
        @props.didClickAscending()

  render: ->
    e '.table-filter',
      e '.table-filter-cover-descending',
        e '.table-filter-descending',
          className: active: @props.sortOrder is 'descending'
          onClick: => @onClickSort 'descending'
      e '.table-filter-cover-ascending',
        e '.table-filter-ascending',
          className: active: @props.sortOrder is 'ascending'
          onClick: => @onClickSort 'ascending'

TableFilter.displayName = "TableFilter"

module.exports = TableFilter
