import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {build} from 'utils/container_helpers';
import {getUnitDates} from 'utils/date_normalizer';
import {formatLetterForModal, letterIndexInBucket, mergeClassroomWithArticleSelections, mergeClassroomWithMessages,
  nextLetterInBucket, numberOfLettersInBucket, previousLetterInBucket} from './normalization_helper';
import {firstnameComparator, focusArticleDateComparator, focusArticleStatusComparator, letterExchangeDateComparator,
letterExchangeStatusComparator, sortBy} from './sort_helper';
const style = require('./classroom_unit.styl');
const cn = require('classnames/bind').bind(style);
import unitsEvents from '../units/events';
import events from './events';
import articleSelectionsActions from 'actions/article_selections';
import classroomUnitsActions from 'actions/classroom_units';
import messagesActions from 'actions/messages';
import routingActions from 'actions/routing';

import Button from 'components/shared/button';
import Card from 'components/shared/card';
import Dropdown from 'components/shared/dropdown';
import LetterModal from 'components/letter_modal';
import Modal from 'components/shared/modal';
import FocusArticleStatusCell from 'components/focus_article_status_cell';
import LetterExchangeLetterCell from 'components/letter_exchange_letter_cell';
import SelectionModal from 'components/shared/selection_modal';
import Table from 'components/shared/table';
import TabView from 'components/shared/tab_view';
import UnitDetailDatePicker from 'components/unit_detail_date_picker';
import UnitDetail from 'components/unit_detail';
import Placeholder from 'components/shared/placeholder';
import LoadingSpinner from 'components/shared/loading_spinner';

const mapStateToProps = function({store: {articleSelections, classroom, classroomUnit, messages, messagesForClassroomUnit, messagesForClassroom}}) {
  if (classroomUnit && classroomUnit.data) {
    let letterExchangeData;
    if (classroomUnit.data._id !== classroom.data.currentUnit._id) {
      messages = messagesForClassroomUnit.data ? messagesForClassroomUnit.data[classroomUnit.data._id] : undefined;
      letterExchangeData = mergeClassroomWithMessages(classroom.data, messages);
    } else {
      letterExchangeData = mergeClassroomWithMessages(classroom.data, messages ? messages.data : undefined);
    }

    return {
      articleSelections: mergeClassroomWithArticleSelections(classroom.data, classroomUnit.data, articleSelections && articleSelections.data),
      classroom: classroom.data,
      classroomUnit: classroomUnit.data,
      letterExchangeData,
      messagesForClassroom: _.values(messagesForClassroom.data),
      unitActive: classroomUnit.data.state === 'active',
      unitCompleted: classroomUnit.data.state === 'completed',
    };
  }

  return {classroom: classroom.data};

};

class ClassroomUnit extends React.Component {

  constructor(props) {
    super(props);
    this.onClickLetterExchangeReviewButton = this.onClickLetterExchangeReviewButton.bind(this);
    this.onClickLetterExchangeViewButton = this.onClickLetterExchangeViewButton.bind(this);

    const tabIndices = {
      focus_articles: 0,
      letters: 1
    };

    this.state = {
      focusArticleComparator: focusArticleStatusComparator(1),
      isInReviewMode: false,
      letterExchangeComparator: firstnameComparator(1, 'student'),
      letterRole: null,
      modalLetter: null,
      selectedFocusArticleFilter: props.location.query.focusArticles || 'all',
      selectedLetterExchangeFilter: props.location.query.letters || 'all',
      selectedTabIndex: tabIndices[props.tab] || 0,
      showUnitDetail: !props.unitActive,
      pendingArticleSelections: {},
    };
  }


  componentDidMount() {
    const {classroom, params, dispatch} = this.props;
    let classroomUnitId = params.classroom_unit_id || classroom.currentUnit._id;

    dispatch(classroomUnitsActions.find(classroomUnitId, (classroomUnit) => {
      dispatch(articleSelectionsActions.fetch({classroomUnit_id: classroomUnit._id}));
      dispatch(messagesActions.fetchForClassroom(classroom, classroomUnit, true));
      dispatch(messagesActions.fetchAllForClassroom(classroom, true));
    }));
  }


  _articleTitle(articleId) {
    const {classroomUnit} = this.props;
    if (articleId) {
      const article = classroomUnit.content.find(x => x.id === articleId);
      if (!article) {
        console.warn(`Article '${articleId}' not found`);
        return `Article '${articleId}' not found`;
      }
      return article.title;
      // const articles = classroomUnit.content.filter(article => (article != null ? article.id : undefined) === articleId);
    }
  }

  onChangeFocusArticle(studentId, option) {
    const articleId = (option === null) ? undefined : option.value;
    const pendingArticleSelections = Object.assign(this.state.pendingArticleSelections, {[studentId]: articleId});
    this.setState({pendingArticleSelections});
  }

  onChangeFocusArticleFilter(option) {
    if (option != null ? option.value : undefined) {
      return this.setState({selectedFocusArticleFilter: option.value});
    }
  }

  onClickEditUnit() {
    const {classroomUnit, dispatch} = this.props;
    unitsEvents.editUnit(classroomUnit.state, 'Unit Page');
    return dispatch(routingActions.redirect(`/units/${classroomUnit._id}/edit`));
  }

  onClickFocusArticleApprove(data) {
    const {dispatch} = this.props;
    const approvedData = Object.assign({}, data, {status: 'approved'});
    events.focusArticle.approved({article: this._articleTitle(data.article_id)});
    return dispatch(articleSelectionsActions.update(approvedData));
  }

  onClickFocusArticleReject(data, reason) {
    const {dispatch} = this.props;
    const rejectedData = Object.assign({}, data, {rejectReason: reason, status: 'rejected'});
    return dispatch(articleSelectionsActions.update(rejectedData));
  }

  onClickFocusArticleSelect(studentId) {
    const {classroomUnit, dispatch} = this.props;
    const pendingArticleId = this.state.pendingArticleSelections[studentId];
    dispatch(articleSelectionsActions.create({
      article_id: pendingArticleId,
      classroomUnit_id: classroomUnit._id,
      student_id: studentId,
      status: 'approved'
    }));
  }

  onClickLetter(letter) {
    switch (letter.status) {
      case 'pending':
        return this.onClickLetterExchangeReviewButton(letter);
      default:
        return this.onClickLetterExchangeViewButton(letter);
    }
  }

  onClickLetterExchangeApproveButton(letter) {
    this.setState({modalLetter: null});
    return this.props.dispatch(messagesActions.updateStatus(letter, 'approved'));
  }

  onChangeLetterExchangeFilter(option) {
    if (option != null ? option.value : undefined) {
      return this.setState({selectedLetterExchangeFilter: option.value});
    }
  }

  onClickLetterExchangeRejectButton(letter, rejectMessage) {
    this.setState({modalLetter: null});
    return this.props.dispatch(messagesActions.updateStatus(letter, 'rejected', rejectMessage));
  }

  onClickLetterExchangeReviewButton(role) {
    return letter => {
      if (!letter) {
        return;
      }
      return this.setState({isInReviewMode: true, letterRole: role, modalLetter: letter});
    };
  }

  onClickLetterExchangeViewButton(role) {
    return letter => {
      if (!letter) {
        return;
      }
      return this.setState({isInReviewMode: false, letterRole: role, modalLetter: letter});
    };
  }

  onClickShowUnitToggle() {
    const prev = this.state.showUnitDetail;
    return this.setState({showUnitDetail: !prev});
  }

  onClickSortFocus(column, order) {
    const sortDirection = order === 'ascending' ? 1 : -1;
    let comparator;
    if (column === 'student') {
      comparator = firstnameComparator;
    } else if (column === 'status') {
      comparator = focusArticleStatusComparator;
    } else if (column === 'date') {
      comparator = focusArticleDateComparator;
    }

    const newState = {
      activeSort: `${column}Focus`,
      focusArticleComparator: comparator(sortDirection, column),
      [`${column}FocusSortOrder`]: order,
    };
    this.setState(newState);
  }

  onClickSortLetterExchange(column, order) {
    const sortDirection = order === 'ascending' ? 1 : -1;
    let comparator;
    if (column === 'studentName') {
      comparator = firstnameComparator(sortDirection, 'student');
    } else if (column === 'studentStatus') {
      comparator = letterExchangeStatusComparator(sortDirection, 'studentLetters');
    } else if (column === 'studentDate') {
      comparator = letterExchangeDateComparator(sortDirection, 'studentLetters');
    } else if (column === 'mentorName') {
      comparator = firstnameComparator(sortDirection, 'mentor');
    } else if (column === 'mentorStatus') {
      comparator = letterExchangeStatusComparator(sortDirection, 'mentorLetters');
    } else if (column === 'mentorDate') {
      comparator = letterExchangeDateComparator(sortDirection, 'mentorLetters');
    }

    const newState = {
      activeSort: column,
      letterExchangeComparator: comparator,
      [`${column}LetterOrder`]: order,
    };

    this.setState(newState);
  }

  renderFocusArticles() {
    const {articleSelections, classroom, classroomUnit} = this.props;
    const {currentUnit} = classroom;

    if ((currentUnit.isReflectionUnit && classroomUnit.isReflectionUnit) || currentUnit.isDefaultUnit) {
      return (
          <div className={cn('focus-article-placeholder')}>
            <Placeholder
                icon={<div className={cn('placeholder-icon-container')}>
                  <div className={cn('placeholder-icon')}/>
                </div>}
                text={t('classroomUnitPage.noFocusArticles')}/>
          </div>
      );
    }

    const missingCount = articleSelections.filter(x => ['missing', 'rejected'].includes(x.status)).length;
    const totalCount = articleSelections.length;
    let data = sortBy(this.state.focusArticleComparator, articleSelections);

    if (this.state.selectedFocusArticleFilter !== 'all') {
      data = data.filter(({status}) => status === this.state.selectedFocusArticleFilter);
    }

    const filterOptions = ['all', 'pending', 'approved', 'rejected', 'missing'].map(value => {
      return {label: t(`classroomUnitPage.filter.${value}`), value};
    });

    const articleOptions = classroomUnit.content.map( x => {
      const lexile = x.lexile && ` (${x.lexile}L)`;
      return {
        value: x._id,
        label: `${x.title}${lexile && lexile}`
      }
    });

    const tableColumns = [
      {
        key: 'student',
        header: t('classroomUnitPage.student'),
        flex: 2,
        sortOrder: (this.state.activeSort === 'studentFocus' ? this.state.studentFocusSortOrder : undefined),
        transformValue(data) {
          if (!data.student) {
            return;
          }
          return <div className={cn('focus-article-column')}>{data.student.displayName}</div>;
        }
      },
      {
        key: 'article',
        header: t('classroomUnitPage.articleSelection'),
        flex: 4,
        isSortingDisabled: true,
        transformValue: data => {
          const article = classroomUnit.content.find(({article_id}) => article_id === data.article_id);
          return (
              <div className={cn('focus-article-column')}>
                {article &&
                <div className={cn('article-selection')}>
                  <div className={cn('article-title')}>{article.title}</div>
                  <div className={cn('article-metadata')}>
                    {article.grades &&
                    <div className={cn('article-grades')}>
                      {t('classroomUnitPage.grades', {grades: article.grades})}
                    </div>
                    }
                    {article.lexile &&
                    <div className={cn('article-lexile')}>
                      {t('classroomUnitPage.lexile', {lexile: article.lexile})}
                    </div>
                    }
                  </div>
                </div>
                }
                {!article &&
                <div className={cn('article-dropdown')}>
                  <Dropdown clearable={true} disabled={false}
                            onChange={(option) => this.onChangeFocusArticle(data.student._id, option)}
                            options={articleOptions}
                            value={this.state.pendingArticleSelections[data.student._id]}/>
                </div>
                }
              </div>
          );
        }
      },
      {
        key: 'status',
        header: t('classroomUnitPage.status'),
        flex: 2,
        sortOrder: (this.state.activeSort === 'statusFocus' ? this.state.statusFocusSortOrder : undefined),
        transformValue: data => {
          const studentId = data.student._id;
          const pendingArticleSelection = this.state.pendingArticleSelections[studentId];
          if (pendingArticleSelection) {
            return (
                <Button size='small' type='regular' onClick={() => this.onClickFocusArticleSelect(data.student._id)}>
                  {t('classroomUnit.selectArticle')}
                </Button>
            );
          }
          return (
              <div className={cn('focus-article-column')}>
                <FocusArticleStatusCell
                    article={this._articleTitle(data.article_id)}
                    onApproveButtonClick={() => this.onClickFocusArticleApprove(data)}
                    onRejectButtonClick={(reason) => this.onClickFocusArticleReject(data, reason)}
                    readOnly={classroom.status !== 'active'}
                    rejectDate={data['modified']}
                    rejectReason={data['rejectReason']}
                    status={data.status}/>
              </div>
          );
        }
      },
      {
        key: 'date',
        header: t('classroomUnitPage.date'),
        sortOrder: (this.state.activeSort === 'dateFocus' ? this.state.dateFocusSortOrder : undefined),
        transformValue: data => {
          if (!data.status) {
            return;
          }
          if (data['status'] === 'missing') {
            const {unitDates} = classroomUnit;
            if (!unitDates) {
              return <div className={cn('focus-article-column')}>-</div>;
            }
            return (
                <div className={cn('focus-article-column')}>
                  {unitDates.focusArticleDate && t('classroomUnitPage.dueDate', {date: moment(unitDates.focusArticleDate).format('MMM DD')})}
                </div>
            );
          } else {
            return <div className={cn('focus-article-column')}>{moment(data.created).format('MMM DD')}</div>;
          }
        }
      }
    ];

    return (
        <div className={cn('focus-article-container')}>
          <div className={cn('focus-article-container-header')}>
            <div className={cn('focus-article-caption')}>
              {t('classroomUnitPage.missingFocusArticle', {missingCount, totalCount})}
              <a className={cn('missing')} onClick={() => this.setState({selectedFocusArticleFilter: 'missing'})}>
                {t('classroomUnitPage.whosMissing')}
              </a>
              <a className={cn('missing')} onClick={() => this.setState({selectedFocusArticleFilter: 'rejected'})}>
                {t('classroomUnitPage.whosRejected')}
              </a>
            </div>

            <div className={cn('focus-article-filter-container')}>
              <div className={cn('focus-article-filter-label')}>{t('classroomUnitPage.filter.status')}</div>
              <div className={cn('focus-article-filter')}>
                <Dropdown clearable={false} disabled={false}
                          onChange={(option) => this.onChangeFocusArticleFilter(option)}
                          options={filterOptions}
                          value={this.state.selectedFocusArticleFilter}/>
              </div>
            </div>
          </div>
          <div className={cn('focus-article-table')}>
            <Table columns={tableColumns} data={data} isPagingEnabled={false}
                   onClickHeader={(column, order) => this.onClickSortFocus(column, order)}/>
          </div>
        </div>
    );
  }

  renderLetterExchange() {
    const {classroom, classroomUnit, letterExchangeData, unitActive} = this.props;

    const studentsCount = classroom.students.length;
    const missingCount = _.reduce(letterExchangeData,
        function (missingCount, data) {
          const isMissing = data.studentLetters
              .every(letter => (letter.status === 'missing') || (letter.status === 'draft'));

          if (isMissing) {
            return missingCount + 1;
          } else {
            return missingCount;
          }
        }, 0);

    let data = sortBy(this.state.letterExchangeComparator, letterExchangeData);
    if (this.state.selectedLetterExchangeFilter !== 'all') {
      data = data.filter(({mentor, mentorLetters, student, studentLetters}) => {
        return [mentorLetters, studentLetters].some(letters => {
          return letters.some(({status}) => {
            return status === this.state.selectedLetterExchangeFilter;
          });
        });
      });
    }

    const filterOptions = ['all', 'pending', 'revisionPending', 'approved', 'revisionApproved',
      'rejected', 'missing', 'draft'].map(value => {
      return {label: t(`classroomUnitPage.filter.${value}`), value}
    });

    const tableColumns = [
      {
        key: 'studentName',
        header: t('classroomUnitPage.student'),
        headerFlex: 1.5,
        flex: 1.5,
        sortOrder: (this.state.activeSort === 'studentName' ? this.state.studentNameLetterOrder : undefined),
        transformValue(data) {
          return <div className={cn('letter-exchange-column')}>{data['student'] && data['student'].displayName}</div>;
        }
      },
      {
        key: 'studentStatus',
        header: t('classroomUnitPage.status'),
        headerFlex: 1,
        headerIsCentered: true,
        flex: 3,
        sortOrder: (this.state.activeSort === 'studentStatus' ? this.state.studentStatusLetterOrder : undefined),
        transformValue: data => {
          return (
              <div className={cn('letter-status-column')}>
                <LetterExchangeLetterCell
                    data={data['studentLetters']}
                    letterDueDate={classroomUnit.unitDates ? classroomUnit.unitDates.studentDate : undefined}
                    onReviewButtonClick={this.onClickLetterExchangeReviewButton('student')}
                    onViewButtonClick={this.onClickLetterExchangeViewButton('student')}
                    readOnly={classroom.status !== 'active'}
                    role='student'
                    unitType={unitActive ? 'current' : 'past'}/>
              </div>
          );
        }
      },
      {
        key: 'letter',
        header: t('classroomUnitPage.letter'),
        headerFlex: 1,
        headerIsCentered: true,
        isDisabled: true,
        isSortingDisabled: true,
      },
      {
        key: 'studentDate',
        header: t('classroomUnitPage.date'),
        headerFlex: 1,
        headerIsCentered: true,
        isDisabled: true,
        sortOrder: (this.state.activeSort === 'studentDate' ? this.state.studentDateLetterOrder : undefined)
      },
      {isSeparator: true},
      {
        key: 'mentorName',
        flex: 1.5,
        header: t('classroomUnitPage.penpal'),
        headerFlex: 1.5,
        sortOrder: (this.state.activeSort === 'mentorName' ? this.state.mentorNameLetterOrder : undefined),
        transformValue(data) {
          return <div className={cn('letter-exchange-column')}>{data['mentor'] && data['mentor'].displayName}</div>;
        }
      },
      {
        key: 'mentorStatus',
        flex: 3,
        header: t('classroomUnitPage.status'),
        headerFlex: 1,
        headerIsCentered: true,
        sortOrder: (this.state.activeSort === 'mentorStatus' ? this.state.mentorStatusLetterOrder : undefined),
        transformValue: data => {
          return (
              <div className={cn('letter-status-column')}>
                <LetterExchangeLetterCell
                    data={data['mentorLetters']}
                    letterDueDate={classroomUnit.unitDates ? classroomUnit.unitDates.mentorDate : undefined}
                    onReviewButtonClick={this.onClickLetterExchangeReviewButton('mentor')}
                    onViewButtonClick={this.onClickLetterExchangeViewButton('mentor')}
                    readOnly={classroom.status !== 'active'}
                    role='mentor'
                    unitType={unitActive ? 'current' : 'past'} />
              </div>
          );
        }
      },
      {
        key: 'letter',
        header: t('classroomUnitPage.letter'),
        headerFlex: 1,
        headerIsCentered: true,
        isDisabled: true,
        isSortingDisabled: true,
      },
      {
        key: 'mentorDate',
        header: t('classroomUnitPage.date'),
        headerFlex: 1,
        headerIsCentered: true,
        isDisabled: true,
        sortOrder: (this.state.activeSort === 'mentorDate' ? this.state.mentorDateLetterOrder : undefined)
      }
    ];

    return (
        <div className={cn('letter-exchange-container')}>
          <div className={cn('letter-exchange-container-header')}>
            <div className={cn('letter-exchange-caption')}>
              {t('classroomUnitPage.missingLetters', {missingCount, studentsCount})}

              <a className={cn('missing')} onClick={() => this.setState({selectedLetterExchangeFilter: 'missing'})}>
                {t('classroomUnitPage.whosMissing')}
              </a>

              <a className={cn('missing')} onClick={() => this.setState({selectedLetterExchangeFilter: 'draft'})}>
                {t('classroomUnitPage.whosDraft')}
              </a>
            </div>
            <div className={cn('letter-exchange-filter-container')}>
              <div className={cn('letter-exchange-filter-label')}>{t('classroomUnitPage.filter.status')}</div>
              <div className={cn('letter-exchange-filter')}>
                <Dropdown clearable={false} disabled={false}
                          onChange={(option) => this.onChangeLetterExchangeFilter(option)}
                          options={filterOptions} value={this.state.selectedLetterExchangeFilter}/>
              </div>
            </div>
          </div>
          <div className={cn('letter-exchange-table')}>
            <Table columns={tableColumns} data={data} isPagingEnabled={false}
                   onClickHeader={(column, order) => this.onClickSortLetterExchange(column, order)}/>
          </div>
        </div>
    );
  }

  renderLetterModal() {
    if (!this.state.modalLetter) {
      return;
    }

    const {letterExchangeData, unitActive} = this.props;
    const letter = this.state.modalLetter;

    const indexOfMessageInConversation = letterIndexInBucket(letterExchangeData, letter);
    const nextLetter = nextLetterInBucket(letterExchangeData, letter);
    const previousLetter = previousLetterInBucket(letterExchangeData, letter);
    const totalMessagesInConversation = numberOfLettersInBucket(letterExchangeData, letter);

    const next = {
      onClick: () => this.onClickLetter(this.state.letterRole)(nextLetter),
      visible: (nextLetter != null)
    };

    const previous = {
      onClick: () => this.onClickLetter(this.state.letterRole)(previousLetter),
      visible: (previousLetter != null)
    };

    return (
        <div className={cn('modal')}>
          <Modal maxHeight={true} next={next} previous={previous} top={true}
                 onClose={() => this.setState({modalLetter: null})}>
            <LetterModal role={this.state.letterRole} print={true} reduced={true} events={events}
                         message={formatLetterForModal(this.state.modalLetter)}
                         indexOfMessageInConversation={indexOfMessageInConversation + 1}
                         isInReviewMode={this.state.isInReviewMode}
                         isNextMessageButtonEnabled={indexOfMessageInConversation < (totalMessagesInConversation - 1)}
                         isPreviousMessageButtonEnabled={indexOfMessageInConversation > 0}
                         isReplyButtonEnabled={false}
                         onReviewModeApproveButtonClick={(letter) => this.onClickLetterExchangeApproveButton(letter)}
                         onReviewModeRejectButtonClick={(letter, rejectMessage) => this.onClickLetterExchangeRejectButton(letter, rejectMessage)}
                         totalMessagesInConversation={totalMessagesInConversation}
                         unitType={unitActive ? 'current' : 'past'}/>
          </Modal>
        </div>
    );
  }

  renderStartModal() {
    const {classroom, classroomUnit, messagesForClassroom, dispatch} = this.props;
    const allDatesSelected = getUnitDates(classroomUnit).every(({value}) => value != null);

    const mentors = classroom.studentMentorGroups
        .filter(({mentor}) => mentor != null)
        .map(({mentor}) => mentor);
    const students = classroom.students.map(({id}) => id);
    const mentorMessages = messagesForClassroom.filter(({from, classroomUnitID}) => {
      return (classroomUnitID === classroom.currentUnit._id) && mentors.includes(from.id);
    });
    const studentMessages = messagesForClassroom.filter(({from, classroomUnitID}) => {
      return (classroomUnitID === classroom.currentUnit._id) && students.includes(from.id);
    });
    const mentorsWithMessages = new Set((mentorMessages.map(({from: {id}}) => id)));
    const studentsWithMessages = new Set((studentMessages.map(({from: {id}}) => id)));
    const missingMessageCountMentor = mentors.length - mentorsWithMessages.size;
    const missingMessageCountStudent = students.length - studentsWithMessages.size;
    const draftMessageCountMentor = mentorMessages.filter(({state}) => state === 'newMessageDraft').length;
    const draftMessageCountStudent = studentMessages.filter(({state}) => state === 'newMessageDraft').length;
    const revisionMessageCountMentor = mentorMessages.filter(({status}) => status === 'rejected').length;
    const revisionMessageCountStudent = studentMessages.filter(({status}) => status === 'rejected').length;
    const pendingMessageCountMentor = mentorMessages.filter(({state, status}) => (status === 'pending') && (state === 'delivered')).length;
    const pendingMessageCountStudent = studentMessages.filter(({state, status}) => (status === 'pending') && (state === 'delivered')).length;

    const content = [t('classroomUnit.confirmStartSubtitle')];
    const unfinishedItems = [];
    if (missingMessageCountStudent) {
      unfinishedItems.push(
          <li key='missingStudent'>{t('classroomUnit.unfinishedItem.missingStudent', {count: missingMessageCountStudent})}</li>);
    }
    if (missingMessageCountMentor) {
      unfinishedItems.push(
          <li key='missingMentor'>{t('classroomUnit.unfinishedItem.missingMentor', {count: missingMessageCountMentor})}</li>);
    }
    if (draftMessageCountStudent + draftMessageCountMentor) {
      unfinishedItems.push(
          <li key='draft'>{t('classroomUnit.unfinishedItem.draft', {count: draftMessageCountStudent + draftMessageCountMentor})}</li>);
    }
    if (revisionMessageCountStudent + revisionMessageCountMentor) {
      unfinishedItems.push(
          <li key='revision'>{t('classroomUnit.unfinishedItem.revision', {count: revisionMessageCountStudent + revisionMessageCountMentor})}</li>);
    }
    if (pendingMessageCountStudent + pendingMessageCountMentor) {
      unfinishedItems.push(
          <li key='pending'>{t('classroomUnit.unfinishedItem.pending', {count: pendingMessageCountStudent + pendingMessageCountMentor})}</li>);
    }

    if (unfinishedItems.length) {
      content.push(t('classroomUnit.unfinishedItem.preText'));
      content.push(<ul className={cn('start-unit-list')}>{unfinishedItems}</ul>);
      content.push(t('classroomUnit.unfinishedItem.postText'));
    }

    return (
        <div className={cn('start-modal')}>
          <SelectionModal
              closeButtons={[
                {
                  element: <Button size='large' type='regular'>{t('classroomUnit.cancelStart')}</Button>,
                  onClick() {
                    return unitsEvents.canceledStart();
                  }
                },
                {
                  element: <Button size='large' type='regular'>{t('classroomUnit.submitStart')}</Button>,
                  onClick() {
                    unitsEvents.startedUnit();
                    return dispatch(classroomUnitsActions.startUnit(classroomUnit));
                  }
                }
              ]}
              content={content}
              enabled={allDatesSelected}
              onModalCrossButtonClick={() => {
                return unitsEvents.canceledStart()
              }}
              title={t('classroomUnit.confirmStartTitle')}
              triggerAction={() => {
                return unitsEvents.startingUnit()
              }}
              triggerButtonTitle={t('classroomUnit.startUnit')}/>
        </div>
    );
  }

  renderUnitButtons() {
    const {classroom, unitActive} = this.props;
    const classroomIsActive = classroom.status === 'active';
    if (classroomIsActive) {
      return (
          <div className={cn('button_row')}>
            <div className={cn('button')}>
              <Button size='large' type='regular' onClick={() => this.onClickEditUnit()}>
                {t('classroomUnitPage.editUnit')}
              </Button>
            </div>
            {unitActive &&
            <div className={cn('unit_active')}>
              <div className={cn('check')}/>
              {t('classroomUnitPage.unitActive')}
            </div>
            }
            {!unitActive &&
            <div className={cn('button')}>
              {this.renderStartModal()}
            </div>
            }
          </div>
      );
    }
  }

  render() {
    const {classroomUnit, unitActive, unitCompleted} = this.props;
    const isDataReady = !!classroomUnit;
    if (!isDataReady) {
      return <LoadingSpinner/>;
    }

    const unitDetailCollapsible = classroomUnit.state === 'active';

    return (
        <div className={cn('classroom-unit-container')}>
          {this.renderLetterModal()}
          <div className={cn('classroom_unit')}>
            <Card>
              <div className={cn('card')}>
                <div className={cn('card-header')}>
                  <div className={cn('title_group')}>
                    <div className={cn('title')}>
                      {classroomUnit.name}
                      {unitDetailCollapsible && this.state.showUnitDetail &&
                      <a className={cn('details')} onClick={() => this.onClickShowUnitToggle()}>
                        <span className={cn('minus')}/>
                        <span>{t('classroomUnitPage.hideUnitDetails')}</span>
                      </a>
                      }
                      {unitDetailCollapsible && !this.state.showUnitDetail &&
                      <a className={cn('details')} onClick={() => this.onClickShowUnitToggle()}>
                        <span className={cn('plus')}/>
                        <span>{t('classroomUnitPage.showUnitDetails')}</span>
                      </a>
                      }
                    </div>
                  </div>
                  {classroomUnit.state !== 'completed' && this.renderUnitButtons()}
                </div>
                {this.state.showUnitDetail &&
                <div className={cn('card-body')}>
                  <UnitDetail unit={classroomUnit} mode={classroomUnit.state} />
                </div>
                }
                <div className={cn('card-footer')}>
                  <UnitDetailDatePicker dates={getUnitDates(classroomUnit)} isEditable={false}/>
                </div>
              </div>
            </Card>

            {(unitActive || unitCompleted) &&
            <div className={cn('tabs')}>
              <TabView
                  onSelect={index => this.setState({selectedTabIndex: index})}
                  selectedIndex={this.state.selectedTabIndex}
                  tabs={[{content: this.renderFocusArticles(), name: t('classroomUnitPage.focusArticles')},
                         {content: this.renderLetterExchange(), name: t('classroomUnitPage.letterExchange')}]}/>
            </div>
            }
          </div>
        </div>
    );
  }
}

ClassroomUnit.displayName = "ClassroomUnit";
ClassroomUnit.defaultProps = { tab: 'focus_articles' };
ClassroomUnit.propTypes = {
  tab: PropTypes.oneOf(['focus_articles', 'letters']).isRequired
};

module.exports = build({
  component: ClassroomUnit,
  mapStateToProps
});
