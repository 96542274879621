({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
{map, sortBy} = require 'lodash'
style = require './article_collection.styl'
e = require('react-e/bind') style
{getInstructions, getTitle} = require './localization_helper'
ArticlePreview = createFactory require 'components/shared/article_preview/index.jsx'
Carousel = createFactory require 'components/shared/carousel'

class ArticleCollection extends Component

  @propTypes:
    arrow: PropTypes.bool.isRequired
    articleSelection: PropTypes.shape
      id: PropTypes.string
      rejectReason: PropTypes.string
      status: PropTypes.oneOf [
        'approved'
        'pending'
        'rejected'
        'none'
      ]
    articles: PropTypes.array.isRequired
    isDefaultUnit: PropTypes.bool.isRequired
    onClick: PropTypes.func.isRequired
    readEvent: PropTypes.func.isRequired
    role: PropTypes.oneOf ['student', 'mentor']
    scrollPosition: PropTypes.oneOfType [PropTypes.bool, PropTypes.number]
    selectType: PropTypes.oneOf ['radio', 'check']
    selectedArticleId: PropTypes.string,
    onSelect: PropTypes.func,
    palName: PropTypes.string

  _renderArrow: ->
    e '.bar',
      e '.easier', t 'articleCollection.easier'
      e '.line'
      e '.harder', t 'articleCollection.harder'


  _renderRejectReason: =>
    e '.guidance',
      e '.header', t 'articleCollection.rejectReason'
      e '.rule'
      e '.reason', @props.articleSelection.rejectReason


  render: ->
    e '.article-collection',
      e '.title', getTitle @props.role, @props.isDefaultUnit, @props.articleSelection.status
      if not @props.isDefaultUnit
        e '.instructions', getInstructions(
          @props.role, @props.isDefaultUnit, @props.articleSelection.status, @props.palName
        )
      else
        e '.instructions', t 'articleCollection.introUnitInstructions'
      if @props.role is 'student' and @props.articleSelection.rejectReason
        @_renderRejectReason()
      if @props.articles.length > 1 and @props.arrow then @_renderArrow()
      e '.articles',
        Carousel
          scrollPosition: @props.scrollPosition
          map sortBy(@props.articles, 'lexile'), (article) =>
            e '.carousel-article', key: article._id,
              ArticlePreview
                article: article
                focus:
                  if not @props.isDefaultUnit and @props.articleSelection.id is article._id
                    # don't disable rejected focus articles for mentors
                    if @props.role is 'mentor' and @props.articleSelection.status isnt 'approved'
                      'none'
                    else
                      @props.articleSelection.status
                  else
                    'none'
                onClickLink: => @props.onClick article._id, article.title
                onClickRead: => @props.readEvent article.title
                selectType: @props.selectType
                onSelect: () => @props.onSelect(article)
                isSelected: @props.selectedArticleId is article._id
                scheme: 'plain'

ArticleCollection.displayName = "ArticleCollection"

module.exports = ArticleCollection
