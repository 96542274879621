{createStore, applyMiddleware} = require 'redux'
{default: thunk} = require 'redux-thunk'
createLogger = require 'redux-logger'
reducers = require '../reducers'

if process.env.NODE_ENV in ['production', 'test-unit']
  middleware = [
      thunk
  ]
else
  middleware = [
      thunk
      REDUX_LOGGER
  ].filter(Boolean)

module.exports = configureStore = (initialState) ->
  createStore(
    reducers,
    initialState,
    applyMiddleware(middleware...)
  )
