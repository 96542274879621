{createFactory} = require 'react'
_ = require 'lodash'
style = require './unit_preview.styl'
e = require('react-e/bind') style
events = require '../units/events'
{build} = require 'utils/container_helpers'
routingActions = require 'actions/routing'
unitPreviewActions = require 'actions/unit_preview'
UnitPreviewBody = createFactory require './unit_preview_body.jsx'
ContentLoaderContainer = createFactory require 'containers/content_loader_container'

_descriptionText = e '.description',
  e 'ol',
    e 'li', t 'unitPreview.desc.line1'
    e 'li', t 'unitPreview.desc.line2'

UnitPreviewView = (props) ->
  {dispatch} = props
  {template_id} = props.params

  ContentLoaderContainer
    getContentFromStore: ({unitTemplate}) -> {unitTemplate}
    getError: ({unitTemplate}) -> unitTemplate.error
    getLoading: ({unitTemplate}) -> unitTemplate.loading
    getReady: ({unitTemplate}) -> unitTemplate.data? and unitTemplate.data._id is template_id
    loadContent: ->
      dispatch unitPreviewActions.find template_id
    name: 'unit'
    renderChild: ({unitTemplate}) ->

      UnitPreviewBody _.assign {}, props,
        buttonContent: 'Add Unit'
        buttonIsEnabled: -> true
        buttonOnClickWithState: (state, testMode) ->
          events.addingUnit unitTemplate.data.name
          if testMode
            dispatch routingActions.redirect "/units/explore/#{template_id}/new/sample"
          else
            dispatch routingActions.redirect "/units/explore/#{template_id}/new"
        datePickerIsEditable: false
        datePickerShouldOpenFirstPicker: false
        datePickerShouldShow: false
        datePickerShouldStartWithToday: true
        descriptionHeader: t 'unitPreview.previewDescHeader'
        descriptionText: _descriptionText
        showChecks: false
        unitTemplate: unitTemplate.data

UnitPreviewView.displayName = "UnitPreviewView"

module.exports = build
  component: UnitPreviewView
