analytics = require 'utils/analytics'

category = 'Library'

module.exports =

  details: (article) ->
    analytics.recordEvent
      action: 'Viewed Article Details'
      category: category
      label: article

  readArticle: (article) ->
    analytics.recordEvent
      action: 'Read Article'
      category: category
      label: article
