_ = require 'lodash'
moment = require 'moment'

firstnameComparator = (sortDirection = 1, userKey) -> (data) ->
  return [] unless data

  data.sort (a, b) ->
    aFirstname = a[userKey]?.displayName.toLowerCase()
    bFirstname = b[userKey]?.displayName.toLowerCase()

    return -1 * sortDirection if aFirstname < bFirstname
    return sortDirection if aFirstname > bFirstname
    return 0


getField = (data, key, field) -> data[key]?[0]?[field]


sortBy = (comparator, data) ->
  return [] unless data
  comparator data


module.exports =

  firstnameComparator: firstnameComparator


  focusArticleDateComparator: (sortDirection = 1) -> (data) ->
    return [] unless data

    relevant = data.filter (d) ->
      d.status isnt 'missing'
    irrelevant = data.filter (d) ->
      d.status is 'missing'
    relevant = relevant.sort (a, b) ->
      return moment.utc(a['updated_at']).diff(moment.utc(b['updated_at'])) * sortDirection

    if sortDirection is 1
      return relevant.concat irrelevant
    else
      return irrelevant.concat relevant


  focusArticleStatusComparator: (sortDirection = 1) -> (data) ->
    return [] unless data

    comparator = firstnameComparator(sortDirection, 'student')
    pending = sortBy comparator, data.filter (d) -> d.status is 'pending'
    missing = sortBy comparator, data.filter (d) -> d.status is 'missing'
    rejected = sortBy comparator, data.filter (d) -> d.status is 'rejected'
    approved = sortBy comparator, data.filter (d) -> d.status is 'approved'

    if sortDirection is 1
      return pending.concat missing, rejected, approved
    else
      return approved.concat rejected, missing, pending


  letterExchangeDateComparator: (sortDirection = 1, key) -> (data) ->
    return [] unless data

    relevant = data
    .filter (d) -> getField(d, key, 'status') isnt 'missing'

    irrelevant = data
    .filter (d) -> getField(d, key, 'status') is 'missing'

    relevant = relevant.sort (a, b) ->
      return moment
        .utc(a[key]?[0]?['modified'])
        .diff(moment.utc(b[key]?[0]?['modified'])) * sortDirection

    if sortDirection is 1
      return relevant.concat irrelevant
    else
      return irrelevant.concat relevant


  letterExchangeStatusComparator: (sortDirection = 1, key) -> (data) ->
    return [] unless data

    pending = data.filter (d) -> getField(d, key, 'status') is 'pending'
    missing = data.filter (d) -> getField(d, key, 'status') is 'missing'
    rejected = data.filter (d) -> getField(d, key, 'status') is 'rejected'
    approved = data.filter (d) -> getField(d, key, 'status') is 'approved'

    if sortDirection is 1
      return pending.concat missing, rejected, approved
    else
      return approved.concat rejected, missing, pending


  sortBy: sortBy
