{Component, createFactory} = require 'react'
style = require './not_authorized.styl'
e = (require 'react-e/bind') style
{build} = require 'utils/container_helpers'
{redirect} = require 'actions/routing'
{session} = require 'actions'
Login = createFactory require 'components/shared/login'
Card = createFactory require 'components/shared/card'

class NotAuthorized extends Component

  render: ->
    redirectPath = @props.location.pathname+@props.location.search
    e '.login',
      Card {},
        Login
          error: @props.session.error
          loading: @props.session.loading
          onSubmit: (credentials) => @props.dispatch(session.login credentials,
            => @props.dispatch redirect redirectPath)

NotAuthorized.displayName = "NotAuthorized"

module.exports = build {
  component: NotAuthorized
  mapStateToProps: ({store}) -> session: store.session
}
