// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._30cd14c059af7c26536ed896c9db2361-styl {\n  background-color: var(--theme-white, #fff);\n  border-radius: 0.625rem;\n  position: relative;\n  width: 100%;\n}\n.e534713709c9ea7d36fa63d46236d02d-styl {\n  border-radius: 0.625rem;\n}\n.ff331f8ef43057f0e87025f1ebb7c615-styl {\n  margin-top: 2.625rem;\n  padding-bottom: 1.75rem;\n}\n._304fc7eebd91666303b063e4c7709c6a-styl {\n  align-items: center;\n  background-color: var(--theme-green-dark, #78c23a);\n  border-radius: 50%;\n  display: flex;\n  height: 4.375rem;\n  justify-content: center;\n  left: calc(50% - 2.1875rem);\n  position: absolute;\n  top: -2.625rem;\n  width: 4.375rem;\n}\n", "",{"version":3,"sources":["webpack://cricket-together/app/components/shared/card/card.styl"],"names":[],"mappings":"AAUA;EACE,0CAA+C;EAC/C,uBAAc;EACd,kBAAS;EACT,WAAM;AATR;AAWA;EACE,uBAAc;AAThB;AAWA;EACE,oBAAW;EACX,uBAAwC;AAT1C;AAWA;EACE,mBAAY;EACZ,kDAAyD;EACzD,kBAAc;EACd,aAAQ;EACR,gBAAO;EACP,uBAAgB;EAChB,2BAAoD;EACpD,kBAAS;EACT,cAA6B;EAC7B,eAAM;AATR","sourcesContent":["@import 'variables'\n\n$icon-container-height = 4.375rem\n$icon-container-overlap = 2.625rem\n$icon-container-width = 4.375rem\n$icon-height = 3.25rem\n$icon-width = 3.25rem\n$center-circle-container-radius = 4.3125rem\n$card-border-radius = 0.625rem\n\n.card\n  background-color var(--theme-white,$theme-white)\n  border-radius .625rem\n  position relative\n  width 100%\n\n.card-container\n  border-radius $card-border-radius\n\n.icon-container\n  margin-top $icon-container-overlap\n  padding-bottom $icon-container-height - $icon-container-overlap\n\n.icon-container-circle\n  align-items center\n  background-color var(--theme-green-dark,$theme-green-dark)\n  border-radius 50%\n  display flex\n  height $icon-container-height\n  justify-content center\n  left \"calc(50% - %s)\" % ($icon-container-width / 2.0)\n  position absolute\n  top -($icon-container-overlap)\n  width $icon-container-width\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "_30cd14c059af7c26536ed896c9db2361-styl",
	"card-container": "e534713709c9ea7d36fa63d46236d02d-styl",
	"icon-container": "ff331f8ef43057f0e87025f1ebb7c615-styl",
	"icon-container-circle": "_304fc7eebd91666303b063e4c7709c6a-styl"
};
module.exports = ___CSS_LOADER_EXPORT___;
