analytics = require 'utils/analytics'

categories =
  introVideo: 'Played Intro Video'

module.exports =

  clickedVideo: (source) ->
    analytics.recordEvent
      action: 'Played Video'
      category: categories.introVideo
      label: source
