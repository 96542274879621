api = require '../api'

module.exports =

  fetch: ->
    api.send
      method: 'GET'
      path: "/api/v2/units?tenantID=#{TENANT}&status=active"

  find: (id) ->
    api.send
      method: 'GET'
      path: "/api/v2/units/#{id}"
