import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import UserHelpers from "utils/user_helpers";
import Button from 'components/shared/button';
import usersActions from 'actions/users';
import classroomsActions from 'actions/classrooms';
const style = require('./onboarding.styl');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
import {redirect} from 'actions/routing';
const _ = require('lodash');

const mapStateToProps = ({store: {user, sessions, classrooms, tenant}}) => ({
  user: UserHelpers.getTransformedUser(user.data, sessions.data, classrooms.data, tenant.data),
  classrooms: classrooms.data,
  sessions: sessions.data,
  tenant: tenant.data,
});


const ItemBackgroundCheck = ({user, dispatch}) => {
  const check = user.backgroundCheck;
  return (
      <div className={cn('checklist-item')}>
        <div className={cn('text')}>
          <span className={cn('icon', 'icon-thumbs-up')} />
          {(!check || check.isExpired || check.status === 'cancelled') &&
          <a  onClick={() => dispatch(redirect('/onboarding/background'))}>
            {t('onboarding.background.itemText')}
          </a>
          }
          {(check && !check.isExpired && check.status !== 'cancelled') &&
          <span>{t('onboarding.background.itemText')}</span>
          }
        </div>
        {check && check.status === 'created' &&
        <span className={cn('label')}>REQUESTED</span>
        }
        {check && ['invited', 'submitted', 'needsreview', 'failed'].includes(check.status) &&
        <span className={cn('label')}>INVITED</span>
        }
        {check && check.status === 'needsinfo' &&
        <span className={cn('label')}>ACTION REQUIRED</span>
        }
        {check && check.status === 'passed' && !check.isExpired &&
        <span className={cn('icon', 'icon-check-2')} />
        }
        {check && check.status === 'passed' && check.isExpired &&
        <a className={cn('action-text')}  onClick={() => dispatch(redirect('/onboarding/background'))}>
          Go
        </a>
        }
        {(!check || check.status === 'cancelled') &&
        <a className={cn('action-text')}  onClick={() => dispatch(redirect('/onboarding/background'))}>
          Go
        </a>
        }
      </div>
    );

};

const ItemTraining = ({user, dispatch}) => {
  const backgroundCheck = user.backgroundCheck;
  const showButton = (!user.backgroundCheckRequired || (backgroundCheck && !backgroundCheck.isExpired)) && !user.trainingIsComplete;
  const numVideosRemaining = user.trainingRequired - user.trainingWatched;
  const text = t('onboarding.training.itemText');

  return (
      <div className={cn('checklist-item')}>
        <div className={cn('text')}>
          <span className={cn('icon', 'icon-watch-video')}/>
          {(showButton || user.trainingIsComplete) &&
          <a onClick={() => dispatch(redirect('/onboarding/training'))}>{text}</a>
          }
          {!showButton && !user.trainingIsComplete && text}
        </div>
        {showButton &&
        <a className={cn('action-text')} onClick={() => dispatch(redirect('/onboarding/training'))}>
          Go
        </a>
        }
        {user.trainingIsComplete &&
        <span className={cn('icon', 'icon-check-2')}/>
        }
      </div>
  );
};

const ItemLetter = ({user, dispatch}) => {
  const backgroundCheck = user.backgroundCheck;
  const prerequisitesCompleted = (!user.backgroundCheckRequired || (backgroundCheck && backgroundCheck.status)) && user.trainingIsComplete;
  const letter = user.introLetter;

  let text = t('onboarding.letter.itemText');
  if (letter) {
    const letterStarted =
        (letter.answers && letter.answers.length > 0) ||
        (letter.text && letter.text.length > 0);
    if (letter.status === 'draft' && letterStarted) {
      text = t('onboarding.letter.itemTextFinish');
    }
  }

  return (
      <div className={cn('checklist-item')}>
        <div className={cn('text')}>
          <span className={cn('icon', 'icon-write-letter')}/>
          {prerequisitesCompleted && (!letter || ['draft', 'rejected'].includes(letter.status)) &&
          <a onClick={() => dispatch(redirect("/onboarding/letter"))}>{text}</a>
          }
          {(!prerequisitesCompleted || (letter && ['review', 'approved'].includes(letter.status))) && text}
        </div>
        {prerequisitesCompleted && !letter &&
        <a className={cn('action-text')} onClick={() => dispatch(redirect('/onboarding/letter'))}>
          Go
        </a>
        }
        {letter && letter.status === 'review' &&
        <span className={cn('label')}>SUBMITTED</span>
        }
        {letter && letter.status === 'draft' &&
        <span className={cn('label')}>DRAFT</span>
        }
        {letter && letter.status === 'approved' &&
        <span className={cn('icon', 'icon-check-2')}/>
        }
        {letter && letter.status === 'rejected' &&
        <a className={cn('action-text')} onClick={() => dispatch(redirect('/onboarding/letter'))}>
          <span className={cn('label')}>REVISION NEEDED</span>
        </a>
        }
      </div>
  );
};

const ItemDeadline = ({user, dueDate}) => {
  const check = user.backgroundCheck;
  const checkStarted = check && check.status && check.status !== 'failed';
  const letter = user.introLetter;
  const allPassed = (!user.backgroundCheckRequired || (check && check.status === 'passed')) && user.trainingIsComplete && letter && letter.status === 'approved';
  const allSubmitted = (!user.backgroundCheckRequired || checkStarted) && user.trainingIsComplete && letter && ['review', 'approved'].includes(letter.status);
  const dueDateFormatted = dueDate ? moment(dueDate).format('MMMM D') : null;

  return (
      <div className={cn('checklist-item')}>
        <div className={cn('text')}>
          <span className={cn('icon', 'icon-calendar')}/>
          {t('onboarding.overview.completeBy')} <b>{dueDateFormatted}</b>
        </div>
        {allPassed &&
        <span className={cn('icon', 'icon-check-2')}/>
        }
        {allSubmitted && moment(dueDate).isAfter(moment()) && false &&
        <span className={cn('icon', 'icon-check-tentative')}/>
        }
        {moment().isAfter(dueDate) && !allPassed &&
        <span className={cn('label', 'label-overdue')}>OVERDUE</span>
        }
      </div>
  );
};

const ItemMessage = ({user, tenant}) => {
  const check = user.backgroundCheck;
  const checkRequired = user.backgroundCheckRequired;
  const letter = user.introLetter;
  const allComplete = (!checkRequired || (check && check.status === 'passed' && !check.isExpired)) && user.trainingIsComplete && letter && letter.status === 'approved';
  const contactEmail = tenant.contactEmail;
  let message = '';
  if (allComplete) {
    message = t('onboarding.message.allComplete');
  } else if( checkRequired && !check ) {
    message = t('onboarding.message.start');
  } else if( checkRequired && check && check.isExpired ) {
    message = t('onboarding.message.expired');
  } else if (letter && letter.status === 'rejected') {
    message = t('onboarding.message.letterRejected');
  } else if( checkRequired && check && ['created', 'invited', 'failed', 'needsreview'].includes(check.status) && user.country === 'US') {
    message = t('onboarding.message.checkRequestedUS', {contactEmail});
  } else if( checkRequired && check && check.status === 'created' && user.country !== 'US') {
    message = t('onboarding.message.checkRequestedIntl');
  } else if( checkRequired && check && ['invited', 'failed', 'needsreview'].includes(check.status) && user.country !== 'US') {
    message = t('onboarding.message.checkInvitedIntl', {contactEmail});
  } else if( checkRequired && check && check.status === 'needsinfo') {
    message = t('onboarding.message.needsinfo', {contactEmail});
  } else if( checkRequired && check && check.status === 'cancelled') {
    message = t('onboarding.message.cancelled');
  } else if( (!checkRequired || (check && check.status === 'passed')) && letter && letter.status === 'review') {
    message = t('onboarding.message.letterSubmitted');
  } else if( (!checkRequired || (check && check.status === 'passed' && !check.isExpired)) && user.isReturningMentor) {
    message = t('onboarding.message.checkStillValid');
  }

  return (
      <div className={cn('message')}>
        {message}
      </div>
  );
};

class MentorOnboarding extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dueDate: null,
      showClassroomButton: false,
    }
  }

  componentDidMount() {
    let {user, tenant, sessions, classrooms, dispatch} = this.props;
    dispatch(usersActions.checkOnboardedStatus(user.id, (updatedUser) => {
      if (updatedUser) {
        user = UserHelpers.getTransformedUser(updatedUser, sessions, classrooms, tenant);
      }

      const serviceRecord = _.find(user.cm_services, ['service', TENANT]);
      const sessionsOnboarded = (serviceRecord.appState && serviceRecord.appState.onboarded) || {};
      const requiresOnboarding = sessions.filter((session) => {
        const onboarded = sessionsOnboarded[session.id];
        return session.status === 'active' && !onboarded;
      });
      let requiresMatching = [];
      if (classrooms.length > 0) {
        requiresMatching = classrooms.filter( x => !x.studentMentorGroups.find( smg => smg.mentor === user.id));
      }

      if (!user.emailVerified) {
        dispatch(redirect('/onboarding/verify'));
      } else if (requiresOnboarding.length === 0 && requiresMatching.length === 0 && classrooms.length > 0) {
        dispatch(redirect('/classroom'));
      }

      const farFutureDate = new Date(8640000000000000);
      const activeSessions = sessions.filter(x => x.status === 'active')
          .map((x) => {
            x.mentorLetterDue = new Date(x.mentorLetterDue);
            return x;
          });
      let dueDate = null;
      if (sessions.length > 0) {
        dueDate = activeSessions.reduce(((a, s) => s.mentorLetterDue < a ? s.mentorLetterDue : a), farFutureDate);
      }
      if (dueDate === farFutureDate || dueDate === null) {
        dueDate = null;
        console.warn('No due date specified for session');
      }

      const showClassroomButton = classrooms.find( classroom => {
        const isOnboarded = classroom.sessions.some( (sessionId) => user.onboarded[ sessionId ] );
        const isMatched = classroom.studentMentorGroups.find(smg => smg.mentor === user.id );
        return isOnboarded && isMatched;
      });

      this.setState({dueDate, showClassroomButton});
    }));
  }

  onClickGoToClassroom() {
    const {classrooms, user, dispatch} = this.props;
    if (classrooms && classrooms.length > 0) {
      const defaultClassroom = classrooms.find(x => x.studentMentorGroups.find( smg => smg.mentor === user.id));
      dispatch(classroomsActions.setClassroom(defaultClassroom.id, () => {
        dispatch(redirect('/classroom'));
      }));
    }
  }

  render() {
    const {user, sessions, tenant, dispatch} = this.props;
    const {dueDate} = this.state;

    if (sessions !== undefined && sessions.length === 0) {
      return (
          <div className={cn('onboarding-page')}>
            <div className={cn('container')}>
              {t('onboarding.nosession', {appName: tenant.name, contactEmail: tenant.contactEmail})}
            </div>
          </div>
      );
    }

    if (dueDate === null) {
      return null;
    }

    const dueDateFormatted = dueDate ? moment(dueDate).format('MMMM D') : null;
    return (
        <div className={cn('onboarding-page', 'onboarding-overview')}>
          <h2>{t('onboarding.overview.heading', {name: user.firstname})}</h2>
          <div className={cn('instructions')}>{t('onboarding.overview.message', {date: dueDateFormatted})}</div>
          <div className={cn('checklist-items')}>
            <h3>
              {t(user.isReturningMentor ? 'onboarding.overview.titleReturning' : 'onboarding.overview.titleNew')}
            </h3>
            {user.backgroundCheckRequired && <ItemBackgroundCheck user={user} dispatch={dispatch}/>}
            <ItemTraining user={user} dispatch={dispatch}/>
            <ItemLetter user={user} dispatch={dispatch}/>
            <ItemDeadline user={user} dueDate={dueDate} dispatch={dispatch}/>
            <ItemMessage user={user} dispatch={dispatch} tenant={tenant}/>
            {this.state.showClassroomButton &&
            <div className={cn('classroom-button')}>
              <Button onClick={(ev) => this.onClickGoToClassroom(ev)} type="regular">
                {t('onboarding.overview.classroomButton')}
              </Button>
            </div>
            }
          </div>
        </div>
    );
  }
}

MentorOnboarding.displayName = "MentorOnboarding";

MentorOnboarding.propTypes = {
  user: PropTypes.object,
};

module.exports = build({
  component: MentorOnboarding,
  mapStateToProps
});
