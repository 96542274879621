{makeAction} = require 'utils/action_helpers'
mailService = require '../services/mail_service.coffee'

module.exports =

  getResources: (classroomId, callback) -> makeAction (setState) ->
    mailService.getResources(classroomId)
    .then (res) ->
      setState 'mailHtml', data: res, loading: false, classroomId: classroomId
      callback?()
    .catch (error) ->
      setState 'mailHtml', error: error.statusText, loading: false, classroomId: undefined
