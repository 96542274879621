_ = require 'lodash'
moment = require 'moment'

getLettersForUser = (messages, userId) ->
  messages = messages
    .filter ({from, state}) ->
      from.id is userId and state not in ['draft']
    .map (m) ->
      if m.state is 'newMessageDraft'
        _.assign {}, m, status: 'draft'
      else if m.status is 'pending' and m.letterRejected?
        _.assign {}, m, status: 'revisionPending'
      else if m.status is 'approved' and m.letterRejected?
        _.assign {}, m, status: 'revisionApproved'  
      else
        m
    .sort (l, r) -> moment.utc(r.modified).diff(moment(l.modified))
  if messages.length is 0 and userId?
    [{status: 'missing'}]
  else messages


letterInBucket = (letterExchangeData, letter, offset) ->
  studentIndex = _.findIndex letterExchangeData, (item) -> letter.from?._id is item.student?._id
  mentorIndex = _.findIndex letterExchangeData, (item) -> letter.from?._id is item.mentor?._id

  letters = []
  if studentIndex > -1
    letters = letterExchangeData[studentIndex].studentLetters
  else if mentorIndex > -1
    letters = letterExchangeData[mentorIndex].mentorLetters

  letterIndex = _.findIndex letters, (item) -> item._id is letter._id
  letters[letterIndex + offset]


module.exports =

  formatLetterForModal: (letter) ->
    letter = Object.assign {}, letter
    letter.date = moment(letter.modified).format 'MMMM DD, YYYY'
    letter.from = letter.from?.displayName
    letter.to = letter.to?.displayName
    letter


  formatRejectionDate: (rejectDate) ->
    moment(rejectDate).format('MM/DD/YY hh:mma')


  letterIndexInBucket: (letterExchangeData, letter) ->
    studentIndex = _.findIndex letterExchangeData, (item) -> letter.from?._id is item.student?._id
    mentorIndex = _.findIndex letterExchangeData, (item) -> letter.from?._id is item.mentor?._id

    letters =
      if studentIndex > -1 then letterExchangeData[studentIndex].studentLetters
      else if mentorIndex > -1 then letters = letterExchangeData[mentorIndex].mentorLetters
      else []

    _.findIndex letters, (o) -> o._id is letter._id


  mergeClassroomWithArticleSelections: (classroom, classroomUnit, articleSelections) ->
    return [] if not classroomUnit or not articleSelections
    selections = []
    selectionIDs = []
    _.values(articleSelections)
      .forEach (selection) ->
        {classroomUnit_id, student_id} = selection
        if classroomUnit._id is classroomUnit_id and student_id not in selectionIDs
          student = classroom.students.filter ({_id}) -> _id is selection.student_id
          #TODO: ask for student key and value in articleSelection data model: #12 on spreadsheet
          if student[0]?
            selection.student = displayName: student[0]?.displayName
            selections.push selection
            selectionIDs.push selection.student_id

    missingStudentsArticleSelections = classroom.students
      .filter (student) ->
        student._id not in selectionIDs
      .map (student) ->
        article: null
        classroomUnitID: classroomUnit._id
        status: 'missing'
        student:
          _id: student._id
          displayName: student.displayName

    selections.concat missingStudentsArticleSelections


  mergeClassroomWithMessages: (classroom, messages) ->
    return [] unless classroom? and messages?

    messages = _.values messages
    mentors = _.keyBy classroom?.mentors, 'id'
    studentMentorGroups = classroom?.studentMentorGroups
    students = _.keyBy classroom?.students, 'id'

    studentMentorGroups
      .map (studentMentorGroup) ->
        mentor = mentors[studentMentorGroup['mentor']]
        student = students[studentMentorGroup['student']]

        mentor: mentor
        mentorLetters: getLettersForUser messages, mentor?._id
        student: student
        studentLetters: getLettersForUser messages, student?._id


  nextLetterInBucket: (letterExchangeData, letter) ->
    letterInBucket letterExchangeData, letter, 1


  numberOfLettersInBucket: (letterExchangeData, letter) ->
    studentIndex = _.findIndex letterExchangeData, (item) -> letter.from?._id is item.student?._id
    mentorIndex = _.findIndex letterExchangeData, (item) -> letter.from?._id is item.mentor?._id

    return letterExchangeData[studentIndex].studentLetters?.length if studentIndex > -1
    return letterExchangeData[mentorIndex].mentorLetters?.length if mentorIndex > -1
    0


  previousLetterInBucket: (letterExchangeData, letter) ->
    letterInBucket letterExchangeData, letter, -1
