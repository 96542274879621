({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
_ = require 'lodash'
{build} = require 'utils/container_helpers'
{addInDates, validateDates} = require './helpers'
events = require '../units/events'
routingActions = require 'actions/routing'
unitPreviewActions = require 'actions/unit_preview'
classroomsActions = require 'actions/classrooms'
classroomUnitsActions = require 'actions/classroom_units'
messagesActions = require 'actions/messages'
moment = require 'moment'
UnitPreviewBody = createFactory require './unit_preview_body.jsx'
ContentLoaderContainer = createFactory require 'containers/content_loader_container'

_makeDate = (value) -> if value? then moment value else undefined

mapStateToProps = ({store: {classroom}}) -> {classroom: classroom.data}

UnitPreviewEdit = (props) ->
  {classroom, params, dispatch} = props
  classroomUnitId = params.classroom_unit_id or classroom.currentUnit._id

  ContentLoaderContainer
    getContentFromStore: ({classroom, classroomUnit}) -> {classroom, classroomUnit}
    getError: ({classroom, classroomUnit}) -> classroom.error or classroomUnit.error
    getLoading: ({classroom, classroomUnit}) -> classroom.error or classroomUnit.loading
    getReady: ({classroom, classroomUnit}) ->
      classroomUnit.data? and classroomUnit.data._id is classroomUnitId
    loadContent: -> dispatch classroomUnitsActions.find classroomUnitId
    name: 'classroom unit'
    renderChild: ({classroom, classroomUnit}) ->
      template_id = classroomUnit.data.parent

      ContentLoaderContainer
        getContentFromStore: ({articleSelections, unitTemplate}) -> {articleSelections, unitTemplate}
        getError: ({articleSelections, unitTemplate}) -> unitTemplate.error
        getLoading: ({articleSelections, unitTemplate}) -> unitTemplate.loading
        getReady: ({articleSelections, unitTemplate}) -> unitTemplate.data? and unitTemplate.data._id is template_id
        loadContent: ->
          dispatch unitPreviewActions.find template_id
        name: 'unit'
        renderChild: ({articleSelections, unitTemplate}) ->
          classroomIsActive = classroom.data.status is 'active'
          selectedArticleIds = _.values(articleSelections.data or {})
            .filter ({classroomUnit_id, status}) ->
              classroomUnit_id is classroomUnit.data.id and status in ['pending', 'approved']
            .map ({article_id}) -> article_id

          UnitPreviewBody _.assign {}, props,
            allowAttachments: classroomUnit.data.allowAttachments
            buttonContent: t 'unitPreviewEdit.saveUnit'
            buttonIsEnabled: ({articleMap, dates, isDefaultUnit, isReflectionUnit}) ->
              classroomIsActive and validateDates(dates) and
                ((_.filter(_.values articleMap).length > 0) or isDefaultUnit or isReflectionUnit)
            buttonOnClickWithState: ({allowAttachments, articleMap, dates}) ->
              return if classroomUnit.loading
              payload = _.assign {}, classroomUnit.data,
                allowAttachments: allowAttachments
                content: _.filter _.values articleMap
              addInDates payload, dates
              dispatch classroomUnitsActions.update payload, ->
                events.savedUnit undefined, classroomUnit.data.state
                dispatch classroomsActions.loadCurrentClassroom ->
                  dispatch classroomUnitsActions.setLocked no, classroomUnit.data.id
                  dispatch routingActions.redirect '/units'
            classroomUnit: classroomUnit.data
            datePickerIsEditable: classroomIsActive
            datePickerShouldOpenFirstPicker: false
            datePickerShouldShow: true
            datePickerShouldStartWithToday: true
            descriptionHeader: t 'unitPreviewEdit.chooseArticles'
            descriptionText: t 'unitPreviewEdit.description'
            displayLink: classroomUnit.data.state is 'pending'
            linkContent: t 'unitPreviewEdit.deleteUnit'
            linkOnClick: ->
              events.deletedUnit()
              dispatch classroomUnitsActions.delete classroomUnit.data.id, ->
                dispatch routingActions.redirect '/units'
            selectedArticleIds: selectedArticleIds
            showChecks: classroomIsActive
            state: classroomUnit.data.state
            unitTemplate: unitTemplate.data

UnitPreviewEdit.displayName = "UnitPreviewEdit"

module.exports = build {
  component: UnitPreviewEdit
  mapStateToProps
}
