import getAppName from 'utils/app_name'

events = require 'pages/shared/utils/events'
letterEvents = require 'pages/shared/letter_center/events'
usersActions = require 'actions/users'

welcomeVideoIdDefault =  '1_2xozh7y9'
allAboutMeLinkDefault = 'https://cm-cdn.s3.amazonaws.com/crickettogether/units/GettingStarted/resources/student' +
      '/CT-AllAboutMeLetters-student.pdf'

welcomeVideoCoverDefault = 'https://cm-cdn.s3.amazonaws.com/crickettogether/images/Cricket-Together' +
  '-still2-retina.png'
module.exports = ({dispatch, userId, classroom, userState, tenant}) ->

  updateAppState = (appState) ->
    dispatch usersActions.updateAppState {id: userId, classroomId: classroom._id, appState}

  palName = (prefix = "your ") -> userState.pal?.firstname or t 'home.defaultPalName', prefix: prefix

  actionItems: [
    active: ({introUnit, initialSurveyComplete, initialSurveyId}) ->
      introUnit and not initialSurveyComplete and initialSurveyId
    icon: 'write-letter'
    redirect: "/survey/#{classroom.initialSurveyIdStudent}/#{classroom._id}/#{userId}?type=initial"
    text: t 'actionItems.student.action1.text', appName: getAppName(tenant.data, classroom)
  ,
    active: ({introUnit, trainingVideosViewed}) ->
      introUnit and not trainingVideosViewed
    event: -> events.clickedVideo 'action item'
    icon: 'watch-video'
    text: t 'actionItems.student.action2.text'
    redirect: '/training'
  ,
    active: ({introUnit, sampleLettersRead}) ->
      introUnit and not sampleLettersRead #not sentLetter
    done: -> updateAppState sampleLettersRead: true
    icon: 'read-letter'
    link: tenant.data.student?.allAboutMe ? allAboutMeLinkDefault
    text: t 'actionItems.student.action3.text'
  ,
    active: ({introUnit, receivedLetterApproved, receivedMultipleLettersApproved, displayUnreadLetterAction}) ->
      introUnit and receivedLetterApproved and not receivedMultipleLettersApproved and displayUnreadLetterAction
    icon: 'read-letter'
    redirect: '/letters'  # TODO "with letter open"
    text: t 'actionItems.student.action4.text', palName: palName()
  ,
    active: ({introUnit, receivedMultipleLettersApproved, displayUnreadLetterAction}) ->
      introUnit and receivedMultipleLettersApproved and displayUnreadLetterAction
    icon: 'read-letter'
    redirect: '/letters'
    text: t 'actionItems.student.action5.text', palName: palName()
  ,
    active: ({receivedLetterApproved, introUnit, sentLetter}) ->
      introUnit and receivedLetterApproved and not sentLetter
    icon: 'write-letter'
    redirect: '/letters'
    text: t 'actionItems.student.action6.text', palName: palName()
  ,
    active: ({introUnit, sentLetterRejected}) ->
      introUnit and sentLetterRejected
    icon: 'write-letter'
    redirect: '/letters/compose'
    text: t 'actionItems.student.action7.text'
  ,
    active: ({articleSelected, hasFocusArticle}) -> hasFocusArticle and not articleSelected
    icon: 'article'
    redirect: '/units'
    text: t 'actionItems.student.action8.text', palName: palName()
  ,
    active: ({introUnit, unitVideoViewed, currentUnit}) ->
      not introUnit and currentUnit.unitVideoStudent?.id and not unitVideoViewed
    event: -> events.clickedVideo 'action item'
    done: -> updateAppState {"#{classroom?.currentUnit?.id}": {unitVideoViewed: true}}
    icon: 'watch-video'
    text: t 'actionItems.student.action9.text', unitVideoDesc: classroom?.currentUnit?.unitVideoStudent?.description ? 'Watch a video'
    video: classroom?.currentUnit?.unitVideoStudent?.id
  ,
    active: ({displayUnreadLetterAction, introUnit, receivedLetterApproved}) ->
      not introUnit and displayUnreadLetterAction
    icon: 'read-letter'
    redirect: '/letters'  # TODO "with letter open"
    text: t 'actionItems.student.action10.text', palName: palName()
  ,
    active: ({prevUnitDraft, palNeedsReply, introUnit, receivedLetterApproved}) ->
      not prevUnitDraft and not introUnit and palNeedsReply
    icon: 'write-letter'
    redirect: '/letters/compose'
    text: t 'actionItems.student.action11.text', palName: palName()
  ,
    active: ({prevUnitDraft, palNeedsReply, introUnit, receivedLetterApproved}) ->
      prevUnitDraft and not introUnit
    icon: 'write-letter'
    redirect: '/letters'
    text: t 'actionItems.student.action12.text'
  ,
    active: ({articleSelectionRejected, introUnit}) ->
      articleSelectionRejected and not introUnit
    icon: 'article'
    redirect: '/units'  # TODO "with teacher comment" - pending CKLT-237
    text: t 'actionItems.student.action13.text'
  ,
    active: ({introUnit, sentLetterRejected}) ->
      sentLetterRejected and not introUnit
    icon: 'write-letter'
    redirect: '/letters/compose'
    text: t 'actionItems.student.action14.text'
  ,
    active: ({currentUnit, finalSurveyComplete, finalSurveyId}) ->
      currentUnit.isReflectionUnit and not finalSurveyComplete and finalSurveyId
    icon: 'write-letter'
    redirect: "/survey/#{classroom.finalSurveyIdStudent}/#{classroom._id}/#{userId}?type=final"
    text: t 'actionItems.student.action15.text', appName: getAppName(tenant.data, classroom)

  ]

  topContents: [
    # keeping this stuff in here for now commented out for now in case we
    # want to reintroduce some of these states or variations of them...
    #
    # "Intro State 1"
    #action: 'Watch This Video And Find Out More!'
    active: ({introUnit}) ->
      introUnit
    description: t 'topContents.student.top1.unitDesc', appName: getAppName(tenant.data, classroom), palName: palName()
    event: -> events.clickedVideo 'top card'
    hideFlag: yes
    image: tenant.data.student?.welcomeVideoCover ? welcomeVideoCoverDefault
    video: 'training' #tenant.data.student?.welcomeVideoId ? welcomeVideoIdDefault
    welcome: t 'topContents.student.top1.welcome', appName: getAppName(tenant.data, classroom), palName: palName()
  ,
    # "Intro State 2"
    #action: 'Choose Your Focus Article'
    active: ({introUnit, sentLetter}) ->
      introUnit and sentLetter
    #redirect: '/units'
    welcome: t 'topContents.student.top2.welcome'
    hideDate: yes
  ,
    # "Intro State 3"
    #action: 'Read Your Letter'
    active: ({introUnit, receivedLetterApproved, sentLetter}) ->
      introUnit and sentLetter and receivedLetterApproved
    #redirect: '/letters'
    welcome: t 'topContents.student.top3.welcome', palName: palName()
    hideDate: yes
  ,
    active: ({introUnit, receivedLetterApproved, sentMultipleLetters}) ->
      introUnit and sentMultipleLetters and receivedLetterApproved
    welcome: t 'topContents.student.top4.welcome', palName: palName()
    hideDate: yes
  ,
    # "Non-Intro State 1"
    #action: 'Choose Focus Article'
    active: ({hasFocusArticle}) -> hasFocusArticle
    #redirect: '/units'
    welcome: t 'topContents.student.top5.welcome', palName: palName()
    hideDate: yes
  ,
    # "Non-Intro State 2"
    #action: 'Read Your Focus Article'
    active: ({articleSelectionApproved, introUnit}) ->
      articleSelectionApproved and not introUnit
    #redirect: '/units'
    welcome: t 'topContents.student.top6.welcome', palName: palName()
    hideDate: yes
  ,
    # "Non-Intro State 3"
    active: ({articleSelectionApproved, introUnit, sentLetter, receivedLetterApproved}) ->
      articleSelectionApproved and (sentLetter or receivedLetterApproved) and not introUnit
    #redirect: '/letters'
    welcome: t 'topContents.student.top7.welcome'
    hideDate: yes
  ,
    # "Non-Intro State 4"
    #action: 'Read Your Letter'
    active: ({articleSelectionApproved, introUnit, receivedLetterApproved, displayUnreadLetterAction}) ->
        not introUnit and displayUnreadLetterAction
        #redirect: '/letters'
    welcome: t 'topContents.student.top8.welcome', palName: palName()
    hideDate: yes
  ,
    active: ({sentLetterRejected}) ->
        sentLetterRejected
    welcome: t 'topContents.student.top9.welcome'
    hideDate: yes
  ,
    # "Completed State 1"
    active: ({classroomStatusIsCompleted}) -> classroomStatusIsCompleted
    hideFlag: yes
    image: 'http://cm-cdn.s3.amazonaws.com/crickettogether/images/CT_completed.jpg'
    welcome: t 'topContents.student.top10.welcome', appName: getAppName(tenant.data, classroom)
  ]
