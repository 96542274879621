{Component} = require 'react'
PropTypes = require 'prop-types'
{arrayOf, bool, element, number, oneOfType} = PropTypes
style = require './carousel.styl'
e = (require 'react-e/bind') style

class Carousel extends Component

  constructor: (props) ->
    super(props)
    @state = position: @_getInitialPosition()


  @propTypes =
    children: arrayOf(element).isRequired
    scrollPosition: oneOfType [bool, number]


  componentDidMount: ->
    @setState position: @_getInitialPosition()
    @carouselContainer.scrollLeft = @_positionToScroll()
    @forceUpdate()


  componentDidUpdate: (prevState) ->
    if prevState.position isnt @state.position
      @carouselContainer.scrollLeft = @_positionToScroll()


  _canScroll: ->
    @carouselContainer?.scrollWidth > @carouselContainer?.offsetWidth


  _getInitialPosition: ->
    if typeof @props.scrollPosition is 'number'
      @props.scrollPosition
    else if @props.scrollPosition
      numCardsShowing = @_getNumCardsShowing()
      halfCards = Math.floor(@props.children.length / 2)
      if numCardsShowing then halfCards - Math.floor(numCardsShowing / 2)
      else halfCards
    else 0


  _getNumCardsShowing: ->
    cardWidth = @carouselContainer?.scrollWidth / @props.children.length
    Math.floor(@carouselContainer?.offsetWidth / cardWidth)


  _hideArrow: (direction) ->
    if direction is 'left'
      @state.position is 0
    else
      @state.position >= (@props.children.length - @_getNumCardsShowing())


  _positionToScroll: ->
    percent = @state.position / @props.children.length
    @carouselContainer.scrollWidth * percent


  _shift: (direction) ->
    offset = if direction is 'right' then 1 else -1
    @setState position: @state.position + offset


  render: ->
    {children} = @props
    e '.carousel-container',
      e '.left.arrow',
        className: hide: @_hideArrow 'left'
        onClick: => @_shift 'left'
      e '.content-container',
        className: shadow: @_canScroll()
        ref: (@carouselContainer) =>
        e '.carousel', children...
      e '.right.arrow',
        className: hide: @_hideArrow 'right'
        onClick: => @_shift 'right'

Carousel.displayName = "Carousel"

module.exports = Carousel
