api = require '../api'
{makeAction} = require 'utils/action_helpers.coffee'
usersService = require 'services/users_service.coffee'
tenantsAction =  require 'actions/tenant.coffee'
{redirect} = require 'actions/routing'

module.exports =

  clearError: -> makeAction (setState) ->
    setState 'session', error: undefined


  fetchLocalToken: -> makeAction (setState) ->
    return unless localStorage.cricketTogetherSession
    setState 'session', data: JSON.parse(localStorage.cricketTogetherSession), loading: no

  impersonate: (credentials, impersonateUsername, callback) -> makeAction (setState) ->
    setState 'session', loading: yes
    usersService.impersonate credentials, impersonateUsername
      .then ({user}) ->
        sessionData =
          impersonate: impersonateUsername
          login: user
        setState 'session', data: sessionData, loading: no
        localStorage.setItem 'cricketTogetherSession', JSON.stringify sessionData
        callback?()
      .catch (error) ->
        setState 'session', {error} , loading: no

  login: (credentials, callback) -> makeAction (setState, dispatch) ->
    setState 'session', loading: yes
    usersService.login credentials
      .then ({user}) ->
        if 'admin' in (user?.roles or [])
          return setState 'session', error: {statusText: t('login.adminAccess')}, loading: no
        sessionData = login: user
        setState 'session', data: sessionData, loading: no
        localStorage.setItem 'cricketTogetherSession', JSON.stringify sessionData
#        console.log('logged in as ', user);
        usersService.find user.id
          .then () ->
            dispatch tenantsAction.fetch ->
              callback? {role: user.roles[0], userId: user.id}
            ,
            false
      .catch (error) ->
        setState 'session', {error} , loading: no

  logout: (callback) -> makeAction (setState) ->
    localStorage.clear()
    setState '', {}, clearState: yes
    callback?()


  set: (sessionData, callback) -> makeAction (setState) ->
    localStorage.setItem 'cricketTogetherSession', JSON.stringify sessionData
    setState 'session', data: sessionData
    callback?()
