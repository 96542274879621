analytics = require 'utils/analytics'

CATEGORY =
  current: 'Current Unit - Letter Exchange'
  focusArticles: 'Current Unit - Focus Articles'
  past: 'Past Unit - Letter Exchange'


FOCUS_ARTICLE_ACTIONS =
  approved: 'FA Approved'
  canceledRejection: 'Canceled FA Rejection'
  clickedRejected: "Clicked 'Rejected' on FA"
  rejected: 'FA Rejected'


LETTER_EXCHANGE_ACTIONS =
  approved: 'Approved Letter'
  canceledRejection: 'Canceled Letter Rejection'
  clickedRevision: "Clicked 'Ask for Revision' on Letter"
  print: 'Print Letter'
  rejected: 'Letter Rejected'
  reviewed: 'Reviewed Letter'
  viewed: 'Viewed Letter'
  viewedActionItem: 'Viewed Pending Past Letters - Action Item'
  viewedCard: 'Viewed Pending Past Letters - Past Unit Card'


focusArticle = _.mapValues FOCUS_ARTICLE_ACTIONS, (action) -> ({article, value}) ->
  analytics.recordEvent
    action: action
    category: CATEGORY.focusArticles
    label: article
    value: value


letterExchange = _.mapValues LETTER_EXCHANGE_ACTIONS, (action) -> ({role, unit, value}) ->
  analytics.recordEvent
    action: if role? then "#{action} - #{role}" else action
    category: CATEGORY[unit]
    value: value


module.exports = {
  focusArticle
  letterExchange
}
