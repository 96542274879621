{build} = require 'utils/container_helpers'
e = require 'react-e'
{redirect} = require 'actions/routing'

Link = ({children, dispatch, href, onClick, to}) ->
  e 'a',
    href: to or href
    onClick: (e) ->
      e.preventDefault()
      if onClick? then onClick()
      if to?
        dispatch redirect to
    children

Link.displayName = "Link" # another component has this name...
module.exports = build
  component: Link
