{Component, createFactory} = require 'react'
{build} = require 'utils/container_helpers'
{redirect} = require 'actions/routing'
_ = require 'lodash'
moment = require 'moment'
articleSelectionActions = require 'actions/article_selections'
messagesActions = require 'actions/messages'
usersActions = require 'actions/users'

ContentLoaderContainer = createFactory require 'containers/content_loader_container'
Home = createFactory require 'components/home'

getLocalizationData = require './localization_helper'
UserHelpers = require('utils/user_helpers').default

actionItems = topContents = null

mapStateToProps = ({store: {classroom, classrooms, messages, session, tenant}}) ->
  {classroom, classrooms, messages, session, tenant}

class StudentHome extends Component

  render: ->
    {classroom, dispatch, messages, session, tenant} = @props
    {currentUnit} = classroom.data
    userId = session.data.login.id
    classroomId = classroom.data._id
    firstName = session.data.login.firstname
    mentorId = classroom.data.studentMentorGroups.find ({student}) -> student is userId
      ?.mentor

    userState = UserHelpers.getTransformedUser session.data.login, null, classroom.data, tenant.data

    {actionItems, topContents} = getLocalizationData {dispatch, userId, classroom: classroom.data, userState, tenant}

    ContentLoaderContainer
      getContentFromStore: ({articleSelections, messages, user}) ->
        {articleSelections, messages, user}
      getError: ({articleSelections, messages, user}) ->
        articleSelections.error or messages.error or user.error
      getLoading: ({articleSelections, messages, user}) ->
        if articleSelections.error or messages.error or user.error then true else false
      getReady: ({articleSelections, messages, user}) ->
        articleSelections.data? and messages.data? and (user.data?.id is userId)
      loadContent: ->
        dispatch articleSelectionActions.fetch
          student_id: userId
        dispatch messagesActions.fetchUserClassroomMessages classroom.data, true
        dispatch usersActions.find userId
      name: 'article selections, messages and user'
      renderChild: ({articleSelections, messages, user}) =>
        Home
          actionItems: actionItems
          classroom: @props.classroom
          context: @_getContext {articleSelections, mentorId, messages, user}
          currentUnit: currentUnit
          dispatchRedirect: (path) -> dispatch redirect path
          firstName: firstName
          palFirstName: userState.pal?.firstname
          footerCards: []
          progress:
            articleSelections: articleSelections.data
            id: userId
            messages: messages.data
          role: 'student'
          showOwl: yes
          # don't display "real" topContent data until further notice...
          topContents: topContents
          unitPage: '/units'



  _convertToTimestamp: (value) ->
    defaultTimeStamp = moment '2016-01-01T00:00:00.000Z'
    if value then moment(value) else defaultTimeStamp


  _getContext: ({articleSelections, mentorId, messages, user}) ->
    {classroom, classrooms, session, tenant} = @props

    {currentUnit} = classroom.data
    userId = session.data.login.id

    #prevUnitIndex = classroom.data.units.map ({id}) -> id
      #.indexOf(currentUnit.id) - 1
    #prevUnit = classroom.data.units[prevUnitIndex]

    articleSelections = _.values articleSelections.data
      .filter ({classroomUnit_id}) -> classroomUnit_id is currentUnit.id

    allMessages = _.values messages.data
    messages = allMessages.filter ({classroomUnitID, state}) -> classroomUnitID is currentUnit.id and state not in ['draft','newMessageDraft']

    prevUnitDraft = allMessages.find ({classroomUnitID,state,from}) -> from?.id is userId and classroomUnitID isnt currentUnit.id and state is 'newMessageDraft'

    sentMessages = messages.filter ({from}) -> from?.id is userId
    allSentMessages = allMessages.filter ({from}) -> from?.id is userId
    receivedMessages = messages.filter ({to}) -> to?.id is userId

    receivedMessagesApproved = receivedMessages.filter ({status}) -> status is 'approved'
    allReceivedMessagesApproved = allMessages.filter ({status, to}) -> to?.id is userId and status is 'approved'

    nonDraftSent = allSentMessages.filter ({status}) -> status in ['approved', 'pending', 'rejected']
    palReceived = allMessages.filter ({from: {id}, status}) -> id is mentorId and status is 'approved'

    myLastSent = _.maxBy nonDraftSent, 'letterSent'
    palLastSent = _.maxBy palReceived, 'letterApproved'

    classroomId = classroom.data._id
    appState = user.data?.appState?[classroomId] ? {}

    userState = UserHelpers.getTransformedUser user.data, null, classroom.data, tenant.data

    articleSelected:
      articleSelections.length > 0
    articleSelectionApproved:
      articleSelections.some ({status}) -> status is 'approved'
    articleSelectionRejected:
      articleSelections.some ({status}) -> status is 'rejected'
    classroomStatusIsCompleted: classroom.data.status is 'completed'
    displayUnreadLetterAction:
      allReceivedMessagesApproved.some ({state}) -> state isnt 'read'
    palNeedsReply:
      @_convertToTimestamp(myLastSent?.letterSent) < @_convertToTimestamp(palLastSent?.letterSent)
    introUnit:
      currentUnit.isDefaultUnit
    hasFocusArticle:
      not currentUnit.isDefaultUnit and not currentUnit.isReflectionUnit
    currentUnit: currentUnit
    prevUnitDraft:
      prevUnitDraft?
    receivedLetterApproved:
      receivedMessagesApproved.length > 0
    receivedMultipleLettersApproved:
      receivedMessagesApproved.length > 1
    sentLetter:
      sentMessages.length > 0
    sentMultipleLetters:
      sentMessages.length > 1
    sentLetterRejected:
      allSentMessages.some ({status}) -> status is 'rejected'
    sampleLettersRead:
      appState.sampleLettersRead is true
    welcomeVideoViewed:
      appState.welcomeVideoViewed is true
    initialSurveyComplete:
      appState.initialSurveyComplete is true
    initialSurveyId:
      classroom.data.initialSurveyIdStudent
    finalSurveyComplete:
      appState.finalSurveyComplete is true
    finalSurveyId:
      classroom.data.finalSurveyIdStudent
    unitVideoViewed:
      appState[currentUnit.id]?.unitVideoViewed is true
    trainingVideosViewed:
      userState.trainingIsComplete

StudentHome.displayName = "StudentHome"

module.exports = build {
  component: StudentHome
  mapStateToProps
}
